import { useContext, useEffect, useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, IconButton, TextField, Tooltip, Container } from "@mui/material";
import {
  Close,
  Edit,
  Done,
  Add,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import { ToggleButton } from "@mui/lab";
import { COLORS } from "../../../helpers/Colors";
import {
  actionNotAllowedDemo,
  actionNotAllowedGeneral,
} from "../../../helpers/constants";
import { BranchHeaderContext } from "../../../contexts/BranchHeaderContext";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import LoadingPage from "../../../layout/LoadingPage";
import { createIssue, editIssue } from "../api";

const useStyles = makeStyles({
  container: {
    padding: "30px",
  },
  newTabLink: {
    padding: "6px 16px",
    paddingLeft: "6px",
    color: COLORS.black,
  },
  body: {
    maxWidth: "90vw",
    margin: "auto",
  },
  TableContainer: {
    overflow: "auto",
    border: `1px solid ${COLORS.meliorYellowGentle} `,
    maxHeight: "600px",
  },
  table: {
    minWidth: "650px",
    overflow: "auto",
  },
  fixedWidth: {
    minWidth: "160px",
    maxWidth: "160px",
  },
  warning: {
    color: COLORS.failRed,
  },
  safe: {
    color: COLORS.successGreen,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.silver,
    },
  },
  toggleIcon: {
    width: "35px",
    height: "auto",
  },
  toggleIconOn: {
    width: "35px",
    height: "auto",
    color: COLORS.successGreen,
  },
  toggleIconOff: {
    width: "35px",
    height: "auto",
    color: COLORS.greyDisabled,
  },
});

const AddIcon = withStyles({
  root: (props) => ({
    color: COLORS.white,
    fontSize: "30px",
    background: props.disabled ? COLORS.greyDisabled : COLORS.meliorYellow,
    borderRadius: "50%",
  }),
})(Add);

const ToggleIssueAvailabilityButton = withStyles({
  root: (props) => ({
    border: "none",
    background: props.disabled
      ? `${COLORS.greyDisabled} !important`
      : "transparent !important",
    padding: "0 !important",
  }),
  selected: {
    backgroundColor: "transparent !important",
    color: `${COLORS.successGreen} !important`,
  },
})(ToggleButton);

const TableHeader = ({
  showActions = true,
  setNew,
  setEdited,
  isMax = false,
  isDemo = false,
  disableEdits = false,
  isFacilityManager,
}) => (
  <TableHead>
    <TableRow
      style={{ borderBottom: `2px solid ${COLORS.meliorYellowGentle} ` }}
    >
      <TableCell style={{ fontSize: "1.1rem", fontFamily: "Avenir-Heavy" }}>
        Issue Name
      </TableCell>
      <TableCell
        style={{
          fontSize: "1.1rem",
          fontFamily: "Avenir-Heavy",
          marginRight: "15px",
        }}
        align="right"
      >
        <p style={{ marginRight: "10px" }}>
          {(showActions && isFacilityManager) || isDemo ? `Actions` : `Active`}
        </p>
      </TableCell>
      {!((showActions && isFacilityManager) || isDemo) ? null : (
        <TableCell align="right" style={{ width: "30px" }}>
          <Tooltip
            title={
              isDemo
                ? actionNotAllowedDemo
                : disableEdits
                ? actionNotAllowedGeneral
                : `New Issue`
            }
            position="top"
          >
            <span>
              <IconButton
                disabled={disableEdits}
                onClick={() => {
                  setEdited({ active: -1, name: "" });
                  setNew({ name: "", active: true });
                }}
                size="large"
              >
                <AddIcon disabled={isMax || disableEdits} />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  </TableHead>
);

const NewEntityRow = ({ setNewIssue, newIssue, classes, createNewEntity }) => {
  const newFunction = setNewIssue;
  const newValue = newIssue;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          type="name"
          id="issue_name"
          value={newValue.name}
          onChange={(e) => newFunction({ ...newValue, name: e.target.value })}
        />
      </TableCell>

      <TableCell align="right">
        <Tooltip title={`Create Issue`} position="top">
          <IconButton
            className={classes.safe}
            onClick={() => createNewEntity()}
            size="large"
          >
            <Done />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel" position="top">
          <IconButton
            onClick={() => newFunction({ name: "", active: false })}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell style={{ width: "10px" }} />
    </TableRow>
  );
};

const Row = ({
  row,
  setEditedIssue,
  editedIssue,
  setNewIssue,
  classes,
  isFacilityManager,
  confirmEdits,
  disableEdits,
  isDemo,
}) => {
  const editFunction = setEditedIssue;
  const editedValue = editedIssue;
  const newFunction = setNewIssue;

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.fixedWidth} component="th" scope="row">
        {parseInt(editedValue.acive) === row.id ? (
          <TextField
            type="name"
            id="issue_name_edit"
            value={editedValue.name}
            onChange={(e) =>
              editFunction({ ...editedValue, name: e.target.value })
            }
          />
        ) : (
          row.name
        )}
      </TableCell>

      {!isFacilityManager && !isDemo ? (
        <TableCell align="right" className={classes.fixedWidth}>
          {row.available ? (
            <ToggleOn className={classes.toggleIconOn} />
          ) : (
            <ToggleOff className={classes.toggleIconOff} />
          )}
        </TableCell>
      ) : (
        <TableCell align="right" className={classes.fixedWidth}>
          {/* ACCESS 
          facility manager  */}
          {parseInt(editedValue.acive) !== row.id ? (
            <Tooltip
              title={
                isDemo
                  ? actionNotAllowedDemo
                  : disableEdits
                  ? actionNotAllowedGeneral
                  : `Edit Issue`
              }
              position="top"
            >
              <span>
                <IconButton
                  disabled={disableEdits || isDemo}
                  onClick={() => {
                    editFunction({ acive: row.id, name: row.name });
                    newFunction({ acive: false, name: "" });
                  }}
                  size="large"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Save" position="top">
                <IconButton
                  className={classes.safe}
                  onClick={() => confirmEdits({ row: row })}
                  size="large"
                >
                  <Done />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel" position="top">
                <IconButton
                  onClick={() => {
                    editFunction({ active: -1, name: "" });
                  }}
                  size="large"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          )}

          {/* ACCESS 
          facility manager*/}
          {!(isFacilityManager || isDemo) ? null : (
            <Tooltip
              title={isDemo ? actionNotAllowedDemo : `Toggle issue activity`}
              position="top"
            >
              <span>
                <ToggleIssueAvailabilityButton
                  selected={row.available}
                  value={row.available}
                  disabled={disableEdits || isDemo}
                  onChange={() =>
                    confirmEdits({
                      row: row,
                      editedValue: { ...row, available: !row.available },
                    })
                  }
                >
                  {row.available ? (
                    <ToggleOn className={classes.toggleIcon} />
                  ) : (
                    <ToggleOff className={classes.toggleIcon} />
                  )}
                </ToggleIssueAvailabilityButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
      )}

      {!isFacilityManager && !isDemo ? null : (
        <TableCell style={{ width: "10px" }} />
      )}
    </TableRow>
  );
};

export const IssuesAdminPage = ({ disableEdits = false }) => {
  const classes = useStyles();

  const [error, setError] = useState(false);

  const { issues, getIssues } = useContext(BranchHeaderContext);

  const { user, isDemo, isFacilityManager } = useContext(AuthenticationContext);

  const [editedIssue, setEditedIssue] = useState({
    active: -1,
    name: "",
    available: true,
  });

  const [newIssue, setNewIssue] = useState({
    active: false,
    name: "",
    available: true,
  });

  useEffect(() => {
    if (!user) return;
    if (issues) {
      setError({ isError: false });
    } else getIssues();
  }, [user]);

  const confirmEdits = ({ row, editedValue }) => {
    const value = editedValue || editedIssue;
    const editFunction = setEditedIssue;
    if (
      value.name.trim() === "" ||
      (value.name.trim() === row.name && value.available === row.available)
    ) {
      editFunction({ active: -1, name: "" });
      return;
    }

    editIssue({
      id: row.id,
      newIssue: value,
    })
      .then(() => {
        editFunction({ active: -1, name: "", available: true });
        getIssues({ hardRefresh: true });
        setError({ isError: false });
      })
      .catch(() => {
        setError({
          isError: true,
          errorMessage: "Network Error, please try again later",
        });
      });
  };

  const createNewEntity = () => {
    const newValue = newIssue;
    const newFunction = setNewIssue;

    if (newValue.name.trim() === "") {
      setError({
        isError: true,
        errorMessage: "You can't create an issue with no name",
      });
      return;
    }

    createIssue(newIssue)
      .then(() => {
        getIssues({ hardRefresh: true });
        newFunction({ active: false, name: "", available: true });
        setError({ isError: false });
      })
      .catch((error) => {
        setError({
          isError: true,
          errorMessage: error.response.data.detail,
        });
      });
  };

  return (
    <Container className={classes.container} maxWidth="xl">
      <Grid className={classes.body}>
        <Grid container justifyContent="center">
          {!error.isError ? null : (
            <UserErrorSuccessFormDisplay
              color={COLORS.white}
              type="error"
              message={error.errorMessage}
              width="90%"
              margin="10px auto"
              padding="15px 15px"
              bg={COLORS.failRed}
            />
          )}
          {!issues ? (
            <div>
              <LoadingPage height="70vh" />
            </div>
          ) : (
            <TableContainer className={classes.TableContainer}>
              {/*           
            Issues TABLE
          */}
              <Table
                className={classes.table}
                size="small"
                aria-label="a dense table"
              >
                <TableHeader
                  showActions={true}
                  setNew={setNewIssue}
                  setEdited={setEditedIssue}
                  disableEdits={disableEdits}
                  isFacilityManager={isFacilityManager}
                  isDemo={isDemo}
                />
                <TableBody>
                  {!newIssue.active ? null : (
                    <NewEntityRow
                      type="Branch"
                      setNewIssue={setNewIssue}
                      newIssue={newIssue}
                      classes={classes}
                      createNewEntity={createNewEntity}
                    />
                  )}
                  {issues.map((row, index) => (
                    <Row
                      key={row.id}
                      index={index}
                      row={row}
                      setEditedIssue={setEditedIssue}
                      editedIssue={editedIssue}
                      setNewIssue={setNewIssue}
                      classes={classes}
                      confirmEdits={confirmEdits}
                      disableEdits={disableEdits}
                      isDemo={isDemo}
                      isFacilityManager={isFacilityManager}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Grid>
      </Grid>
    </Container>
  );
};
