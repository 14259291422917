import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getReputationResponseActivityLogs = ({ reputationResponseID }) => {
  const requestURL = `${API_URL}/reputation/${reputationResponseID}/internal-actions`;

  return melior.get(requestURL);
};

export const useGetReputationResponseActivityLogs = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["reputation-response-activity-logs", params],
    queryFn: () => getReputationResponseActivityLogs(params),
    keepPreviousData: true,
  });
};
