import { Box, Skeleton } from "@mui/material";
import { renderReadableMilliseconds } from "../../../helpers/timeHelpers";
import {
  CARD_VARIANTS,
  RawNumberCard,
} from "../../tabbed-landing-page/components/shared/RawNumberCard";

export const SpecificBranchesRawNumbersCards = ({
  rawNumbersCardsData,
  selectedStartDate,
  selectedEndDate,
  isFetching,
}) => {
  return isFetching ? (
    <SkeletonForRawNumbersCards />
  ) : (
    <Box display="flex" justifyContent="space-between" boxSizing="border-box">
      {rawNumbersCardsData?.map(function renderRawNumberCard(
        rawNumberCardData,
        index
      ) {
        return (
          <RawNumberCard
            key={index}
            variant={getCardVariant(index)}
            cardData={formatCardContent({ index, cardData: rawNumberCardData })}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
          />
        );
      })}
    </Box>
  );
};

function getCardVariant(cardIndex) {
  switch (cardIndex) {
    //Total Reviews
    case 0:
      return CARD_VARIANTS.green;
    //Total Complaints
    case 1:
      return CARD_VARIANTS.red;
    // % of Resolved Complaints
    case 2:
      return CARD_VARIANTS.blue;
    // Average Resoltion Time
    case 3:
      return CARD_VARIANTS.blue;

    default:
      break;
  }
}

function formatCardContent({ index, cardData }) {
  let cardContent = {
    title: cardData.title,
    percentage: cardData.percentage,
    semantic: cardData.semantic,
    arrow: cardData.arrow,
  };

  switch (index) {
    // Reviews Count & Complaints Count
    case 0:
    case 1: {
      cardContent.mainText = cardData.value.toLocaleString();
      break;
    } //   % of Resolved Complaints
    case 2: {
      cardContent.mainText = `${cardData.value}%`;
      cardContent.secondaryText = `(${cardData.total_resolved} resolved)`;
      break;
    }
    // Average Resolution Time
    case 3: {
      const { readableTime, timeUnit } = renderReadableMilliseconds(
        cardData.value
      );
      cardContent.mainText = readableTime;
      cardContent.secondaryText = timeUnit;
      break;
    }
    default:
      break;
  }
  return cardContent;
}

const SkeletonForRawNumbersCards = () => {
  return (
    <Box display="flex" justifyContent="space-between" boxSizing="border-box">
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
    </Box>
  );
};
