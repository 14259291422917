import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const deleteMember = ({ memberID }) => {
  const requestURL = `${API_URL}/users/${memberID}`;
  return melior.delete(requestURL);
};

export const useDeleteMember = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["members"], { type: "active" });
    },
    ...config,
    mutationFn: deleteMember,
  });
};
