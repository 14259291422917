import {
  Box,
  Checkbox,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FormControl from "@mui/material/FormControl";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

export const ColoredMultiSelectWithInfo = ({
  label,
  labelSingular,
  selectedValues,
  onChangeHandler,
  onCloseHandler,
  options,
  disabled,
  withColors = false,
  skeleton = false,
  width = 120,
}) => {
  return skeleton ? (
    <Skeleton variant="rounded" animation="wave" width={width} height={30} />
  ) : (
    <FormControl sx={{ width: width, position: "relative" }} focused={false}>
      <InputLabel
        sx={{
          position: "absolute",
          top: selectedValues.length === 0 && "-9px",
          fontSize: "0.9rem",
        }}
        id="demo-multiple-checkbox-label"
      >
        {label}
      </InputLabel>
      <Select
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        multiple
        sx={{ height: "35px" }}
        value={selectedValues}
        onClose={onCloseHandler}
        onChange={(event) => {
          const lastItemSelected =
            event.target.value[event.target.value.length - 1];

          let selectedItemDisabled = false;

          options.forEach((option) => {
            if (option.value.toString() === lastItemSelected) {
              if (option.has_data !== undefined && option.has_data === false) {
                selectedItemDisabled = true;
              }
            }
          });

          if (!selectedItemDisabled) {
            onChangeHandler(event);
          }
        }}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) => {
          return (
            <Typography sx={{ fontSize: "0.9rem" }}>
              {selected.length} Selected
            </Typography>
          );
        }}
        disabled={disabled}
        MenuProps={MenuProps}
      >
        {options.map((option, index) => {
          const menuItemTitle =
            option.has_data === undefined || option.has_data === true
              ? option.label
              : `No data available for this ${labelSingular}`;

          return (
            <MenuItem
              key={option.label + index + option.value}
              value={option.value.toString()}
              title={menuItemTitle}
              sx={{
                paddingLeft: option.resetOption ? "15px" : "10px",
                opacity:
                  option.has_data !== undefined &&
                  option.has_data === false &&
                  0.5,
              }}
            >
              {!option.resetOption && (
                <Checkbox
                  color="secondary"
                  sx={{ paddingLeft: 0 }}
                  checked={selectedValues.includes(option.value.toString())}
                />
              )}
              {withColors && (
                <Box
                  borderRadius="50%"
                  width="12px"
                  height="12px"
                  bgcolor={option.color}
                  mr="10px"
                ></Box>
              )}
              <ListItemText
                primary={
                  option.label.length > 23
                    ? option.label.substring(0, 23) + "..."
                    : option.label
                }
                secondary={option.subtitle && option.subtitle}
              />
              {option.has_data !== undefined && option.has_data === false && (
                <InfoOutlinedIcon color="error" fontSize="1rem" />
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
