import { API_URL, melior } from "../../../helpers/constants";

export const getTemplateQuestionsForOnlineSurvey = (templateId) => {
    const requestURL = `${API_URL}/templates/${templateId}/questions`;
  
    return new Promise((resolve, reject) => {
      melior
        .get(requestURL)
        .then(async (response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  