import GoogleSVG from "../../../../assets/icons/reputation-dashboard/google-logo.svg";
import SearchSVG from "../../../../assets/icons/reputation-dashboard/search.svg";
import EyeSVG from "../../../../assets/icons/reputation-dashboard/eye.svg";
import PhoneCallSVG from "../../../../assets/icons/reputation-dashboard/phone-call.svg";
import { Box, Skeleton, Typography } from "@mui/material";
import { CARD_VARIANTS, RawNumberCard } from "../shared/RawNumberCard";
import { PlatformIconCard } from "./PlatformsInsightsCards";
import {
  useGetBusinessProfileSearches,
  useGetBusinessProfileViews,
  useGetBusinessProfileCallClicks,
  useGetBusinessProfileTopSearchTerms,
} from "../../api/reputation-dashboard";

export const GoogleBusinessProfileInsightsCards = ({
  startDate,
  endDate,
  branches,
}) => {
  const { data: searchesServerResponse, isFetching: isFetchingSearches } =
    useGetBusinessProfileSearches({
      params: {
        startDate,
        endDate,
        branches,
      },
      config: { staleTime: 120000 },
    });
  const { data: viewsServerResponse, isFetching: isFetchingViews } =
    useGetBusinessProfileViews({
      params: {
        startDate,
        endDate,
        branches,
      },
      config: { staleTime: 120000 },
    });
  const { data: callClicksServerResponse, isFetching: isFetchingCallClicks } =
    useGetBusinessProfileCallClicks({
      params: {
        startDate,
        endDate,
        branches,
      },
      config: { staleTime: 120000 },
    });
  const {
    data: topSearchTermsServerResponse,
    isFetching: isFetchingTopSearchTerms,
  } = useGetBusinessProfileTopSearchTerms({
    params: {
      startDate,
      endDate,
      branches,
    },
    config: { staleTime: 120000 },
  });

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      gap="12px"
      width="100%"
    >
      <PlatformIconCard
        platformName="Business Profile"
        platformIcon={GoogleSVG}
      />
      {/* searches card */}
      {isFetchingSearches || !searchesServerResponse ? (
        <SkeletonForBusinessProfileCard />
      ) : (
        <RawNumberCard
          width="24%"
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          variant={CARD_VARIANTS.blue}
          cardData={{
            title: searchesServerResponse.data.title,
            semantic: searchesServerResponse.data.semantic,
            arrow: searchesServerResponse.data.arrow,
            percentage: searchesServerResponse.data.percentage,
            mainText: searchesServerResponse.data.value.toLocaleString(),
            icon: (
              <Box
                component="img"
                src={SearchSVG}
                style={{ width: "34px" }}
                alt="phone-call-img"
              />
            ),
          }}
        />
      )}

      {/* views card */}
      {isFetchingViews || !viewsServerResponse ? (
        <SkeletonForBusinessProfileCard />
      ) : (
        <RawNumberCard
          width="24%"
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          variant={CARD_VARIANTS.blue}
          cardData={{
            title: viewsServerResponse.data.title,
            semantic: viewsServerResponse.data.semantic,
            arrow: viewsServerResponse.data.arrow,
            percentage: viewsServerResponse.data.percentage,
            mainText: viewsServerResponse.data.value.toLocaleString(),
            icon: (
              <Box
                component="img"
                src={EyeSVG}
                style={{ width: "34px" }}
                alt="phone-call-img"
              />
            ),
          }}
        />
      )}

      {/* call clicks card */}
      {isFetchingCallClicks || !callClicksServerResponse ? (
        <SkeletonForBusinessProfileCard />
      ) : (
        <RawNumberCard
          width="24%"
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          variant={CARD_VARIANTS.blue}
          cardData={{
            title: callClicksServerResponse.data.title,
            semantic: callClicksServerResponse.data.semantic,
            arrow: callClicksServerResponse.data.arrow,
            percentage: callClicksServerResponse.data.percentage,
            mainText: callClicksServerResponse.data.value.toLocaleString(),
            icon: (
              <Box
                component="img"
                src={PhoneCallSVG}
                style={{ width: "34px" }}
                alt="phone-call-img"
              />
            ),
          }}
        />
      )}

      {/* top search terms card */}
      {isFetchingTopSearchTerms || !topSearchTermsServerResponse ? (
        <SkeletonForBusinessProfileCard />
      ) : (
        <RawNumberCard
          width="24%"
          selectedStartDate={startDate}
          selectedEndDate={endDate}
          variant={CARD_VARIANTS.blue}
          cardData={{
            title: topSearchTermsServerResponse.data.title,
          }}
          hideChangeInRateBadge={true}
        >
          <Box display="flex" flexDirection="column" height="70%">
            {topSearchTermsServerResponse.data.value.length === 0 ? (
              <Typography variant="text-sm">
                Your date range must cover a minimum of one month.
              </Typography>
            ) : (
              topSearchTermsServerResponse.data.value
                ?.slice(0, 3)
                .map(function renderTopSearchTerm(termData, index) {
                  return (
                    <Box
                      key={index}
                      display="flex"
                      flexDirection="row"
                      justifyContent="space-between"
                    >
                      <Typography>{`${index + 1}. ${
                        termData.name
                      }`}</Typography>
                      <Typography>{termData.count}</Typography>
                    </Box>
                  );
                })
            )}
          </Box>
        </RawNumberCard>
      )}
    </Box>
  );
};
const SkeletonForBusinessProfileCard = () => {
  return (
    <Skeleton
      variant="rectangular"
      width="24%"
      height={188}
      sx={{ borderRadius: "8px" }}
    />
  );
};

function formatBusinessProfileCardContent({ index, cardData }) {
  let cardContent = {
    title: cardData.title,
    semantic: cardData.semantic,
    arrow: cardData.arrow,
    percentage: cardData.percentage,
    mainText: cardData.value.toLocaleString(),
  };

  switch (index) {
    // Searches
    case 0: {
      cardContent.icon = (
        <Box
          component="img"
          src={SearchSVG}
          style={{ width: "34px" }}
          alt="phone-call-img"
        />
      );
      break;
    }
    // Profile Views
    case 1: {
      cardContent.icon = (
        <Box
          component="img"
          src={EyeSVG}
          style={{ width: "34px" }}
          alt="phone-call-img"
        />
      );
      break;
    }
    // Calls through Google
    case 2: {
      cardContent.icon = (
        <Box
          component="img"
          src={PhoneCallSVG}
          style={{ width: "34px" }}
          alt="phone-call-img"
        />
      );
      break;
    }
    default:
      break;
  }

  return cardContent;
}
