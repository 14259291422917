import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getBranchDetails = ({ branchID }) => {
  const requestURL = `${API_URL}/branches/${branchID}`;

  return melior.get(requestURL);
};

export const useGetBranchDetails = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["branch-details", params],
    queryFn: () => getBranchDetails(params),
    keepPreviousData: true,
  });
};