import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { PhoneNumberUtil } from "google-libphonenumber";

export const PhoneNumberLayout = ({ language, layoutProps, submitAnswer }) => {
  const phoneNumberUtil = PhoneNumberUtil.getInstance();

  return (
    <PhoneInput
      placeholder={layoutProps.placeholder}
      country={layoutProps.country.toLowerCase()}
      containerStyle={{
        width: "100%",
        height: "auto",
      }}
      inputStyle={{
        width: "100%",
      }}
      isValid={function validatePhoneNumber(value, country) {
        try {
          const parsedPhoneNumber = phoneNumberUtil.parse(value, country.iso2);
          if (
            parsedPhoneNumber.getNationalNumber() ===
            parsedPhoneNumber.getCountryCode()
          )
            return true;
          else if (phoneNumberUtil.isValidNumber(parsedPhoneNumber)) {
            submitAnswer(value);
            return true;
          } else {
            return "please enter a valid phone number";
          }
        } catch (err) {
          console.error(err);
        }
      }}
    />
  );
};
