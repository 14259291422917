import { ArrowDropDown, CopyrightOutlined } from "@mui/icons-material";

import MUIReactTable from "material-react-table";
import { primaryColors } from "../../../../helpers/customColors";
import { useEffect, useRef, useState } from "react";
import { Box, Button } from "@mui/material";
import ExpandAllIcon from "../../../../assets/icons/table-custom-icons/expand-all.svg";
import CollapseAllIcon from "../../../../assets/icons/table-custom-icons/collapse-all.svg";
import { objectsDeeplyEqual } from "../../../../helpers/General";

export const OverallViewTable = ({
  columns,
  data,
  totalRecords,
  renderRowDetails,
  rowsSelectionHandling,
  externalPaginationProps,
  isLoading,
  uniqueColumnName,
  isError,
  withRowSelection,
  resetRowPanelDetailsTypeCallback,
  expandAllByDefault = false,
}) => {
  const [rowSelection, setRowSelection] = useState({});
  const [allRowsSelected, setAllRowsSelected] = useState(false);
  const [rowCount, setRowCount] = useState(totalRecords);

  const tableInstanceRef = useRef(null);

  const previousRowSelectionRef = useRef();

  const {
    externalCurrentPage,
    updateExternalCurrentPage,
    updatePageSizeHandler,
    externalPageSize,
    isFetching,
  } = externalPaginationProps;

  let selectedRowsCount = 0;
  for (let selectedRow in rowSelection) {
    selectedRowsCount++;
  }

  useEffect(
    function updateRowsCount() {
      setRowCount(totalRecords);
    },
    [totalRecords]
  );

  useEffect(
    function updateSelectedRowsDBIDs() {
      const selectedRowsDBIDs = [];

      // this is to only set the selectedRowsIDs only when the selection changes
      const rowSelectionChanged = !objectsDeeplyEqual(
        rowSelection,
        previousRowSelectionRef.current
      );

      if (rowSelectionChanged) {
        for (let selectedTableRow in rowSelection) {
          selectedRowsDBIDs.push(selectedTableRow);
        }
        rowsSelectionHandling?.updaterHandler &&
          rowsSelectionHandling.updaterHandler([...selectedRowsDBIDs]);
      }

      previousRowSelectionRef.current = rowSelection;
    },
    [rowSelection, rowsSelectionHandling]
  );

  return (
    <Box display="flex" flexDirection="column">
      {selectedRowsCount > 0 && (
        <Box
          display="flex"
          alignItems="center"
          gap={2}
          bgcolor={primaryColors.base.white}
          p="1rem"
        >
          <Box>
            {allRowsSelected === true
              ? `${totalRecords} Records Selected`
              : `${selectedRowsCount} of ${totalRecords} Selected`}
          </Box>
          {selectedRowsCount === externalPageSize && !allRowsSelected && (
            <Button
              variant="outlined"
              color="primary"
              sx={{ textTransform: "capitalize" }}
              onClick={function toggleAllRowSelectedState() {
                rowsSelectionHandling?.updaterHandler &&
                  rowsSelectionHandling.updaterHandler("all");

                setAllRowsSelected(true);
              }}
            >
              {"Select All Records"}
            </Button>
          )}
        </Box>
      )}
      <MUIReactTable
        columns={columns}
        data={data}
        muiTableBodyRowProps={({ row, isDetailPanel }) => ({
          onClick: (event) => {
            const rowIsExpanded = row.getIsExpanded();

            if (!isDetailPanel) {
              if (rowIsExpanded) {
                // Delay whatever action that needs to taken to be after the closing animation.
                setTimeout(function callbackWhenClosed() {
                  resetRowPanelDetailsTypeCallback &&
                    resetRowPanelDetailsTypeCallback();
                }, 200);
              }

              row.toggleExpanded(!rowIsExpanded);
              if (rowIsExpanded) {
                // Delay whatever action that needs to taken to be after the closing animation.
                setTimeout(function callbackWhenClosed() {
                  resetRowPanelDetailsTypeCallback &&
                    resetRowPanelDetailsTypeCallback();
                }, 200);
              }
            }
          },
          title:
            renderRowDetails && !isDetailPanel
              ? "Click to expand/collapse"
              : "",
          sx: {
            cursor: renderRowDetails && !isDetailPanel ? "pointer" : "default", //you might want to change the cursor too when adding an onClick
          },
        })}
        muiTableHeadCellProps={{
          sx: {
            backgroundColor: primaryColors.gray[50],
          },
        }}
        muiTablePaperProps={{
          sx: {
            width: "100%",
            boxShadow: "none",
          },
        }}
        initialState={{
          pagination: {
            pageIndex: externalCurrentPage - 1,
            pageSize: externalPageSize,
          },
          expanded: expandAllByDefault,
        }}
        state={{
          rowSelection: rowSelection,
          isLoading,
          pagination: {
            pageIndex: externalCurrentPage - 1,
            pageSize: externalPageSize,
          },
          showAlertBanner: isError,
          showProgressBars: isFetching,
        }} //pass our managed row selection state to the table to use
        getRowId={(row, index) => {
          return uniqueColumnName ? row[uniqueColumnName] : index;
        }} //give each row a more useful id
        renderDetailPanel={renderRowDetails}
        icons={{
          //change sort icon, connect internal props so that it gets styled correctly
          ExpandMoreIcon: (props) => <ArrowDropDown {...props} />,
          KeyboardDoubleArrowDownIcon: (props) => {
            const expanded = props.style.transform === "rotate(-180deg)";

            const expandAllImage = (
              <img
                src={ExpandAllIcon}
                width="25px"
                alt="expand-all-icon"
                title="Expand All"
              />
            );
            const collapseAllImage = (
              <img
                src={CollapseAllIcon}
                width="25px"
                alt="expand-all-icon"
                title="Collapse All"
              />
            );

            return expanded ? collapseAllImage : expandAllImage;
          },
        }}
        onRowSelectionChange={function test(args) {
          setRowSelection(args);
          setAllRowsSelected(false);
        }} //connect internal row selection state to your own
        rowCount={rowCount}
        onPaginationChange={(updater) => {
          const { pageIndex: updatedPageIndex, pageSize: updatedPageCount } =
            updater(externalCurrentPage - 1);
          const updatePageNumber = updatedPageIndex + 1;

          updateExternalCurrentPage(updatePageNumber);
          updatedPageCount && updatePageSizeHandler(updatedPageCount);
        }}
        muiTablePaginationProps={{
          rowsPerPageOptions: !updatePageSizeHandler
            ? []
            : [
                { label: 5, value: 5 },
                { label: 10, value: 10 },
                { label: 20, value: 20 },
                { label: 30, value: 30 },
                { label: 40, value: 40 },
                { label: 50, value: 50 },
              ],
        }}
        muiToolbarAlertBannerProps={
          isError
            ? {
                color: "error",
                children: "Error loading data",
              }
            : undefined
        }
        tableInstanceRef={tableInstanceRef}
        positionExpandColumn="last"
        enableColumnActions={false}
        enableColumnFilters={false}
        enableTopToolbar={false}
        enableRowSelection={withRowSelection}
        manualPagination
        enablePagination
      />
    </Box>
  );
};
