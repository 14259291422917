import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthenticationContext } from "../features/login";
import { PaymentStatuses } from "../features/payment-funnel";
import { NoAccessPage } from "../reusable/components/NoAccessPage";
import RestrictedPage from "../screens/RestrictedPage";

/**
 *
 * @param {[]} allowedRoles
 * Array of allowed User Roles to access the current Route's Element.
 *
 * @returns
 * Specified Route's Element, Forbidden Page, or Redirect to Login Page
 */

export const ProtectedRoute = ({
  children: requestedRouteElement,
  restrictable,
  allowedRoles,
}) => {
  const { user } = useContext(AuthenticationContext);

  // Not Logged in => Redirect to Login Page
  if (!user) return <Navigate to="/" replace />;

  const { status, message } = user.subscription;

  // Show Forbidden Page for Users not included in the allowedRoles array
  if (!allowedRoles.includes(user.role)) return <NoAccessPage />;

  return restrictable && status === PaymentStatuses.DeadlinePhase ? (
    <RestrictedPage message={message} />
  ) : (
    requestedRouteElement
  );
};
