import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { COLORS } from "../../../helpers/Colors";

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    border: "1px solid",
    borderColor: COLORS.silver,
    backgroundColor: COLORS.white,
    paddingBottom: "0%",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    opacity: "0.7",
    width: "auto",
  },
  tooltipTitle: {
    margin: "5px",
    color: COLORS.brightBlack,
  },
}));

export const NPSVerticalBarChart = (props) => {
  const [finalData, setFinalData] = useState([]);

  const CustomTooltip = ({ payload, active }) => {
    const classes = useStyles();
    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.tooltipTitle}>
            Total count: {payload[0].payload.totalCount}
          </p>
        </div>
      );
    }

    return null;
  };

  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    if (width === 0) return;
    return (
      <text
        x={x + width - 15}
        y={y + height / 2 + 6}
        fontSize="11px"
        textAnchor="middle"
      >
        {value.toFixed(2)}
      </text>
    );
  };

  useEffect(() => {
    setFinalData(
      props.data?.map((record) => ({
        name: record.name,
        value: record.data?.value,
        totalCount: record.data?.["total_count"],
        color: record.color,
      }))
    );
  }, [props]);

  return (
    <div
      style={{
        width: "100%",
        height: `${400}px`,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          data={finalData}
          margin={{
            top: 20,
            right: 100,
            bottom: 40,
            left: 20,
          }}
        >
          <CartesianGrid stroke={COLORS.silver} />
          <XAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <YAxis tickCount={5} type="number" width={110} />
          <Bar
            dataKey="value"
            barSize={30}
            background={{ fill: COLORS.silver }}
          >
            {finalData.map((entry) => (
              <Cell fill={entry.color ?? COLORS.grey} />
            ))}
            <LabelList
              content={renderCustomizedLabel}
              style={{ fill: COLORS.brightBlack }}
            />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};