import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

const getTeams = () => {
  const requestURL = `${API_URL}/teams`;

  return melior.get(requestURL);
};

export const useGetTeams = ({ config }) => {
  return useQuery({
    ...config,
    queryKey: ["teams"],
    queryFn: () => getTeams(),
    keepPreviousData: true,
  });
};
