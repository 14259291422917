import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const REPUTATION_RESPONSES_PAGE_SIZE = 10;

export const defaultReputationResponsesParams = {
  page: 1,
  page_size: REPUTATION_RESPONSES_PAGE_SIZE,
};

export const getExternalReputationResponses = (queryStrings) => {
  const queryData = {
    params: {
      ...queryStrings,
    },
  };
  const requestURL = `${API_URL}/reputation`;

  return melior.get(requestURL, queryData);
};

export const useGetExternalReputationResponses = (
  { params, config } = {
    params: defaultReputationResponsesParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["reputation-responses", params],
    queryFn: () => getExternalReputationResponses(params),
    keepPreviousData: true,
  });
};
