import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import {
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  IconButton,
} from "@mui/material";
import clsx from "clsx";
import { Add } from "@mui/icons-material";
import {
  actionNotAllowedDemo,
  actionNotAllowedGeneral,
} from "../../../helpers/constants";

const useStyles = makeStyles({
  rowBorder: {
    borderBottom: `2px solid ${COLORS.meliorYellowGentle}`,
  },
  cell: {
    fontSize: "1.1rem",
    fontFamily: "Avenir-Heavy",
    width: "23%",
  },
  margin: {
    marginRight: "15px",
  },
  addIcon: {
    color: COLORS.white,
    fontSize: "30px",
    background: COLORS.meliorYellow,
    borderRadius: "50%",
  },
  widthThird: {
    width: "30px",
  },
  disabledAddButton: {
    background: COLORS.greyDisabled,
  },
});

const TableHeader = ({ type, activateInvite, isMax, isDemo, disableEdits }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow className={classes.rowBorder}>
        <TableCell className={classes.cell}>
          {type === "Invited" ? "Invited user" : "User"}
          {isDemo ? "name" : " email/name"}
        </TableCell>
        <TableCell className={classes.cell} align="center">
          Role
        </TableCell>
        <TableCell className={classes.cell} align="center">
          Responsibility
        </TableCell>

        <TableCell className={clsx(classes.cell, classes.margin)} align="right">
          <p className={classes.margin}>Actions</p>
        </TableCell>

        {type === "Normal" ? (
          <TableCell />
        ) : (
          <TableCell align="right" className={classes.widthThird}>
            <Tooltip
              title={
                isDemo
                  ? actionNotAllowedDemo
                  : disableEdits
                  ? actionNotAllowedGeneral
                  : `New User`
              }
              position="top"
            >
              <span>
                <IconButton disabled={isMax || disableEdits} onClick={activateInvite} size="large">
                  <Add
                    className={clsx(
                      classes.addIcon,
                      isMax || disableEdits ? classes.disabledAddButton : ""
                    )}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </TableCell>
        )}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
