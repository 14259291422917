import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import clsx from "clsx";
import {
  TableRow,
  TableCell,
  IconButton,
  Tooltip,
  TextField,
  FormControl,
  Input,
  Chip,
  Select,
  Avatar,
  MenuItem,
} from "@mui/material";
import { Done, Close } from "@mui/icons-material";
import ResponsibilityListMenu from "./ResponsibilityListMenu";
import MenuProps from "./MenuProps";
import {
  APP_ONLY,
  BRANCH_MANAGER,
  DIVISION_MANAGER,
  FACILITY_MANAGER,
  FEEDBACK_ONLY,
} from "../../../helpers/constants";
import { SettingsContext } from "../../../contexts/SettingsContext";

const useStyles = makeStyles({
  formControl: {
    margin: "10px",
    maxWidth: "180px",
    width: "100%",
  },
  facilityManagerChip: {
    background: COLORS.facilityManagerChip,
  },
  branchManagerChip: {
    background: COLORS.branchManagerChip,
  },
  divisionManagerChip: {
    background: COLORS.divisionManagerChip,
  },
  appOnlyChip: {
    background: COLORS.appOnlyChip,
  },
  feedbackOnlyChip: {
    background: COLORS.feedbackOnlyChip,
  },
  chip: {
    fontFamily: "Avenir-Heavy",
    margin: "center",
  },
  avatar: {
    background: COLORS.white,
  },
  selectedNewInviteResponsibiity: {
    color: COLORS.meliorYellow,
    fontFamily: "Avenir-Heavy",
  },
  safe: {
    color: COLORS.successGreen,
  },
});

const NewInviteRow = ({
  setNewInvite,
  newInvite,
  resetInvite,
  inviteUser,
  user,
}) => {
  const classes = useStyles();

  const { branches } = useContext(SettingsContext);

  const roles = [BRANCH_MANAGER, DIVISION_MANAGER, APP_ONLY, FEEDBACK_ONLY];

  const handleChange = (event) => {
    setNewInvite({
      ...newInvite,
      role: event.target.value,
      responsibility: [],
    });
  };

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          id="new_invite"
          type="email"
          value={newInvite.email}
          onChange={(e) =>
            setNewInvite({ ...newInvite, email: e.target.value })
          }
        />
      </TableCell>
      <TableCell align="center">
        <FormControl className={classes.formControl}>
          <Select
            id="role-chip"
            value={newInvite.role}
            onChange={handleChange}
            input={<Input style={{ height: "40px" }} id="select-role-chip" />}
            renderValue={(selected) => {
              return (
                <Chip
                  classes={{
                    root: clsx(
                      classes.chip,
                      selected === BRANCH_MANAGER
                        ? classes.branchManagerChip
                        : selected === FACILITY_MANAGER
                        ? classes.facilityManagerChip
                        : selected === DIVISION_MANAGER
                        ? classes.divisionManagerChip
                        : selected === APP_ONLY
                        ? classes.appOnlyChip
                        : selected === FEEDBACK_ONLY
                        ? classes.feedbackOnlyChip
                        : ""
                    ),
                  }}
                  avatar={
                    <Avatar className={classes.avatar}>
                      {selected[0].charAt(0).toUpperCase()}
                    </Avatar>
                  }
                  key={selected}
                  label={selected.replace("_", " ")}
                  className={classes.chip}
                />
              );
            }}
            MenuProps={MenuProps}
          >
            {roles.map((name) => (
              <MenuItem
                classes={{ selected: classes.selectedNewInviteResponsibiity }}
                key={name}
                value={name}
              >
                {name.replace("_", " ")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>

      <TableCell style={{ width: "190px" }} align="center">
        {newInvite.role === FACILITY_MANAGER ? (
          <span>
            Facility Managers get access to all branches and divisions
          </span>
        ) : newInvite.role === BRANCH_MANAGER ? (
          <ResponsibilityListMenu
            user={newInvite}
            object={newInvite}
            setObject={setNewInvite}
            pool={{ items: user["branches"] }}
          />
        ) : (
          <ResponsibilityListMenu
            user={newInvite}
            object={newInvite}
            setObject={setNewInvite}
            pool={branches}
          />
        )}
      </TableCell>

      <TableCell align="right">
        <Tooltip title="Invite User" position="top">
          <IconButton
            className={classes.safe}
            onClick={inviteUser}
            size="large"
          >
            <Done />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel" position="top">
          <IconButton
            onClick={() => resetInvite({ func: setNewInvite, active: false })}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell style={{ width: "10px" }} />
    </TableRow>
  );
};
export default NewInviteRow;
