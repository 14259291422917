import { useEffect, useState, useRef } from "react";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";

const renderActiveShapePercentageOnly = (props) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    payload,
    percent,
  } = props;
  return (
    <g>
      <text
        x={cx}
        y={cy}
        dy={8}
        textAnchor="middle"
        fill={COLORS.brightRed}
        style={{ fontSize: "28px", fontFamily: "Avenir-Black" }}
      >
        {percent * 100 === 100 ? "100" : (percent * 100).toFixed(1)}%{" "}
      </text>

      <text
        x={cx}
        y={cy + 15}
        dy={8}
        textAnchor="middle"
        fill={COLORS.brightRed}
        style={{ fontSize: "22px" }}
      >
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={COLORS.brightRed}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={COLORS.brightRed}
      />
    </g>
  );
};

const useStyles = makeStyles(() => ({
  displayOnHover: {
    opacity: 0,
  },
  root: {
    "&:hover $displayOnHover": {
      opacity: 1,
    },
  },
}));

export const PieChartPercentage = (props) => {
  const classes = useStyles();

  const [finalData, setFinalData] = useState([]);
  const controlsRef = useRef();
  useEffect(() => {
    let x = 0;
    let y = 0;
    let final = [];
    x = props.data.value;
    y = 100 - props.data.value;
    final = [
      { name: "", value: x, total: props.data.entries_count },
      { name: "", value: y },
    ];
    setFinalData(final);
  }, [props]);

  let size = { height: 250, strokeBeg: "70%", strokeEnd: "80%" };

  return (
    <Tooltip
      title={` ${finalData[0]?.total} / ${props.data["total_count"]} total entries`}
      className={classes.root}
    >
      <div id={`${props.question ?? ""}${props.title}PCP`}>
        <Tooltip
          title={`Save as PNG`}
          className={classes.displayOnHover}
          ref={controlsRef}
        >
          <IconButton
            onClick={() =>
              downloadAsPNG(
                `${props.question ?? ""}${props.title}PCP`,
                `${props.question ?? ""}${props.title}-PCP`,
                controlsRef
              )
            }
            style={{
              padding: "10",
              marginLeft: "65%",
              transform: "rotate(90deg)",
            }}
            size="large">
            <KeyboardTabIcon />
          </IconButton>
        </Tooltip>
        <div
          style={{
            width: "100%",
            height: size.height,
            textAlign: "center",
            alignSelf: "center",
          }}
        >
          <ResponsiveContainer height={200}>
            <PieChart>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShapePercentageOnly}
                data={finalData}
                cx="50%"
                cy="50%"
                startAngle={270}
                endAngle={630}
                isAnimationActive={false}
                innerRadius={size.strokeBeg}
                outerRadius={size.strokeEnd}
                dataKey="value"
              />
            </PieChart>
          </ResponsiveContainer>
          <h2
            style={{
              fontSize: "1rem",
              fontWeight: "normal",
              maxWidth: "200px",
              margin: "auto",
            }}
          >
            {props.title}
          </h2>
        </div>
      </div>
    </Tooltip>
  );
};