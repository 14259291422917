import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Archive, Unarchive } from "@mui/icons-material";
import { useUpdateReputationResponseArchivalState } from "../../api/reputation-responses/updateReputationResponseArchivalState";

export const ArchiveReputationResponse = ({ reputationResponseData }) => {
  const updateReputationResponseArchivalStateMutation =
    useUpdateReputationResponseArchivalState();

  return (
    <Tooltip
      title={
        reputationResponseData.archived === true ? "Un-Archive" : "Archive"
      }
      placement="top"
    >
      {updateReputationResponseArchivalStateMutation.isLoading ? (
        <CircularProgress size="30px" />
      ) : (
        <IconButton
          sx={{ height: "50px", width: "40px" }}
          aria-label="star"
          onClick={(e) => {
            updateReputationResponseArchivalStateMutation.mutateAsync({
              reputationResponseID: reputationResponseData.id,
              newArchivalState: !reputationResponseData.archived,
            });
            e.stopPropagation();
          }}
        >
          {reputationResponseData.archived === true ? (
            <Unarchive
              sx={{
                height: "30px",
                width: "32px",
              }}
            />
          ) : (
            <Archive
              sx={{
                height: "30px",
                width: "32px",
              }}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
