import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getSilentTimes = () => {
  const requestURL = `${API_URL}/users/silent-times`;
  return melior.get(requestURL);
};

export const useGetSilentTimes = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["silent-times"],
    queryFn: () => getSilentTimes(),
    keepPreviousData: true,
  });
};
