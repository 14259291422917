import { useState, useEffect, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import jwt from "jwt-decode";

import queryString from "query-string";
import { AuthenticationContext } from "../../login";
import { verifyToken } from "../api";
import { NotFoundPage } from "../../../reusable/components/NotFoundPage";
import LoadingPage from "../../../layout/LoadingPage";

export const TokenVerify = (props) => {
  let location = useLocation();

  const { user } = useContext(AuthenticationContext);

  const navigate = useNavigate();

  const [verified, setVerified] = useState(false);
  const [loading, setLoading] = useState(true);

  const verify = async () => {
    setLoading(true);
    const token = queryString.parse(location.search).token;
    if (!token) {
      setVerified(false);
      setLoading(false);
      return;
    }
    const type = props.type;
    const data = {
      token: token,
    };
    verifyToken(data, type)
      .then(async (res) => {
        const tokenDecode = jwt(token);
        setLoading(false);
        localStorage.setItem(
          "verifiedUser",
          JSON.stringify({
            email: tokenDecode.email,
            first_name: tokenDecode.first_name,
            last_name: tokenDecode.last_name,
            token: token,
          })
        );
        setVerified(true);
      })
      .catch((e) => {
        alert(e.response.data.detail);
        setLoading(false);
      });
  };

  useEffect(() => {
    verify();
  }, []);

  if (user) {
    return <NotFoundPage />;
  }

  return (
    <div>
      {loading ? (
        <LoadingPage />
      ) : !verified ? (
        <NotFoundPage />
      ) : props.type === "invite" ? (
        navigate("/user_invite/invite")
      ) : (
        navigate("/registration/newFacility")
      )}
    </div>
  );
};