import { useContext } from "react";
import {
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { BranchHeaderContext } from "../../../contexts/BranchHeaderContext";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import Error from "@mui/icons-material/Error";

const useStyles = makeStyles(() => ({
  body: {
    display: "inline-flex",
  },
  saveAsFormControl: {
    height: "30px",
    borderRadius: "10px",
    alignSelf: "center",
    fontFamily: "Avenir-Medium",
    padding: "0px",
  },
  saveAsMenu: {
    width: "125px",
    height: "30px",
    backgroundColor: COLORS.meliorYellow,
    borderRadius: "7px",
    textAlign: "center",
    color: COLORS.black,
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "Avenir-Medium",
    fontSize: "14px",
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    height: "30px",
    padding: "20px 15px",
  },
  dropdownIcon: {
    color: COLORS.black,
  },
  noPadding: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  progress: {
    width: "30px",
    height: "30px",
    marginLeft: "10px",
    color: COLORS.meliorYellow,
  },
  errorContainer: {
    display: "inline-flex",
  },
  error: {
    width: "25px",
    height: "30px",
    marginLeft: "10px",
    color: COLORS.failRed,
  },
  errorMessage: {
    margin: "5px",
    fontFamily: "Avenir-Medium",
    fontSize: "14px",
  },
}));

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: 280,
      width: 150,
    },
  },
};

const SaveAs = ({
  questions,
  disabled,
  rawSelectedRecords,
  queryStringsForAPI,
  resetSelectedRows,
  saveAll,
  entityToExportFrom,
  questionsDataToRender
}) => {
  const classes = useStyles();

  const {
    downloadMethodOpen,
    setDownloadMethodOpen,
    downloadMethodHandleChange,
    downloading,
    downloadingError,
  } = useContext(BranchHeaderContext);

  const downloadMethodHandleClose = () => {
    setDownloadMethodOpen(false);
  };

  const downloadMethodHandleOpen = () => {
    setDownloadMethodOpen(true);
  };

  return (
    <div className={classes.body}>
      <FormControl variant="filled" className={classes.saveAsFormControl}>
        <Select
          MenuProps={MenuProps}
          disableUnderline={true}
          className={classes.saveAsMenu}
          open={downloadMethodOpen}
          onClose={downloadMethodHandleClose}
          onOpen={downloadMethodHandleOpen}
          value={0}
          onChange={(e) => {
            downloadMethodHandleChange({
              event: e,
              questions,
              rawSelectedRecords: saveAll ? undefined : rawSelectedRecords,
              queryStringsForAPI,
              resetSelectedRows,
              entityToExportFrom,
              questionsDataToRender,
            });
          }}
          disabled={downloading || disabled}
          inputProps={{
            classes: {
              icon: classes.dropdownIcon,
              root: classes.noPadding,
            },
          }}
        >
          <MenuItem className={classes.menuItem} disabled value={0}>
            <Typography
              sx={{
                textTransform: "none",
                fontWeight: "normal",
                fontSize: "16px",
                height: "30px",
                paddingBottom: "10px",
              }}
            >
              Save as
            </Typography>
          </MenuItem>

          <MenuItem value={"Excel"} className={classes.menuItem}>
            Excel
          </MenuItem>
        </Select>
      </FormControl>
      {/* loading and error display */}
      {downloading && <CircularProgress className={classes.progress} />}
      {downloadingError?.isError ? (
        <div className={classes.errorContainer}>
          <Error className={classes.error}></Error>
          <p className={classes.errorMessage}>
            {downloadingError?.errorMessage}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SaveAs;
