import { Container } from "@mui/material";
import { primaryColors } from "../helpers/customColors";

export const ScreenContainer = ({ children }) => {
  return (
    <Container
      maxWidth={false}
      sx={{
        padding: "48px 40px",
        height: "fit-content",
        minHeight: "91vh",
        backgroundColor: primaryColors.gray[50],
      }}
    >
      {children}
    </Container>
  );
};
