import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { useDeleteBranch } from "../../api/branches-divisions/deleteBranch";

export const DeleteBranch = ({ branchData }) => {
  const deleteBranchMutation = useDeleteBranch();

  const deleteBranchHandler = () => {
    deleteBranchMutation
      .mutateAsync({
        branchToDeleteID: branchData.id,
      })
  };

  return (
    <GenericDialog
      dialogTitle="Delete Branch"
      dialogSubtitle={
        "Are you sure you want to delete this branch? This action cannot be undone."
      }
      maxWidth="xs"
      triggerButton={
        <Tooltip title="Delete Branch">
          <IconButton
            sx={{ padding: "10px", borderRadius: "16px" }}
            aria-label="delete"
          >
            <DeleteIcon
              sx={{
                color: primaryColors.base.black,
                height: "20px",
                width: "20px",
              }}
            />
          </IconButton>
        </Tooltip>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.delete}
      submitButtonText={"Delete"}
      onSubmitCallback={deleteBranchHandler}
      closeButtonText={"Cancel"}
      isDone={deleteBranchMutation.isSuccess}
      isSubmitting={deleteBranchMutation.isLoading}
      stopPropagation
    />
  );
};
