import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ROLES } from "../../helpers/constants";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from "@mui/icons-material/Error";

export const PaymentStatuses = {
  Active: "active",
  CountdownPhase: "countdown",
  GracePhase: "grace",
  DeadlinePhase: "deadline",
};

export const paymentStatusBarHeight = "62px";

const MessageBehavior = {
  Closable: "closable",
  Unclosable: "unclosable",
  Freeze: "freeze",
};

export const PaymentStatusMessage = ({
  showPaymentStatus,
  paymentStatusMsgOpen,
  currentPhaseIsCountdown,
  setPaymentStatusMsgOpen,
  paymentStatusMessage,
}) => {
  useEffect(() => {
    setPaymentStatusMsgOpen(showPaymentStatus);
  }, [setPaymentStatusMsgOpen, showPaymentStatus]);

  return (
    paymentStatusMsgOpen && (
      <Box
        display="flex"
        justifyContent="space-between"
        padding="16px"
        color={currentPhaseIsCountdown ? "#B54708" : "#B42318"}
        position="sticky"
        height={paymentStatusBarHeight}
        boxSizing="border-box"
        top="0"
        zIndex={99999}
        sx={{
          background: `${
            currentPhaseIsCountdown ? "#FFFCF5" : "#FFFBFA"
          } !important`,
        }}
      >
        <Box display="flex" gap={2} alignItems="center">
          {currentPhaseIsCountdown ? <WarningIcon /> : <ErrorIcon />}
          <Typography fontSize="14px">{paymentStatusMessage}</Typography>
        </Box>

        {currentPhaseIsCountdown && (
          <IconButton
            sx={{ color: "#B54708" }}
            size="small"
            onClick={() => setPaymentStatusMsgOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    )
  );
};

export const usePaymentStatusInfo = ({ user }) => {
  const { pathname } = useLocation();

  let currentRouteIsRestricted = false;

  const showPaymentStatus = user
    ? user.subscription.status === PaymentStatuses.CountdownPhase ||
      user.subscription.status === PaymentStatuses.GracePhase ||
      user.subscription.status === PaymentStatuses.DeadlinePhase
    : false;

  const currentPhaseIsCountdown =
    user && user.subscription.status === PaymentStatuses.CountdownPhase;
  const currentPhaseIsGrace =
    user && user.subscription.status === PaymentStatuses.GracePhase;
  const currentPhaseIsDeadline =
    user && user.subscription.status === PaymentStatuses.DeadlinePhase;

  const roleBasedRestrictedRoutes =
    user?.role === ROLES.FacilityManager
      ? restrictedRoutesForFacilityManagers
      : restrictedRoutesForBranchManagers;

  roleBasedRestrictedRoutes.forEach((restrictedPath) => {
    // To be able to navigate to Login Page normally
    if (user && currentPhaseIsDeadline) {
      if (restrictedPath !== "/" && pathname.includes(restrictedPath)) {
        // Pages other than Landing Page
        currentRouteIsRestricted = true;
      } else if (restrictedPath === "/" && pathname === restrictedPath) {
        // Landing Page
        currentRouteIsRestricted = true;
      }
    }
  });

  return {
    // userSubscriptionExists: user && user.subscription,
    currentRouteIsRestricted,
    showPaymentStatus,
    currentPhaseIsCountdown,
    currentPhaseIsGrace,
    currentPhaseIsDeadline,
    paymentStatusMessage: user?.subscription?.message,
  };
};

const restrictedRoutesForFacilityManagers = [
  "/overall-view/progress",
  "/overall-view/reviews",
  "/overall-view/complaints",
  "/overall-view/archived",
  "/overall-view/starred",
  "/branches",
];

const restrictedRoutesForBranchManagers = [
  "/overall-view/progress",
  "/overall-view/reviews",
  "/overall-view/complaints",
  "/overall-view/archived",
  "/overall-view/starred",
  "/branches",
  "/",
];
