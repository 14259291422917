import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import { COLORS } from "../../../helpers/Colors";
import { getAnotherPage } from "../../../features/overall-view/api";

const useStyles = makeStyles({
  paginationContainer: {
    float: "right",
    marginRight: "0.8rem",
  },

  paginationIcon: {
    color: COLORS.white,
    fontSize: "30px",
    background: COLORS.meliorYellow,
    borderRadius: "50%",
  },
});

const goToPage = ({ url, func, type, setLoading }) => {
  setLoading(type);
  getAnotherPage(url).then((response) => {
    func(response);
    setLoading(false);
  });
};

const TablePagination = ({ users, setUsers, type, setLoading }) => {
  const classes = useStyles();
  return (
    <div className={classes.paginationContainer}>
      <Tooltip title="Previous Page">
        <span>
          <IconButton
            disabled={!users["previous"]}
            onClick={() =>
              goToPage({
                url: users["previous"],
                func: setUsers,
                type: type,
                setLoading: setLoading,
              })
            }
            size="large"
          >
            <ArrowLeft
              className={classes.paginationIcon}
              style={{
                background: !users["previous"]
                  ? COLORS.greyDisabled
                  : COLORS.meliorYellow,
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
      <Tooltip title="Next Page">
        <span>
          <IconButton
            disabled={!users["next"]}
            onClick={() =>
              goToPage({
                url: users["next"],
                func: setUsers,
                type: type,
                setLoading: setLoading,
              })
            }
            size="large"
          >
            <ArrowRight
              className={classes.paginationIcon}
              style={{
                background: !users["next"]
                  ? COLORS.greyDisabled
                  : COLORS.meliorYellow,
              }}
            />
          </IconButton>
        </span>
      </Tooltip>
    </div>
  );
};

export default TablePagination;
