export const fontWeightVariations = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

export const fontSizeVariations = {
  "display-2xl": {
    fontSize: "72px",
    lineHeight: "90px",
  },
  "display-xl": {
    fontSize: "60px",
    lineHeight: "72px",
  },
  "display-lg": {
    fontSize: "48px",
    lineHeight: "60px",
  },
  "display-md": {
    fontSize: "36px",
    lineHeight: "44px",
  },
  "display-sm": {
    fontSize: "30px",
    lineHeight: "38px",
  },
  "display-xs": {
    fontSize: "24px",
    lineHeight: "32px",
  },
  "text-xl": {
    fontSize: "20px",
    lineHeight: "30px",
  },
  "text-lg": {
    fontSize: "18px",
    lineHeight: "28px",
  },
  "text-md": {
    fontSize: "16px",
    lineHeight: "24px",
  },
  "text-sm": {
    fontSize: "14px",
    lineHeight: "20px",
  },
  "text-xs": {
    fontSize: "12px",
    lineHeight: "18px",
  },
};


export const responsiveFontSizeVariations = {
    "display-2xl": {
      fontSize: "72px",
      lineHeight: "90px",
    },
    "display-xl": {
      fontSize: "60px",
      lineHeight: "72px",
    },
    "display-lg": {
      fontSize: "48px",
      lineHeight: "60px",
    },
    "display-md": {
      fontSize: "1.88vw",
      lineHeight: "44px",
    },
    "display-sm": {
      fontSize: "30px",
      lineHeight: "38px",
    },
    "display-xs": {
      fontSize: "24px",
      lineHeight: "32px",
    },
    "text-xl": {
      fontSize: "20px",
      lineHeight: "30px",
    },
    "text-lg": {
      fontSize: "18px",
      lineHeight: "28px",
    },
    "text-md": {
      fontSize: "0.83vw",
      lineHeight: "24px",
    },
    "text-sm": {
      fontSize: "14px",
      lineHeight: "20px",
    },
    "text-xs": {
      fontSize: "12px",
      lineHeight: "18px",
    },
  };