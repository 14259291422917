import { Box, Rating } from "@mui/material";
import { renderReadableMilliseconds } from "../../../../helpers/timeHelpers";
import {
  PlatformIconCard,
  SkeletonForRawNumbersCards,
} from "./PlatformsInsightsCards";
import GoogleSVG from "../../../../assets/icons/reputation-dashboard/google-logo.svg";
import { CARD_VARIANTS, RawNumberCard } from "../shared/RawNumberCard";
import { primaryColors } from "../../../../helpers/customColors";

export const GoogleReviewsInsightsCards = ({
  startDate,
  endDate,
  isFetching,
  googleReviewsCardsServerResponse,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      gap="12px"
      width="100%"
    >
      <PlatformIconCard
        platformName="Google reviews"
        platformIcon={GoogleSVG}
      />
      {isFetching || !googleReviewsCardsServerResponse ? (
        <SkeletonForRawNumbersCards />
      ) : (
        googleReviewsCardsServerResponse.map(function renderGoogleReviewsCard(
          cardData,
          index
        ) {
          return (
            <RawNumberCard
              key={index}
              width="24%"
              selectedStartDate={startDate}
              selectedEndDate={endDate}
              variant={CARD_VARIANTS.red}
              cardData={formatGoogleReviewsCardContent({ index, cardData })}
              reverseIconAndMainText={index === 0}
            />
          );
        })
      )}
    </Box>
  );
};
function formatGoogleReviewsCardContent({ index, cardData }) {
  const cardContent = {
    title: cardData.title,
    semantic: cardData.semantic,
    arrow: cardData.arrow,
  };

  switch (index) {
    // Average Rating
    case 0: {
      cardContent.mainText = cardData.value;
      cardContent.points = cardData.points;
      cardContent.icon = (
        <Rating
          readOnly
          precision={0.5}
          defaultValue={cardData.value}
          size="large"
          sx={{
            "& .MuiRating-icon": {
              color: primaryColors.warning[400],
            },
          }}
        />
      );
      break;
    }
    // Total Reviews
    case 1: {
      cardContent.mainText = cardData.value.toLocaleString();
      cardContent.percentage = cardData.percentage;
      break;
    }
    // % of reviews with replies
    case 2: {
      cardContent.mainText = `${cardData.value}%`;
      cardContent.secondaryText = `(${cardData.total_replies.toLocaleString()} replied to)`;
      cardContent.percentage = cardData.percentage;
      break;
    }
    // Average Response Time
    case 3: {
      const { readableTime, timeUnit } = renderReadableMilliseconds(
        cardData.value
      );
      cardContent.mainText = readableTime;
      cardContent.secondaryText = timeUnit;
      cardContent.percentage = cardData.percentage;
      break;
    }
    default:
      break;
  }

  return cardContent;
}
