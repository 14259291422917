import { Box, Button, Grid, Typography } from "@mui/material";
import {
  gradientColorsData,
  secondaryColors,
} from "../../../../helpers/customColors";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import {
  PAYMENT_METHODS,
  SUBSCRIPTION_PLANS,
} from "../../screens/BillingSubScreen";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const BillingCards = ({
  currentPlan,
  planRenewalType,
  currentPaymentMethod,
}) => {
  const nextSubscriptionPlan = getNextSubscriptionPlan(currentPlan);
  const { fireNotification } = useContext(NotificationContext);

  const updatePaymentMethodDialog = (
    <CardActionDialog
      dialogTitle="Payment Method Card Dialog Title"
      triggerButtonLabel={"Change Payment Method"}
      disabled={currentPlan === SUBSCRIPTION_PLANS.free}
    >
      Payment Method Card Dialog Content
    </CardActionDialog>
  );

  const updatePaymentCardInfoDialog = (
    <CardActionDialog
      dialogTitle="Payment Card Info Card Dialog Title"
      triggerButtonLabel={"Update Card Info"}
    >
      Update Payment Card Info Dialog Content
    </CardActionDialog>
  );

  function renderUpgradeActionElement(nextSubscriptionPlanArg) {
    switch (nextSubscriptionPlanArg) {
      case undefined: {
        // We're at the top tier plan, Enterprise Plan.
        return null;
      }
      case SUBSCRIPTION_PLANS.enterprise: {
        // We're at Premium Plan, and upon clicking to upgrade to enterprise, send an email. 
        return (
          <BillinActionButton
            triggerButtonLabel={`Upgrade to ${SUBSCRIPTION_PLANS.enterprise} Plan`}
            onClickHandler={function sendEmailToUpgradeToEnterprisePlan() {
              fireNotification({
                title: "Upgrade request successfully sent",
                description:
                  "Our team will reach out to you promptly. Expect an email from us shortly to schedule a 30-minute call",
                type: NOTIFICATIONS_TYPE.success,
                verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
                target: NOTIFICATIONS_TARGET.admin,
              });
            }}
          />
        );
      }
      default: {
        // We're at either Free or Standard Plans
        return (
          <CardActionDialog
            dialogTitle="Plan Card Dialog Title"
            triggerButtonLabel={`Upgrade to ${nextSubscriptionPlanArg} Plan`}
          >
            Plan Card Dialog Content
          </CardActionDialog>
        );
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <BillingCard
            title={
              currentPlan === SUBSCRIPTION_PLANS.free
                ? "You've been with us since"
                : "Next invoice issued date"
            }
            body="Apr 4, 2023"
            backgroundColor={gradientColorsData.yellow.bg}
            textColor={gradientColorsData.yellow.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Invoice total"
            body="FREE"
            backgroundColor={gradientColorsData.green.bg}
            textColor={gradientColorsData.green.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total Branches"
            body="1"
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total Divisions"
            body="1"
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <BillingCard
            title={
              currentPlan === SUBSCRIPTION_PLANS.free
                ? "Free Plan"
                : `${currentPlan} plan - ${planRenewalType} renewal`
            }
            body={
              currentPlan === SUBSCRIPTION_PLANS.free
                ? "Give it a try!"
                : "July 4, 2023"
            }
            backgroundColor={gradientColorsData.yellow.bg}
            textColor={gradientColorsData.yellow.text}
            action={renderUpgradeActionElement(nextSubscriptionPlan)}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total active users today"
            body="3"
            backgroundColor={gradientColorsData.green.bg}
            textColor={gradientColorsData.green.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Payment Method"
            body={
              currentPaymentMethod === PAYMENT_METHODS.offline
                ? "Offline"
                : "Online"
            }
            backgroundColor={gradientColorsData.gray.bg}
            textColor={gradientColorsData.gray.text}
            action={
              currentPaymentMethod === PAYMENT_METHODS.offline
                ? updatePaymentMethodDialog
                : updatePaymentCardInfoDialog
            }
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Invoice sent to"
            body="samygeorge007@gmail.com"
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
            action={
              <CardActionDialog
                dialogTitle="Email Card Dialog Title"
                triggerButtonLabel="Change Email"
              >
                Email Card Dialog Content
              </CardActionDialog>
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BillingCard = ({ title, body, action, backgroundColor, textColor }) => {
  const slicedBodyText = body.substring(0, 17);
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={3}
      boxShadow={2}
      height="140px"
      p="24px"
      sx={{
        background: backgroundColor,
      }}
    >
      <Typography
        variant="text-md"
        fontWeight="bold"
        color={textColor}
        sx={{ mb: "24px" }}
      >
        {title}
      </Typography>
      <Typography variant="display-md" fontWeight="bold" sx={{ mb: "16px" }}>
        {`${slicedBodyText}${slicedBodyText.length >= 17 ? "..." : ""}`}
      </Typography>
      {action && action}
    </Box>
  );
};

export const CardActionDialog = ({
  dialogTitle,
  triggerButtonLabel,
  children,
  disabled,
}) => {
  return (
    <GenericDialog
      dialogTitle={dialogTitle}
      maxWidth="sm"
      triggerButton={
        <Button
          variant="text"
          sx={{
            width: "fit-content",
            color: secondaryColors.blueLight[500],
            fontSize: fontSizeVariations["text-md"],
            fontWeight: fontWeightVariations.bold,
            textTransform: "capitalize",
            padding: 0,
          }}
          disabled={disabled}
        >
          {triggerButtonLabel}
        </Button>
      }
    >
      {children}
    </GenericDialog>
  );
};

const BillinActionButton = ({
  disabled,
  triggerButtonLabel,
  onClickHandler,
}) => {
  return (
    <Button
      variant="text"
      onClick={onClickHandler}
      sx={{
        width: "fit-content",
        color: secondaryColors.blueLight[500],
        fontSize: fontSizeVariations["text-md"],
        fontWeight: fontWeightVariations.bold,
        textTransform: "capitalize",
        padding: 0,
      }}
      disabled={disabled}
    >
      {triggerButtonLabel}
    </Button>
  );
};

function getNextSubscriptionPlan(currentSubscriptionPlan) {
  let nextSubscriptionPlan = undefined;

  switch (currentSubscriptionPlan) {
    case SUBSCRIPTION_PLANS.free: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.standard;
      break;
    }
    case SUBSCRIPTION_PLANS.standard: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.premium;
      break;
    }
    case SUBSCRIPTION_PLANS.premium: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.enterprise;
      break;
    }
    case SUBSCRIPTION_PLANS.enterprise: {
      nextSubscriptionPlan = undefined;
      break;
    }
    default: {
      nextSubscriptionPlan = currentSubscriptionPlan;
      break;
    }
  }

  return nextSubscriptionPlan;
}
