import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getComplaintsRecurrence = ({
  startDate,
  endDate,
  branches,
  divisions,
  relatedTeams,
  labelsIDs,
}) => {
  const requestURL = `${API_URL}/complaints-overview/complaints-recurrence`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
      ...(divisions?.length > 0 ? { divisions: divisions?.join(",") } : {}),
      ...(relatedTeams?.length > 0
        ? { related_teams: relatedTeams?.join(",") }
        : {}),
      ...(labelsIDs ? { label: labelsIDs ? labelsIDs : "" } : {}),
    },
  });
};

export const useGetComplaintsRecurrence = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["complaints-recurrence-data", params],
    queryFn: () => getComplaintsRecurrence(params),
    keepPreviousData: true,
  });
};
