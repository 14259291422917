import { useMemo, useState } from "react";
import { TableContainer } from "../../revamped-overall-view/components/shared/TableContainer";
import moment from "moment";
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useGetReports } from "../api/getReports";
import { Link } from "react-router-dom";
import { CloudDownload } from "@mui/icons-material";
import { OverallViewTable } from "../../revamped-overall-view/components/shared/OverallViewTable";
import { IsLoadingContainer } from "../../../reusable/components/IsLoadingContainer";
import MUIReactTable from "material-react-table";

export const ReportsHubPage = () => {
  const currentYear = moment().year();
  const currentMonth = moment().month();

  const [selectedReportsMonth, setSelectedReportsMonth] =
    useState(currentMonth);
  const [selectedReportsYear, setSelectedReportsYear] = useState(currentYear);

  const {
    data: reportsDataResponse,
    isLoading: isLoadingReportsData,
    isError: isErrorInReportsData,
    isFetching: isFetchingReportsData,
  } = useGetReports({
    params: { year: selectedReportsYear, month: selectedReportsMonth },
  });

  function updateSelectedReporsYearHandler(newlySelectedYear) {
    setSelectedReportsYear(newlySelectedYear);
  }
  function updateSelectedReporsMonthHandler(newlySelectedMonth) {
    setSelectedReportsMonth(newlySelectedMonth);
  }

  let yearsToSelectFrom = [currentYear];
  let currentYearForYearsOptions = moment().year();

  for (let i = 0; i < 10; i++) {
    currentYearForYearsOptions -= 1;
    yearsToSelectFrom.push(currentYearForYearsOptions);
  }

  const reportsTableColumns = useMemo(
    () => [
      {
        header: "Date",
        id: "date",
        accessorFn: function renderReportDate(reportData) {
          console.log(reportData.date, "reportData.date");
          return reportData.date;
        },
      },
      {
        header: "Report Name",
        id: "report_type",
        accessorFn: function renderReportNamee(reportData) {
          console.log(
            reportTypeSlugToLabel[reportData.report_type],
            "reportTypeSlugToLabel[reportData.report_type]"
          );
          return reportTypeSlugToLabel[reportData.report_type];
        },
      },
      {
        header: "",
        id: "actions",
        muiTableHeadCellProps: {
          align: "right",
        },
        muiTableBodyCellProps: {
          align: "right",
        },
        accessorFn: function renderShowReportButton(reportData) {
          return (
            <Link
              to={`report/${reportData.report_type}?${new URLSearchParams(
                reportData.query_parameter
              ).toString()}`}
              target="_blank"
            >
              <IconButton size="large">
                <CloudDownload />
              </IconButton>
            </Link>
          );
        },
      },
    ],
    []
  );

  const yearFilter = (
    <Box width="7rem">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Year</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedReportsYear.toString()}
          label="Year"
          onChange={function (event) {
            updateSelectedReporsYearHandler(event.target.value);
          }}
          sx={{
            height: "2.5rem",
          }}
        >
          {yearsToSelectFrom.map(function renderYearOption(year) {
            return <MenuItem value={year}>{year}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );

  const montjFilter = (
    <Box width="7rem">
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Month</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selectedReportsMonth}
          label="Month"
          onChange={function (event) {
            updateSelectedReporsMonthHandler(event.target.value);
          }}
          sx={{
            height: "2.5rem",
          }}
        >
          {months.map(function renderMonthOption(month) {
            return <MenuItem value={month}>{month}</MenuItem>;
          })}
        </Select>
      </FormControl>
    </Box>
  );

  const reportsFilters = (
    <Box display="flex" gap={4}>
      {yearFilter}
      {montjFilter}
    </Box>
  );

  return (
    <TableContainer label="Reports" tableFiltersBelowName={reportsFilters}>
      <IsLoadingContainer isLoading={isLoadingReportsData}>
        {reportsDataResponse && reportsDataResponse.data !== undefined && (
          <MUIReactTable
            columns={reportsTableColumns}
            data={reportsDataResponse.data}
            state={{
              isLoading: isLoadingReportsData,
              showAlertBanner: isErrorInReportsData,
              showProgressBars: isFetchingReportsData,
            }}
            muiToolbarAlertBannerProps={
              isErrorInReportsData
                ? {
                    color: "error",
                    children: "Error loading data",
                  }
                : undefined
            }
            enableColumnActions={false}
            enableColumnFilters={false}
            enableTopToolbar={false}
          />
        )}
      </IsLoadingContainer>
    </TableContainer>
  );
};

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const reportTypeSlugToLabel = {
  "monthly-performance": "Monthly Performance",
  "sms-count-weekly": "SMS Count Weekly",
  "sms-count-monthly": "SMS Count Monthly",
  "response-rate": "Response Rate",
};

// export const reportTypes = {
//     MonthlyPerformance: 'monthly-performance',
//     SMSCountWeekly: 'sms-count-weekly',
//     SMSCountMonthly: 'sms-count-monthly',
//     ResponseRate: 'response-rate,'
// };
