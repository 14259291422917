import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const COMPLAINTS_PAGE_COUNT = 10;

export const defaultComplaintsListParams = {
  page: 1,
  pageSize: COMPLAINTS_PAGE_COUNT,
};

const getComplaints = (queryStrings) => {
  const queryData = {
    params: {
      ...queryStrings,
      page_size: queryStrings.pageSize
    },
  };
  const requestURL = `${API_URL}/complaints`;

  return melior.get(requestURL, queryData);
};

export const useGetComplaints = (
  { params, config } = {
    params: defaultComplaintsListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["complaints", params],
    queryFn: () => getComplaints(params),
    keepPreviousData: true,
  });
};
