import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const INVOICES_PAGE_COUNT = 8;

export const defaultInvoicesListParams = {
  page: 1,
};

const getInvoices = (queryStrings) => {
  const queryData = {
    params: {
      ...queryStrings,
    },
  };
  const requestURL = `${API_URL}/invoices`;

  return melior.get(requestURL, queryData);
};

export const useGetInvoices = (
  { params, config } = {
    params: defaultInvoicesListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["invoices", params],
    queryFn: () => getInvoices(params),
    keepPreviousData: true,
  });
};
