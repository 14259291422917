import { useMutation, useQueryClient } from "@tanstack/react-query";
import { melior } from "../../../../helpers/constants";

export const alterCurrentUserDetails = (data) => {
  const requestURL = `/user/details`;
  return melior.patch(requestURL, data);
};
export const useAlterCurrentUserDetails = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["current-user-details"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: alterCurrentUserDetails,
  });
};
