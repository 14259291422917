import { API_URL, melior } from "../../../helpers/constants";

export const submitOnlineSurvey = ({ surveyType, surveyData }) => {
  const requestURL = `${API_URL}/feedback-invitation/submit/${surveyType}`;

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, { ...surveyData })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
