import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const addReviewNote = ({ reviewID, newNoteText }) => {
  let requestURL = `${API_URL}/reviews/${reviewID}/note`;

  return melior.post(requestURL, {
    text: newNoteText,
  });
};

export const useAddReviewNote = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["review-notes"], { type: "active" });
    },
    ...config,
    mutationFn: addReviewNote,
  });
};
