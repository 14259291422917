import axios from "axios";

import { URL, API_URL, melior } from "./constants";

const login = (username, password) => {
  let loginFormData = new FormData();
  loginFormData.append("grant_type", "password");
  loginFormData.append("username", username);
  loginFormData.append("password", password);

  return new Promise((resolve, reject) => {
    axios
      .post(`${URL}/o/token/`, loginFormData, {
        headers: {
          Authorization: "Basic TWVsaW9yRGFzaGJvYXJkOg==",
        },
      })
      .then(async (response) => {
        response.data.lastRefresh = new Date().getTime();
        localStorage.setItem("meliorUser", JSON.stringify(response.data));
        getUser()
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const logout = () => {
  localStorage.removeItem("meliorUser");

  localStorage.setItem("regFirebase", "false");
  return Promise.resolve();
};

const getUser = async () => {
  let meliorUser = JSON.parse(localStorage.getItem("meliorUser"));

  if (!meliorUser) {
    return Promise.resolve(null);
  }

  melior.defaults.headers.common["Authorization"] =
    "Bearer " + meliorUser.access_token;

  melior.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error?.response?.status && error.response.status === 401) {
        console.error("Authentication Token Expired", error);
        logout();
        window.location.reload(true);
      }
      return Promise.reject(error);
    }
  );

  return await melior
    .get("/users/current")
    .catch(function failedToGetUser(error) {
      logout();
      console.error("Failed to get the users in /users/current", error);
      window.location.reload(true);
    });

  //   let currDate = new Date();
  //   let duration = meliorUser["expires_in"] * 1000;
  //   let diff = currDate.getTime() - meliorUser.lastRefresh;
  //   if (diff >= duration) {
  //     // Activate Refresh Token by Uncommenting this "if" block
  //     // refresh
  //     // let getUserFormData = new FormData();
  //     // getUserFormData.append("grant_type", "refresh_token");
  //     // getUserFormData.append("refresh_token", meliorUser.refresh_token);
  //     // return axios
  //     //   .post(`${URL}/o/token/`, getUserFormData, {
  //     //     headers: {
  //     //       Authorization: "Basic TWVsaW9yRGFzaGJvYXJkOg==",
  //     //     },
  //     //   })
  //     //   .then((response) => {
  //     //     meliorUser.refresh_token = response.data.refresh_token;
  //     //     meliorUser.access_token = response.data.access_token;
  //     //     meliorUser.lastRefresh = new Date().getTime();
  //     //     localStorage.setItem("meliorUser", JSON.stringify(meliorUser));
  //     //     melior.defaults.headers.common["Authorization"] =
  //     //       "Bearer " + meliorUser.access_token;
  //     //     return melior.get("/users/current").catch((error) => {
  //     //       logout();
  //     //       throw error;
  //     //     });
  //     //   })
  //     //   .catch((error) => {
  //     //     logout();
  //     //   });
  //   } else {
  //     // Refresh Token "only if" API responded with 401
  //     melior.defaults.headers.common["Authorization"] =
  //       "Bearer " + meliorUser.access_token;

  //     melior.interceptors.response.use(
  //       (response) => {
  //         return response;
  //       },
  //       (error) => {
  //         // Uncomment this block to Activate Refresh Token upon receiving a 401 status
  //         // if (error?.response?.status && error.response.status === 401) {
  //         //   return refreshToken()
  //         //     .then((response) => {
  //         //       meliorUser.refresh_token = response.data.refresh_token;
  //         //       meliorUser.access_token = response.data.access_token;
  //         //       meliorUser.lastRefresh = new Date().getTime();
  //         //       localStorage.setItem("meliorUser", JSON.stringify(meliorUser));
  //         //       melior.defaults.headers.common["Authorization"] =
  //         //         "Bearer " + response.data.access_token;
  //         //       const config = error.config;
  //         //       config.headers.Authorization =
  //         //         "Bearer " + response.data.access_token;
  //         //       return new Promise((resolve, reject) => {
  //         //         melior
  //         //           .request(config)
  //         //           .then((response) => {
  //         //             resolve(response);
  //         //           })
  //         //           .catch((error) => {
  //         //             reject(error);
  //         //           });
  //         //       });
  //         //     })
  //         //     .catch((error) => {
  //         //       Promise.reject(error);
  //         //       logout();
  //         //     });
  //         // }
  //         // logout();
  //         // return new Promise((resolve, reject) => {
  //         //   reject(error);
  //         // });
  //       }
  //     );
  //     return melior.get("/users/current").catch((error) => {
  //       logout();
  //       throw error;
  //     });
  //   }
};

const refreshToken = () => {
  let meliorUser = JSON.parse(localStorage.getItem("meliorUser"));
  let getUserFormData = new FormData();
  getUserFormData.append("grant_type", "refresh_token");
  getUserFormData.append("refresh_token", meliorUser.refresh_token);
  return new Promise((resolve, reject) => {
    melior
      .post(`${URL}/o/token/`, getUserFormData, {
        headers: {
          Authorization: "Basic TWVsaW9yRGFzaGJvYXJkOg==",
        },
      })
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const setFirebaseActive = async () => {
  let token = JSON.parse(localStorage.getItem("meliorUser")).access_token;
  token = "Bearer " + token;
  const regToken = localStorage.getItem("firebase");
  const input = {
    registration_id: regToken,
    type: "web",
    active: true,
  };

  axios({
    method: "put",
    url: `${API_URL}/devices/${localStorage.getItem("firebase")}`,
    data: input,
    headers: {
      Authorization: token,
    },
  })
    .then(async (res) => {})
    .catch((e) => {});
};
//set firebase server to inactive
const setFirebaseInactive = async () => {
  let token = JSON.parse(localStorage.getItem("meliorUser")).access_token;
  token = "Bearer " + token;
  const regToken = localStorage.getItem("firebase");
  const input = {
    registration_id: regToken,
    type: "web",
    active: false,
  };

  axios({
    method: "put",
    url: `${API_URL}/devices/${localStorage.getItem("firebase")}`,
    data: input,
    headers: {
      Authorization: token,
    },
  })
    .then(async (res) => {})
    .catch((e) => {});
};
// check if registeration is done or not
const checkFirebaseRegister = async () => {
  let token = JSON.parse(localStorage.getItem("meliorUser")).access_token;
  token = "Bearer " + token;
  const regToken = localStorage.getItem("firebase");

  axios({
    method: "get",
    url: `${API_URL}/devices/${regToken}`,
    headers: {
      Authorization: token,
    },
  })
    .then(async (res) => {
      await setFirebaseActive();
    })
    .catch(async () => {
      await firebaseRegister();
    });
};
// firebase Registeration
const firebaseRegister = async () => {
  let user_id = 0;
  await getUser().then((res) => (user_id = res.data.id));
  let token = JSON.parse(localStorage.getItem("meliorUser")).access_token;
  token = "Bearer " + token;
  const regToken = localStorage.getItem("firebase");
  const input = {
    registration_id: regToken,
    type: "web",
    user_id: user_id,
  };
  axios({
    method: "post",
    url: `${API_URL}/devices`,
    data: input,
    headers: {
      Authorization: token,
    },
  })
    .then(async (res) => {
      localStorage.setItem("regFirebase", "true");
    })
    .catch((e) => {});
};
export default {
  login,
  logout,
  getUser,
  firebaseRegister,
  setFirebaseActive,
  setFirebaseInactive,
  checkFirebaseRegister,
};
