import { useQuery } from "@tanstack/react-query";
import { melior, API_URL } from "../../../../helpers/constants";

export const getCurrentUserDetails = () => {
  const requestURL = `/user/details`;
  return melior.get(requestURL);
};

export const useGetCurrentUserDetails = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["current-user-details"],
    queryFn: () => getCurrentUserDetails(),
    keepPreviousData: true,
  });
};
