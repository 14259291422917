import { Box, Icon, Rating, Typography } from "@mui/material";
import { CARD_VARIANTS, RawNumberCard } from "../shared/RawNumberCard";
import TripadvisorSVG from "../../../../assets/icons/reputation-dashboard/tripadvisor-logo.svg";
import FilledBubbleSVG from "../../../../assets/icons/reputation-dashboard/filled-bubble.svg";
import EmptyBubbleSVG from "../../../../assets/icons/reputation-dashboard/empty-bubble.svg";
import FoodSubRatingSVG from "../../../../assets/icons/reputation-dashboard/food-sub-rating.svg";
import ServiceSubRatingSVG from "../../../../assets/icons/reputation-dashboard/service-sub-rating.svg";
import ValueSubRatingSVG from "../../../../assets/icons/reputation-dashboard/value-sub-rating.svg";
import AtmosphereSubRatingSVG from "../../../../assets/icons/reputation-dashboard/atmosphere-sub-rating.svg";
import {
  PlatformIconCard,
  SkeletonForRawNumbersCards,
} from "./PlatformsInsightsCards";

export const TripadvisorInsightsCards = ({
  startDate,
  endDate,
  isFetching,
  tripadvisorCardsServerResponse,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="flex-start"
      gap="12px"
      width="100%"
    >
      <PlatformIconCard
        platformName="Tripadvisor reviews"
        platformIcon={TripadvisorSVG}
      />
      {isFetching || !tripadvisorCardsServerResponse ? (
        <SkeletonForRawNumbersCards />
      ) : (
        tripadvisorCardsServerResponse?.map(function renderTripadvisorCard(
          cardData,
          index
        ) {
          // Sub Ratings
          if (index === 3)
            return (
              <RawNumberCard
                key={index}
                width="22%"
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                variant={CARD_VARIANTS.tripgreen}
                cardData={{
                  title: cardData.title,
                }}
                hideChangeInRateBadge={true}
              >
                <Box>
                  {cardData.value?.map(function renderSubRatings(
                    subRatingData,
                    index
                  ) {
                    return (
                      <Box
                        key={index}
                        display="flex"
                        flexDirection="row"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Box
                          display="flex"
                          flexDirection="row"
                          alignItems="baseline"
                          justifyContent="center"
                          gap={1}
                        >
                          <Icon
                            sx={{
                              width: "20px",
                            }}
                          >
                            {renderSubRatingsIcon(subRatingData.name)}
                          </Icon>
                          <Typography variant="text-md">
                            {subRatingData.name}
                          </Typography>
                        </Box>
                        <TripadvisorBubbleRating
                          rating={subRatingData.rating}
                          size="16px"
                        />
                      </Box>
                    );
                  })}
                </Box>
              </RawNumberCard>
            );
          else
            return (
              <RawNumberCard
                key={index}
                width="22%"
                selectedStartDate={startDate}
                selectedEndDate={endDate}
                variant={CARD_VARIANTS.tripgreen}
                cardData={formatTripadvisorCardContent({
                  index,
                  cardData,
                })}
                reverseIconAndMainText={index === 0}
              />
            );
        })
      )}
    </Box>
  );
};
const TripadvisorBubbleRating = ({ rating, size }) => (
  <Rating
    readOnly
    precision={0.5}
    defaultValue={rating}
    icon={
      <Box
        component="img"
        src={FilledBubbleSVG}
        style={{ width: size, marginInline: "2px" }}
        alt="filled-bubble-ing"
      />
    }
    emptyIcon={
      <Box
        component="img"
        src={EmptyBubbleSVG}
        style={{ width: size, marginInline: "2px" }}
        alt="empty-bubble-img"
      />
    }
    sx={{
      "& .MuiRating-icon": {
        color: "#00AA6C", //TODO: color variable?
      },
    }}
  />
);

function formatTripadvisorCardContent({ index, cardData }) {
  let cardContent = {
    title: cardData.title,
    semantic: cardData.semantic,
    arrow: cardData.arrow,
  };

  switch (index) {
    // Average Rating
    case 0: {
      cardContent.mainText = cardData.value;
      cardContent.points = cardData.points;
      cardContent.icon = (
        <TripadvisorBubbleRating rating={cardData.value} size="24px" />
      );
      break;
    }
    // Total Reviews
    case 1: {
      cardContent.mainText = cardData.value;
      cardContent.percentage = cardData.percentage;
      break;
    }
    // % of reviews with replies
    case 2: {
      cardContent.mainText = `${cardData.value}%`;
      cardContent.secondaryText = `(${cardData.total_replies} replied to)`;
      cardContent.percentage = cardData.percentage;
      break;
    }
    default:
      break;
  }

  return cardContent;
}
function renderSubRatingsIcon(subRatingName) {
  switch (subRatingName) {
    case TRIPADVISOR_SUB_RATINGS.Food:
      return (
        <Box
          component="img"
          src={FoodSubRatingSVG}
          style={{ width: "16px", marginInline: "2px" }}
          alt="empty-bubble-img"
        />
      );
    case TRIPADVISOR_SUB_RATINGS.Atmosphere:
      return (
        <Box
          component="img"
          src={AtmosphereSubRatingSVG}
          style={{ width: "16px", marginInline: "2px" }}
          alt="empty-bubble-img"
        />
      );
    case TRIPADVISOR_SUB_RATINGS.Service:
      return (
        <Box
          component="img"
          src={ServiceSubRatingSVG}
          style={{ width: "16px", marginInline: "2px" }}
          alt="empty-bubble-img"
        />
      );
    case TRIPADVISOR_SUB_RATINGS.Value:
      return (
        <Box
          component="img"
          src={ValueSubRatingSVG}
          style={{ width: "16px", marginInline: "2px" }}
          alt="empty-bubble-img"
        />
      );
    default:
      break;
  }
}

const TRIPADVISOR_SUB_RATINGS = {
  Food: "Food",
  Atmosphere: "Atmosphere",
  Service: "Service",
  Value: "Value",
};
