import { OverallViewTable } from "../../../revamped-overall-view/components/shared/OverallViewTable";
import { AllMembersTableRowDetails } from "./AllMembersTableRowDetails";
import { AllMembersTableEditDetails } from "./AllMembersTableEditDetails";

import { DeleteMember } from "./DeleteMember";
import EditIcon from "@mui/icons-material/Edit";
import { NoMembersPage } from "./NoMembersPage";
import { useListAPIsStateManagement } from "../../../../reusable/hooks/useListAPIStateManagement";
import {
  defaultMembersListParams,
  useGetMembers,
  useGetUserRoles,
} from "../../api/members";
import { Box, IconButton, Tooltip } from "@mui/material";
import LoadingPage from "../../../../layout/LoadingPage";
import { primaryColors } from "../../../../helpers/customColors";
import { useState } from "react";

export const AllMembersTable = () => {
  const [rowPanelDetailsType, setRowPanelDetailsType] = useState(
    rowPanelDetailsTypes.default
  );

  const { apiListArgsState, updatePageHandler } = useListAPIsStateManagement({
    initialState: {
      ...defaultMembersListParams,
    },
  });
  const {
    data: membersResponse,
    isFetching: isFetchingMembers,
    isError: membersFetchingError,
    isLoading: isLoadingMembers,
  } = useGetMembers({ params: { type: "users", page: apiListArgsState.page } });
  const { data: userRolesResponse, isLoading: isLoadingUserRoles } =
    useGetUserRoles();

  function renderDynamicRowPanelDetails({ rowPanelDetailsTypeArg, row }) {
    switch (rowPanelDetailsTypeArg) {
      case rowPanelDetailsTypes.default:
        return <AllMembersTableRowDetails memberDetails={row.original} />;
      case rowPanelDetailsTypes.edit:
        return <AllMembersTableEditDetails memberDetails={row.original} />;
      default: {
        return null;
      }
    }
  }

  const membersTableColumns = [
    {
      header: "Email",
      id: "email",
      accessorKey: "email",
      accessorFn: function renderUserEmail(memberData) {
        if (memberData.email.length > 0) return memberData.email;
        else return "N/A";
      },
      size: 50,
    },
    {
      header: "Username",
      accessorKey: "username",
      size: 50,
    },
    {
      header: "Role",
      id: "role",
      accessorFn: function renderUserRole(memberData) {
        for (let userRole of userRolesResponse.data) {
          if (userRole.value === memberData.role) return userRole.label;
        }
      },
      size: 50,
    },
    {
      header: "",
      id: "actions",
      accessorFn: function renderMemberActions(memberData) {
        return (
          <Box>
            <Tooltip title="Edit Member">
              <IconButton
                sx={{ padding: "10px", borderRadius: "16px" }}
                aria-label="edit"
                onClick={() =>
                  setRowPanelDetailsType(rowPanelDetailsTypes.edit)
                }
              >
                <EditIcon
                  sx={{
                    color: primaryColors.base.black,
                    height: "20px",
                    width: "20px",
                  }}
                />
              </IconButton>
            </Tooltip>
            <DeleteMember memberData={memberData} />
          </Box>
        );
      },
      size: 50,
    },
  ];

  return (
    <Box>
      {isLoadingMembers || isLoadingUserRoles ? (
        <LoadingPage />
      ) : membersResponse.data.count > 1 ? (
        <OverallViewTable
          columns={membersTableColumns}
          data={membersResponse.data.results}
          totalRecords={membersResponse.data.count}
          isLoading={isLoadingMembers}
          isError={membersFetchingError}
          externalPaginationProps={{
            externalCurrentPage: apiListArgsState.page,
            updateExternalCurrentPage: updatePageHandler,
            isFetching: isFetchingMembers,
          }}
          uniqueColumnName="username"
          resetRowPanelDetailsTypeCallback={function setRowPanelDetailsTypeToDefaultOnClose() {
            setRowPanelDetailsType(rowPanelDetailsTypes.default);
          }}
          renderRowDetails={({ row }) => {
            return renderDynamicRowPanelDetails({
              rowPanelDetailsTypeArg: rowPanelDetailsType,
              row,
            });
          }}
        />
      ) : (
        <NoMembersPage />
      )}
    </Box>
  );
};
const rowPanelDetailsTypes = {
  default: "default",
  edit: "edit",
};
