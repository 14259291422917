import { makeStyles } from "@mui/styles";
import { CircularProgress, Grid } from "@mui/material";
import { COLORS } from "../../../helpers/Colors";

const useStyles = makeStyles(() => ({
  loadingBody: {
    height: "100%",
    minHeight: "100px",
  },
  meliorYellow: {
    color: COLORS.meliorYellow,
  },
}));

const LoadingInModal = ({ height }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      className={classes.loadingBody}
      style={{ maxHeight: height, minHeight: height }}
    >
      <CircularProgress className={classes.meliorYellow} />
    </Grid>
  );
};
export default LoadingInModal;
