import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Archive, Unarchive } from "@mui/icons-material";
import { useUpdateReviewArchivalState } from "../../api/reviews/updateReviewArchivalState";

export const ArchiveReview = ({ reviewRecord }) => {
  const updateReviewArchivalStateMutation = useUpdateReviewArchivalState();

  return (
    <Tooltip
      title={reviewRecord.archived === true ? "Un-Archive" : "Archive"}
      placement="top"
    >
      {updateReviewArchivalStateMutation.isLoading ? (
        <CircularProgress size="30px" />
      ) : (
        <IconButton
          sx={{ height: "50px", width: "40px" }}
          aria-label="star"
          onClick={(e) => {
            updateReviewArchivalStateMutation.mutateAsync({
              reviewID: reviewRecord.id,
              newArchivalState: !reviewRecord.archived,
            });
            e.stopPropagation();
          }}
        >
          {reviewRecord.archived === true ? (
            <Unarchive
              sx={{
                //   color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          ) : (
            <Archive
              sx={{
                //   color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
