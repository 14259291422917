import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import NoContactInfoSVG from "../../assets/icons/contacts-icon-states/NoContactInfo.svg";
import ContactOnlySVG from "../../assets/icons/contacts-icon-states/ContactOnly.svg";
import ContactCommentOnlySVG from "../../assets/icons/contacts-icon-states/ContactCommentOnly.svg";
import ContactAndCommentSVG from "../../assets/icons/contacts-icon-states/ContactAndComment.svg";
import { COLORS } from "../../helpers/Colors";
import StringHelpers from "../../helpers/StringHelpers";
import { renderAudio } from "../../helpers/General";
import CloseIcon from "@mui/icons-material/Close";
import React, { useCallback, useEffect, useRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "400px",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 2, 2, 2),
    outline: "none",
    paddingLeft: "10px",
    backgroundColor: COLORS.white,
  },
  content: {
    width: "400px",
    overflowY: "auto",
  },
  contactLabel: {
    color: COLORS.brightRed,
    fontSize: "16px",
    fontFamily: "Avenir-Heavy",
  },
  contactData: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Avenir-Medium",
    marginLeft: "5px",
  },
  comment: {
    fontSize: "18px",
    fontFamily: "Avenir-Heavy",
    marginTop: "20px",
    marginBottom: "0px",
    textAlign: "center",
  },
  closeButtonContainer: {
    width: "100%",
    textAlign: "end",
    maxHeight: "10px",
  },
  closeButton: {
    margin: "0px",
    marginRight: "auto",
  },
  row: {
    margin: "10px",
  },
}));

export const ContactsDialog = ({
  rawRecord,
  contactsFor,
  customTriggerButton,
}) => {
  const classes = useStyles();

  const {
    id,
    contacts,
    comment_text: comment,
    comment_audio: commentAudio,
    channel,
  } = rawRecord;

  let channelText = StringHelpers.capitalizeFirstLetter(channel);

  let contactsSVG = undefined;
  const contactsExist = contacts?.name || contacts?.phone || contacts?.email;
  const commentExists = comment || commentAudio;

  if (contactsExist && commentExists) contactsSVG = ContactAndCommentSVG;
  if (!contactsExist && !commentExists) contactsSVG = NoContactInfoSVG;
  if (!contactsExist && commentExists) contactsSVG = ContactCommentOnlySVG;
  if (contactsExist && !commentExists) contactsSVG = ContactOnlySVG;

  return (
    <OlderGenericDialog
      dialogTitle="Contacts Info"
      dialogSubtitle={`${contactsFor} - ${id}`}
      triggerButton={
        customTriggerButton ? (
          customTriggerButton
        ) : (
          <Tooltip title={"Contacts Info"} placement="top">
            <span>
              <IconButton
                aria-label="star"
                // Because the Icons SVG design is not consistent with the Activity
                // Logs SVG, so it's shifted downwards a bit.
                sx={{ marginBottom: "4px" }}
              >
                <img
                  src={contactsSVG}
                  alt="Contacts Icon"
                  style={{ width: "28px" }}
                />
              </IconButton>
            </span>
          </Tooltip>
        )
      }
      triggerButtonDisplay="inline"
    >
      <Grid className={classes.content}>
        <Grid
          item
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div>
            {contacts?.name && (
              <span className={classes.contactLabel}>
                Name:
                <span className={classes.contactData}>{contacts?.name}</span>
              </span>
            )}
          </div>
          <div>
            {contacts?.email && (
              <span className={classes.contactLabel}>
                Email:
                <span className={classes.contactData}>{contacts?.email}</span>
              </span>
            )}
          </div>
          <div>
            {(!!contacts?.number || !!contacts?.phone) && (
              <span className={classes.contactLabel}>
                Number:
                <span className={classes.contactData}>
                  {contacts?.number}
                  {contacts?.phone}
                </span>
              </span>
            )}
          </div>
          {!!comment && <div className={classes.comment}>{comment}</div>}
          {!!commentAudio && (
            <div className={classes.comment}>{renderAudio(commentAudio)}</div>
          )}
          <Box
            display="flex"
            justifyContent="space-around"
            marginTop="2rem"
            width="100%"
          >
            <span className={classes.contactLabel}>
              Channel:
              <span className={classes.contactData}>{channelText}</span>
            </span>
          </Box>
        </Grid>
      </Grid>
    </OlderGenericDialog>
  );
};

export const OlderGenericDialog = ({
  children,
  triggerButton,
  triggerButtonDisplay = "block",
  dialogTitle,
  dialogSubtitle,
  dialogContentText,
  dialogActionsContent,
  closeButtonText = undefined,
  isDone = false,
  maxWidth = false,
  scrollType = "paper",
  openByDefault = false,
  forceReopen = false,
  preventClosing = false,
  variant = "secondary",
  onCloseCallback = undefined,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    onCloseCallback && onCloseCallback();
  }, [onCloseCallback]);

  const descriptionElementRef = useRef(null);
  useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  useEffect(() => {
    if (isDone) {
      handleClose();
    }
  }, [handleClose, isDone]);

  useEffect(() => {
    triggerButton === undefined && setOpen(openByDefault);
  }, [openByDefault, triggerButton]);

  useEffect(() => {
    if (forceReopen === true) {
      setOpen(true);
    }
  }, [forceReopen]);

  const triggerDialog =
    triggerButton !== undefined
      ? React.cloneElement(triggerButton, {
          onClick: handleClickOpen(),
        })
      : null;

  useEffect(() => {
    if (openByDefault) {
      setOpen(true);
    }
  }, [openByDefault]);

  return (
    <Box display={triggerButtonDisplay}>
      {triggerButton && triggerDialog}
      <Dialog
        open={open}
        onClose={!preventClosing && handleClose}
        fullWidth={maxWidth !== false ? true : undefined}
        maxWidth={maxWidth}
        scroll={scrollType}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle
          color={variant}
          sx={{ fontWeight: "bold", fontSize: "150%", textAlign: "center" }}
          id="scroll-dialog-title"
        >
          {dialogTitle}
          <Box display="flex flex-col">
            {dialogSubtitle && (
              <Typography fontSize={"60%"} color="gray">
                {dialogSubtitle}
              </Typography>
            )}
          </Box>
          {!preventClosing && (
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
        <DialogContent dividers={scrollType === "paper"}>
          {dialogContentText && (
            <DialogContentText sx={{ textAlign: "center" }}>
              {dialogContentText}
            </DialogContentText>
          )}
          {children}
        </DialogContent>
        {((!preventClosing && closeButtonText) || dialogActionsContent) && (
          <DialogActions>
            <Box display="flex" gap={2} width="100%">
              {dialogActionsContent && dialogActionsContent}
              {!preventClosing && closeButtonText && (
                <Button onClick={handleClose} variant="outlined">
                  {closeButtonText}
                </Button>
              )}
            </Box>
          </DialogActions>
        )}
      </Dialog>
    </Box>
  );
};
