import { API_URL, melior } from "../../../helpers/constants";

export const getTemplateQuestions = ({ selectedTemplate, isMminimalized }) => {
    let requestURL = `${API_URL}/templates/${selectedTemplate}/questions`;
    if (!!isMminimalized) {
      requestURL += "?reviews-list";
    }
    return new Promise((resolve, reject) => {
      melior
        .get(requestURL)
        .then(async (response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };