import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getComplaintAttachment = ({ complaintID, fileID }) => {
  const requestURL = `${API_URL}/complaints/${complaintID}/attachments/${fileID}`;

  return melior.get(requestURL);
};

export const useGetComplaintAttachment = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["complaint-notes", params],
    queryFn: () => getComplaintAttachment(params),
    keepPreviousData: true,
  });
};
