import { Box, Skeleton } from "@mui/material";
import { CARD_VARIANTS, RawNumberCard } from "../shared/RawNumberCard";

export const ComplaintsInsightsCards = ({
  rawNumbersCardsData,
  selectedStartDate,
  selectedEndDate,
  isFetching,
}) => {
  return isFetching ? (
    <SkeletonForRawNumbersCards />
  ) : (
    <Box display="flex" justifyContent="space-between" boxSizing="border-box">
      {rawNumbersCardsData?.map(function renderRawNumberCard(
        rawNumberCardData,
        index
      ) {
        return (
          <RawNumberCard
            key={index}
            variant={getCardVariant(rawNumberCardData.title)}
            cardData={formatCardContent({
              index,
              cardData: rawNumberCardData,
            })}
            selectedStartDate={selectedStartDate}
            selectedEndDate={selectedEndDate}
          />
        );
      })}
    </Box>
  );
};

function getCardVariant(title) {
  switch (title) {
    case "Total Complaints":
    case "Pending to resolve": {
      return CARD_VARIANTS.red;
    }
    case "Classified Complaints":
    case "Assigned Complaints": {
      return CARD_VARIANTS.orange;
    }
    default:
      break;
  }
}

function formatCardContent({ index, cardData }) {
  let cardContent = {
    title: cardData.title,
    percentage: cardData.percentage,
    semantic: cardData.semantic,
    arrow: cardData.arrow,
  };

  switch (cardData.title) {
    case "Total Complaints": {
      cardContent.mainText = cardData.value.toLocaleString();
      break;
    }
    case "Classified Complaints": {
      cardContent.mainText = cardData.value.toLocaleString();
      cardContent.secondaryText = `(${Math.round(
        cardData.percentage_classified
      )}%)`;
      break;
    }
    case "Assigned Complaints": {
      cardContent.mainText = cardData.value.toLocaleString();
      cardContent.secondaryText = `(${Math.round(
        cardData.percentage_Assigned
      )}%)`;
      break;
    }
    case "Pending to resolve": {
      cardContent.mainText = cardData.value.toLocaleString();
      cardContent.secondaryText = `(${Math.round(
        cardData.pending_percentage
      )}%)`;
      break;
    }
    default:
      break;
  }

  return cardContent;
}

const SkeletonForRawNumbersCards = () => {
  return (
    <Box display="flex" justifyContent="space-between" boxSizing="border-box">
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
      <Skeleton
        variant="rectangular"
        width={"24.5%"}
        height={200}
        sx={{ borderRadius: 5 }}
      />
    </Box>
  );
};
