export const ReputationResponsePlatforms = {
    Google: 'google',
    TripAdvisor: 'tripadvisor',
}

export const ReputationResponseStatuses = {
    All: undefined,
    Unattended: 'unattended',
    Seen: 'seen',
    Answered: 'answered'
}