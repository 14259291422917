import moment from "moment";
import { useReducer } from "react";

export const useInvoicesFiltersState = () => {
  const [selectedFiltersState, dispatchActionToFilters] = useReducer(
    overallViewFiltersReducer,
    overallViewSelecterFiltersInitialState
  );

  function updateSelectedDateRange({ from, to }) {
    const fromDate = moment(from).toISOString(true).split("T")[0];
    const toDate = moment(to).toISOString(true).split("T")[0];

    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-DATE-RANGE"],
      payload: { date_from: fromDate, date_to: toDate },
    });
  }

  function resetDateRange() {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["RESET-SELECTED-DATE"],
    });
  }

  function resetAllFiltersState() {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["RESET-ALL-FILTERS"],
    });
  }

  function updateSelectedStatus(event) {
    dispatchActionToFilters({
      type: overallViewFiltersDispatchActions["UPDATE-SELECTED-STATUS"],
      payload: { status: event.target.value },
    });
  }

  return {
    selectedFiltersState,
    dispatchActionToFilters,
    updateSelectedDateRange,
    resetDateRange,
    resetAllFiltersState,
    updateSelectedStatus,
  };
};

export const overallViewSelecterFiltersInitialState = {
  // Default Date Range is the past Week
  date_from:  moment().subtract(3, "months").format("YYYY-MM-DD"),
  date_to: moment().format("YYYY-MM-DD"),
  status: "",
};

const overallViewFiltersReducer = (state, action) => {
  switch (action.type) {
    case overallViewFiltersDispatchActions["UPDATE-DATE-RANGE"]: {
      return {
        ...state,
        date_from: action.payload.date_from,
        date_to: action.payload.date_to,
      };
    }
    case overallViewFiltersDispatchActions["RESET-SELECTED-DATE"]: {
      return {
        ...state,
        date_from: overallViewSelecterFiltersInitialState.date_from,
        date_to: overallViewSelecterFiltersInitialState.date_to,
      };
    }
    case overallViewFiltersDispatchActions["UPDATE-SELECTED-STATUS"]: {
      return {
        ...state,
        status: action.payload.status,
      };
    }

    default: {
      return state;
    }
  }
};

export const overallViewFiltersDispatchActions = {
  "UPDATE-DATE-RANGE": "UPDATE-DATE-RANGE",
  "RESET-SELECTED-DATE": "RESET-SELECTED-DATE",
  "RESET-ALL-FILTERS": "RESET-ALL-FILTERS",
  "UPDATE-SELECTED-STATUS": "UPDATE-SELECTED-STATUS",
};
