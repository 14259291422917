import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  LabelList,
  Legend,
} from "recharts";
import { COLORS } from "../../../helpers/Colors";

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    border: "1px solid",
    borderColor: COLORS.silver,
    backgroundColor: COLORS.white,
    paddingBottom: "0%",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    opacity: "0.7",
    width: "auto",
  },
  tooltipTitle: {
    margin: "5px",
    color: COLORS.brightBlack,
  },
}));

const tickFormatter = (tick) =>
  tick.length > 10 ? `${tick.substring(0, 9)} ${tick.substring(9)}` : tick;

export const StackedBarChart = (props) => {
  const [finalData, setFinalData] = useState([]);
  const [questions, setQuestions] = useState([]);

  const renderCustomizedLabel = (props, value) => {
    const { x, y, width, height } = props;
    let offset = width || 0;
    if (width === 0) return;
    return (
      <text
        x={x + offset - 2}
        y={y + height - 5}
        fontSize="11px"
        textAnchor="end"
      >
        {finalData[props.index][`${value}-percentage`]}%
      </text>
    );
  };

  const CustomTooltip = ({ payload, active }) => {
    const classes = useStyles();
    if (active) {
      return (
        <div className={classes.tooltip}>
          <p className={classes.tooltipTitle}>
            Branch: {payload[0].payload.name}
            {questions.map((question) => {
              return `\n${question}: ${payload[0].payload[question] || 0} (${
                payload[0].payload[`${question}-percentage`] || "0"
              }%)`;
            })}
          </p>
        </div>
      );
    }
    return null;
  };

  useEffect(() => {
    let finalData = [];
    let questionPoints = [];
    props.data.data.map((branch) => {
      let branchDataEntry = {};
      branchDataEntry.name = branch.name;
      branch.data.map((dataEntry) => {
        if (!questionPoints.includes(dataEntry.name))
          questionPoints.push(dataEntry.name);
        branchDataEntry[`${dataEntry.name}-percentage`] = (
          (dataEntry.value / branch.total_count) *
          100
        ).toFixed(1);
        return (branchDataEntry[dataEntry.name] = dataEntry.value);
      });
      return finalData.push(branchDataEntry);
    });
    setFinalData(finalData);
    setQuestions(questionPoints);
  }, [props]);

  return (
    <div
      style={{
        width: "100%",
        height: `${props?.data?.data?.length * 120 || 300}px`,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          layout="vertical"
          data={finalData}
          margin={{
            top: 20,
            right: 100,
            bottom: 40,
            left: 20,
          }}
        >
          <CartesianGrid stroke={COLORS.silver} />
          <XAxis type="number" />
          <RechartsTooltip content={<CustomTooltip />} />
          <YAxis dataKey="name" type="category" tickFormatter={tickFormatter} />
          {questions.map((dataPoint, i) => (
            <Bar
              key={`${dataPoint}-percentage-${i}`}
              dataKey={dataPoint}
              stackId={"a"}
              barSize={20}
              fill={props.data.colors[dataPoint] || COLORS.darkGrey}
            >
              {/* <LabelList
                content={(e) => renderCustomizedLabel(e, dataPoint)}
                style={{ fill: COLORS.brightBlack }}
              /> */}
            </Bar>
          ))}
          {/* <Legend height={50} /> */}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
