import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getReviewsOverviewData = ({
  startDate,
  endDate,
  branches,
  divisions,
}) => {
  const requestURL = `${API_URL}/overall-progress`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
      ...(divisions?.length > 0 ? { divisions: divisions?.join(",") } : {}),
    },
  });
};

export const useGetReviewsOverviewData = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["reviews-overview-data", params],
    queryFn: () => getReviewsOverviewData(params),
    keepPreviousData: true,
  });
};
