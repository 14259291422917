import { useState } from "react";
import { Box, Button, Radio, Typography } from "@mui/material";
import { useGetAllowedSubscriptionPlans } from "../../features/settings/api/billing/getSubscriptionPlans";
import { useUpgradeSubscriptionPlan } from "../../features/settings/api/billing/upgradeSubscriptionPlan";
import { primaryColors } from "../../helpers/customColors";

export const useUpgradePaymentPlanHook = () => {
  const [selectedNewPaymentPlan, setSelectedNewPaymentPlan] = useState();

  const {
    data: allowedSubscriptionPlansResponse,
    isLoading: isLoadingAllowedSubscriptionPlansResponse,
  } = useGetAllowedSubscriptionPlans();

  const planUpgradeOptions = useRenderPlansRadioButtons({
    selectedNewPaymentPlan,
    setSelectedNewPaymentPlan,
    allowedSubscriptionPlans: allowedSubscriptionPlansResponse?.data,
  });

  const upgradeSubscriptionPlanMutation = useUpgradeSubscriptionPlan();

  function onSubmitPlanUpgradeHandler() {
    const {
      id: planID,
      name: planName,
      subscription_type: renewalType,
    } = JSON.parse(selectedNewPaymentPlan);

    upgradeSubscriptionPlanMutation
      .mutateAsync({
        planID,
        planName,
        renewalType,
      })
      .then(function successfulUpgradeSubsriptionPlanRequestHandler(response) {
        window.open(response.data.url, "_blank");
      });
  }

  return {
    isLoadingAllowedSubscriptionPlansResponse,
    allowedSubscriptionPlansResponse,
    selectedNewPaymentPlan,
    onSubmitPlanUpgradeHandler,
    upgradeSubscriptionPlanMutation,
    planUpgradeOptions,
  };
};

function useRenderPlansRadioButtons({
  selectedNewPaymentPlan,
  setSelectedNewPaymentPlan,
  allowedSubscriptionPlans,
}) {
  return allowedSubscriptionPlans ? (
    <Box display="flex" flexDirection="column" gap={3}>
      {allowedSubscriptionPlans.map(function (plan) {
        return (
          <PlanRadioButton
            planName={plan.name}
            planRenewalType={plan.subscription_type}
            planPricing={plan.description}
            radioInputName="subscription_plan"
            radioInputValue={JSON.stringify(plan)}
            selectedNewPaymentPlan={selectedNewPaymentPlan}
            setSelectedNewPaymentPlan={setSelectedNewPaymentPlan}
          />
        );
      })}
    </Box>
  ) : null;
}

function PlanRadioButton({
  planName,
  planRenewalType,
  planPricing,
  radioInputName,
  radioInputValue,
  selectedNewPaymentPlan,
  setSelectedNewPaymentPlan,
}) {
  const isSelected = selectedNewPaymentPlan === radioInputValue;
  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        width: "100%",
        gap: 2,
        padding: 2,
        border: `1px solid ${
          isSelected ? primaryColors.brand[200] : primaryColors.gray[200]
        }`,
        borderRadius: 3,
        bgcolor: isSelected ? primaryColors.brand[50] : undefined,
      }}
      onClick={function () {
        setSelectedNewPaymentPlan(radioInputValue);
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="start" width="80%">
        <Typography
          sx={{ textTransform: "none" }}
          color={
            isSelected ? primaryColors.brand[800] : primaryColors.gray[600]
          }
          variant="text-lg"
          fontWeight="bold"
        >
          {planName}
        </Typography>
        <Typography
          sx={{ textTransform: "none" }}
          color={
            isSelected ? primaryColors.brand[700] : primaryColors.gray[700]
          }
          variant="text-md"
        >
          {planRenewalType}
        </Typography>
        <Typography
          sx={{ textTransform: "none", paddingTop: 2 }}
          color={
            isSelected ? primaryColors.brand[600] : primaryColors.gray[600]
          }
          variant="text-md"
        >
          {planPricing}
        </Typography>
      </Box>
      <Radio
        sx={{ padding: 0 }}
        checked={selectedNewPaymentPlan === radioInputValue}
        onChange={function (e) {
          setSelectedNewPaymentPlan(e.target.value);
        }}
        value={radioInputValue}
        name={radioInputName}
        inputProps={{ "aria-label": "A" }}
      />
    </Button>
  );
}