import * as yup from "yup";
import { ANSWER_LAYOUT_TYPES, PROFILE_IDS } from "../../../helpers/constants";
import { PhoneNumberUtil } from "google-libphonenumber";
import { ReviewAnswerInputLayout } from "./ReviewAnswerInputLayout";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

// const FORM_FIELDS_NAMES = {
//   commentText: "comment_text",
//   name: "name",
//   phone: "phone",
// };

const commentTextMaxChars = 500;

// const egyPhoneRegex = /^01[0125][0-9]{8}$/gm;
export const nigeriaPhoneRegex = /^(\d{13})?$/gm;

// export function getRelativePhoneNumberRegex(accountID) {
//   switch (accountID) {
//     case PROFILE_IDS.Nigeria: {
//       return nigeriaPhoneRegex;
//     }
//     default: {
//       return egyPhoneRegex;
//     }
//   }
// }

export const getSharedReviewComplaintYupValidations = ({
  country,
  makeTextCommentRequired,
}) => {
  const sharedValidations = {
    name: yup.string().required("Required"),
    phone: yup
      .string()
      .required("Required")
      .test(
        "isValidPhoneNumber",
        "please enter a valid phone number",
        (value) => {
          if (country === "NG") return value.match(nigeriaPhoneRegex);
          else
            return phoneNumberUtil.isValidNumber(
              phoneNumberUtil.parse(value, country)
            );
        }
      ),
  };
  if (makeTextCommentRequired)
    sharedValidations["comment_text"] = yup
      .string()
      .required("Required")
      .max(commentTextMaxChars, "Exceeded Max Characters (500)");
  else
    sharedValidations["comment_text"] = yup
      .string()
      .optional()
      .max(commentTextMaxChars, "Exceeded Max Characters (500)");

  return sharedValidations;
};

export const SharedFeedbackInputs = ({ resetField, country }) => {
  const sharedFeedbackQuestions = [
    {
      name: "comment_text",
      answer_type: ANSWER_LAYOUT_TYPES.long_text,
      question_text: "What could be better?",
      required: true,
      details: {
        placeholder: "Type customer answer...",
      },
    },
    {
      name: "name",
      answer_type: ANSWER_LAYOUT_TYPES.short_text,
      question_text: "Name",
      required: true,
      details: {
        placeholder: "Type customer answer...",
      },
    },
    {
      name: "phone",
      answer_type: ANSWER_LAYOUT_TYPES.phone_number,
      question_text: "Phone Number",
      required: true,
      details: {
        country: country,
      },
    },
  ];
  return sharedFeedbackQuestions.map((questionRawData, index) => {
    return (
      <ReviewAnswerInputLayout
        key={index}
        question={questionRawData}
        answerInput={{
          name: questionRawData.name,
        }}
        resetField={resetField}
        lastQuestion={sharedFeedbackQuestions.length - 1 === index}
      />
    );
  });
};
