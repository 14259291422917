import { API_URL, melior } from "../../../helpers/constants";

export const createSingleLink = ({ divisionId, branchId, qr = false , type}) => {
  const requestURL = `${API_URL}/feedback-invitation/generate/link${
    qr ? "?qr_code" : ""
  }`;
  const requestData = { branch_id: branchId, type };
  
  if (divisionId && divisionId !== 0) {
    requestData.division_id = divisionId;
  }
  
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, requestData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
