import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const triggerEmailUserMentionInReputationResponse = ({
  reputationResponseID,
  mentionText,
  mentionedUserID,
}) => {
  let requestURL = `${API_URL}/reputation/${reputationResponseID}/mention`;

  return melior.post(requestURL, {
    text: mentionText,
    mentioned_user: mentionedUserID,
  });
};

export const useTriggerEmailForUserMentionInReputationResponse = ({
  config,
} = {}) => {
  return useMutation({
    ...config,
    mutationFn: triggerEmailUserMentionInReputationResponse,
  });
};
