import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ArrowLeftIcon from "@mui/icons-material/KeyboardBackspace";
import { ReactComponent as Logo } from "../../assets/melior-logo-black.svg";
import clsx from "clsx";
import { COLORS } from "../../helpers/Colors";
import { primaryColors, secondaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { AntSwitch } from "./OnlineSurveyWelcomeCard";
import { FitScreen } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  body: { padding: "10px", height: "100%", marginTop: "10px" },
  question: {
    fontSize: "1.1rem",
    marginBottom: "1rem",
    color: COLORS.brightBlack,
  },
  footerButton: {
    textTransform: "none",
    fontSize: "14px",
    borderRadius: "10px",
    color: COLORS.brightBlack,
    "&:hover": {
      background: COLORS.meliorYellow,
      color: COLORS.white,
    },
  },
  text: {
    fontSize: "14px",
  },
  face: {
    width: "60px",
    height: "auto",
  },
  logo: {
    width: "80%",
    maxWidth: "200px",
    height: "auto",
    maxHeight: "80px",

    margin: "20px 0px",
  },
  goToBottom: {
    margin: "5px auto",
    width: "95%",
  },
  source: {
    padding: "10px 0px",
    width: "55%",
    background: COLORS.meliorYellowGentle,
    color: COLORS.black,
    textTransform: "capitalize",
    borderRadius: "40px",
    margin: "1px 0px",
    "&:hover": {
      background: COLORS.meliorYellow,
      color: COLORS.white,
    },
  },
  sourceLeft: {
    marginRight: "auto",
  },
  sourceRight: {
    marginLeft: "auto",
  },
  icon: {
    margin: "auto",
    width: "50%",
    maxWidth: "50%",
    height: "auto",
  },
}));

const OnlineSurveyIssuePickerCard = (props) => {
  const classes = useStyles();

  const goNext = (issuesID) => {
    props.nextPage(issuesID);
  };
  const source = (issues) => (
    <Box display="flex" flexDirection="column" alignItems="center">
      {issues?.map((issue, i) => (
        <Button
          key={"issue" + issue.id}
          onClick={() => goNext(issue.id)}
          sx={{
            padding: "10px 8px",
            margin: "4px",
            borderRadius: "6px",
            width: "100%",
            height: "44px",
            bgcolor: primaryColors.brand[100],
            "&:hover": {
              bgcolor: primaryColors.brand[100],
              //   bgcolor: primaryColors.brand[200],
            },
            order: props.language ? 1 : 2,
          }}
        >
          <Typography
            sx={{
              color: primaryColors.base.black,
              fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
              fontWeight: fontWeightVariations.medium,
              fontSize: fontSizeVariations["text-md"],
              width: "100%",
              textAlign: props.language ? "right" : "left",
              textTransform: "none",
            }}
          >
            {issue.name}
          </Typography>
        </Button>
      ))}
    </Box>
  );

  const cardFooter = () => (
    <Grid
      container
      justifyContent="space-between"
      className={classes.goToBottom}
    >
      <Button onClick={props.prevPage} className={classes.footerButton}>
        <ArrowLeftIcon />
        {props.language ? "السابق" : "Back"}
      </Button>
      <Button className={classes.footerButton}>
        {props.language ? "اغلق" : "Close"}
        <CloseIcon />
      </Button>
    </Grid>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={"20px"}
      px="15px"
      height="fit-content"
      gap={"48px"}
      textAlign="center"
    >
      {/* {props.facilityData.image ? (
        <Box display="flex" width="100%" justifyContent="center">
          <img
            src={props.facilityData.image}
            alt={props.facilityData.name}
            style={{
              width: "90%",
              maxWidth: "250px",
              maxHeight: "250px",
              display: "block",
              height: "auto",
              objectFit: "contain",
            }}
          />
        </Box>
      ) : (
        <Logo
          style={{
            width: "90%",
            maxWidth: "250px",
            maxHeight: "250px",
            display: "block",
            height: "auto",
            objectFit: "contain",
            visibility: "hidden",
          }}
        />
      )} */}
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography
          className={classes.question}
          sx={{
            fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
            fontSize: fontSizeVariations["text-medium"],
            fontWeight: fontWeightVariations.semiBold,
            color: primaryColors.gray[900],
            textAlign: props.language ? "right" : "left",
          }}
        >
          {props.language
            ? props.dynamicText.issue_page.short_quote.ar
            : props.dynamicText.issue_page.short_quote.en}
        </Typography>

        <Typography
          className={classes.question}
          sx={{
            fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
            fontSize: fontSizeVariations["text-medium"],
            fontWeight: fontWeightVariations.semiBold,
            color: primaryColors.gray[900],
            textAlign: props.language ? "right" : "left",
          }}
        >
          {props.language
            ? props.dynamicText.issue_page.select_issue.ar
            : props.dynamicText.issue_page.select_issue.en}
        </Typography>
        {source(props.language ? props.issues["ar"] : props.issues["en"])}
      </Box>
      {/* {cardFooter()} */}
    </Box>
    // <Grid
    //   container
    //   justifyContent="center"
    //   direction="column"
    //   alignItems="center"
    //   className={classes.body}
    // >
    //   <Logo className={classes.logo} />
    //   <p className={classes.question}>
    //     {props.language ? "قم باختيار سبب الشكوى" : "Please pick an issue"}
    //   </p>
    //   {source(props.language ? props.issues["ar"] : props.issues["en"])}
    //   {/* {cardFooter()} */}
    // </Grid>
  );
};

export default OnlineSurveyIssuePickerCard;
