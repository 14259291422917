import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const giveFeedback = (feedbackData) => {
  let requestURL = `${API_URL}/give-feedback`;
  return melior.post(requestURL, {
    comment: feedbackData.feedbackMessage,
    feedback_type: feedbackData.feedbackType,
  });
};
export const useGiveFeedback = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: giveFeedback,
  });
};
