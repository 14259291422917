import { Button, Typography, Grid } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { useState } from "react";

export const MultipleChoiceLayout = ({
  language,
  layoutProps,
  submitAnswer,
}) => {
  const [selectedChoice, setSelectedChoice] = useState(null);

  return (
    <Grid container direction="row" alignItems="center">
      {layoutProps.choices.map((choice, i) => (
        <Grid
          item
          key={i}
          xs={layoutProps.choices.length === 2 ? 6 : 12}
          display="flex"
          alignItems="center"
          gap={0.5}
          width="100%"
        >
          {choice.emoji && (
            <img
              src={choice.emoji}
              alt={choice.text}
              style={{
                width: "40px",
                height: "auto",
                margin: "5px 10px",
                order: language ? 2 : 1,
              }}
            />
          )}
          <Button
            key={"source" + choice.id}
            onClick={() => {
              setSelectedChoice(choice.id);
              submitAnswer(choice.id);
            }}
            sx={{
              padding: "10px 8px",
              margin: "5px",
              borderRadius: "6px",
              width: "100%",
              height: "44px",
              bgcolor:
                selectedChoice === choice.id &&
                layoutProps.highlightSelectedChoice
                  ? primaryColors.brand[400]
                  : getChoiceColor({
                      choicesLength: layoutProps.choices.length,
                      choiceIndex: i,
                      withSmiley: Boolean(choice.emoji),
                    }),
              "&:hover": {
                bgcolor: getChoiceColor({
                  choicesLength: layoutProps.choices.length,
                  choiceIndex: i,
                  withSmiley: Boolean(choice.emoji),
                }),
              },
              order: language ? 1 : 2,
            }}
          >
            <Typography
              variant="text-md"
              sx={{
                fontFamily: language ? "BalooBhaijaan2" : "Nunito",
                fontWeight: fontWeightVariations.medium,
                color: multipleChoicesColors.text,
                width: "100%",
                textAlign: language ? "right" : "left",
                textTransform: "none",
              }}
            >
              {choice.text}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

const getTwoHorizontalChoicesColor = (choiceIndex) => {
  switch (choiceIndex) {
    case 0:
      return multipleChoicesColors.twoChoices["yes"];
    case 1:
      return multipleChoicesColors.twoChoices["no"];
    default:
      return;
  }
};
const getThreeVerticalChoicesColor = (choiceIndex) => {
  switch (choiceIndex) {
    case 0:
      return multipleChoicesColors.threeChoices["yes"];
    case 1:
      return multipleChoicesColors.threeChoices["neutral"];
    case 2:
      return multipleChoicesColors.threeChoices["no"];
    default:
      return;
  }
};
const getFourVerticalChoicesColor = (choiceIndex) => {
  switch (choiceIndex) {
    case 0:
      return multipleChoicesColors.fourChoice["always"];
    case 1:
      return multipleChoicesColors.fourChoice["sometimes"];
    case 2:
      return multipleChoicesColors.fourChoice["rarely"];
    case 3:
      return multipleChoicesColors.fourChoice["never"];
    default:
      return;
  }
};
const getFiveVerticalChoicesColor = (choiceIndex) => {
  switch (choiceIndex) {
    case 0:
      return multipleChoicesColors.fiveChoices["excellent"];
    case 1:
      return multipleChoicesColors.fiveChoices["good"];

    case 2:
      return multipleChoicesColors.fiveChoices["average"];
    case 3:
      return multipleChoicesColors.fiveChoices["needsImprovement"];
    case 4:
      return multipleChoicesColors.fiveChoices["bad"];
    default:
      return;
  }
};

const getChoiceColor = ({ choicesLength, choiceIndex, withSmiley }) => {
  if (withSmiley) return multipleChoicesColors.choice;
  else {
    switch (choicesLength) {
      case 2: {
        return getTwoHorizontalChoicesColor(choiceIndex);
      }
      case 3: {
        return getThreeVerticalChoicesColor(choiceIndex);
      }
      case 4: {
        return getFourVerticalChoicesColor(choiceIndex);
      }
      case 5: {
        return getFiveVerticalChoicesColor(choiceIndex);
      }
      default:
        return multipleChoicesColors.choice; //more than 5 choices
    }
  }
};

const multipleChoicesColors = {
  text: "#212121",
  choice: "#FFF1D9",
  twoChoices: {
    yes: "#E7F8E7",
    no: "#FFECEE",
  },
  threeChoices: {
    yes: "#D3F1D2",
    neutral: "#EBEBEB",
    no: "#FFDDE0",
  },
  fourChoice: {
    always: "#D3F1D2",
    sometimes: "#EAF4E6",
    rarely: "#FFEDDD",
    never: "#FFDDE0",
  },
  fiveChoices: {
    excellent: "#BFDEC0",
    good: "#E4EAD5",
    average: "#FFF2D9",
    needsImprovement: "#FFE7D2",
    bad: "#FFDDE0",
  },
};
