import { API_URL, melior } from "../../../helpers/constants";

// Facility manager
export const editIssue = ({ newIssue, id }) => {
  const requestURL = `${API_URL}/complaint-issues/${id}`;
  let payload = { ...newIssue };

  return new Promise((resolve, reject) => {
    melior
      .patch(requestURL, payload)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
