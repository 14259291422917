import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const updateReputationResponseArchivalState = ({
  reputationResponseID,
  newArchivalState,
//   archivingComment,
}) => {
  let requestURL = `${API_URL}/reputation/${reputationResponseID}/archive`;

  return melior.patch(requestURL, {
    archived: newArchivalState,
    // comment: archivingComment,
  });
};

export const useUpdateReputationResponseArchivalState = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
        queryClient.invalidateQueries(["reputation-responses"], {
            type: "active",
          });
          queryClient.invalidateQueries(["reputation-responses-activity-logs"], {
            type: "active",
          });
    },
    ...config,
    mutationFn: updateReputationResponseArchivalState,
  });
};
