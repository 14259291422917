import { Print } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { getMonthlyPerformanceReport } from "../api/getMonthlyPerformanceReport";
import { getSMSMonthlyCountReport } from "../api/getSMSMonthlyCountReport";
import { getSMSSendingResults } from "../api/getSMSSendingResults";
import { getSMSWeeklyCountReport } from "../api/getSMSWeeklyCountReport";
import { getUnopenedSMSInvitationsReport } from "../api/getUnopenedSMSInvitationsReport";
import { FullReportPage } from "./FullReportPage";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import { getResponseRateReport } from "../api/getResponseRateReport";

export const ReportPageWithPrinting = ({ reportQueryParameters }) => {
  const [preparedReportData, setPreparedReportData] = useState();
  const printingAreaRef = useRef();
  const { reportType } = useParams();


  const printHandler = useReactToPrint({
    content: () => printingAreaRef.current,
    documentTitle: `${reportType}-report (Exported at ${moment().format(
      "DD-MM-YYYY"
    )})`,
  });

  useEffect(
    function invokeRelevantReportAPI() {
      switch (reportType) {
        case reportTypes.MonthlySMSCount: {
          getSMSMonthlyCountReport({ ...reportQueryParameters }).then(
            (reportDataRaw) => {
              const preparedReportDataTemp =
                prepareAndGetReportData(reportDataRaw);

              setPreparedReportData(preparedReportDataTemp);
            }
          );
          break;
        }
        case reportTypes.PerformanceMonthly: {
          getMonthlyPerformanceReport({ ...reportQueryParameters }).then(
            (reportDataRaw) => {
              const preparedReportDataTemp =
                prepareAndGetReportData(reportDataRaw);

              setPreparedReportData(preparedReportDataTemp);
            }
          );
          break;
        }
        case reportTypes.SMSSendingResult: {
          getSMSSendingResults({ ...reportQueryParameters }).then(
            (reportDataRaw) => {
              const preparedReportDataTemp =
                prepareAndGetReportData(reportDataRaw);

              setPreparedReportData({
                ...preparedReportDataTemp,
              });
            }
          );
          break;
        }
        case reportTypes.UnopenedSMSInvitations: {
          getUnopenedSMSInvitationsReport().then((reportDataRaw) => {
            const preparedReportDataTemp =
              prepareAndGetReportData(reportDataRaw);

            setPreparedReportData(preparedReportDataTemp);
          });
          break;
        }
        case reportTypes.WeeklySMSCount: {
          getSMSWeeklyCountReport({ ...reportQueryParameters }).then(
            (reportDataRaw) => {
              const preparedReportDataTemp =
                prepareAndGetReportData(reportDataRaw);

              setPreparedReportData(preparedReportDataTemp);
            }
          );
          break;
        }
        case reportTypes.ResponseRate: {
          getResponseRateReport({ ...reportQueryParameters }).then(
            (reportDataRaw) => {
              const preparedReportDataTemp =
                prepareAndGetReportData(reportDataRaw);

              setPreparedReportData(preparedReportDataTemp);
            }
          );
          break;
        }
        default: {
          console.error("Invalid Report Type - Nothing to Fetch");
        }
      }
    },
    [reportQueryParameters, reportType]
  );
  
  return (
    <Box display="flex" flexDirection="column" justifyContent="center" gap={8}>
      <Box display="flex" justifyContent="space-around" alignItem="center">
        {/* <Typography variant="h6">{currentReportType} Report</Typography> */}
        <Button
          //   onClick={() => exportAsPDFHandler(currentReportType)}
          onClick={printHandler}
          variant="outlined"
          startIcon={<Print />}
          sx={{ textTransform: "none" }}
        >
          Print or Save as PDF
        </Button>
      </Box>

      {!preparedReportData ? (
        <Box display="flex" justifyContent="center" width="100%">
          <CircularProgress color="secondary" />
        </Box>
      ) : (
        <Box
          sx={{
            border: "1px dashed gray",
            borderRadius: "10px",
          }}
        >
          <FullReportPage
            reportType={reportType}
            issueDate={preparedReportData.issueDate}
            accountName={preparedReportData.accountName}
            facilityLogoData={preparedReportData.facilityLogoData}
            reportSubtitleData={preparedReportData.reportSubTitleData}
            reportTablesData={preparedReportData.reportTablesData}
            printingAreaRef={printingAreaRef}
          />
        </Box>
      )}
    </Box>
  );
};

export const reportTypes = {
  PerformanceMonthly: "monthly-performance",
  SMSSendingResult: "sms-sending-results",
  WeeklySMSCount: "sms-count-weekly",
  MonthlySMSCount: "sms-count-monthly",
  UnopenedSMSInvitations: "unopened-sms-invitations",
  ResponseRate: "response-rate",
};

const prepareAndGetReportData = (reportResponse) => {
  const {
    logo: facilityLogoString,
    issue_date: issueDate,
    account_name: accountName,
  } = reportResponse;

  const reportSubTitleData = {
    month: reportResponse.month_name || "",
    year: reportResponse.year || "",
    date: reportResponse.date || "",
    time: reportResponse.time || "",
    startDate: reportResponse.startDate || "",
    endDate: reportResponse.endDate || "",
  };

  const reportTablesData = [];

  reportResponse.branches.forEach((branchData) => {
    const branchTableData = {
      branchName: branchData.name,
      columnsData: [{ label: "Division", key: "InsightDivision" }],
      rowsData: [],
    };

    // Add Divisions to the Columns Data And all the Row Data
    let rowData = {};
    branchData.divisions.forEach((divisionData) => {
      // Add Divisions Data
      branchTableData.columnsData.push({
        label: divisionData.name,
        key: divisionData.name,
      });

      divisionData.insights.forEach((insightData, index) => {
        rowData["InsightDivision"] = insightData.key;
        rowData[divisionData.name] = insightData.value;
      });
    });
    branchTableData.rowsData.push(rowData);

    reportTablesData.push(branchTableData);
  });

  const facilityLogoIsSVG = !facilityLogoString.includes("http");

  const facilityLogo = facilityLogoIsSVG
    ? window.atob(facilityLogoString)
    : facilityLogoString;

  return {
    facilityLogoData: {
      logo: facilityLogo,
      isSVG: facilityLogoIsSVG,
    },
    issueDate,
    accountName,
    reportSubTitleData,
    reportTablesData,
  };
};
