import { Box, Container, Typography } from "@mui/material";
import { useLocation, useSearchParams } from "react-router-dom";
import { ReportPageWithPrinting } from "../components/ReportPageWithPrinting";

export const ReportPageWithPrintingScreen = () => {
  const [searchParams] = useSearchParams();

  const reportQueryParameters = {};

  for (let entry of searchParams.entries()) {
    reportQueryParameters[entry[0]] = entry[1];
  }

  return (
    <Container maxWidth={false} sx={{ mt: 4 }}>
      <Box display="flex" flexDirection="column" gap={5} mt={2}>
        <ReportPageWithPrinting reportQueryParameters={reportQueryParameters} />
      </Box>
    </Container>
  );
};
