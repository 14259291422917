import { FormControlLabel, Radio, RadioGroup, Box } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormRadioInput = ({ name, options, sx, width }) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <RadioGroup
          helperText={error ? error.message : null}
          value={value ? value : ""}
          onChange={onChange}
          //   row
        >
          <Box
            display="flex"
            flexWrap="wrap"
            flexDirection="row"
            justifyContent="flex-start"
            width={width}
          >
            {options.map((option, index) => (
              <Box key={index}>
                <FormControlLabel
                  control={<Radio color="secondary" />}
                  label={option.label}
                  value={option.value.toString()}
                  sx={sx}
                  labelPlacement="end"
                />
              </Box>
            ))}
          </Box>
        </RadioGroup>
      )}
    />
  );
};
