import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getRatingsComparison = ({
  startDate,
  endDate,
  branches,
  chartType,
}) => {
  const requestURL = `${API_URL}/reputation-overview/charts/average-rating/${chartType}`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
    },
  });
};

export const useGetRatingsComparison = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["reputation-ratings-comparison", params],
    queryFn: () => getRatingsComparison(params),
    keepPreviousData: true,
  });
};
