import { Box } from "@mui/material";
import { TableContainer } from "../../revamped-overall-view/components/shared/TableContainer";
import { InvitationDialogButton } from "../components/members/InvitationDialogButton";
import { AllMembersTable } from "../components/members/AllMembersTable";

export const MembersSubScreen = () => {
  return (
    <TableContainer
      label={"Members"}
      tableFiltersNextToName={
        <Box display="flex" flexDirection={"row"} alignItems="center">
          <InvitationDialogButton />
        </Box>
      }
    >
      <AllMembersTable />
    </TableContainer>
  );
};
