import { API_URL, melior } from "../../../helpers/constants";

export const getResponseRateReport = async ({ from_week }) => {
  let requestURL = `${API_URL}/reports/response-rate`;

  return await melior
    .get(requestURL, {
      params: {
        from_week,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
