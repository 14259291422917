import { createContext, useState } from "react";
import { getAllBranches } from "../features/general-settings";

export const SettingsContext = createContext();

const SettingsContextProvider = (props) => {
  const [branches, setBranches] = useState(null);
  const [loadingBranches, setLoadingBranches] = useState(false);

  const [branchRetrievalError, setBranchRetrievalError] = useState({
    isError: false,
    errorMessage: "",
  });

  const getBranches = (reload) => {
    if ((branches && reload === false) || loadingBranches) return;
    setLoadingBranches(true);
    getAllBranches({withPagination: false})
      .then((response) => {
        setBranches({items: response});
        setBranchRetrievalError({
          isError: false,
        });
        setLoadingBranches(false);
      })
      .catch(() => {
        setBranchRetrievalError({
          isError: true,
          errorMessage: "Network Error, please try again later",
        });
        setLoadingBranches(false);
      });
  };

  return (
    <SettingsContext.Provider
      value={{
        getBranches,
        branches,
        branchRetrievalError,
        loadingBranches,
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  );
};

export { SettingsContextProvider };
