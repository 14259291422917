import { Grid, OutlinedInput, Typography } from "@mui/material";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useState } from "react";

export const PasswordConfirmationDialog = ({
  openDialog,
  title,
  description,
  callbackFunction,
}) => {
  const [input, setInput] = useState();

  return (
    <GenericDialog
      dialogTitle={title}
      dialogSubtitle={description}
      maxWidth="xs"
      openByDefault={openDialog}
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      submitButtonText={"Submit"}
      onSubmitCallback={() => {
        callbackFunction(input);
      }}
      closeButtonText={"Cancel"}
    >
      <Grid container width={350}>
        <Typography
          sx={{
            color: primaryColors.gray[700],
            fontSize: fontSizeVariations["text-sm"],
            fontWeight: fontWeightVariations.bold,
            marginBottom: "6px",
          }}
        >
          Current password
        </Typography>
        <OutlinedInput
          placeholder="Enter the passoword"
          size="small"
          type="password"
          fullWidth
          onInput={(event) => setInput(event.target.value)}
          sx={{
            border: `1px solid ${primaryColors.gray[300]}`,
            borderRadius: "8px",
          }}
        />
      </Grid>
    </GenericDialog>
  );
};
