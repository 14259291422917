import { useState, useEffect } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { COLORS } from "../../../helpers/Colors";
import { makeStyles } from "@mui/styles";
import { ReactComponent as Logo } from "../../../assets/melior-logo-black.svg";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { CircularProgress } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { addFacility, registerUser } from "../api";

const useStyles = makeStyles(() => ({
  backgroundContainer: {
    backgroundColor: COLORS.black,
  },
  loginButton: {
    margin: "18px 0px 15px 0px",
    fontSize: "20px",
    background: COLORS.meliorYellow,
    fontFamily: "Avenir-Heavy",
    color: COLORS.white,
    width: "35%",
    textTransform: "none",
    borderRadius: "10px",
    height: "30%",
    "&:disabled": {
      backgroundColor: COLORS.meliorYellowDisabled,
      color: COLORS.greyDisabled,
    },
    "&:hover": {
      background: COLORS.meliorYellow,
      color: COLORS.white,
    },
  },

  textField: {
    overflow: "hidden",
    backgroundColor: COLORS.white,
    marginTop: "18px",
    fontSize: 20,
    width: "90%",
    height: "35px",

    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: COLORS.meliorYellow,
        borderWidth: "1.5px",
        borderRadius: "10px",
      },
    },
  },
  textFieldPassword: {
    overflow: "hidden",
    height: "35px",
    backgroundColor: COLORS.white,
    fontSize: 20,
    width: "80%",
    marginLeft: "5%",
    borderRadius: "10px",
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: COLORS.meliorYellow,
        borderWidth: "1.5px",
        borderRadius: "10px",
      },
    },
  },
  resizeFont: {
    fontFamily: "Avenir-Medium",

    fontSize: "14px",
    padding: "10px",
  },

  headers: {
    color: COLORS.white,
    marginBottom: "0px",
    paddingBottom: "0px",
    fontFamily: "Avenir-Heavy",
    fontWeight: "normal",
    marginLeft: "5%",
    fontSize: "22px",
  },
  meliorLogo: {
    width: "90%",
    height: "80px",
    margin: "25px 0px",
  },
  circularProgress: {
    color: COLORS.white,
    width: "20px",
    maxWidth: "20px",
    maxHeight: "20px",
    height: "20px",
  },
  uploadLogo: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: COLORS.meliorYellow,
    },
  },
  input: {
    display: "none",
  },
  note: {
    fontSize: "12px",
    fontWeight: "normal",
    color: COLORS.white,
    textTransform: "none",
  },
  passwordInstructions: {
    fontSize: "13px",
    color: COLORS.silver,
    marginTop: "15px",
    width: "400px",
  },
}));

export const SignUpPageScreen = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const { type } = useParams();
  const [facilityName, setFacilityName] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSecond, setLoadingSecond] = useState(false);
  const [signupError /*setSignupError*/] = useState({
    error: false,
    message: "",
  });

  const [signnedUp, setSignnedUp] = useState(false);
  const [image, setImage] = useState(null);
  const [secondPage, setSecondPage] = useState(false);
  const [facilityId, setFacilityId] = useState(0);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [token, setToken] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();
    var bodyFormData = new FormData();
    bodyFormData.append("facility_logo", image);
    bodyFormData.append("facility_name", facilityName);
    
    addFacility(bodyFormData, token)
      .then(async (res) => {
        setFacilityId(res.id);
        setLoading(true);
        setSecondPage(true);
      })
      .catch((e) => {
        alert(
          e.response.data.facility_name !== undefined
            ? e.response.data.facility_name[0]
            : "please upload a logo"
        );
      });
  };
  const handleSubmit2 = async () => {
    var bodyFormData = new FormData();
    bodyFormData.append("username", username);
    bodyFormData.append("password1", password);
    bodyFormData.append("password2", password2);
    bodyFormData.append("profile", facilityId);
    bodyFormData.append("email", email);

    registerUser(bodyFormData, token, type)
      .then(async (res) => {
        setSignnedUp(true);
      })
      .catch((e) => {
        alert(e.response.data[Object.keys(e.response.data)[0]]);
      });
  };

  useEffect(() => {
    if (signupError.error) {
      setLoading(false);
      setLoadingSecond(false);
    }
    async function fetchData() {
      // You can await here
      // ...
      const verifiedUser = await JSON.parse(
        localStorage.getItem("verifiedUser")
      );
      if (verifiedUser) {
        setEmail(verifiedUser.email);
        let currFullName = verifiedUser.first_name
          ? verifiedUser.first_name
          : "" + " " + verifiedUser.last_name
          ? verifiedUser.last_name
          : "";
        setFullName(currFullName === " " ? null : currFullName);
        setToken(verifiedUser.token);
      }
    }
    fetchData();
  }, [signupError]);

  const uploadLogo = (file) => {
    setImage(file[0]);
  };

  const FirstSignUp = () => (
    <Container maxWidth="sm" style={{ minHeight: "100vh" }}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="flex-start"
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Logo className={classes.meliorLogo} />
        </Grid>
        <form style={{ width: "100%" }} onSubmit={handleSubmit}>
          <h2 className={classes.headers}>Facility Name</h2>

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <TextField
              autoFocus
              className={classes.textField}
              InputProps={{ classes: { input: classes.resizeFont } }}
              id="Facilityname"
              type="name"
              variant="outlined"
              fullWidth
              onChange={(e) => {
                setFacilityName(e.target.value);
              }}
              value={facilityName}
            />
          </Grid>
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
            style={{ width: "50%", paddingLeft: "25px", marginTop: "18px" }}
          >
            <input
              accept="image/*"
              className={classes.input}
              id="raised-button-file"
              type="file"
              onChange={(e) => {
                uploadLogo(e.target.files);
              }}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                className={classes.uploadLogo}
              >
                Upload new Logo
              </Button>
            </label>

            <ul className={classes.passwordInstructions}>
              <li> Recommended Size: 2MB or less</li>
              <li>Recommended Dimension: 250*100 px</li>
            </ul>

            <h3 className={classes.note}>{image ? image.name : null}</h3>
          </Grid>

          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="center"
          >
            <Button
              disabled={!facilityName.trim() | loading ? true : false}
              type="submit"
              variant="contained"
              className={classes.loginButton}
            >
              {loading ? (
                <CircularProgress className={classes.circularProgress} />
              ) : (
                "Create Profile"
              )}
            </Button>
          </Grid>
        </form>
      </Grid>
    </Container>
  );

  const SecondSignUp = () => (
    <Container maxWidth={false} style={{ minHeight: "100vh" }}>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="flex-start"
        style={{ minHeight: "100vh" }}
      >
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Logo className={classes.meliorLogo} />
        </Grid>

        <Grid container justifyContent="space-around" direction="row">
          <Grid
            style={{ display: "block" }}
            item
            xs={11}
            md={5}
            lg={5}
            container
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            <h2 className={classes.headers}>Username</h2>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <TextField
                autoFocus
                className={classes.textField}
                InputProps={{ classes: { input: classes.resizeFont } }}
                id="username"
                type="username"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                value={username}
              />
            </Grid>
            {fullName && fullName !== "" && (
              <h2 className={classes.headers}>Full Name</h2>
            )}
            {fullName && fullName !== "" && (
              <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
              >
                <TextField
                  className={classes.textField}
                  InputProps={{ classes: { input: classes.resizeFont } }}
                  id="fullname"
                  type="name"
                  variant="outlined"
                  fullWidth
                  disabled
                  onChange={(e) => {
                    setFullName(e.target.value);
                  }}
                  value={fullName}
                />
              </Grid>
            )}

            <h2 className={classes.headers}>Email</h2>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="center"
            >
              <TextField
                className={classes.textField}
                InputProps={{ classes: { input: classes.resizeFont } }}
                disabled
                id="email"
                type="email"
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
              />
            </Grid>
          </Grid>

          <Grid
            style={{ display: "block" }}
            item
            xs={11}
            md={5}
            lg={5}
            container
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            <h2 className={classes.headers}>Password</h2>

            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              style={{
                marginTop: -5,
                marginBottom: -18,
              }}
            >
              <TextField
                className={classes.textFieldPassword}
                InputProps={{ classes: { input: classes.resizeFont } }}
                id="passwordSignup"
                fullWidth
                type={showPassword ? "text" : "password"}
                variant="outlined"
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
                value={password}
              ></TextField>
              <IconButton
                style={{
                  margin: "10px",
                  marginTop: "10px",
                }}
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(true);
                }}
                onMouseDown={() => {
                  setShowPassword(false);
                }}
                edge="end"
                size="large"
              >
                {showPassword ? (
                  <Visibility
                    style={{
                      height: "30px",
                      width: "32px",
                      color: COLORS.meliorYellow,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      height: "30px",
                      width: "32px",
                      color: COLORS.meliorYellow,
                    }}
                  />
                )}
              </IconButton>
            </Grid>

            {/* Retype Password   */}
            <h2 className={classes.headers}>Re-type Password</h2>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <TextField
                className={classes.textFieldPassword}
                InputProps={{ classes: { input: classes.resizeFont } }}
                id="passwordSignup"
                fullWidth
                type={showPassword2 ? "text" : "password"}
                variant="outlined"
                onChange={(e) => {
                  setPassword2(e.target.value);
                }}
                value={password2}
              ></TextField>
              <IconButton
                style={{
                  margin: "10px",
                  marginTop: "10px",
                }}
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword2(true);
                }}
                onMouseDown={() => {
                  setShowPassword2(false);
                }}
                edge="end"
                size="large"
              >
                {showPassword2 ? (
                  <Visibility
                    style={{
                      height: "30px",
                      width: "32px",
                      color: COLORS.meliorYellow,
                    }}
                  />
                ) : (
                  <VisibilityOff
                    style={{
                      height: "30px",
                      width: "32px",
                      color: COLORS.meliorYellow,
                    }}
                  />
                )}
              </IconButton>
            </Grid>
            <ul className={classes.passwordInstructions}>
              <li>Your password can't be less than 8 characters</li>
              <li>
                Your password can't be too similar to your personal information
              </li>
              <li>Your password can't be a commonly used password</li>
              <li>Your password can't be entirely numeric</li>
            </ul>
          </Grid>
          <Button
            disabled={
              !username.trim() |
              !password.trim() |
              !password2.trim() |
              !email.trim() |
              loadingSecond
                ? true
                : false
            }
            type="submit"
            variant="contained"
            className={classes.loginButton}
            onClick={() => {
              handleSubmit2();
            }}
          >
            {loadingSecond ? (
              <CircularProgress className={classes.circularProgress} />
            ) : (
              "SignUp"
            )}
          </Button>
        </Grid>
      </Grid>
    </Container>
  );

  return (
    <Container maxWidth={false} className={classes.backgroundContainer}>
      {/* {loading ? <LoadingPage /> : authenticationView()} */}
      {signnedUp ? navigate("/") : null}
      {type === "invite"
        ? SecondSignUp()
        : !secondPage
        ? FirstSignUp()
        : SecondSignUp()}
    </Container>
  );
};