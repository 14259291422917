import { useContext, useState } from "react";
import { ReactComponent as Logo } from "../assets/melior-logo-black.svg";
import { AuthenticationContext } from "../features/login";
import { Menu as MenuIcon, KeyboardTab } from "@mui/icons-material";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Menu,
  ListItemIcon,
  ListItemText,
  Box,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { primaryColors } from "../helpers/customColors";
import { NavLink, useLocation } from "react-router-dom";
import { fontWeightVariations } from "../helpers/customFont";
import { paymentStatusBarHeight } from "../features/payment-funnel";
import { useNavItems } from "./useNavItems";
import { LANDING_PAGE_TABS } from "../features/tabbed-landing-page/screens/TabbedLandingPageScreen";

export const RevampedAppHeader = ({ paymentStatusMsgOpen }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, setFirebaseInactive, user } = useContext(
    AuthenticationContext
  );
  const { mainNavItems, secondaryNavItems } = useNavItems();
  const isLoggedIn = user !== null;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const useLogout = async () => {
    if (localStorage.getItem("firebasePermission") === "allow") {
      await setFirebaseInactive();
    }
    handleClose();
    logout();
  };

  // Determine if the NavLink is active based on the current pathname
  const location = useLocation();
  const isActive = (path) => {
    if (path === "/")
      return Object.values(LANDING_PAGE_TABS).includes(
        location.pathname.replace("/", "")
      );
    else return location.pathname.includes(path);
  };

  if (location.pathname.includes("online-survey")) return null;
  return (
    <AppBar position="static" sx={{ boxShadow: "none" }}>
      <Container
        maxWidth="100%"
        sx={{
          padding: "8px 40px",
          top: paymentStatusMsgOpen ? paymentStatusBarHeight : "0",
          background: primaryColors.base.white,
        }}
      >
        <Toolbar
          disableGutters
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: isLoggedIn ? "space-between" : "center",
            alignItems: "center",
          }}
        >
          <Box justifyContent={isLoggedIn ? "flex-start" : "center"}>
            <NavLink to={"/"}>
              <Logo width="113px" height="32px" />
            </NavLink>
          </Box>

          {/* small screen nav menu */}
          {isLoggedIn && (
            <Box
              container
              xs={6}
              justifyContent="flex-end"
              sx={{ display: { xs: "flex", lg: "none" } }}
            >
              <Tooltip title="Open Menu" position="top">
                <IconButton
                  aria-controls="customized-menu"
                  aria-haspopup="true"
                  variant="contained"
                  onClick={handleClick}
                  size="medium"
                  sx={{
                    height: "55px",
                    width: "55px",
                    color: primaryColors.brand[500],
                  }}
                >
                  <MenuIcon />
                </IconButton>
              </Tooltip>
              <Menu
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {mainNavItems.map((navItem) =>
                  !navItem.permitted ? null : (
                    <NavLink to={navItem.to}>
                      <MenuItem
                        sx={{
                          display: "flex",
                          background: isActive(navItem.to)
                            ? primaryColors.brand[500]
                            : primaryColors.base.white,
                        }}
                        onClick={handleClose}
                      >
                        <ListItemIcon
                          sx={{
                            display: "flex",
                            color: isActive(navItem.to)
                              ? primaryColors.base.white
                              : primaryColors.gray[900],
                          }}
                        >
                          {navItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={navItem.title}
                          sx={{
                            display: "flex",
                            color: isActive(navItem.to)
                              ? primaryColors.base.white
                              : primaryColors.gray[900],
                          }}
                        />
                      </MenuItem>
                    </NavLink>
                  )
                )}
                {secondaryNavItems.map((navItem) =>
                  !navItem.permitted ? null : (
                    <NavLink to={navItem.to}>
                      <MenuItem
                        sx={{
                          display: "flex",
                          background: isActive(navItem.to)
                            ? primaryColors.brand[500]
                            : primaryColors.base.white,
                        }}
                        onClick={handleClose}
                      >
                        <ListItemIcon
                          sx={{
                            display: "flex",
                            color: isActive(navItem.to)
                              ? primaryColors.base.white
                              : primaryColors.gray[900],
                          }}
                        >
                          {navItem.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={navItem.title}
                          sx={{
                            display: "flex",
                            color: isActive(navItem.to)
                              ? primaryColors.base.white
                              : primaryColors.gray[900],
                          }}
                        />
                      </MenuItem>
                    </NavLink>
                  )
                )}
                <MenuItem onClick={useLogout}>
                  <ListItemIcon>
                    <KeyboardTab />
                  </ListItemIcon>
                  <ListItemText primary={"Logout"} />
                </MenuItem>
              </Menu>
            </Box>
          )}

          {/* big screen nav */}
          {isLoggedIn && (
            <Box
              flexDirection="row"
              gap="22px"
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              {mainNavItems.map((navItem) =>
                !navItem.permitted ? null : (
                  <NavLink
                    to={navItem.to}
                    style={{
                      maxWidth: "inherit",
                      minWidth: "50px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <IconButton
                      sx={{
                        width: 24,
                        height: 24,
                        color: isActive(navItem.to)
                          ? primaryColors.brand[500]
                          : primaryColors.gray[900],
                      }}
                    >
                      {navItem.icon}
                    </IconButton>
                    <Typography
                      variant="text-md"
                      sx={{
                        color: isActive(navItem.to)
                          ? primaryColors.brand[500]
                          : primaryColors.gray[900],

                        fontFamily: "Nunito",
                        fontWeight: fontWeightVariations.medium,
                      }}
                    >
                      {navItem.title}
                    </Typography>
                  </NavLink>
                )
              )}
            </Box>
          )}
          {isLoggedIn && (
            <Box
              flexDirection="row"
              gap="22px"
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              {secondaryNavItems.map((navItem) =>
                !navItem.permitted ? null : (
                  <NavLink
                    to={navItem.to}
                    style={{
                      maxWidth: "inherit",
                      minWidth: "50px",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <IconButton
                      sx={{
                        width: 24,
                        height: 24,
                        color: isActive(navItem.to)
                          ? primaryColors.brand[500]
                          : primaryColors.gray[900],
                      }}
                    >
                      {navItem.icon}
                    </IconButton>
                    <Typography
                      variant="text-md"
                      sx={{
                        color: isActive(navItem.to)
                          ? primaryColors.brand[500]
                          : primaryColors.gray[900],

                        fontFamily: "Nunito",
                        fontWeight: fontWeightVariations.medium,
                      }}
                    >
                      {navItem.title}
                    </Typography>
                  </NavLink>
                )
              )}
              <IconButton
                onClick={useLogout}
                sx={{
                  maxWidth: "inherit",
                  minWidth: "50px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  "&:hover": {
                    background: primaryColors.base.white,
                  },
                }}
              >
                <KeyboardTab
                  sx={{
                    width: 24,
                    height: 24,
                    color: primaryColors.gray[900],
                  }}
                />
                <Typography
                  variant="text-md"
                  sx={{
                    color: primaryColors.gray[900],
                    fontFamily: "Nunito",
                    fontWeight: fontWeightVariations.medium,
                  }}
                >
                  Logout
                </Typography>
              </IconButton>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
