import { Box, Tab, Tabs } from "@mui/material";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useNavigate, useParams } from "react-router-dom";
import { SharedFiltration } from "../components/SharedFiltration";
import { OverallViewContextProvider } from "../contexts/OverallViewContext";
import { AuthenticationContext } from "../../login";
import { OverallProgress } from "../components/OverallProgress";
import { ReviewsManagement } from "../components/ReviewsManagement";
import { ComplaintsManagement } from "../components/ComplaintsManagement";
import { FEEDBACK_ONLY } from "../../../helpers/constants";
import StringHelpers from "../../../helpers/StringHelpers";

export const overallViewTabs = {
  progress: "progress",
  reviews: "reviews",
  complaints: "complaints",
};

export const OverallViewScreen = () => {
  const { overallViewTab } = useParams();
  const navigate = useNavigate();

  const { user } = useContext(AuthenticationContext);

  const allowedTabsBasedOnRole = useMemo(
    () =>
      user.role === FEEDBACK_ONLY
        ? [overallViewTabs.complaints]
        : [
            overallViewTabs.progress,
            overallViewTabs.reviews,
            overallViewTabs.complaints,
          ],
    [user.role]
  );

  // Mappers
  // Map RouteParam to its corresponding Tab Index
  const tabRouteParamToTabIndex = {};
  // Map Tab Index to its corresponding Route Param
  const tabIndexToTabRouteParam = {};

  allowedTabsBasedOnRole.forEach((allowedTab, index) => {
    tabRouteParamToTabIndex[allowedTab] = index;
    tabIndexToTabRouteParam[index] = allowedTab;
  });

  //Set the default Tab index from the one coming from the current Route Param.
  const [currentTabIndex, setCurrentTabIndex] = useState(
    tabRouteParamToTabIndex[overallViewTab]
  );
  const handleTabChange = (event, newTabIndex) => {
    navigate(`/overall-view/${tabIndexToTabRouteParam[newTabIndex]}`);
    setCurrentTabIndex(newTabIndex);
  };

  /**
   * Wrapped in a callback to avoid unnecessary re-renders for the Tabs content
   * like Reviews, Complaints, and Progress Page.
   *
   * Return null if the tab is not allowed for the current User Role
   *  */
  const OverallViewTabContent = useCallback(
    ({ skeleton = false }) => {
      switch (overallViewTab) {
        case overallViewTabs.progress: {
          return allowedTabsBasedOnRole.includes(overallViewTabs.progress) ? (
            <OverallProgress />
          ) : null;
        }
        case overallViewTabs.reviews: {
          return allowedTabsBasedOnRole.includes(overallViewTabs.reviews) ? (
            <ReviewsManagement />
          ) : null;
        }
        case overallViewTabs.complaints: {
          return allowedTabsBasedOnRole.includes(overallViewTabs.complaints) ? (
            <ComplaintsManagement />
          ) : null;
        }
        default: {
          return <></>;
        }
      }
    },
    [allowedTabsBasedOnRole, overallViewTab]
  );

  const isSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <OverallViewContextProvider currentTab={overallViewTab}>
      <Box display="flex" justifyContent="center" width="100%" mt="1rem">
        <Box display="flex" flexDirection="column" width="90%">
          <Box width={isSmallScreen ? "auto" : "30rem"}>
            <Box borderBottom={1} borderColor="divider">
              <Tabs
                value={currentTabIndex}
                onChange={handleTabChange}
                aria-label="icon position tabs example"
                textColor="primary"
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    height: "2px",
                  },
                }}
                variant="fullWidth"
              >
                {allowedTabsBasedOnRole.map((allowedTabLabel) => {
                  return (
                    <Tab
                      label={StringHelpers.capitalizeFirstLetter(
                        allowedTabLabel
                      )}
                      sx={{
                        fontSize: "18px",
                        fontWeight: "bold",
                        textTransform: "capitalize",
                      }}
                      disableRipple
                    />
                  );
                })}
              </Tabs>
            </Box>
          </Box>

          <Box mt="1.5rem" mb="0.5rem">
            <SharedFiltration currentTab={overallViewTab} />
          </Box>
          <Box width="100%" mt="1rem">
            <OverallViewTabContent
            //   skeleton={
            //     user &&
            //     user.subscription.status === PaymentStatuses.DeadlinePhase
            //   }
            />
          </Box>
        </Box>
      </Box>
    </OverallViewContextProvider>
  );
};
