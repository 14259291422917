export const getAnimation = (url) => {
  const requestURL = `${url}`;

  return new Promise((resolve, reject) => {
    fetch(requestURL, { json: true, method: "Get" })
      .then(async (response) => {
        resolve(response.json());
      })
      .catch((error) => {
        reject(error);
      });
  });
};
