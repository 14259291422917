import { API_URL, melior } from "../../../helpers/constants";

export const getUnopenedSMSInvitationsReport = async () => {
  let requestURL = `${API_URL}/reports/unopened-sms-invitations`;

  return await melior
    .get(requestURL)
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
