import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const REVIEWS_PAGE_COUNT = 10;

export const defaultReviewsListParams = {
  page: 1,
  pageSize: REVIEWS_PAGE_COUNT,
};

const getReviews = (queryStrings) => {
  const queryData = {
    params: {
      ...queryStrings,
      page_size: queryStrings.pageSize
    },
  };
  const requestURL = `${API_URL}/reviews`;

  return melior.get(requestURL, queryData);
};

export const useGetReviews = (
  { params, config } = {
    params: defaultReviewsListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["reviews", params],
    queryFn: () => getReviews(params),
    keepPreviousData: true,
  });
};
