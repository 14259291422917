import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Box,
  createStyles,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Controller, useFormContext } from "react-hook-form";
import { COLORS } from "../../../../helpers/Colors";
import { AgnosticFormCard } from "../../AgnosticFormCard";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";

const useStyles = makeStyles((theme) =>
  createStyles({
    formControlLabel: {
      "& svg": {
        width: "0.8em",
        height: "0.8em",
      },
      textAlign: "center",

      /**
       * To make the spacing between the radio button and its label consistent
       * across all the other choices, whatever height they take.
       */
      display: "flex",
      flexDirection: "column-reverse",
      height: "100%",
      justifyContent: "space-between",
    },
    radioGroup: {
      width: "100%",
    },
  })
);

export const FormRadioInputReviewSpecific = ({
  name,
  questionShortText,
  questionFullText,
  options,
  required,
  //   error,
  lastQuestion = false,
}) => {
  const classes = useStyles();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const errorMessage = errors[name] ? errors[name].message : null;

  return (
    <FormControl component="fieldset">
      <AgnosticFormCard>
        <Box display="flex" flexDirection="column" width="100%">
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              flexDirection={"column"}
              justifyContent="flex-start"
              className="labelBox"
              width="40%"
              gap={"5px"}
            >
              <FormLabel
                component="legend"
                sx={{
                  color: primaryColors.gray[700],
                }}
              >
                {required && <span style={{ color: "red" }}>*&nbsp;</span>}
                {questionShortText}
              </FormLabel>
              {questionFullText && (
                <Typography
                  variant="text-md"
                  fontWeight={fontWeightVariations.semiBold}
                  fontFamily={"Nunito"}
                  color={primaryColors.base.black}
                >
                  {questionFullText}
                </Typography>
              )}
            </Box>
            <Box display="flex" justifyContent="flex-start" width="60%">
              <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <RadioGroup
                    aria-label="gender"
                    value={value ? value : ""}
                    onChange={onChange}
                    className={classes.radioGroup}
                    row
                  >
                    <Box
                      display="flex"
                      justifyContent={"space-evenly"}
                      width="100%"
                    >
                      {options.map((option, index) => (
                        <Box key={index}>
                          <FormControlLabel
                            control={<Radio color="secondary" />}
                            label={option.label}
                            value={option.value.toString()}
                            labelPlacement="top"
                            className={classes.formControlLabel}
                          />
                        </Box>
                      ))}
                    </Box>
                  </RadioGroup>
                )}
              />
            </Box>
          </Box>

          <Box display="block">
            <FormHelperText style={{ color: "red" }}>
              {errorMessage ? errorMessage : ""}
            </FormHelperText>
          </Box>
        </Box>
      </AgnosticFormCard>
    </FormControl>
  );
};
