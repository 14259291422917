import { useReducer } from "react";

export const useTemplateSelectionState = () => {
  const [templateSelectionState, dispatchActionToFilters] =
    useReducer(
      distributionTemplateSelectionReducer,
      distributionTemplateSelectionDefaultState
    );

  function selectBranchHandler(updatedBranchData) {
    dispatchActionToFilters({
      type: distributionTemplateSelectionDispatchActions[
        "UPDATE-SELECTED-BRANCH"
      ],
      payload: {
        branchData: updatedBranchData,
      },
    });
  }

  function selectDivisionHandler(updatedDivisionData) {
    dispatchActionToFilters({
      type: distributionTemplateSelectionDispatchActions[
        "UPDATE-SELECTED-DIVISION"
      ],
      payload: {
        divisionData: updatedDivisionData,
      },
    });
  }

  function selectTemplateHandler(updatedTemplateData) {
    dispatchActionToFilters({
      type: distributionTemplateSelectionDispatchActions[
        "UPDATE-SELECTED-TEMPLATE"
      ],
      payload: {
        templateData: updatedTemplateData,
      },
    });
  }

  return {
    templateSelectionState,
    selectBranchHandler,
    selectDivisionHandler,
    selectTemplateHandler
  }
};

const distributionTemplateSelectionDefaultState = {
  branchData: undefined,
  divisionData: undefined,
  templateData: undefined,
};

const distributionTemplateSelectionReducer = (state, action) => {
  switch (action.type) {
    case distributionTemplateSelectionDispatchActions[
      "UPDATE-SELECTED-BRANCH"
    ]: {
      return {
        branchData: action.payload.branchData,
        divisionData: undefined,
        templateData: undefined,
      };
    }
    case distributionTemplateSelectionDispatchActions[
      "UPDATE-SELECTED-DIVISION"
    ]: {
      return {
        ...state,
        divisionData: action.payload.divisionData,
        templateData: undefined,
      };
    }
    case distributionTemplateSelectionDispatchActions[
      "UPDATE-SELECTED-TEMPLATE"
    ]: {
      return {
        ...state,
        templateData: action.payload.templateData,
      };
    }
    default: {
      return state;
    }
  }
};

export const distributionTemplateSelectionDispatchActions = {
  "UPDATE-SELECTED-BRANCH": "UPDATE-SELECTED-BRANCH",
  "UPDATE-SELECTED-DIVISION": "UPDATE-SELECTED-DIVISION",
  "UPDATE-SELECTED-TEMPLATE": "UPDATE-SELECTED-TEMPLATE",
};