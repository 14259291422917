import { useContext, useEffect, useState } from "react";
import { Grid, MenuItem, Select, Tooltip, useMediaQuery } from "@mui/material";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { useSelectBranchAndDivision } from "../hooks/useSelectBranchAndDivision";
import { BranchesAndDivisionsSelectMenu } from "./BranchesAndDivisionsSelectMenu";
import {
  actionNotAllowedDemo,
  featureNotInPlanWarning,
} from "../../../helpers/constants";
import LinkGeneratorModal from "./LinkGeneratorModal";
import { primaryColors } from "../../../helpers/customColors";

const NoBranchOrDivisionSelected =
  "You must choose a branch and division first";

export const LinkGenerator = () => {
  const { user, isFeedbackOnly, isDemo, canSendSMS } = useContext(
    AuthenticationContext
  );
  const [modal, setModal] = useState({ id: -1, surveyVariant: "" });

  const {
    divisions,
    selectedDivisionID,
    selectedBranchID,
    CBopen,
    setCBOpen,
    CDopen,
    setCDOpen,
    error,
    setSelectedBranchID,
    setSelectedDivisionID,
  } = useSelectBranchAndDivision();

  //handle survey type selection
  const selectionHandler = (event) => {
    let selected_variant;
    switch (event.target.value) {
      case SURVEY_VARIANTS.BOTH.value: {
        selected_variant = "both";
        break;
      }
      case SURVEY_VARIANTS.REVIEW.value: {
        selected_variant = "review";
        break;
      }
      case SURVEY_VARIANTS.COMPLAINT.value: {
        selected_variant = "complaint";
        break;
      }
      default:
        break;
    }

    switch (event.target.name) {
      case SURVEY_TYPES.ONE_LINK.name: {
        setModal({
          id: SURVEY_TYPES.ONE_LINK.id,
          surveyVariant: selected_variant,
        });
        break;
      }
      case SURVEY_TYPES.ONE_QR.name: {
        setModal({
          id: SURVEY_TYPES.ONE_QR.id,
          surveyVariant: selected_variant,
        });
        break;
      }
      case SURVEY_TYPES.BULK_SMS.name: {
        setModal({
          id: SURVEY_TYPES.BULK_SMS.id,
          surveyVariant: selected_variant,
        });
        break;
      }
      case SURVEY_TYPES.BULK_LINKS.name: {
        setModal({
          id: SURVEY_TYPES.BULK_LINKS.id,
          surveyVariant: selected_variant,
        });
        break;
      }
      default:
        break;
    }
  };

  //not needed
  // useEffect(() => {
  //   if (!isFeedbackOnly || !selectedDivisionID) return;
  //   setModalId(2);
  // }, [isFeedbackOnly, selectedDivisionID]);

  return (
    <div>
      <BranchesAndDivisionsSelectMenu
        divisions={divisions}
        selectedDivisionID={selectedDivisionID}
        selectedBranchID={selectedBranchID}
        CBopen={CBopen}
        setCBOpen={setCBOpen}
        CDopen={CDopen}
        setCDOpen={setCDOpen}
        error={error}
        setSelectedBranchID={setSelectedBranchID}
        setSelectedDivisionID={setSelectedDivisionID}
      />

      <Grid
        container
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        padding="60px 0px"
      >
        {!isFeedbackOnly && (
          <Grid item xs={6} md={3}>
            <Tooltip
              leaveDelay={2}
              title={
                !(selectedBranchID && selectedDivisionID)
                  ? NoBranchOrDivisionSelected
                  : "Create a QR code that generates same link to hang on your walls"
              }
            >
              <span>
                <Select
                  disabled={!(selectedBranchID && selectedDivisionID)}
                  sx={{
                    backgroundColor: !(selectedBranchID && selectedDivisionID)
                      ? primaryColors.gray[200]
                      : primaryColors.brand[500],
                    color: primaryColors.base.white,
                    borderRadius: "8px",
                    width: "90%",
                    ".MuiSvgIcon-root ": {
                      fill: `${primaryColors.base.white} !important`,
                    },
                  }}
                  name="generate-one-link"
                  value={"0"}
                  onChange={selectionHandler}
                >
                  <MenuItem disabled value="0">
                    Generate One Link
                  </MenuItem>
                  {Object.keys(SURVEY_VARIANTS).map((variant, i) => {
                    return (
                      <MenuItem value={SURVEY_VARIANTS[variant].value}>
                        {SURVEY_VARIANTS[variant].text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </span>
            </Tooltip>
          </Grid>
        )}

        {!isFeedbackOnly && (
          <Grid item xs={6} md={3}>
            <Tooltip
              title={
                !(selectedBranchID && selectedDivisionID)
                  ? NoBranchOrDivisionSelected
                  : "Create a QR code that generates same link to hang on your walls"
              }
            >
              <span>
                <Select
                  disabled={!(selectedBranchID && selectedDivisionID)}
                  sx={{
                    backgroundColor: !(selectedBranchID && selectedDivisionID)
                      ? primaryColors.gray[200]
                      : primaryColors.brand[500],
                    color: primaryColors.base.white,
                    borderRadius: "8px",
                    width: "90%",
                    ".MuiSvgIcon-root ": {
                      fill: `${primaryColors.base.white} !important`,
                    },
                  }}
                  name="generate-one-QR-code"
                  value={"0"}
                  onChange={selectionHandler}
                >
                  <MenuItem disabled value="0">
                    Generate One QR Code
                  </MenuItem>
                  {Object.keys(SURVEY_VARIANTS).map((variant, i) => {
                    return (
                      <MenuItem value={SURVEY_VARIANTS[variant].value}>
                        {SURVEY_VARIANTS[variant].text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </span>
            </Tooltip>
          </Grid>
        )}
        <Grid item xs={6} md={3}>
          <Tooltip
            title={
              isDemo
                ? actionNotAllowedDemo
                : !canSendSMS
                ? featureNotInPlanWarning
                : !(selectedBranchID && selectedDivisionID)
                ? NoBranchOrDivisionSelected
                : "Send unique links to a list of phone numbers"
            }
          >
            <span>
              <Select
                disabled={!(selectedBranchID && selectedDivisionID)}
                sx={{
                  backgroundColor: !(selectedBranchID && selectedDivisionID)
                    ? primaryColors.gray[200]
                    : primaryColors.brand[500],
                  color: primaryColors.base.white,
                  borderRadius: "8px",
                  width: "90%",
                  ".MuiSvgIcon-root ": {
                    fill: `${primaryColors.base.white} !important`,
                  },
                }}
                name="send-bulk-of-SMS"
                value={"0"}
                onChange={selectionHandler}
              >
                <MenuItem disabled value="0">
                  Bulk Send SMS
                </MenuItem>
                {Object.keys(SURVEY_VARIANTS).map((variant, i) => {
                  return (
                    <MenuItem value={SURVEY_VARIANTS[variant].value}>
                      {SURVEY_VARIANTS[variant].text}
                    </MenuItem>
                  );
                })}
              </Select>
            </span>
          </Tooltip>
        </Grid>
        {!isFeedbackOnly && (
          <Grid item xs={6} md={3}>
            <Tooltip
              title={
                !(selectedBranchID && selectedDivisionID)
                  ? NoBranchOrDivisionSelected
                  : "Gnerate multiple links"
              }
            >
              <span>
                <Select
                  disabled={!(selectedBranchID && selectedDivisionID)}
                  sx={{
                    backgroundColor: !(selectedBranchID && selectedDivisionID)
                      ? primaryColors.gray[200]
                      : primaryColors.brand[500],
                    color: primaryColors.base.white,
                    borderRadius: "8px",
                    width: "90%",
                    ".MuiSvgIcon-root ": {
                      fill: `${primaryColors.base.white} !important`,
                    },
                  }}
                  name="generate-bulk-of-links"
                  value={"0"}
                  onChange={selectionHandler}
                >
                  <MenuItem disabled value="0">
                    Bulk Generate Links
                  </MenuItem>

                  {Object.keys(SURVEY_VARIANTS).map((variant, i) => {
                    return (
                      <MenuItem value={SURVEY_VARIANTS[variant].value}>
                        {SURVEY_VARIANTS[variant].text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      <LinkGeneratorModal
        {...modal}
        close={() => setModal({ id: "-1" })}
        branch={user.branches?.find((branch) => branch.id === selectedBranchID)}
        division={divisions[selectedBranchID]?.find(
          (division) => division.id === selectedDivisionID
        )}
      />
    </div>
  );
};

export const SURVEY_TYPES = {
  ONE_LINK: { name: "generate-one-link", id: 0 },
  ONE_QR: { name: "generate-one-QR-code", id: 1 },
  BULK_SMS: { name: "send-bulk-of-SMS", id: 2 },
  BULK_LINKS: { name: "generate-bulk-of-links", id: 3 },
};
const SURVEY_VARIANTS = {
  BOTH: { value: "1", text: "Both" },
  REVIEW: { value: "2", text: "Review" },
  COMPLAINT: { value: "3", text: "Complaint" },
};
