import { Box, Typography } from "@mui/material";
import ExcellentFaceSVG from "../../../assets/icons/faces/Excellent.svg";
import GoodFaceSVG from "../../../assets/icons/faces/Good.svg";
import AverageFaceSVG from "../../../assets/icons/faces/Average.svg";
import NeedsImprovementFaceSVG from "../../../assets/icons/faces/NeedsImprovement.svg";
import BadFaceSVG from "../../../assets/icons/faces/Bad.svg";
import { HashLink } from "react-router-hash-link";
import { COLORS } from "../../../helpers/Colors";
import { getNavigatableSectionSlug } from "../../../helpers/General";
import { CircularChartMini } from "../../../reusable/components/charts/CircularChartMini";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import { formatTime } from "../../../helpers/timeHelpers";
import { averageSatisfactionColors } from "../../revamped-landing-page/components/AllBranchesQuestionBarChart";

export const BranchProgressCard = ({ cardRawData, branchData }) => {
  return (
    <HashLink
      title="Click to view more insights in the Overall Progress Page"
      to={`overall-view/progress#${getNavigatableSectionSlug(
        cardRawData.text
      )}`}
      style={{ color: COLORS.black }}
      smooth
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        borderRadius={3}
        bgcolor={COLORS.white}
        p={2}
        width="300px"
        height="300px"
        sx={{
          "&:hover": {
            bgcolor: COLORS.white,
            border: `1px solid ${COLORS.grey}`,
            transition: "ease-in",
            cursor: "pointer !important",
          },
        }}
      >
        {/*Start: Branch Title */}
        <Box
          width="100%"
          display="flex"
          alignItems="flex-start"
          //   gap={1}
        >
          {/* Card Title */}
          <Typography fontWeight="bold">{cardRawData.text}</Typography>

          {/*Start: Selected Branch Filter */}
          {branchData && (
            <Box
              display="flex"
              alignItems="center"
              borderRadius={1}
              bgcolor="#E0E0E0"
              px={1}
              width="fit-content"
              py={"2px"}
            >
              <Box
                borderRadius="50%"
                width="12px"
                height="12px"
                bgcolor={branchData.color}
                mr="10px"
              ></Box>
              <Typography sx={{ fontSize: "0.8rem", whiteSpace: "nowrap" }}>
                {branchData.name}
              </Typography>
            </Box>
          )}
          {/*End: Selected Branch Filter */}
        </Box>
        {/*End: Current Branch Badge */}

        {/*Start: Card Dynamic Section (Faces, Graphs...etc) */}
        <Box>{generateCardDynamicSection(cardRawData)}</Box>
        {/*End: Card Dynamic Section (Faces, Graphs...etc) */}

        {/*Start: End Section */}

        {/*End: End Section */}
      </Box>
    </HashLink>
  );
};

const getNPSChartData = (NPSValue) => {
  const dataForNPSChart = [
    {
      name: "positive",
      value: undefined,
      color: "#1C5E9B",
    },
    {
      name: "negative",
      value: undefined,
      color: primaryColors.error[600],
    },
    {
      name: "neutral",
      value: undefined,
      color: primaryColors.gray[200],
    },
  ];
  if (NPSValue === 0) {
    // clear negative value + set positive and neutral values to draw 2 gray parts on the chart
    dataForNPSChart[0].color = primaryColors.gray[200];
    dataForNPSChart[0].value = 1;
    dataForNPSChart[1].value = 0;
    dataForNPSChart[2].value = 1;
  } else if (NPSValue < 0) {
    // adjust the values to ruin the chart start and end angles
    if (NPSValue <= -95) {
      dataForNPSChart[1].value = 94;
      dataForNPSChart[2].value = 6;
    } else {
      dataForNPSChart[1].value =
        Math.abs(NPSValue) < 12.5
          ? Math.abs(NPSValue) * 1.4
          : Math.abs(NPSValue);
      dataForNPSChart[2].value = 100 + NPSValue;
    }
    // set value and color of positive section to make positive half of the chart gray
    dataForNPSChart[0].value = 90;
    dataForNPSChart[0].color = primaryColors.gray[200];
  } else if (NPSValue > 0) {
    if (NPSValue >= 95) {
      dataForNPSChart[0].value = 94;
      dataForNPSChart[2].value = 6;
    } else {
      dataForNPSChart[0].value = NPSValue < 12.5 ? NPSValue * 1.4 : NPSValue;
      dataForNPSChart[2].value = 100 - NPSValue;
    }

    // set value and color of negative section to make negative half of the chart gray
    dataForNPSChart[1].value = 90;
    dataForNPSChart[1].color = primaryColors.gray[200];

    // swap positive and negative objects to draw the sections in order
    let positiveTemp = dataForNPSChart[0];
    dataForNPSChart[0] = dataForNPSChart[1];
    dataForNPSChart[1] = positiveTemp;
  }
  return dataForNPSChart;
};

const getNPSNeedleValue = (NPSValue) => {
  const firstRangeMultiplicator = 0.6,
    secondRangeMultiplicator = 0.8,
    thirdRangeMultiplicator = 1,
    fourthRangeMultiplicator = 1.1;

  // set multiplication value according to the score's range, to adjust the needle with the colored part
  let multiplicator;
  if (Math.abs(NPSValue) <= 15) multiplicator = firstRangeMultiplicator;
  else if (Math.abs(NPSValue) > 15 && Math.abs(NPSValue) <= 25)
    multiplicator = secondRangeMultiplicator;
  else if (Math.abs(NPSValue) > 25 && Math.abs(NPSValue) < 50)
    multiplicator = thirdRangeMultiplicator;
  else if (Math.abs(NPSValue) >= 50) multiplicator = fourthRangeMultiplicator;

  if (NPSValue === 0) return 100;
  else if (NPSValue >= 95) return 200;
  else if (NPSValue <= -95) return 0;
  else return 100 + NPSValue * multiplicator;
};

export const generateCardDynamicSection = (cardRawData) => {
  let cardTextValue, cardIndexValue;

  if (cardRawData.type === "AS") {
    const cardDataToRender = getNearestScaleItemTextToValue(cardRawData);
    cardTextValue = cardDataToRender.text;
    cardIndexValue = cardDataToRender.index;
  } else if (cardRawData.type === "MC") {
    cardTextValue = cardRawData.name;
  }

  switch (cardRawData.type) {
    case "AS": {
      const chartDataInsights = [
        {
          name: "Excellent",
          value: 20,
          color: averageSatisfactionColors["Excellent"],
        },
        {
          name: "Good",
          value: 20,
          color: averageSatisfactionColors["Good"],
        },
        {
          name: "Average",
          value: 20,
          color: averageSatisfactionColors["Average"],
        },
        {
          name: "Needs Improvement",
          value: 20,
          color: averageSatisfactionColors["Needs Improvement"],
        },
        {
          name: "Bad",
          value: 20,
          color: averageSatisfactionColors["Bad"],
        },
      ];
      return (
        <CircularChartMini
          chartDataInsights={chartDataInsights}
          titleOverChart={undefined}
          showNeedle={true}
          value={cardRawData?.value?.toFixed(1) / 5}
          labelInsideChart={{
            mainText: cardRawData.value ? cardRawData.value.toFixed(1) : 0,
            secondaryText: "/5",
          }}
          // titleInsideChart={"AS"}
        />
      );
    }
    case "CSAT": {
      const IsScoreZero = cardRawData.data.score === 0;
      const chartDataInsights = [
        {
          name: "Dissatisfied",
          value: 100 - cardRawData.data.score,
          color: IsScoreZero
            ? primaryColors.gray[200]
            : primaryColors.error[500],
        },
        {
          name: "Satisfied",
          value: cardRawData.data.score === 0 ? 100 : cardRawData.data.score,
          color: IsScoreZero ? primaryColors.gray[200] : "#1C5E9B",
        },
      ];
      return (
        <CircularChartMini
          chartDataInsights={chartDataInsights}
          titleOverChart={undefined}
          labelInsideChart={{
            mainText: cardRawData.data ? cardRawData.data.score : 0,
            secondaryText: "%",
          }}
        />
      );
    }
    case "NPS": {
      const NPSValue = cardRawData.data.value;
      const preparedDataForChart = getNPSChartData(NPSValue);

      return (
        <CircularChartMini
          chartDataInsights={preparedDataForChart.reverse()}
          titleOverChart={undefined}
          labelInsideChart={{
            mainText: cardRawData.data ? NPSValue : 0,
          }}
          showNeedle={true}
          value={getNPSNeedleValue(NPSValue) / 200}
          showChartScale={true}
          reverseChartDirection={NPSValue < 0}
        />
      );
    }
    case "MC": {
      return (
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Typography
            sx={{
              fontSize: "20px",
              mt: "3.2rem",
            }}
            fontWeight="bold"
          >
            {cardTextValue}
          </Typography>
          <Typography sx={{ fontSize: "40px" }} fontWeight="bold">
            {`${cardRawData.value}${
              cardRawData.value_type === "percentage" ? "%" : ""
            }`}
          </Typography>
        </Box>
      );
    }
    case "time": {
      const formattedTime = formatTime(cardRawData.value);
      return <DigitalTimeView {...formattedTime} />;
    }
    case "rate": {
      const IsScoreZero = cardRawData.value === 0;
      const chartDataInsights = [
        {
          name: "Dissatisfied",
          value: 100 - cardRawData.value,
          color: primaryColors.gray[200],
        },
        {
          name: "Satisfied",
          value: cardRawData.value === 0 ? 100 : cardRawData.value,
          color: IsScoreZero
            ? primaryColors.gray[200]
            : secondaryColors.green[500],
        },
      ];
      return (
        <CircularChartMini
          chartDataInsights={chartDataInsights}
          titleOverChart={undefined}
          labelInsideChart={{
            mainText: cardRawData ? cardRawData.value : 0,
            secondaryText: "%",
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
};

const getNearestScaleItemTextToValue = (cardRawData) => {
  const { value: valueToFind, scale } = cardRawData;

  let latestScaleIndexCandidate;
  let latestDifferenceCandidate;

  scale.forEach((scaleItem, index) => {
    if (index === 0) {
      latestDifferenceCandidate = Math.abs(scaleItem.weight - valueToFind);
      latestScaleIndexCandidate = index;
    } else {
      const currentDifferenceCandidate = Math.abs(
        scaleItem.weight - valueToFind
      );

      if (currentDifferenceCandidate < latestDifferenceCandidate) {
        latestDifferenceCandidate = currentDifferenceCandidate;
        latestScaleIndexCandidate = index;
      }
    }
  });

  return {
    text: scale[latestScaleIndexCandidate].text,
    index: latestScaleIndexCandidate,
  };
};

const getCorrespondingIconAndColorToText = (scoreIndex) => {
  switch (scoreIndex) {
    case 0: {
      return { icon: ExcellentFaceSVG, color: "#39B54A" };
    }
    case 1: {
      return { icon: GoodFaceSVG, color: "#91CA60" };
    }
    case 2: {
      return { icon: AverageFaceSVG, color: "#FBD240" };
    }
    case 3: {
      return { icon: NeedsImprovementFaceSVG, color: "#F17129" };
    }
    case 4: {
      return { icon: BadFaceSVG, color: "#DA4348" };
    }
    default: {
      return null;
    }
  }
};

const DigitalTimeView = ({ days = 0, hours = 0, minutes = 0 }) => {
  return (
    <Box height="300px">
      <Box
        display="flex"
        flexDirection="Row"
        gap={2}
        justifyContent="center"
        paddingY="30px"
      >
        {/* days */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="display-xl"
            color={primaryColors.gray[900]}
            fontWeight={fontWeightVariations.regular}
          >
            {days.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Typography
            variant="text-sm"
            color={primaryColors.gray[600]}
            fontWeight={fontWeightVariations.regular}
          >
            Days
          </Typography>
        </Box>
        <Typography
          variant="display-xl"
          color={primaryColors.gray[900]}
          fontWeight={fontWeightVariations.regular}
        >
          :
        </Typography>
        {/* hours */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="display-xl"
            color={primaryColors.gray[900]}
            fontWeight={fontWeightVariations.regular}
          >
            {hours.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Typography
            variant="text-sm"
            color={primaryColors.gray[600]}
            fontWeight={fontWeightVariations.regular}
          >
            Hours
          </Typography>
        </Box>
        <Typography
          variant="display-xl"
          color={primaryColors.gray[900]}
          fontWeight={fontWeightVariations.regular}
        >
          :
        </Typography>
        {/* minutes */}
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="display-xl"
            color={primaryColors.gray[900]}
            fontWeight={fontWeightVariations.regular}
          >
            {minutes.toLocaleString("en-US", {
              minimumIntegerDigits: 2,
              useGrouping: false,
            })}
          </Typography>
          <Typography
            variant="text-sm"
            color={primaryColors.gray[600]}
            fontWeight={fontWeightVariations.regular}
          >
            Minutes
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
