import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getTemplateQuestions = ({ templateID }) => {
  const requestURL = `${API_URL}/templates/${templateID}/questions`;

  return melior
    .get(requestURL)
    .then(function updateQuestionsResponse(response) {
      const englishQuestionsData = response.data.find(function getEnglishQuestions(
        questionsData
      ) {
        return questionsData.lang === "en";
      });

      return { ...response, data: englishQuestionsData.questions };
    });
};

export const useGetTemplateQuestions = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["template-questions", params],
    queryFn: () => getTemplateQuestions(params),
    keepPreviousData: true,
  });
};
