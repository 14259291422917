import { Grid, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useChangeFacilityInfo } from "../../api";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  FormTextInput,
  FormImageInput,
} from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";
import { FormContainer } from "./FormContainer";

export const FacilityInformationSection = () => {
  const { user, getUser } = useContext(AuthenticationContext);
  const { fireNotification } = useContext(NotificationContext);

  const {
    mutateAsync: changeFacilityInfoMutation,
    isLoading: isChangeFacilityInfoLoading,
  } = useChangeFacilityInfo();

  const validationSchema = yup.object({
    facility_name: yup.string().required("this field can't be empty"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      facility_name: user.profile["facility_name"],
      facility_logo: user.profile["facility_logo"],
    },
  });
  const { handleSubmit, reset, formState } = reactHookFormMethods;
  const updateFacilityInfoHandler = async (facilityData) => {
    if (!(facilityData.facility_logo instanceof File))
      delete facilityData.facility_logo;

    changeFacilityInfoMutation(facilityData)
      .then(async () => {
        getUser();
        fireNotification({
          title: "Changed successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch((error) =>
        fireNotification({
          title: "An error occurred, please try again.",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        })
      );
  };

  return (
    <>
      <FormProvider {...reactHookFormMethods}>
        <form method="PATCH" onSubmit={handleSubmit(updateFacilityInfoHandler)}>
          <FormContainer
            formTitle={"Facility Information"}
            formState={formState}
            submitting={isChangeFacilityInfoLoading}
            resetCallback={() =>
              reset({
                facility_name: user.profile["facility_name"],
                facility_logo: user.profile["facility_logo"],
              })
            }
          >
            <Grid
              container
              justifyContent="flex-start"
              direction="row"
              width={{ sm: "100%", lg: "660px", md: "660px", xl: "660px" }}
              mr={"50%"}
              spacing={2}
            >
              <Grid item xs={12} md={6}>
                <FormImageInput name="facility_logo" />
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography
                  sx={{
                    fontFamily: "Nunito",
                    fontSize: fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                    color: primaryColors.gray[700],
                    marginBottom: "6px",
                  }}
                >
                  Facility Name
                </Typography>
                <FormTextInput
                  name="facility_name"
                  placeholder={user.profile["facility_name"]}
                  type="text"
                  sx={{
                    color: primaryColors.gray[500],
                    backgroundColor: primaryColors.base.white,
                    border: `1px solid ${primaryColors.gray[300]}`,
                    borderRadius: "8px",
                  }}
                />
              </Grid>
            </Grid>
          </FormContainer>
        </form>
      </FormProvider>
    </>
  );
};
