import { Box, CircularProgress, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../../helpers/Colors";
import StringHelpers from "../../../../../helpers/StringHelpers";
import { renderAudio } from "../../../../../helpers/General";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { overallViewTabs } from "../../../screens";
import { getSingleComplaint } from "../../../api";
import { OlderGenericDialog } from "../../../../../reusable/components/ContactsDialog";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "400px",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 2, 2, 2),
    outline: "none",
    paddingLeft: "10px",
    backgroundColor: COLORS.white,
  },
  content: {
    width: "400px",
    overflowY: "auto",
  },
  contactLabel: {
    color: COLORS.brightRed,
    fontSize: "16px",
    fontFamily: "Avenir-Heavy",
  },
  contactData: {
    color: "black",
    fontSize: "16px",
    fontFamily: "Avenir-Medium",
    marginLeft: "5px",
  },
  comment: {
    fontSize: "18px",
    fontFamily: "Avenir-Heavy",
    marginTop: "20px",
    marginBottom: "0px",
    textAlign: "center",
  },
  closeButtonContainer: {
    width: "100%",
    textAlign: "end",
    maxHeight: "10px",
  },
  closeButton: {
    margin: "0px",
    marginRight: "auto",
  },
  row: {
    margin: "10px",
  },
}));

export const ComplaintsContactsDialog = () => {
  const classes = useStyles();

  const [complaintData, setComplaintData] = useState();
  const [isFetching, setIsFetching] = useState(true);
  const [complaintNotFound, setComplaintNotFound] = useState(false);

  const navigate = useNavigate();
  const { complaintIDParam } = useParams();

  //   const  complaintData  = undefined;

  const fetchAndSetComplaintsContactsData = () => {
    console.log("Fetching Single Complaint (URL)");
  };

  const channelText = StringHelpers.capitalizeFirstLetter(
    complaintData?.channel
  );

  useEffect(() => {
    if (complaintData === undefined && complaintIDParam !== undefined) {
      fetchAndSetComplaintsContactsData();
    } else {
      console.log("no complaint ID in URL");
    }
  }, [complaintIDParam, complaintData]);

  useEffect(() => {
    setIsFetching(true);
    getSingleComplaint(complaintIDParam)
      .then((singleComplaintResponse) => {
        setComplaintData(singleComplaintResponse);
        setIsFetching(false);
      })
      .catch((error) => {
        if (error.toJSON().status === 404) {
          setComplaintNotFound(true);
        }
        setIsFetching(false);
      });
  }, [complaintIDParam]);

  return (
    <OlderGenericDialog
      dialogTitle="Contacts Info"
      dialogSubtitle={`Complaint - ${complaintIDParam}`}
      onCloseCallback={() =>
        navigate(`/overall-view/${overallViewTabs.complaints}`)
      }
      openByDefault
      triggerButtonDisplay="inline"
    >
      <Grid className={classes.content}>
        {complaintNotFound ? (
          <span
            className={classes.contactLabel}
            style={{ display: "flex", justifyContent: "center" }}
          >
            Complaint Not Found
          </span>
        ) : isFetching ? (
          <Box display="flex" justifyContent="center" my={4}>
            <CircularProgress style={{ color: COLORS.meliorYellow }} />
          </Box>
        ) : (
          <Grid
            item
            xs={12}
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <div>
              {complaintData?.contacts?.name && (
                <span className={classes.contactLabel}>
                  Name:
                  <span className={classes.contactData}>
                    {complaintData?.contacts?.name}
                  </span>
                </span>
              )}
            </div>
            <div>
              {complaintData?.contacts?.email && (
                <span className={classes.contactLabel}>
                  Email:
                  <span className={classes.contactData}>
                    {complaintData?.contacts?.email}
                  </span>
                </span>
              )}
            </div>
            <div>
              {(!!complaintData?.contacts?.number ||
                !!complaintData?.contacts?.phone) && (
                <span className={classes.contactLabel}>
                  Number:
                  <span className={classes.contactData}>
                    {complaintData?.contacts?.number}
                    {complaintData?.contacts?.phone}
                  </span>
                </span>
              )}
            </div>
            {!!complaintData?.comment_text && (
              <div className={classes.comment}>{complaintData?.comment_text}</div>
            )}
            {!!complaintData?.comment_audio && (
              <div className={classes.comment_text}>
                {renderAudio(complaintData?.comment_audio)}
              </div>
            )}
            <Box
              display="flex"
              justifyContent="space-around"
              marginTop="2rem"
              width="100%"
            >
              <span className={classes.contactLabel}>
                Channel:
                <span className={classes.contactData}>{channelText}</span>
              </span>
            </Box>
          </Grid>
        )}
      </Grid>
    </OlderGenericDialog>
  );
};
