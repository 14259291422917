import { makeStyles } from "@mui/styles";

import {
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
  BarChart,
  ReferenceLine,
} from "recharts";
import { COLORS } from "../../../helpers/Colors";
import { primaryColors } from "../../../helpers/customColors";

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    border: "1px solid",
    borderColor: COLORS.silver,
    backgroundColor: COLORS.white,
    paddingBottom: "0%",
    overflowWrap: "break-word",
    whiteSpace: "pre-wrap",
    opacity: "0.7",
    width: "auto",
  },
  tooltipTitle: {
    margin: "5px",
    color: COLORS.brightBlack,
  },
}));

const CustomTooltip = ({ payload, active }) => {
  const classes = useStyles();
  if (active && payload) {
    return (
      <div className={classes.tooltip}>
        <p className={classes.tooltipTitle}>
          Total count: {payload[0].payload.tooltipValue}
        </p>
      </div>
    );
  }

  return null;
};
export const PositiveNegativeBarChart = ({
  data,
  YAxisScale,
  getBarColor,
  showTooltip,
  rotateXAxisTick,
}) => {
  const renderCustomizedLabel = ({ viewBox, value }) => {
    const { x, y, width, height } = viewBox;
    if (width === 0) return;
    return (
      <text
        x={x + 22}
        y={value > 0 ? y + height + 15 : y + height - 5}
        fontSize="16px"
        fontFamily="Nunito"
        textAnchor="middle"
        fill={primaryColors.gray[500]}
      >
        {value}
      </text>
    );
  };

  function getBranchTickData(branchId) {
    return data?.find((branch) => branch.id === branchId);
  }
  const renderCustomizedTick = ({ x, y, textAnchor, payload }) => {
    let branchData = getBranchTickData(payload.value);

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = getComputedStyle(document.body).font;
    let circleTextGap = context.measureText(branchData.name).width / 2 + 12;

    return rotateXAxisTick ? (
      <g transform={`translate(${x},${y})`}>
        <g x={0} y={0} transform="rotate(25)">
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="start"
            fill="#666"
            fontSize="14px"
          >
            <title>{branchData.fullName}</title>
            {branchData.name}
          </text>
          <circle
            cx={-10}
            cy={10}
            r={5}
            fill={branchData.color || primaryColors.gray[100]}
          />
        </g>
      </g>
    ) : (
      [
        <text x={x} y={y + 15} textAnchor={textAnchor}>
          {branchData.name}
        </text>,
        <circle
          cx={x - circleTextGap}
          cy={y + 10}
          r={5}
          fill={branchData.color || primaryColors.gray[100]}
        />,
      ]
    );
  };

  return (
    <ResponsiveContainer>
      <BarChart
        width="100%"
        height="100%"
        data={data}
        margin={{
          top: 20,
          right: 40,
          bottom: 40,
          left: 0,
        }}
      >
        <CartesianGrid stroke={primaryColors.gray[100]} vertical={false} />
        <XAxis
          dataKey="id"
          type="category"
          axisLine={false}
          tickLine={false}
          tick={renderCustomizedTick}
        />
        {showTooltip && <Tooltip content={<CustomTooltip />} />}
        <YAxis ticks={YAxisScale} axisLine={false} tickLine={false} />
        <ReferenceLine y={0} stroke={primaryColors.gray[400]} />
        <Bar dataKey="value" barSize={45} style={{}} radius={[8, 8, 0, 0]}>
          {data?.map((entry, index) => (
            <Cell key={index} fill={getBarColor(entry.value)} />
          ))}
          <LabelList content={renderCustomizedLabel} />
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  );
};
