import { useState, useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import useOnClickOutside from "../../../../reusable/hooks/useOnClickOutside";
import { primaryColors } from "../../../../helpers/customColors";

export const UsersToMentionListMenu = ({
  showUsersToMentionListMenu,
  setShowUsersToMentionListMenu,
  filteredFacilityUsers,
  replacePartialMentionWithFullMention,
}) => {
  const clickedOutsideUsersToMentionListMenuHandler = () => {
    setShowUsersToMentionListMenu(false);
  };

  const usersListMenuContainerRef = useRef(null);

  useOnClickOutside(
    usersListMenuContainerRef,
    clickedOutsideUsersToMentionListMenuHandler
  );

  return (
    <Box ref={usersListMenuContainerRef} position="relative">
      {showUsersToMentionListMenu && (
        <Box
          minHeight="10rem"
          height="10rem"
          bgcolor={primaryColors.base.white}
          boxShadow={3}
          borderRadius={3}
          p={1}
          overflow="auto"
          width="fit-content"
          display="flex"
          flexDirection="column"
          gap={1}
        >
          {filteredFacilityUsers.map(function renderUserToMentionButton(
            facilityUser,
            index
          ) {
            return (
              <Button
                key={index}
                variant="text"
                onClick={() =>
                  replacePartialMentionWithFullMention(facilityUser)
                }
                sx={{
                  bgcolor:
                    index === 0
                      ? primaryColors.gray[300]
                      : primaryColors.gray[100],
                  borderRadius: 2,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 1,
                  textTransform: "none",
                  width: "100%",
                  mb: "0.5rem",
                  "&:hover": {
                    bgcolor: primaryColors.gray[100],
                  },
                }}
              >
                {/* TODO: Later show Name with Username */}
                {/* <Typography
                  variant="text-md"
                  color={primaryColors.gray[500]}
                  fontWeight="bold"
                >
                  {facilityUser.name}
                </Typography>
                <Typography variant="text-sm" color={primaryColors.gray[400]}>
                  {facilityUser.username}
                </Typography> */}

                <Typography
                  variant="text-md"
                  color={primaryColors.gray[500]}
                  fontWeight="bold"
                >
                  {facilityUser.username}
                </Typography>
              </Button>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export function getMentionUsernameTag(text) {
  let mentionText = "";
  const regexToExtractMentionWord = /\B@\w+/g;

  const regexResult = regexToExtractMentionWord.exec(text);
  if (regexResult) {
    mentionText = regexResult[0];
  }

  return mentionText;
}

export function startedMentioning(text){
    // let mentioningStarted = false;
    const regexToDetectMentioningSign = /\B@/g;

    return regexToDetectMentioningSign.test(text)
}

export function mentionedUsernameIsValid({
  mentionedUsername,
  listOfFacilityUsers,
}) {
  let mentionedUserExistsInFacilityUsersList = false;
  let matchedUser = undefined;
    
  listOfFacilityUsers.forEach(function checkIfMentionedUsernameExists(
    facilityUser
  ) {
    if (facilityUser.username === mentionedUsername) {
      mentionedUserExistsInFacilityUsersList = true;
      matchedUser = facilityUser;
    }
  });
  return {
    mentionedUsernameValid: mentionedUserExistsInFacilityUsersList,
    matchedFacilityUser: matchedUser,
  };
}

// export const facilityUsers = [
//   {
//     userID: 2,
//     name: "Samy George",
//     username: "samygeorge",
//   },
//   {
//     userID: 3,
//     name: "Abdelrahman Darwish",
//     username: "darwish",
//   },
//   {
//     userID: 4,
//     name: "Israa",
//     username: "israa",
//   },
//   {
//     userID: 5,
//     name: "Mohamed Seada",
//     username: "seada",
//   },
//   {
//     userID: 6,
//     name: "Daniel Louis",
//     username: "daniel",
//   },
// ];
