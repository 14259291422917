import { Box } from "@mui/material";
import MyMeliorSVG from "../../../assets/MyMeliorWithName.svg";
import "./reports-styles.css";
import { reportTypes } from "./ReportPageWithPrinting";

export const FullReportPage = ({
  reportType,
  facilityLogoData,
  issueDate,
  accountName,
  reportSubtitleData,
  reportTablesData,
  printingAreaRef,
}) => {
  //   console.log(facilityLogoSVGData, "facilityLogoSVGData");
  //   console.log(MyMeliorSVG, "MyMeliorSVG");

  const getReportTitle = ({ reportType }) => {
    switch (reportType) {
      case reportTypes.SMSSendingResult: {
        return `General`;
      }
      case reportTypes.PerformanceMonthly: {
        return `Performance`;
      }
      case reportTypes.UnopenedSMSInvitations: {
        return `Unopened`;
      }
      case reportTypes.WeeklySMSCount: {
        return `Weekly`;
      }
      case reportTypes.MonthlySMSCount: {
        return `Monthly`;
      }
      default: {
        return null;
      }
    }
  };
  const renderReportSubtitle = ({ reportType, reportSubtitleData }) => {
    switch (reportType) {
      case reportTypes.SMSSendingResult: {
        return `Here’s a report of the total numbers of your latest SMS request per
              branch. At ${reportSubtitleData.date}, ${reportSubtitleData.time}`;
      }
      case reportTypes.PerformanceMonthly: {
        return `Here’s a report of the performance at ${reportSubtitleData.month}, ${reportSubtitleData.year}`;
      }
      case reportTypes.UnopenedSMSInvitations: {
        return `Here’s a report of the total numbers of unopened SMS per branch`;
      }
      case reportTypes.WeeklySMSCount:
      case reportTypes.MonthlySMSCount: {
        return `Here’s a report of the total numbers of SMS from ${reportSubtitleData.startDate} to
        ${reportSubtitleData.endDate} per branch.`;
      }
      default: {
        return null;
      }
    }
  };

  const renderReportBoldSubtitle = ({ reportType }) => {
    switch (reportType) {
      case reportTypes.PerformanceMonthly: {
        return `Please, note that this report exclude archived reviews`;
      }
      default: {
        return null;
      }
    }
  };

  // Use either SVG Data for the Facility Logo or use an Image URL
  const facilityLogo = facilityLogoData.isSVG
    ? `data:image/svg+xml;utf8,${encodeURIComponent(facilityLogoData.logo)}`
    : facilityLogoData.logo;

  return (
    <Box className="container" ref={printingAreaRef}>
      <Box className="header">
        <Box className="logos-container">
          <img className="melior-logo" src={MyMeliorSVG} alt="MyMeliorLogo" />

          <img className="facility-logo" src={facilityLogo} alt="ClientLogo" />
        </Box>
        <p className="issue-date-text">
          <span className="font-size-m font-color-light">Issue Date</span>
          <span className="font-size-m font-color-dark">{issueDate}</span>
        </p>
      </Box>
      <Box className="body">
        <p className="font-size-xl headline">
          {getReportTitle({ reportType })}
        </p>

        <p className="font-size-l font-color-light">Hello [{accountName}]</p>
        <p className="font-size-l">
          {renderReportSubtitle({ reportType, reportSubtitleData })}
        </p>

        <p className="font-size-m font-weight-600 report-note">
          {renderReportBoldSubtitle({ reportType })}
        </p>
        {reportTablesData.map((reportTableData, index) => {
          return <ReportTable reportTableData={reportTableData} key={index} />;
        })}
        <Box className="closing-text font-size-l font-color-dark">
          <p>
            If you think these numbers are incorrect or have any questions,
            please email us at info@mymelior.com
          </p>
          <p>Cheers,</p>
          <p>Melior Team</p>
        </Box>
      </Box>
      <Box className="footer font-size-s font-color-light">
        <p>© 2023 Melior</p>
        <p>mymelior.com</p>
      </Box>
    </Box>
  );
};

const ReportTable = ({ reportTableData }) => {
  return (
    <Box className="table-container">
      <p className="table-container-label font-size-l">
        {reportTableData.branchName}
      </p>

      <table className="font-size-s font-weight-600">
        <thead>
          <tr>
            {reportTableData.columnsData.map((columnData, index) => {
              return <th key={index}>{columnData.label}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {reportTableData.rowsData.map((rowData, index) => {
            return (
              <tr key={index}>
                {reportTableData.columnsData.map((columnData, index) => {
                  return <td key={index}>{rowData[columnData.key]}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </Box>
  );
};
