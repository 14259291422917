import { useState, useContext } from "react";
import { askForPermissionToReceiveNotifications } from "../../../firebase";
import { CircularProgress, Grid, Button, Box, Typography } from "@mui/material";
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../contexts/NotificationContext";

import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { useNavigate } from "react-router-dom";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../reusable/hooks";

export const LoginPage = () => {
  const { login } = useContext(AuthenticationContext);
  const { checkFirebaseRegister } = useContext(AuthenticationContext);
  const { fireNotification } = useContext(NotificationContext);

  const validationSchema = yup.object({
    usernameOrEmail: yup
      .string()
      .required("This field is required")
      .test("isEmail", "This is not a valid email address", function (value) {
        if (/\@/.test(value)) {
          if (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) return true;
          else return false;
        } else return true;
      }),
    password: yup
      .string()
      .required("This field is required")
      .min(8, "Must have at least 8 characters"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { handleSubmit } = reactHookFormMethods;

  const [loading, setLoading] = useState();
  const navigate = useNavigate();

  const loginHandler = async (userLoginData) => {
    setLoading(true);
    try {
      await login(userLoginData.usernameOrEmail, userLoginData.password);
      await askForPermissionToReceiveNotifications();
      if (localStorage.getItem("firebasePermission") === "allow") {
        await checkFirebaseRegister();
      }
    } catch (error) {
      setLoading(false);
      error.response.status === 400
        ? fireNotification({
            title: "Wrong username, email or password.",
            description:
              " The username, email or password you entered did not match our records. Please double-check and try again!",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            target: NOTIFICATIONS_TARGET.everyone,
          })
        : fireNotification({
            title: "Network Error",
            description: "please try again later",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            target: NOTIFICATIONS_TARGET.everyone,
          });
    }
  };

  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="GET" onSubmit={handleSubmit(loginHandler)}>
        <Grid
          container
          width="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="88px auto 40px"
          gap="20px"
          margin="auto"
        >
          {/* form header / title */}
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["display-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.gray[900],
              marginBottom: "12px",
            }}
          >
            Log in to your account
          </Typography>

          {/* username or email field */}
          <Grid item width="360px">
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                color: primaryColors.gray[700],
                marginBottom: "6px",
              }}
            >
              Username / Email
            </Typography>
            <FormTextInput
              fullWidth
              name="usernameOrEmail"
              placeholder="Your username / email"
              size="small"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                //   border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>
          {/* password field */}
          <Grid item width="360px">
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                color: primaryColors.gray[700],
                marginBottom: "6px",
              }}
            >
              Password
            </Typography>
            <FormTextInput
              fullWidth
              name="password"
              placeholder="Your password"
              size="small"
              type="password"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                //   border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>
          {/* forgot password button */}
          <Grid item alignSelf="flex-end">
            <Button
              variant="text"
              onClick={() => {
                navigate("/forgot-password");
              }}
              sx={{
                marginTop: "4px",
                "&:hover": {
                  backgroundColor: primaryColors.gray[50],
                },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-xs"],
                  fontWeight: fontWeightVariations.bold,
                  color: primaryColors.gray[700],
                  "&:hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Forgot password?
              </Typography>
            </Button>
          </Grid>
          {/* login button */}
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              color: primaryColors.warning[500],
              padding: "10px 18px",
              borderRadius: "8px",
              width: "360px",
              marginTop: "4px",
            }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: primaryColors.base.white,
                  width: "20px",
                  maxWidth: "20px",
                  maxHeight: "20px",
                  height: "20px",
                }}
              />
            ) : (
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-md"],
                  fontWeight: fontWeightVariations.semiBold,
                  color: primaryColors.base.white,
                }}
              >
                Sign in
              </Typography>
            )}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
