/// this code is to connect to the firebase server with proper config

//the then register the website to the firebase server and generate the register token

import firebase from "firebase";

export const initializeFirebase = async () => {
  firebase.initializeApp({
    apiKey: "AIzaSyBcyQ2BUsWKXDTsoYQdel8VcmoQl5pNwLQ",
    authDomain: "melior-aa765.firebaseapp.com",
    databaseURL: "https://melior-aa765.firebaseio.com",
    projectId: "melior-aa765",
    storageBucket: "melior-aa765.appspot.com",
    messagingSenderId: "226083468186",
    appId: "1:226083468186:web:1b1db20dad3c996a438023",
    measurementId: "G-VMNYSMST1D",
  });
};

export const askForPermissionToReceiveNotifications = async () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    try {
      await Notification.requestPermission()
        .then(async (t) => {
          const token = await messaging.getToken();
          console.log(token, "TOKENNN");
          localStorage.setItem("firebase", token);
          localStorage.setItem("firebasePermission", "allow");
          if (localStorage.getItem("meliorUser")) {
            messaging.onMessage(function (payload) {});
          }
        })
        .catch((e) => {
          localStorage.setItem("firebasePermission", "deny");
        });
    } catch (error) {
      console.error(
        "Permission for Sending Notifications - firebase.js",
        error
      );
    }
  } else {
    return Promise.reject(
      "askForPermissionToReceiveNotifications - Firebase messaging not supported"
    );
  }
};

export const requestForToken = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    return messaging
      .getToken(messaging, { vapidKey: publicVapidKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log(
            "No registration token available. Request permission to generate one."
          );
        }
      })
      .catch(function getTokenFailed(err) {
        console.log("An error occurred while retrieving token. ", err);
      });
  } else {
    return Promise.reject("requestForToken - Firebase messaging not supported");
  }
};

export const onMessageListener = () => {
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();

    return new Promise((resolve) => {
      try {
        messaging.onMessage((payload) => {
          resolve(payload);
        });
      } catch (error) {
        console.error("onMessageListener - firebase.js", error);
      }
    });
  } else {
    return Promise.reject(
      "onMessageListener - Firebase messaging not supported"
    );
  }
};

const publicVapidKey =
  "BFXgpHyzFcW-uSUTibhMteaQDJDUS3iZFpcsUfu0rACcNMg570x6HEux6ozn8h70LBQ4miJ-HWVierezMljPgqQ";
