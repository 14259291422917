import {
  Box,
  InputAdornment,
  FormControl,
  MenuItem,
  Select,
  IconButton,
  TextField,
  Skeleton,
} from "@mui/material";
import { Search, ClearOutlined } from "@mui/icons-material";

import { useEffect, useReducer } from "react";
import { useLocation } from "react-router-dom";

export const DynamicSearchInput = ({
  initiateSearchCallback,
  resetSearchCallback,
  currentSearchValue,
  skeleton = false,
  withMRN = false,
  withPhone = false,
}) => {
  // Search Related
  const [searchState, dispatchSearchState] = useReducer(
    searchDataReducer,
    initialSearchState
  );

  const location = useLocation();

  const currentBranchView = location.pathname.split("/")[3];

  const searchValueHandler = (event) => {
    const searchValue = event.target.value;

    dispatchSearchState({
      type: DISPATCH_SEARCH_ACTIONS.updateValue,
      payload: searchValue,
    });

    // Update Search Type if Needed
    const detectedSearchType = detectSearchType(searchValue);
    if (detectedSearchType !== "") {
      dispatchSearchState({
        type: DISPATCH_SEARCH_ACTIONS.updateType,
        payload: detectedSearchType,
      });
      // Reset Current Search Value
      dispatchSearchState({
        type: DISPATCH_SEARCH_ACTIONS.updateValue,
        payload: "",
      });
    }
  };

  const searchTypeHandler = (event) => {
    const searchType = event.target.value;
    dispatchSearchState({
      type: DISPATCH_SEARCH_ACTIONS.updateType,
      payload: searchType,
    });
  };

  const detectSearchType = (searchValue) => {
    let detectedSearchType = "";

    switch (searchValue) {
      case searchTypes.id.shortcut: {
        detectedSearchType = searchTypes.id.key;
        break;
      }
      case searchTypes.phone.shortcut: {
        detectedSearchType = withPhone ? searchTypes.phone.key : "";
        break;
      }
      case searchTypes.name.shortcut: {
        detectedSearchType = searchTypes.name.key;
        break;
      }
      case searchTypes.mrn.shortcut: {
        detectedSearchType = withMRN ? searchTypes.mrn.key : "";
        break;
      }
      default: {
        detectedSearchType = "";
      }
    }

    return detectedSearchType;
  };

  const searchHandler = () => {
    initiateSearchCallback({ ...searchState });
  };

  const resetHandler = () => {
    dispatchSearchState({
      type: DISPATCH_SEARCH_ACTIONS.clearSearchDataToFetch,
    });

    resetSearchCallback();
  };

  useEffect(() => {
    dispatchSearchState({
      type: DISPATCH_SEARCH_ACTIONS.updateSearchIn,
      payload: currentBranchView,
    });

    return () => {
      dispatchSearchState({
        type: DISPATCH_SEARCH_ACTIONS.resetSearchData,
      });
    };
  }, [currentBranchView, dispatchSearchState]);

  useEffect(
    function syncWithOuterSearchValue() {
      if (currentSearchValue === "") {
        dispatchSearchState({
          type: DISPATCH_SEARCH_ACTIONS.clearSearchDataToFetch,
        });
      }
    },
    [currentSearchValue]
  );

  return skeleton ? (
    <Skeleton variant="rectangular" width={300} height={30} />
  ) : (
    <Box display="flex" justifyContent="flex-end" alignItems={"center"}>
      <FormControl sx={{ width: "fit-content" }}>
        <Select
          labelId="search-scope"
          sx={{ height: "35px", fontSize: "0.9rem" }}
          value={searchState.type}
          onChange={searchTypeHandler}
          autoWidth
        >
          <MenuItem value={searchTypes.id.key}>{searchTypes.id.value}</MenuItem>
          {withPhone && (
            <MenuItem value={searchTypes.phone.key}>
              {searchTypes.phone.value}
            </MenuItem>
          )}
          <MenuItem value={searchTypes.name.key}>
            {searchTypes.name.value}
          </MenuItem>
          {withMRN && (
            <MenuItem value={searchTypes.mrn.key}>
              {searchTypes.mrn.value}
            </MenuItem>
          )}
        </Select>
      </FormControl>
      <TextField
        id="outlined-start-adornment"
        sx={{ width: "300px" }}
        value={searchState.value}
        onChange={searchValueHandler}
        onKeyUp={(event) => {
          if (event.key === "Enter") {
            searchHandler();
          }
        }}
        InputProps={{
          style: { height: "35px", fontSize: "0.9rem" },
          startAdornment: (
            <InputAdornment position="start">
              {searchTypes[searchState.type].shortcut}
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              {searchState.value && (
                <IconButton
                  title="Clear and Refetch"
                  onClick={resetHandler}
                  color="default"
                  component="span"
                >
                  <ClearOutlined fontSize="small" />
                </IconButton>
              )}
              <IconButton
                title="Search"
                onClick={searchHandler}
                color="secondary"
                component="span"
              >
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export const searchTypes = {
  id: {
    key: "id",
    value: "ID",
    shortcut: "id: ",
  },
  phone: {
    key: "phone",
    value: "Phone",
    shortcut: "ph: ",
  },
  name: {
    key: "name",
    value: "Name",
    shortcut: "name: ",
  },
  mrn: {
    key: "mrn",
    value: "MRN",
    shortcut: "mrn: ",
  },
};

const initialSearchState = {
  type: searchTypes.id.key,
  value: "",
};

const DISPATCH_SEARCH_ACTIONS = {
  updateType: "UPDATE_SEARCH_TYPE",
  updateValue: "UPDATE_SEARCH_VALUE",
  updateSearchIn: "UPDATE_SEARCH_IN",
  clearSearchDataToFetch: "CLEAR_SEARCH_DATA_TO_FETCH",
  resetSearchData: "RESET_SEARCH_DATA",
};

const searchDataReducer = (state, action) => {
  switch (action.type) {
    case DISPATCH_SEARCH_ACTIONS.updateType: {
      return { ...state, type: action.payload };
    }
    case DISPATCH_SEARCH_ACTIONS.updateValue: {
      return { ...state, value: action.payload };
    }
    case DISPATCH_SEARCH_ACTIONS.clearSearchDataToFetch: {
      return { ...state, value: "" };
    }
    case DISPATCH_SEARCH_ACTIONS.resetSearchData: {
      return initialSearchState;
    }
    default:
      return state;
  }
};
