import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getUserRoles = (data) => {
  const requestURL = `${API_URL}/users-invitations/roles`;
  return melior.get(requestURL);
};

export const useGetUserRoles = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["user-roles"],
    queryFn: () => getUserRoles(),
    keepPreviousData: true,
  });
};
