import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_CLIENT_SECRET,
} from "../../../../helpers/constants";
const qs = require("qs");

export const exchangeGoogleCodeForTokens = ({ authorizationCode }) => {
  const data = {
    code: authorizationCode,
    client_id: GOOGLE_CLIENT_ID,
    client_secret: GOOGLE_CLIENT_SECRET,
    redirect_uri: window.location.origin,
    grant_type: "authorization_code",
  };

  const requestURL = "https://oauth2.googleapis.com/token";
  return axios.post(requestURL, qs.stringify(data), {
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });
};

export const useExchangeGoogleCodeForTokens = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: exchangeGoogleCodeForTokens,
  });
};
