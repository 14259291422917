import { useContext } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../helpers/Colors";

import { NavLink } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import { BranchContext } from "../contexts/BranchContext";

import { ReactComponent as Logo } from "../assets/melior-logo-white.svg";
import BigScreenNav from "./BigScreenNav";
import SmallScreenNavMenu from "./SmallScreenNavMenu";
import { paymentStatusBarHeight } from "../features/payment-funnel";
// import { AuthenticationContext } from "../features/login";

// Menues

const useStyles = makeStyles(() => ({
  meliorLogo: {
    width: 180,
    marginLeft: "2%",
  },
}));

const Header = ({ forSMSUsersOnly, stickToPaymentStatus }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("md"));

  const { setOnBranch, changeHeaderBranch } = useContext(BranchContext);

  return (
    <Container
      maxWidth={false}
      sx={{
        background: COLORS.brightBlack,
        padding: "10px 0px",
        top: stickToPaymentStatus ? paymentStatusBarHeight : "0",
        zIndex: 10,
        maxHeight: "90px",
        minHeight: "70px",
        position: "sticky",
        width: "100%",
        marginLeft: "unset",
        marginRight: "unset",
        // maxWidth: "100%",
      }}
    >
      <Grid
        container
        style={{
          flexWrap: "nowrap",
          maxWidth: "1700px",
          margin: "auto",
          paddingLeft: "15px",
        }}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <NavLink
          to="/"
          end
          onClick={() => {
            setOnBranch(false);
            changeHeaderBranch(0);
          }}
        >
          <Logo className={classes.meliorLogo} />
        </NavLink>
        {matches === true ? (
          // big screen nav links
          <BigScreenNav forSMSUsersOnly={forSMSUsersOnly} />
        ) : (
          //  small screen nav menu
          <SmallScreenNavMenu forSMSUsersOnly={forSMSUsersOnly} />
        )}
      </Grid>
    </Container>
  );
};

export default Header;
