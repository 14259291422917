import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { primaryColors } from "../../../helpers/customColors";

export const RevampedHorizontalBarChart = ({ data }) => {
  const renderCustomizedLabel = ({ x, y, width, height, value }) => {
    let offset = width || 0;
    if (width === 0) return;
    return (
      <text
        x={x + offset - 5}
        y={y + height - 5}
        fontSize="12px"
        fontFamily="Nunito"
        fill={primaryColors.base.white}
        textAnchor="end"
      >
        {`${value} ${value > 1 ? "complains" : "complaint"}`}
      </text>
    );
  };

  const CustomTooltip = ({ payload = [], active }) => {
    if (active) {
      return (
        <div
          style={{
            margin: 0,
            border: "1px solid",
            borderColor: primaryColors.gray[100],
            backgroundColor: primaryColors.base.white,
            paddingBottom: "0%",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            opacity: "0.7",
            width: "auto",
          }}
        >
          <p
            style={{
              margin: "5px",
            }}
          >
            {payload?.length > 0 && payload[0]?.payload.tooltip}
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      style={{
        width: "100%",
        height: `${100 + 25 * data.length}px`,
      }}
    >
      <ResponsiveContainer>
        <ComposedChart
          layout="vertical"
          data={data}
          width={500}
          height="auto"
          margin={{
            top: 20,
            right: 100,
            bottom: 40,
            left: 120,
          }}
        >
          <XAxis type="number" tickLine={false} axisLine={false} tick={false} />
          <RechartsTooltip content={<CustomTooltip />} />
          <YAxis
            dataKey="name"
            type="category"
            tickLine={false}
            axisLine={false}
            width={350} // TODO: increase the width(try to prevent new line)
            tickMargin={20}
            interval={0}
          />
          <Bar dataKey="value" barSize={20} radius={[0, 8, 8, 0]}>
            {data?.map((entry, index) => (
              <Cell
                key={index}
                fill={
                  index < 2 ? primaryColors.error[500] : primaryColors.gray[500]
                }
              />
            ))}
            <LabelList content={renderCustomizedLabel} />
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};
