import { useEffect, useState } from "react";
import { COLORS } from "../../../helpers/Colors";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { isEmpty } from "../../../helpers/General";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import { BasicSelectMenuWithColors } from "../../../reusable/components/form-inputs/uncontrolled/BasicSelectMenuWithColors";
import { Box, CircularProgress, Typography } from "@mui/material";
import moment from "moment";
import { ColorsLegend } from "../../../reusable/components/ColorsLegend";
import { getBranchesPerformanceOvertime } from "../api";

const tickFormatter = (tick) =>
  tick.length > 10 ? `${tick.substring(0, 9)} ${tick.substring(9)}` : tick;

const today = new Date();
const weekAgo = new Date();
const weekAgoDate = today.getDate() - 7;
weekAgo.setDate(weekAgoDate);

const POSSIBLE_DATE_RANGES = {
  oneWeek: "7d",
  lastMonth: "1m",
  sixMonth: "6m",
};

export const BranchesBarChart = () => {
  const [data, setData] = useState(null);
  const [rangeSelected, setSelectedRange] = useState(
    POSSIBLE_DATE_RANGES.lastMonth
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const [finalData, setFinalData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [branchColors, setBranchColors] = useState({});

  const CustomTooltip = ({ payload, active }) => {
    if (active) {
      return (
        <Box
          sx={{
            margin: 0,
            border: "1px solid",
            borderColor: COLORS.silver,
            backgroundColor: COLORS.white,
            paddingBottom: "0%",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            opacity: "0.7",
            width: "auto",
          }}
        >
          <Typography sx={{ margin: "5px", color: COLORS.brightBlack }}>
            Date: {moment(payload[0].payload.name).format("DD-MM-YYYY")}
            {branches.map((branch) => {
              return `\n${branch}: ${
                payload[0].payload[branch]?.toFixed(2) || 0
              }`;
            })}
          </Typography>
        </Box>
      );
    }
    return null;
  };

  useEffect(() => {
    setLoading(true);
    getBranchesPerformanceOvertime({ timePeriod: rangeSelected })
      .then((data) => {
        if (!data || isEmpty(data)) {
          setError({ isError: true, errorMessage: "No data in this duration" });
        } else {
          if (data) {
            setError({
              isError: false,
            });
            setData(data);
            setLoading(false);
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        // Only show the error when the failed payment is not the reason.
        if(error.response.status !== 403){
            setError({
              isError: true,
              errorMessage: "Network Error, Please try again later",
            });
        }
      });
  }, [rangeSelected]);

  useEffect(() => {
    let finalData = [];
    let branchNames = [];
    let branchColors = {};
    if (!data) return;
    data.data.map((dateRange) => {
      const dateRangeName = Object.keys(dateRange)[0];
      let dateRangeEntry = {};
      dateRangeEntry.name = moment(dateRangeName).format("DD-MM-YYYY");
      dateRange[dateRangeName].map((branch) => {
        if (!branchNames.includes(branch.name)) {
          branchNames.push(branch.name);
          branchColors[branch.name] = branch.color;
        }
        return (dateRangeEntry[branch.name] = branch.value);
      });
      return finalData.push(dateRangeEntry);
    });
    setBranches(branchNames);
    setFinalData(finalData);
    setBranchColors(branchColors);
  }, [data]);

  const dateRangeHandler = (event) => {
    setSelectedRange(event.target.value);
  };

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      gap={2}
    >
      <Box display="flex" justifyContent="flex-end">
        <BasicSelectMenuWithColors
          label={"Time Period"}
          options={[
            { label: "Past Week", value: "7d" },
            { label: "Past Month", value: "1m" },
            { label: "Past 6 Months", value: "6m" },
          ]}
          onChangeHandler={dateRangeHandler}
          selectedValue={rangeSelected}
        />
      </Box>
      {error.isError && (
        <UserErrorSuccessFormDisplay
          color={COLORS.white}
          type="error"
          message={error.message}
          width="90%"
          margin="auto"
          padding="15px 15px"
        />
      )}
      {!loading && (
        <>
          <ResponsiveContainer width="100%">
            <ComposedChart
              layout="horizontal"
              data={finalData}
              margin={{
                top: 10,
                right: 40,
                bottom: 0,
                left: 0,
              }}
            >
              <CartesianGrid stroke={COLORS.silver} />
              <YAxis type="number" domain={[0, 5]} padding={{ top: 10 }} />
              <Tooltip content={<CustomTooltip />} />
              <XAxis
                dataKey="name"
                type="category"
                tickFormatter={tickFormatter}
              />
              {branches.map((branch) => (
                <Bar
                  key={`${branch}`}
                  dataKey={`${branch}`}
                  barSize={20}
                  fill={`${branchColors?.[branch]}`}
                  background={{ fill: COLORS.silver }}
                />
              ))}
            </ComposedChart>
          </ResponsiveContainer>
          <ColorsLegend colors={branchColors} />
        </>
      )}
      {loading && (
        <Box
          display="flex"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress style={{ color: COLORS.meliorYellow }} />
        </Box>
      )}
    </Box>
  );
};