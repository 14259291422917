import { Box, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import Review from "./Review";
import Complaint from "./Complaint";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { TemplateSelection } from "../../../reusable/components";
import { useTemplateSelectionState } from "../../../reusable/hooks";

const useStyles = makeStyles((theme) => ({
  feedbackTypeTabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  feedbackTabLabel: {
    // color: primaryColors.gray[500],
    textTransform: "capitalize",
  },
  feedbackTypeTab: {
    transition: "0.5s",
  },
}));

export const feedbackTypes = {
  REVIEW: 0,
  COMPLAINT: 1,
};

export const ReviewComplaint = ({
  complaintOnly = false,
  externalBranchID = undefined,
  externalTimestamp = undefined,
  externalComplaintText = undefined,
  externalUsername = undefined,
  callbackHandlerAfterSubmission = undefined
}) => {
  const classes = useStyles();

  const [feedbackType, setFeedbackType] = useState(feedbackTypes.REVIEW);
  const {
    templateSelectionState,
    selectBranchHandler,
    selectDivisionHandler,
    selectTemplateHandler,
  } = useTemplateSelectionState();

  const updateFeedbackType = (event, newFeedbackType) => {
    setFeedbackType(newFeedbackType);
  };

  const complaintForm = (
    <Complaint
      divisionID={templateSelectionState.divisionData?.id}
      externalDefaultComplaintText={externalComplaintText}
      externalDefaultTimestamp={externalTimestamp}
      externalDefaultUsername={externalUsername}
      callbackHandlerAfterSubmission={callbackHandlerAfterSubmission}
    />
  );
  const reviewForm = (
    <Review
      templateID={templateSelectionState.templateData?.id}
      divisionID={templateSelectionState.divisionData?.id}
    />
  );

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      height="fit-content"
    >
      <TemplateSelection
        templateSelectionState={templateSelectionState}
        selectBranchHandler={selectBranchHandler}
        selectDivisionHandler={selectDivisionHandler}
        selectTemplateHandler={selectTemplateHandler}
        externalDefaultBranchID={externalBranchID}
      />

      {complaintOnly ? (
        <Box
          mt={5}
          p={2}
          borderRadius={5}
          bgcolor={primaryColors.gray[200]}
          width="85%"
        >
          {templateSelectionState.templateData === undefined ? (
            <Typography
              color={primaryColors.error[600]}
              sx={{ textAlign: "center" }}
            >
              Select a Branch, Division, and Template first...
            </Typography>
          ) : (
            complaintForm
          )}
        </Box>
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          mt="2rem"
          width="97%"
          bgcolor={primaryColors.base.white}
          gap="24px"
          padding="10px 24px 24px"
          borderTop="8px solid"
          borderRadius="10px"
          sx={{
            borderImage: "linear-gradient(45deg, #FFB029 0%, #FFE7BD 100%)",
            borderImageSlice: "1",
          }}
        >
          <Tabs
            orientation="horizontal"
            value={feedbackType}
            onChange={updateFeedbackType}
            //   textColor="inherit"
            TabIndicatorProps={{
              style: {
                height: "2px",
                backgroundColor: primaryColors.brand[700],
              },
            }}
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              "& .MuiTab-root": {
                textTransform: "none",
                color: primaryColors.gray[500],
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-md"],
                fontWeight: fontWeightVariations.bold,
              },
              "& .Mui-selected": {
                color: primaryColors.brand[700],
              },
            }}
          >
            <Tab
              label={<span className={classes.feedbackTabLabel}>Review</span>}
              className={classes.feedbackTypeTab}
              disableRipple
            />
            <Tab
              label={
                <span className={classes.feedbackTabLabel}>Complaint</span>
              }
              className={classes.feedbackTypeTab}
              disableRipple
            />
          </Tabs>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="100%"
          >
            {templateSelectionState.templateData === undefined ? (
              <Typography color={primaryColors.error[600]}>
                Select a Branch, Division, and Template first...
              </Typography>
            ) : (
              <>
                {feedbackType === feedbackTypes.REVIEW && reviewForm}
                {feedbackType === feedbackTypes.COMPLAINT && complaintForm}
              </>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};
