import { API_URL, melior } from "../../../helpers/constants";

export const createBulkLinks = ({ divisionId, branchId, templateId, numbersWithVisitDate, type }) => {
  const requestURL = `${API_URL}/feedback-invitation/links`;

  const requestData = { branch_id: branchId, template_id: templateId, data: numbersWithVisitDate, type };
  if (divisionId && divisionId !== 0) {
    requestData.division_id = divisionId;
  }
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, requestData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
