import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const defaultMembersListParams = {
  page: 1,
};

export const getMembers = (params) => {
  //     console.log(params);
  //   const requestPart = params.type === "Invited" ? "users-invitations" : "users";
  const requestURL = `${API_URL}/users`;
  return melior.get(requestURL,{params: {page: params.page}});
};

export const useGetMembers = (
  { params, config } = {
    params: defaultMembersListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["members", params],
    queryFn: () => getMembers(params),
    keepPreviousData: true,
  });
};
