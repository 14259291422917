import { Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { RevampedLandingPageCard } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { ComparisonTable } from "../shared/ComparisonTable";
import { useGetReputationComparisonTable } from "../../api/reputation-dashboard";

export const ReputationComparisonTable = ({ startDate, endDate, branches }) => {
  const {
    data: reputationComparisonTableServerResponse,
    isFetching: isFetchingReputationComparisonTable,
  } = useGetReputationComparisonTable({
    params: { startDate, endDate, branches },
    config: { staleTime: 120000 },
  });

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Performance Ranking
        </Typography>
      }
    >
      <ComparisonTable
        isFetching={isFetchingReputationComparisonTable}
        tableComparisonAPIData={
          reputationComparisonTableServerResponse?.data.branches_comparison_data
        }
        selectedRankingOption="rank_branches"
      />
    </RevampedLandingPageCard>
  );
};
