import { useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthenticationContext } from "../contexts/AuthenticationContext";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../contexts/NotificationContext";
import { NotFoundPage } from "../../../reusable/components/NotFoundPage";
import { confirmTokenForPasswordReset, resetPassword } from "../api";
import { Grid, Button, CircularProgress, Typography } from "@mui/material";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../reusable/hooks";

export const ResetPasswordPage = () => {
  const [loading, setLoading] = useState(false);
  const [verifyingToken, setVerifyingToken] = useState(true);
  const { user } = useContext(AuthenticationContext);
  const { uid, token } = useParams();
  const { fireNotification } = useContext(NotificationContext);

  const navigate = useNavigate();
  useEffect(() => {
    confirmTokenForPasswordReset({ token: token, uid: uid })
      .then(() => {
        setVerifyingToken(false);
      })
      .catch((error) => {
        setTimeout(() => {
          navigate("/login");
        }, 3000);
      });
  }, []);

  const validationSchema = yup.object({
    password: yup
      .string()
      .required("This field is required")
      .min(8, "Must have at least 8 characters")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        " Must have at least 8 characters. (Upper Case, Lower Case, Special Char, and Number"
      ),

    confirmPassword: yup
      .string()
      .required("Please enter the same password as above")
      .oneOf(
        [yup.ref("password"), null],
        "Please enter the same password as above"
      ),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { handleSubmit, reset } = reactHookFormMethods;

  const changePasswordHandler = async (changePasswordData) => {
    setLoading(true);
    resetPassword(
      uid,
      token,
      changePasswordData.password,
      changePasswordData.confirmPassword
    )
      .then((response) => {
        reset();
        setLoading(false);
        fireNotification({
          title: "Changed Successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });

        // After 2 seconds redirect
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
        fireNotification({
          title: "An error occurred",
          description: "please try again later",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      });
  };
  if (user) return <NotFoundPage />;
  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="PUT" onSubmit={handleSubmit(changePasswordHandler)}>
        <Grid
          container
          width="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="88px auto 40px"
          gap="20px"
          margin="auto"
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["display-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.gray[900],
              marginBottom: "12px",
            }}
          >
            Change your password
          </Typography>
          <Grid item width="360px">
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                color: primaryColors.gray[700],
                marginBottom: "6px",
              }}
            >
              Password
            </Typography>
            <FormTextInput
              fullWidth
              name="password"
              placeholder="Create a new password"
              size="small"
              type="password"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                //   border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Grid item width="360px">
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                color: primaryColors.gray[700],
                marginBottom: "6px",
              }}
            >
              Confirmation password
            </Typography>
            <FormTextInput
              fullWidth
              name="confirmPassword"
              placeholder="Retype new password"
              size="small"
              type="password"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                //   border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>

          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              color: primaryColors.warning[500],
              padding: "10px 18px",
              borderRadius: "8px",
              width: "360px",
              marginTop: "4px",
            }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: primaryColors.base.white,
                  width: "20px",
                  maxWidth: "20px",
                  maxHeight: "20px",
                  height: "20px",
                }}
              />
            ) : (
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-md"],
                  fontWeight: fontWeightVariations.semiBold,
                  color: primaryColors.base.white,
                }}
              >
                Submit
              </Typography>
            )}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
