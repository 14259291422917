import { Box, Typography } from "@mui/material";
import { fontWeightVariations } from "../../../helpers/customFont";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import { SouthEast, TrendingFlat, NorthEast } from "@mui/icons-material";
import { insightArrowDirection, insightSemantics } from "./RevampedLandingPage";
import moment from "moment";

export const ChangeInRateBadge = ({
  changeInPercentage,
  changeInPoints,
  arrow,
  semantic,
  selectedStartDate,
  selectedEndDate,
}) => {
  const differenctInDaysBetweenStartAndEndDate = moment(selectedEndDate).diff(
    moment(selectedStartDate),
    "days"
  );

  const correspondingPrevStartDate = moment(selectedStartDate)
    .subtract(differenctInDaysBetweenStartAndEndDate, "days")
    .format("DD MMM, YYYY");
  const correspondingPrevEndDate = moment(selectedEndDate)
    .subtract(differenctInDaysBetweenStartAndEndDate, "days")
    .format("DD MMM, YYYY");

  const correspondingOlderDate = `${correspondingPrevStartDate} To ${correspondingPrevEndDate}`;

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.5}
      py={0.5}
      px={0.7}
      borderRadius={5}
      bgcolor={primaryColors.gray[200]}
      width="fit-content"
    >
      <Box
        display="flex"
        alignItems="center"
        gap={0.5}
        py={0.1}
        px={1.2}
        borderRadius={5}
        bgcolor={primaryColors.base.white}
        width="fit-content"
      >
        {renderIconForSemantic({ arrow, semantic })}
        <Typography
          variant="text-xs"
          fontWeight={fontWeightVariations.semiBold}
          color={getFontColorForSemantic(semantic)}
        >
          {changeInPercentage !== null && changeInPercentage !== undefined
            ? `${changeInPercentage}%`
            : ""}
          {changeInPoints !== null && changeInPoints !== undefined
            ? `${changeInPoints} points`
            : ""}
        </Typography>
      </Box>
      <Typography
        variant="text-xs"
        sx={{
          fontWeight: fontWeightVariations.semiBold,
          color: primaryColors.gray[700],
        }}
      >
        vs {correspondingOlderDate}
      </Typography>
    </Box>
  );
};
function renderIconForSemantic({ arrow, semantic }) {
  switch (arrow) {
    case insightArrowDirection.down: {
      return (
        <SouthEast
          sx={{
            width: "15px",
            color: getFontColorForSemantic(semantic),
          }}
        />
      );
    }
    case insightArrowDirection.flat: {
      return (
        <TrendingFlat
          sx={{
            width: "15px",
            color: getFontColorForSemantic(semantic),
          }}
        />
      );
    }
    case insightArrowDirection.up: {
      return (
        <NorthEast
          sx={{
            width: "15px",
            color: getFontColorForSemantic(semantic),
          }}
        />
      );
    }
    default: {
      return null;
    }
  }
}
function getFontColorForSemantic(semantic) {
  switch (semantic) {
    case insightSemantics.negative: {
      return primaryColors.error[700];
    }
    case insightSemantics.neutral: {
      return secondaryColors.blueSecondary[700];
    }
    case insightSemantics.positive: {
      return primaryColors.success[700];
    }
    default: {
      return "";
    }
  }
}
