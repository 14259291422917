import { melior } from "../../../../helpers/constants";

export const getCurrentUserDetails = () => {
  return new Promise((resolve, reject) => {
    melior
      .get("/user/details")
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
