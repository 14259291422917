import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import { NavLink } from "react-router-dom";
import { COLORS } from "../../helpers/Colors";

const useStyles = makeStyles(theme => ({
  font: {
    fontWeight: "normal",
    textTransform: "none",
    color: COLORS.meliorYellow,
    padding: "0px",
    margin: "0px"
  },
  backToHomeButton: {
    margin: theme.spacing(4),
    marginTop: "30px",
    fontSize: 23,
    fontFamily: "Avenir-Heavy",
    color: COLORS.meliorYellow,
    textTransform: "none",
    borderRadius: "10px",
    borderColor: COLORS.meliorYellow,
    borderWidth: "3px"
  }
}));

export const NotFoundPage = ({onlineSurvey}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Container maxWidth="md" style={{ height: "100vh" }}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <h1 className={classes.font} style={{ fontSize: 160, padding: "25px 0" }}>
            {
              onlineSurvey?
              "Opps ... !"
              : "404"
            }
          </h1>
          <h1
            className={classes.font}
            style={{
              fontSize: 25,
              marginTop: "-25px"
              // color: COLORS.black
            }}
          >
            {
              onlineSurvey?
              "Can't access the online survey?"
              :"Page not found"
            }
          </h1>
          <h1
            className={classes.font}
            style={{ fontSize: 20, marginTop: "30px", color: COLORS.black }}
          >
            {
              onlineSurvey ?
              "It seems Like you are trying to open a survey while you are logged in"
              :"The page you are looking for might have been removed"
            }
          </h1>
          <h1
            className={classes.font}
            style={{ fontSize: 20, color: COLORS.black }}
          >
            {
              onlineSurvey ?
                "Logout or Try from another device" :
                "had its name changed or is temporarily unavailable"
            }
          </h1>
          <NavLink
            to="/"
            end
            className={classes.backToHomeButton}
            style={{ color: COLORS.brightRed }}
          >
            Back to Home
          </NavLink>
        </Grid>
      </Container>
    </Container>
  );
};