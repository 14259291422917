import {
  Box,
  Checkbox,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { Pagination } from "./Pagination";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

const multiselectColumnWidth = 100;
const columnsStandardWidth = 120;
const boxShadowLeftSeparator = "inset -1px 0px 0px #87878745";
const boxShadowRightSeparator = "inset 1px 0px 0px #87878745";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "transparent",
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  minWidth: `${columnsStandardWidth}px`,
//   maxWidth: `${columnsStandardWidth}px`,
//   width: `${columnsStandardWidth}px`,
  paddingLeft: "10px",
  paddingRight: "10px",
  boxSizing: "border-box !important",
  textAlign: "center",
  lineHeight: "17px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  //   "&:nth-of-type(odd)": {
  //     backgroundColor: theme.palette.action.hover,
  //   },
  // hide last border
  //   "&:last-child td, &:last-child th": {
  //     border: 0,
  //   },
}));

const stickyLastColumn = {
  position: "sticky",
  right: -0.5,
  backgroundColor: "white !important",
};

export const MeliorTable = ({
  tableData,
  columnsData,
  changePageHandler,
  currentPageData,
  serverPageSize,
  totalCount,
  selectCurrentPageAllRowsHandler,
  currentPageSelected,
  recordKey,
  allRowsSelected,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{ borderRadius: "15px" }}
      >
        <Table
          aria-label="custom pagination table"
          sx={{ borderCollapse: undefined }}
        >
          <TableHead>
            <TableRow>
              {columnsData.map((column, index) => {
                const stickyColumnStyles = column.sticky && {
                  position: "sticky",
                  left: `${
                    index * columnsStandardWidth -
                    (columnsStandardWidth - multiselectColumnWidth)
                  }px`,
                  backgroundColor: "white !important",
                  boxShadow: column.lastSticky && boxShadowLeftSeparator,
                  minWidth: `${columnsStandardWidth}px !important`,
                  maxWidth: `${columnsStandardWidth}px !important`,
                  width: `${columnsStandardWidth}px !important`,
                };

                const stickyLastColumnStyles = column.id === "info" &&
                  column.lastSticky && {
                    ...stickyLastColumn,
                    boxShadow: boxShadowRightSeparator,
                    textAlign: "center",
                    minWidth: `${columnsStandardWidth}px !important`,
                    maxWidth: `${columnsStandardWidth}px !important`,
                    width: `${columnsStandardWidth}px !important`,
                  };

                if (
                  index === 0 &&
                  column.key === "multiselect" &&
                  selectCurrentPageAllRowsHandler
                ) {
                  return (
                    <StyledTableCell
                      key={index}
                      title={column.title}
                      sx={{
                        position: "sticky",
                        left: 0,
                        minWidth: `${multiselectColumnWidth}px`,
                        maxWidth: `${multiselectColumnWidth}px`,
                        width: `${multiselectColumnWidth}px`,
                        backgroundColor: "white !important",
                        textAlign: 'left'
                      }}
                    >
                      <Checkbox
                        color="secondary"
                        checked={currentPageSelected || allRowsSelected}
                        onChange={selectCurrentPageAllRowsHandler}
                      />
                    </StyledTableCell>
                  );
                } else {
                  return (
                    <StyledTableCell
                      key={index}
                      title={column.title}
                      sx={{
                        ...stickyColumnStyles,
                        ...stickyLastColumnStyles,
                        fontFamily: "Avenir-Heavy",
                      }}
                    >
                      {column.label}
                    </StyledTableCell>
                  );
                }
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((rowData, index) => {
              return (
                <StyledTableRow
                  key={rowData.id || index}
                  sx={{ ...rowData.rowConditionalStyles }}
                >
                  {columnsData.map((column, index) => {
                    const branchColorStyles = column.key === "multiselect" && {
                      /**
                       * Add the branch color as a boxShadow to avoid handling the collapsing border
                       * that happens with sticky items
                       */
                      boxShadow: `inset 5px 0px 0 ${rowData[recordKey].branch.color}`,
                    };
                    const stickyColumnStyles = column.sticky && {
                      position: "sticky",
                      left: `${
                        index * columnsStandardWidth -
                        (index !== 0 &&
                          columnsStandardWidth - multiselectColumnWidth)
                      }px`,
                      backgroundColor: "white !important",
                      boxShadow: column.lastSticky && boxShadowLeftSeparator,
                    };                    
                    
                    const stickyLastColumnStyles = column.id === "info" &&
                      column.lastSticky && {
                        ...stickyLastColumn,
                        boxShadow: boxShadowRightSeparator,
                        minWidth: `${columnsStandardWidth}px !important`,
                        maxWidth: `${columnsStandardWidth}px !important`,
                        width: `${columnsStandardWidth}px !important`,
                      };

                    const firstColumnCellStyles = index === 0 && {
                      minWidth: `${multiselectColumnWidth}px`,
                      maxWidth: `${multiselectColumnWidth}px`,
                      width: `${multiselectColumnWidth}px`,
                    };

                    if (column.renderCustomComponent) {
                      const customCellComponent = (
                        <StyledTableCell
                          key={index}
                          sx={{
                            paddingTop: "5px",
                            paddingBottom: "5px",
                            ...stickyColumnStyles,
                            ...branchColorStyles,
                            ...stickyLastColumnStyles,
                            ...firstColumnCellStyles,
                          }}
                        >
                          {column.renderCustomComponent(rowData)}
                        </StyledTableCell>
                      );

                      return column.key === "multiselect" ? (
                        <Tooltip
                          key={index}
                          title={rowData.branchName}
                          placement="left-start"
                        >
                          {customCellComponent}
                        </Tooltip>
                      ) : (
                        customCellComponent
                      );
                    } else {
                      return (
                        <StyledTableCell
                          key={index}
                          sx={{ ...stickyColumnStyles }}
                        >
                          {rowData[recordKey][column.key]}
                        </StyledTableCell>
                      );
                    }
                  })}
                </StyledTableRow>
                // </Tooltip>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box pt={2} pb={3} w="100%">
        <Pagination
          currentPageData={currentPageData}
          changePageHandler={changePageHandler}
          serverPageSize={serverPageSize}
          totalCount={totalCount}
        />
      </Box>
    </Box>
  );
};

MeliorTable.propTypes = {
  tableData: PropTypes.array.isRequired,
  columnsData: PropTypes.array.isRequired,
  changePageHandler: PropTypes.func.isRequired,
  currentPageData: PropTypes.object.isRequired,
  serverPageSize: PropTypes.number.isRequired,
  selectCurrentPageAllRowsHandler: PropTypes.func,
  currentPageSelected: PropTypes.bool,
  recordKey: PropTypes.string,
};
