import axios from "axios";
import { URL } from "../../../helpers/constants";

export const confirmTokenForPasswordReset = (tokenData) => {
  let requestURL = `${URL}/password/reset/confirm/token/verify/`;

  return new Promise((resolve, reject) => {
    axios
      .post(requestURL, {
        ...tokenData,
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
