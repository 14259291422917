import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { FormProvider, useFieldArray } from "react-hook-form";
import { FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { useDeleteDivision } from "../../api/branches-divisions/deleteDivision";
import AddIcon from "@mui/icons-material/Add";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { useState } from "react";

export const BranchForm = ({
  control,
  reactHookFormMethods,
  maxDivisionsLimit,
  editMode = false,
}) => {
  const [actionActiveOnDivIndex, setActionActiveOnDivIndex] =
    useState(undefined);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "divisions",
  });

  const deleteDivisionMutation = useDeleteDivision();

  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="POST">
        <Box display="flex" flexDirection="column" gap={2}>
          <Box display="flex" flexDirection="column">
            <Typography
              sx={{
                color: primaryColors.gray[700],
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                marginBottom: "6px",
              }}
            >
              Branch
            </Typography>
            <FormTextInput
              name="name"
              placeholder="Branch Name"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            p={1}
            borderRadius={2}
            bgcolor={primaryColors.gray[50]}
          >
            <Typography
              sx={{
                color: primaryColors.gray[700],
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                marginBottom: "6px",
              }}
            >
              Divisions
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              gap={1}
              overflow="auto"
              height="300px"
            >
              {fields.map((divisionFormField, divisionFieldIndex) => (
                <Box
                  key={divisionFormField.id}
                  display="flex"
                  alignItems="center"
                >
                  {/* <input {...register(`divisions.${divisionFieldIndex}.name`)} /> */}
                  <FormTextInput
                    name={`divisions.${divisionFieldIndex}.name`}
                    placeholder="Division Name"
                    type="text"
                    sx={{
                      color: primaryColors.gray[500],
                      backgroundColor: primaryColors.base.white,
                      border: `1px solid ${primaryColors.gray[300]}`,
                      borderRadius: "8px",
                    }}
                  />
                  <IsLoadingContainer
                    isLoading={
                      deleteDivisionMutation.isLoading &&
                      actionActiveOnDivIndex === divisionFieldIndex
                    }
                  >
                    <IconButton
                      sx={{ padding: "10px", borderRadius: "16px" }}
                      aria-label="edit"
                      onClick={() => {
                        if (editMode && divisionFormField.idDB !== undefined) {
                          setActionActiveOnDivIndex(divisionFieldIndex);
                          deleteDivisionMutation
                            .mutateAsync({ id: divisionFormField.idDB })
                            .then(function removeDivisionFromFormState() {
                              remove(divisionFieldIndex);
                              setActionActiveOnDivIndex(undefined);
                            });
                        } else {
                          remove(divisionFieldIndex);
                        }
                      }}
                    >
                      <DeleteIcon
                        sx={{
                          color: primaryColors.base.black,
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    </IconButton>
                  </IsLoadingContainer>
                </Box>
              ))}
              <Button
                type="button"
                variant="text"
                onClick={() => {
                  if (maxDivisionsLimit > fields.length) {
                    append({ name: "" });
                  }
                }}
                sx={{
                  width: "fit-content",
                  opacity:
                    maxDivisionsLimit === fields.length ? 0.5 : undefined,
                  cursor:
                    maxDivisionsLimit === fields.length
                      ? "not-allowed"
                      : undefined,
                }}
                title={
                  maxDivisionsLimit === fields.length
                    ? "You've reached the maximum Divisions Limit"
                    : "Add new Division"
                }
              >
                <AddIcon
                  sx={{ color: primaryColors.brand[500] }}
                  fontSize="small"
                />
                <Typography
                  sx={{
                    textTransform: "none",
                    fontSize: fontSizeVariations["text-sm"],
                    color: primaryColors.brand[500],
                  }}
                >
                  Add Division
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
