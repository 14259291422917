import { API_URL, melior } from "../../../helpers/constants";

export const getAllBranches = ({ withPagination = false }) => {
    const requestURL = `${API_URL}/branches`;
    const queryParams = {
      params: {
        no_pagination: !withPagination,
      },
    };

  return new Promise((resolve, reject) => {
    melior
      .get(requestURL, queryParams)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
