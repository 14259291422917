import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllQuestions = () => {
  const requestURL = `${API_URL}/questions`;

  return melior.get(requestURL);
};

export const useGetAllQuestions = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-questions"],
    queryFn: () => getAllQuestions(),
    keepPreviousData: true,
  });
};
