import { makeStyles, withStyles } from "@mui/styles";
import { COLORS } from "../../../../helpers/Colors";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";

import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

const GreenCheckbox = withStyles({
  root: {
    color: COLORS.RHAGreen,
    "&$checked": {
      color: COLORS.RHAGreen
    }
  },
  checked: {}
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(theme => ({
  title: {
    fontFamily: "Avenir-Heavy",
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
    width: "10%",
    marginLeft: "-30px"
  },
  resizeFont: {
    fontSize: 18
  },
  timeTextField: {
    background: COLORS.yellowBGTextField,
    width: "38%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "10px",
    height: "45px",
    "&:disabled": {
      backgroundColor: COLORS.silver,
      color: COLORS.silver
    }
  },
  dropdownIcon: {
    color: "red"
  },
  formButton: {
    fontSize: 20,
    marginLeft: "2%",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    marginTop: "1%",
    textTransform: "none",
    borderRadius: "10px",
    "&:disabled": {
      backgroundColor: COLORS.meliorYellowDisabled,
      color: COLORS.silver
    }
  },
  deleteDevisionButton: {
    fontSize: 20,
    marginLeft: "2%",
    fontWeight: "normal",
    color: "red",
    marginTop: "1%",
    textTransform: "none"
  }
}));

const ShiftRow = props => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{ marginTop: "20px" }}
      justifyContent="space-between"
      direction="row"
      alignItems="flex-start"
    >
      <FormControlLabel
        style={{ marginRight: "0px" }}
        control={
          <GreenCheckbox
            style={{ marginTop: "6px" }}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={props.details.checked}
            onChange={e =>
              props.setDetails({ ...props.details, checked: e.target.checked })
            }
          />
        }
      />
      <h1 className={classes.title}> {props.time} </h1>
      <TextField
        variant="outlined"
        type="time"
        className={classes.timeTextField}
        InputLabelProps={{ classes: { input: classes.resizeFont } }}
        inputProps={{ step: 300 }}
        value={props.details.from}
        onChange={e => {
          props.setDetails({ ...props.details, from: e.target.value });
        }}
        disabled={!props.details.checked}
      />

      <TextField
        variant="outlined"
        type="time"
        className={classes.timeTextField}
        InputLabelProps={{
          classes: { input: classes.resizeFont }
        }}
        inputProps={{
          step: 300 // 5 min
        }}
        value={props.details.to}
        onChange={e => {
          props.setDetails({ ...props.details, to: e.target.value });
        }}
        disabled={!props.details.checked}
      />
    </Grid>
  );
};

export default ShiftRow;
