import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const addReputationResponseNote = ({ reputationResponseID, newNoteText }) => {
  let requestURL = `${API_URL}/reputation/${reputationResponseID}/note`;

  return melior.post(requestURL, {
    text: newNoteText,
  });
};

export const useAddReputationResponseNote = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reputation-response-activity-logs"], { type: "active" });
    },
    ...config,
    mutationFn: addReputationResponseNote,
  });
};
