import { useCallback, useEffect, useState } from "react";

import { isEmpty } from "../../../../helpers/General";
import { ExternalProgressPage } from "./ExternalProgressPage";
import { getReviewsOverviewData } from "../../api/reviews-overview/getReviewsOverviewData";

export const OverallProgress = ({ skeleton = false, filters }) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [error, setError] = useState({ isError: false, errorMessage: "" });

  const getOverallProgressDataHandler = useCallback(
    (progressPageQueryStrings) => {
      setLoading(true);
      setError({
        isError: false,
      });

      getReviewsOverviewData(progressPageQueryStrings)
        .then((response) => {
          if (!response.data || isEmpty(response.data)) {
            setError({
              isError: true,
              errorMessage: "No data in this duration",
            });
          } else {
            if (response.data) {
              setError({
                isError: false,
              });
              setData(response.data);
              setLoading(false);
            }
          }
        })
        .catch((error) => {
          let errorMsg = "";

          // Show error msg only when the status code doesn't imply payment failure
          if (error.response.status !== 403) {
            errorMsg = "Network Error, Please try again later";
          }

          setError({
            isError: true,
            errorMessage: errorMsg,
          });
        });
    },
    []
  );

  useEffect(
    () => {
      getOverallProgressDataHandler({ ...filters });
    },
    [filters, getOverallProgressDataHandler],
    200
  );

  return <ExternalProgressPage data={data} error={error} loading={loading} />;
};
