import { melior } from "../../../helpers/constants";

export const assignTemplate = ({
  templateId,
  branches,
  divisions,
  allDivisionsInBranch,
}) => {
  let requestURL = `/templates/${templateId}/apply`;
  const postData = {};

  if (allDivisionsInBranch) {
    postData.branches = branches;
  } else {
    postData.divisions = divisions;
  }

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, postData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
