import { createContext, useState, useEffect, useContext } from "react";
import { AuthenticationContext } from "../features/login/contexts/AuthenticationContext";

export const SideNavContext = createContext();

const SideNavContextProvider = (props) => {
  // let pathname = props.location.pathname;
  let pathname = window.location.pathname;
  let user = useContext(AuthenticationContext);
  const [sideNav, setSideNav] = useState(
    pathname.includes("reviews")
      ? "Reviews"
      : pathname.includes("complaints")
      ? "Complaints"
      : "Progress"
  );
  const [archivedReviewsCount, setArchivedReviewsCount] = useState(0);

  useEffect(() => {
    // mirror pathname to sidenav
    let pathname = window.location.pathname;
    pathname.includes("/reviews")
      ? setSideNav("Reviews")
      : pathname.includes("complaints")
      ? setSideNav("Complaints")
      : setSideNav("Progress");
  });

  // useEffect(() => {
  //   setSideNav("Progress");
  // }, [user]);

  return (
    <SideNavContext.Provider
      value={{
        sideNav,
        setSideNav,
        archivedReviewsCount,
        setArchivedReviewsCount,
      }}
    >
      {props.children}
    </SideNavContext.Provider>
  );
};

export { SideNavContextProvider };
