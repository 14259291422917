import { Box } from "@mui/material";
import { BranchesAndDivisionsTable } from "../components/branches-divisions/BranchesAndDivisionsTable";

export const BranchesAndDivisionsSubScreen = () => {

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <BranchesAndDivisionsTable />
    </Box>
  );
};

export const PAYMENT_METHODS = {
  offline: "Offline",
  online: "Online",
};
