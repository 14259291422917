import { API_URL, melior } from "../../../../helpers/constants";

export const changeFacilityLogo = (logo) => {
  let requestURL = `${API_URL}/facility/info/change`;
  var bodyFormData = new FormData();

  bodyFormData.append("facility_logo", logo);

  return new Promise((resolve, reject) => {
    melior
      .patch(requestURL, bodyFormData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
