import { Box, Typography } from "@mui/material";
import { gradientColorsData } from "../../../../helpers/customColors";
import { ChangeInRateBadge } from "../../../revamped-landing-page/components/ChangeInRateBadge";
import { fontWeightVariations } from "../../../../helpers/customFont";

export const RawNumberCard = ({
  width,
  variant,
  cardData,
  selectedStartDate,
  selectedEndDate,
  hideChangeInRateBadge = false,
  reverseIconAndMainText,
  children,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      sx={{
        background: getCardGradientColor(variant),
      }}
      py={2.5}
      px={0.5}
      borderRadius={3}
      width={width || "24.5%"}
      height="186px"
      boxSizing="border-box"
      boxShadow={1}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="60%"
        px={2}
      >
        <Typography
          variant="text-md"
          fontWeight={fontWeightVariations.bold}
          sx={{ color: getCardTitleColor(variant) }}
        >
          {cardData.title}
        </Typography>
        {/* Card Content */}
        <Box display="flex" flexDirection="row" alignItems="flex-end">
          <Box
            display="flex"
            flexDirection={reverseIconAndMainText ? "row-reverse" : "row"}
            alignItems="center"
            gap={1}
          >
            {cardData.icon && cardData.icon}
            {cardData.mainText !== undefined && (
              <Typography
                variant="display-md"
                fontWeight={fontWeightVariations.bold}
              >
                {cardData.mainText}
              </Typography>
            )}
          </Box>
          {cardData.secondaryText && (
            <Typography
              variant="text-xl"
              fontWeight={fontWeightVariations.medium}
            >
              &nbsp;{cardData.secondaryText}
            </Typography>
          )}
        </Box>
        {children}
      </Box>

      {/* Card Footer */}
      {!hideChangeInRateBadge && (
        <ChangeInRateBadge
          changeInPercentage={cardData?.percentage}
          changeInPoints={cardData?.points}
          semantic={cardData?.semantic}
          arrow={cardData?.arrow}
          selectedStartDate={selectedStartDate}
          selectedEndDate={selectedEndDate}
        />
      )}
    </Box>
  );
};

function getCardTitleColor(variant) {
  switch (variant) {
    case CARD_VARIANTS.green: {
      return gradientColorsData.green.text;
    }
    case CARD_VARIANTS.tripgreen: {
      return gradientColorsData.tripgreen.text;
    }
    case CARD_VARIANTS.red: {
      return gradientColorsData.red.text;
    }
    case CARD_VARIANTS.blue: {
      return gradientColorsData.blue.text;
    }
    case CARD_VARIANTS.orange: {
      return gradientColorsData.orange.text;
    }
    default: {
      return "";
    }
  }
}

function getCardGradientColor(variant) {
  switch (variant) {
    case CARD_VARIANTS.green: {
      return gradientColorsData.green.bg;
    }
    case CARD_VARIANTS.tripgreen: {
      return gradientColorsData.tripgreen.bg;
    }
    case CARD_VARIANTS.red: {
      return gradientColorsData.red.bg;
    }
    case CARD_VARIANTS.blue: {
      return gradientColorsData.blue.bg;
    }
    case CARD_VARIANTS.orange: {
      return gradientColorsData.orange.bg;
    }
    default: {
      return "";
    }
  }
}

export const CARD_VARIANTS = {
  green: "green",
  tripgreen: "tripgreen",
  red: "red",
  blue: "blue",
  orange: "orange",
};
