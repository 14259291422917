import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const editMemberResponsibilities = ({ memberID, body }) => {
  const requestURL = `${API_URL}/users/${memberID}`;
  return melior.patch(requestURL,  body );
};

export const useEditMemberResponsibilities = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["members"], { type: "active" });
    },
    ...config,
    mutationFn: editMemberResponsibilities,
  });
};
