import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Skeleton,
  Typography,
} from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { Send } from "@mui/icons-material";
import { useGetReviewDetails } from "../../api/reviews/getReviewDetails";
import { useGetReviewNotes } from "../../api/reviews/getReviewNotes";
import { useState } from "react";
import { useAddReviewNote } from "../../api/reviews/addReviewNote";
import moment from "moment";
import { ActivityLogItem } from "../shared/ActivityLogItem";
import {
  UsersToMentionListMenu,
  getMentionUsernameTag,
} from "../shared/UsersToMentionList";
import { AddNewNote } from "../shared/AddNewNote";
import { useGetListOfFacilityUsersToMention } from "../../api/getListOfFacilityUsersToMention";
import { useTriggerEmailForUserMentionInReview } from "../../api/triggerEmailUserMentionInReview";
import { renderAudio } from "../../../../helpers/General";

export const ReviewTableRowDetails = ({ reviewDetails, isExpanded }) => {
  return (
    <Grid
      container
      columns={12}
      bgcolor={primaryColors.gray[50]}
      width="100%"
      height="350px"
    >
      <Grid item xs={4} py={2} height="100%">
        <PatientComment
          commentData={{
            patientName: reviewDetails?.contacts?.name,
            reviewDate: reviewDetails.submitted_at,
            commentText: reviewDetails.comment_text,
            commentAudio: reviewDetails.comment_audio,
          }}
        />
      </Grid>
      <Grid item xs={4} py={2} height="100%">
        <QuestionsAndAnswers
          reviewID={reviewDetails.id}
          isExpanded={isExpanded}
        />
      </Grid>
      <Grid item xs={4} py={2} height="100%">
        <StaffNotes reviewID={reviewDetails.id} isExpanded={isExpanded} />
      </Grid>
    </Grid>
  );
};

const PatientComment = ({ commentData }) => {
  const commentText = commentData.commentText !== undefined &&
    commentData.commentText !== "" && (
      <Typography
        color={primaryColors.base.black}
        variant="text-md"
        sx={{ fontWeight: fontWeightVariations.medium }}
      >
        {commentData.commentText !== undefined && commentData.commentText !== ""
          ? commentData.commentText
          : "......"}
      </Typography>
    );

  const commentAudio =
    commentData.commentAudio && renderAudio(commentData.commentAudio);

  return (
    <TableRowSection label="Comment">
      <Box display="flex" flexDirection="column" px={2} gap={0.5}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography sx={{ color: primaryColors.gray[700] }} variant="text-sm">
            {commentData.patientName}
          </Typography>
          <Typography sx={{ color: primaryColors.gray[500] }} variant="text-sm">
            {moment(commentData.reviewDate).format("h:mm A, DD/MM/YYYY")}
          </Typography>
        </Box>
        <Box
          px="14px"
          py="10px"
          borderRadius="8px"
          bgcolor={primaryColors.gray[200]}
          width="auto"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {commentText}
          {commentAudio}
        </Box>
      </Box>
    </TableRowSection>
  );
};
const QuestionsAndAnswers = ({ reviewID, isExpanded }) => {
  const {
    data: reviewDetailsResponse,
    isFetching: isReviewDetailsResponseFetching,
  } = useGetReviewDetails({
    params: { reviewID },
    config: {
      enabled: isExpanded,
    },
  });

  const renderQuestionsSkeleton = () => (
    <Box>
      <Skeleton height={40} />
      <Skeleton height={40} />
      <Skeleton height={60} />
    </Box>
  );

  const renderMultipleChoiceQuestions = (questionsAndAnswers) =>
    questionsAndAnswers?.map(function renderQuestionData(questionData, index) {
      return (
        <Box key={index}>
          <Typography variant="text-sm">{questionData.question}</Typography>
          <Typography
            variant="text-sm"
            fontWeight={fontWeightVariations.semiBold}
            sx={{ paddingLeft: 0.5 }}
          >
            {questionData.choice}
          </Typography>
        </Box>
      );
    });

  const renderInputQuestions = (questionsAndAnswers) =>
    questionsAndAnswers?.map(function renderQuestionData(questionData, index) {
      return (
        <Box key={index}>
          <Typography variant="text-sm">{questionData.question}</Typography>
          <Typography
            variant="text-sm"
            fontWeight={fontWeightVariations.semiBold}
            sx={{ paddingLeft: 0.5 }}
          >
            {questionData.input}
          </Typography>
        </Box>
      );
    });

  const renderVoiceQuestions = (questionsAndAnswers) =>
    questionsAndAnswers?.map(function renderQuestionData(questionData, index) {
      return (
        <Box key={index}>
          <Typography variant="text-sm">{questionData.question}</Typography>
          {questionData.voice ? (
            renderAudio(questionData.voice)
          ) : (
            <Typography
              variant="text-sm"
              fontWeight={fontWeightVariations.semiBold}
              sx={{ paddingLeft: 0.5 }}
            >
              {questionData.text_version}
            </Typography>
          )}
        </Box>
      );
    });

  const renderAllQuestionsAndAnswers = () => {
    return (
      <>
        {reviewDetailsResponse?.data["answers"] &&
          renderMultipleChoiceQuestions(reviewDetailsResponse?.data["answers"])}
        {reviewDetailsResponse?.data["input_answers"] &&
          renderInputQuestions(reviewDetailsResponse?.data["input_answers"])}
        {reviewDetailsResponse?.data["voice_answers"] &&
          renderVoiceQuestions(reviewDetailsResponse?.data["voice_answers"])}
      </>
    );
  };

  return (
    <TableRowSection label="Questions" withSideBorders>
      <Box display="flex" flexDirection="column" gap={1}>
        {isReviewDetailsResponseFetching
          ? renderQuestionsSkeleton()
          : renderAllQuestionsAndAnswers()}
      </Box>
    </TableRowSection>
  );
};

const StaffNotes = ({ reviewID, isExpanded }) => {
  const { data: reviewNotesResponse } = useGetReviewNotes({
    params: { reviewID },
    config: {
      enabled: isExpanded === true,
    },
  });

  const { data: facilityUsersToMentionResponse } =
    useGetListOfFacilityUsersToMention();

  const addNewNoteMutation = useAddReviewNote();
  const triggerEmailForUserMentionInReviewMutation =
    useTriggerEmailForUserMentionInReview();

  const addNewNoteInstance = !facilityUsersToMentionResponse ? null : (
    <AddNewNote
      mutationCallback={function addReviewNoteHandler({
        text,
        callbackOnSuccess,
        mentionedUserID,
      }) {
        addNewNoteMutation
          .mutateAsync({ reviewID, newNoteText: text })
          .then(function () {
            callbackOnSuccess();
          })
          .then(function triggerEmailForComplaintMentionHandler() {
            triggerEmailForUserMentionInReviewMutation.mutateAsync({
              reviewID,
              mentionText: text,
              mentionedUserID,
            });
          });
      }}
      facilityUsersToMention={facilityUsersToMentionResponse.data}
      isSubmitting={addNewNoteMutation.isLoading}
    />
  );

  const noNotesExist = (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography color={primaryColors.error[700]}>
        No notes added yet...
      </Typography>
    </Box>
  );

  const notesList = !facilityUsersToMentionResponse ? null : (
    <Box display="flex" flexDirection="column">
      {reviewNotesResponse?.data.map(function renderNoteItem(noteData, index) {
        // Start: Show highlighted mention tag if exists
        const noteText = noteData.text || noteData.comment;
        let noteContentWithMention = null;

        const mentionText = getMentionUsernameTag(noteText);

        if (mentionText !== "") {
          const plainTextItems = noteText.split(mentionText);

          noteContentWithMention = (
            <Typography variant="text-sm" color={primaryColors.gray[500]}>
              "{plainTextItems[0]}
              <span
                style={{ fontWeight: "bold", color: primaryColors.brand[500] }}
              >
                {mentionText}
              </span>
              {plainTextItems[1]}"
            </Typography>
          );
        }
        // End: Show highlighted mention tag if exists
        return (
          <ActivityLogItem
            key={index}
            itemIndex={index}
            withBorderBottom={reviewNotesResponse?.data.length - 1 !== index}
            itemData={{
              title: `Note added by ${noteData.user}`,
              user: noteData.user,
              subtitle: noteData.email,
              date: noteData.performed_at,
              description: mentionText ? noteContentWithMention : noteText,
            }}
          />
        );
      })}
    </Box>
  );

  return (
    <TableRowSection label="Notes" bottomNode={addNewNoteInstance}>
      {reviewNotesResponse !== undefined &&
      reviewNotesResponse.data !== undefined &&
      reviewNotesResponse.data.length === 0
        ? noNotesExist
        : notesList}
    </TableRowSection>
  );
};

const TableRowSection = ({ label, bottomNode, withSideBorders, children }) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderLeft={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      borderRight={
        withSideBorders ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
      px={2}
      boxSizing="border-box"
      height="inherit"
    >
      <Typography variant="text-sm" fontWeight="bold" sx={{ pb: 2 }}>
        {label}
      </Typography>
      <Box py={2} height="inherit" sx={{ overflowY: "auto" }} mb={1}>
        {children}
      </Box>
      {bottomNode}
    </Box>
  );
};
