import { API_URL, melior } from "../../../helpers/constants";

export const getDivisionTemplatesForBulkSMS = async (divisionID) => {
  const requestURL = `${API_URL}/divisions/${divisionID}/templates`;

  return await melior
    .get(requestURL)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
