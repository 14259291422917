import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const BRANCHES_PAGE_COUNT = 8;

export const defaultBranchesListParams = {
  page: 1,
};

const getAllBranchesPaginated = ({ page }) => {
  const queryData = {
    params: {
      page,
    },
  };
  const requestURL = `${API_URL}/branches`;

  return melior.get(requestURL, queryData);
};

export const useGetAllBranchesPaginated = (
  { params, config } = {
    params: defaultBranchesListParams,
  }
) => {
  return useQuery({
    ...config,
    queryKey: ["all-branches-paginated", params],
    queryFn: () => getAllBranchesPaginated(params),
    keepPreviousData: true,
  });
};
