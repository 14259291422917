import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getDivisionTemplates = ({ divisionID }) => {
  const requestURL = `${API_URL}/divisions/${divisionID}/templates`;

  return melior.get(requestURL);
};

export const useGetDivisionTemplates = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["division-templates", params],
    queryFn: () => getDivisionTemplates(params),
    keepPreviousData: true,
  });
};
