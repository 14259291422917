import * as yup from "yup";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";

import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { Button, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import AddIcon from "@mui/icons-material/Add";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useCreateBranch } from "../../api/branches-divisions/createBranch";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { BranchForm } from "./BranchForm";

export const CreateBranchDialog = ({
  reachedMaxBranchesLimit,
  maxDivisionsLimit,
}) => {
  const { user } = useContext(AuthenticationContext);

  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    // divisions: yup.array().of(yup.object().shape({ name: yup.string() })),
  });

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      divisions: [],
    },
  });
  const { reset, handleSubmit, formState, control, watch } =
    reactHookFormMethods;

  const createBranchMutation = useCreateBranch();

  const branchName = watch("name");

  return (
    <GenericDialog
      dialogTitle="Add new Branch"
      dialogSubtitle={
        "Make it easier by adding the division as well. That way, everything will be in one place and more convenient for you."
      }
      maxWidth="xs"
      disableDialogTriggerAction={reachedMaxBranchesLimit}
      triggerButton={
        <Button
          title={
            reachedMaxBranchesLimit
              ? "You've reached the maximum Branches limit"
              : "Create a new Branch"
          }
          sx={{
            borderRadius: "8px",
            padding: "10px 16px",
            backgroundColor: primaryColors.brand[500],
            "&:hover": {
              backgroundColor: primaryColors.brand[500],
            },
          }}
        >
          <AddIcon sx={{ color: primaryColors.base.white }} fontSize="small" />
          <Typography
            sx={{
              textTransform: "none",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.base.white,
            }}
          >
            Add new Branch
          </Typography>
        </Button>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      disableSubmitCondition={branchName === ""}
      submitButtonText={"Add"}
      onSubmitCallback={handleSubmit((newBranchData) => {
        createBranchMutation.mutateAsync({
          ...newBranchData,
          profile: user.profile.id,
        });
        console.log(newBranchData, "newBranchData");
        // invitationHandler(invitationData);
      })}
      onCloseCallback={reset}
      closeButtonText={"Cancel"}
      isSubmitting={createBranchMutation.isLoading}
      isDone={createBranchMutation.isSuccess}
    >
      <BranchForm
        control={control}
        reactHookFormMethods={reactHookFormMethods}
        maxDivisionsLimit={maxDivisionsLimit}
      />
    </GenericDialog>
  );
};
