import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LoginPage } from "../components/LoginPage";

export const LoginPageScreen = () => {
  return (
    <ScreenContainer>
      <LoginPage />
    </ScreenContainer>
  );
};
