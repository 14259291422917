import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ResetPasswordPage } from "../components/ResetPasswordPage";

export const ResetPasswordPageScreen = () => {
  return (
    <ScreenContainer>
      <ResetPasswordPage />
    </ScreenContainer>
  );
};
