import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";

const useStyles = makeStyles(() => ({
  legendRow: {
    flexBasis: "fit-content",
  },
  legendSquare: {
    borderRadius: "50%",
  },
}));

export const ColorSwap = (props) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      key={props.color}
      className={classes.legendRow}
    >
      <div
        style={{
          background: props.color,
          width: props.size ?? "30px",
          height: props.size ?? "30px",
          margin: props.margin ?? "auto",
        }}
        className={classes.legendSquare}
      />
    </Grid>
  );
};
