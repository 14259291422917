import { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import clsx from "clsx";

import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { COLORS } from "../../../helpers/Colors";

import { BRANCH_MANAGER, FACILITY_MANAGER } from "../../../helpers/constants";
import {
  isAppOnly,
  isDivisionManager,
  isFeedbackOnly,
} from "../../../helpers/user";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import LoadingPage from "../../../layout/LoadingPage";
import TableHeader from "../../../reusable/components/shared-settings-components/TableHeader";
import NewInviteRow from "../../../reusable/components/shared-settings-components/NewInviteRow";
import Row from "../../../reusable/components/shared-settings-components/Row";
import TablePagination from "../../../reusable/components/shared-settings-components/TablePagination";
import {
  deleteUser,
  editUserResponsibilities,
  getUsers,
  inviteUser,
} from "../api";

const useStyles = makeStyles({
  TableContainer: {
    maxWidth: "90vw",
    overflow: "auto",
    border: `1px solid ${COLORS.meliorYellowGentle}`,
    maxHeight: "600px",
  },
  table: {
    minWidth: "650px",
    overflow: "auto",
  },

  tableMargin: {
    marginBottom: "80px",
  },

  loadingMargin: {
    width: "100%",
    margin: "50px 0px",
  },
});

const NoUsersRow = () => (
  <TableRow>
    <TableCell>
      <span> No Pending Users </span>
    </TableCell>
  </TableRow>
);

const resetInvite = ({ func, active }) => {
  func({
    email: "",
    role: BRANCH_MANAGER,
    responsibility: [],
    active: active,
  });
};

const UserSettings = ({ disableEdits }) => {
  const classes = useStyles();

  const { user, isDemo } = useContext(AuthenticationContext);

  const [loadingInvited, setLoadingInvited] = useState(true);
  const [loadingNormal, setLoadingNormal] = useState(true);

  const [error, setError] = useState(false);

  const [users, setUsers] = useState(null);
  const [invitedUsers, setInvitedUsers] = useState(null);

  const [newInvite, setNewInvite] = useState({
    active: false,
    email: "",
    role: BRANCH_MANAGER,
    responsibility: [],
  });

  useEffect(() => {
    if (!user) return;
    getUsersOfType("Normal");
    if (isDemo) {
      setInvitedUsers({ users_max_count: 1, results: [] });
      setLoadingInvited(false);
    } else {
      getUsersOfType("Invited");
    }
  }, [user, isDemo]);

  const getUsersOfType = (type) => {
    const loadingFunc =
      type === "Normal" ? setLoadingNormal : setLoadingInvited;
    const usersFunc = type === "Invited" ? setInvitedUsers : setUsers;
    loadingFunc(true);
    getUsers(type)
      .then((response) => {
        usersFunc(response);
        setError({ isError: false });
        loadingFunc(false);
      })
      .catch(() => {
        setError({
          isError: true,
          errorMessage: "Network Error, Please try again later",
        });
        loadingFunc(false);
      });
  };

  const inviteUserHandler = () => {
    let errorMessage = "";
    if (users.results.length >= users["users_max_count"]) {
      errorMessage = "You reached max users count, Please upgrade to proceed";
    }
    if (
      newInvite.email.trim() === "" ||
      !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        newInvite.email
      )
    )
      errorMessage = "You have to type in a correct email to add a user";
    if (
      newInvite.responsibility.length === 0 &&
      newInvite.role !== FACILITY_MANAGER
    )
      errorMessage =
        "You have to assign at least one branch or one division to any new user";
    if (errorMessage.length > 0) {
      setError({ isError: true, errorMessage: errorMessage });
      return;
    }

    setLoadingInvited(true);
    let payload = {
      email: newInvite.email,
      role: newInvite.role,
    };
    if (newInvite.role === BRANCH_MANAGER)
      payload.branches = newInvite.responsibility;
    if (
      isDivisionManager(newInvite) ||
      isAppOnly(newInvite) ||
      isFeedbackOnly(newInvite)
    )
      payload.divisions = newInvite.responsibility;

    inviteUser(payload)
      .then(() => {
        getUsersOfType("Invited");
        resetInvite({ func: setNewInvite, active: false });
        setError({ isError: false });
      })
      .catch((error) => {
        let errorMessage = "";
        if (error && error.response && error.response.data)
          Object.keys(error.response.data).forEach(
            (currKey) => (errorMessage += `, ${error.response.data[currKey]}`)
          );
        else errorMessage = "Network Error, Please try again later";
        setError({
          isError: true,
          errorMessage: errorMessage.substring(1),
        });
        setLoadingInvited(false);
      });
  };

  const deleteUserHandler = ({ id, type, currUser }) => {
    if (type === "User" && users.results.length === 1) {
      setError({
        isError: true,
        errorMessage:
          "You can't delete the last user in your branch, Create a a new one then delete this one",
      });
      return;
    }
    if (type === "User" && id === currUser.id) {
      setError({
        isError: true,
        errorMessage: "You can't Delete yourself",
      });
      return;
    }
    const loadingFunc =
      type === "Normal" ? setLoadingNormal : setLoadingInvited;

    loadingFunc(true);

    deleteUser({ id: id, type: type })
      .then(() => {
        getUsersOfType(type);
        setError({ isError: false });
      })
      .catch(() => {
        setError({
          isError: true,
          errorMessage: "Network error, please try again later",
        });
        loadingFunc(false);
      });
  };

  const assignUserNewResponsibility = (
    isBranchManager,
    id,
    type,
    responsibility
  ) => {
    let body = {};
    if (
      isBranchManager
        ? (body.branches = responsibility)
        : (body.divisions = responsibility)
    )
      editUserResponsibilities({
        id: id,
        type: type,
        body: body,
      })
        .then(() => {
          getUsersOfType(type);
        })
        .catch(() => {
          setError({
            isError: true,
            errorMessage: "Network Error, Please try again later",
          });
        });
  };

  return (
    <Grid container justifyContent="center">
      {!error.isError ? null : (
        <UserErrorSuccessFormDisplay
          color={COLORS.white}
          type="error"
          message={error.errorMessage}
          width="90%"
          margin="10px auto"
          padding="15px 15px"
          bg={COLORS.failRed}
        />
      )}
      {loadingInvited || !invitedUsers ? (
        <div className={classes.loadingMargin}>
          <LoadingPage height="20vh" />
        </div>
      ) : (
        // INVITED USERS
        <TableContainer
          className={clsx(classes.TableContainer, classes.tableMargin)}
        >
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHeader
              isMax={
                users?.count + invitedUsers?.count >=
                invitedUsers["users_max_count"]
              }
              type="Invited"
              activateInvite={() =>
                resetInvite({ func: setNewInvite, active: true })
              }
              disableEdits={disableEdits}
              isDemo={isDemo}
            />
            <TableBody>
              {invitedUsers.results.length === 0 && !newInvite.active ? (
                <NoUsersRow />
              ) : null}
              {!newInvite.active ? null : (
                <NewInviteRow
                  setNewInvite={setNewInvite}
                  newInvite={newInvite}
                  resetInvite={resetInvite}
                  inviteUser={inviteUserHandler}
                  user={user}
                />
              )}
              {invitedUsers.results.map((row, index) => (
                <Row
                  key={row.id}
                  index={index}
                  row={row}
                  type="Invited"
                  user={user}
                  deleteUser={deleteUserHandler}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            users={invitedUsers}
            setUsers={setInvitedUsers}
            type="Invited"
            setLoading={setLoadingInvited}
          />
        </TableContainer>
      )}

      {/* USERS */}
      {loadingNormal || !users ? (
        <div className={classes.loadingMargin}>
          <LoadingPage height="20vh" />
        </div>
      ) : (
        <TableContainer
          className={clsx(classes.TableContainer, classes.tableMargin)}
        >
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHeader type="Normal" />
            <TableBody>
              {users.results.map((row, index) => (
                <Row
                  key={row.id}
                  index={index}
                  row={row}
                  user={user}
                  type="Normal"
                  deleteUser={deleteUserHandler}
                  refreshUsers={getUsersOfType}
                  assignUserNewResponsibility={assignUserNewResponsibility}
                  isDemo={isDemo}
                  disableEdits={disableEdits}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            setUsers={setUsers}
            users={users}
            type="Normal"
            setLoading={setLoadingNormal}
          />
        </TableContainer>
      )}
    </Grid>
  );
};

export default UserSettings;
