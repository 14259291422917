import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { primaryColors } from "../../../../helpers/customColors";
import { useToggleBookmarkStatus } from "../../api/reviews/toggleBookmarkStatus";

export const StarReview = ({ reviewRecord }) => {
  const toggleBookmarkStatusMutation = useToggleBookmarkStatus();

  return (
    <Tooltip
      title={reviewRecord.bookmarked === true ? "Un-star" : "Star"}
      placement="top"
    >
      {toggleBookmarkStatusMutation.isLoading ? (
        <CircularProgress size="30px" />
      ) : (
        <IconButton
          sx={{ height: "50px", width: "40px" }}
          aria-label="star"
          onClick={(e) => {
            toggleBookmarkStatusMutation.mutateAsync({
              reviewID: reviewRecord.id,
              currentBookmarkStatus: reviewRecord.bookmarked,
            });
            e.stopPropagation();
          }}
        >
          {reviewRecord.bookmarked === true ? (
            <Star
              sx={{
                color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          ) : (
            <StarBorder
              sx={{
                color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
