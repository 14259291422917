import axios from "axios";
import { URL } from "../../../helpers/constants";

export const resetPassword = (uid, token, passwordInput1, passwordInput2) => {
  let requestURL = `${URL}/password/reset/confirm/`;

  return new Promise((resolve, reject) => {
    axios
      .post(requestURL, {
        uid: uid,
        token: token,
        new_password1: passwordInput1,
        new_password2: passwordInput2,
      })
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
