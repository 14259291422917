import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getAllBranches = () => {
  const requestURL = `${API_URL}/branches`;
  return melior.get(requestURL);
};

export const useGetAllBranches = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-branches"],
    queryFn: () => getAllBranches(),
    keepPreviousData: true,
  });
};
