import { COLORS } from "../helpers/Colors";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "./Header";

const LoadingPage = (props) => {
  return (
    <Container maxWidth={false}>
      {props.displayHeader ? <Header /> : null}
      <Container
        maxWidth="sm"
        style={props.height ? { height: props.height } : { height: "100vh" }}
      >
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <CircularProgress style={{ color: COLORS.meliorYellow }} />
        </Grid>
      </Container>
    </Container>
  );
};

export default LoadingPage;
