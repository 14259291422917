import { useContext, useState } from "react";
import { CircularProgress, Grid, Button, Box, Typography } from "@mui/material";
import { sendEmailForResetPassword } from "../api";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../contexts/NotificationContext";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../reusable/hooks";

export const ForgotPasswordPage = () => {
  const [loading, setLoading] = useState(false);

  const { fireNotification } = useContext(NotificationContext);

  const validationSchema = yup.object({
    email: yup
      .string()
      .required("This field is required")
      .matches(
        /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
        "This is not a valid email address"
      ),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { handleSubmit } = reactHookFormMethods;

  const forgotPasswordHandler = (forgotPasswordData) => {
    setLoading(true);
    sendEmailForResetPassword(forgotPasswordData.email)
      .then((response) => {
        setLoading(false);
        fireNotification({
          title: "Rest password link sent successfully",
          description:
            "please, click on the link and change your password as soon as possibile. If you don’t receive any email, please contact us at info@mymelior.com",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch((error) => {
        setLoading(false);
        error.response.status === 400
          ? fireNotification({
              title: "Error",
              description: Object.values(error.response.data).join(""),
              type: NOTIFICATIONS_TYPE.error,
              verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
              target: NOTIFICATIONS_TARGET.everyone,
            })
          : fireNotification({
              title: "Reset failed",
              description: "please try again later",
              type: NOTIFICATIONS_TYPE.error,
              verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
              target: NOTIFICATIONS_TARGET.everyone,
            });
      });
  };

  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="GET" onSubmit={handleSubmit(forgotPasswordHandler)}>
        <Grid
          container
          width="fit-content"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          padding="88px auto 40px"
          gap="20px"
          margin="auto"
        >
          <Typography
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["display-sm"],
              fontWeight: fontWeightVariations.semiBold,
              color: primaryColors.gray[900],
              marginBottom: "12px",
            }}
          >
            Forgot password?
          </Typography>
          <Grid item width="360px">
            <Typography
              sx={{
                fontFamily: "Nunito",
                fontSize: fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.bold,
                color: primaryColors.gray[700],
                marginBottom: "6px",
              }}
            >
              Email
            </Typography>
            <FormTextInput
              fullWidth
              name="email"
              placeholder="Your email"
              size="small"
              type="text"
              sx={{
                color: primaryColors.gray[500],
                backgroundColor: primaryColors.base.white,
                //   border: `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
              }}
            />
          </Grid>
          <Button
            variant="contained"
            type="submit"
            disabled={loading}
            sx={{
              color: primaryColors.warning[500],
              padding: "10px 18px",
              borderRadius: "8px",
              width: "360px",
              marginTop: "4px",
            }}
          >
            {loading ? (
              <CircularProgress
                sx={{
                  color: primaryColors.base.white,
                  width: "20px",
                  maxWidth: "20px",
                  maxHeight: "20px",
                  height: "20px",
                }}
              />
            ) : (
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-md"],
                  fontWeight: fontWeightVariations.semiBold,
                  color: primaryColors.base.white,
                }}
              >
                Reset Password
              </Typography>
            )}
          </Button>
        </Grid>
      </form>
    </FormProvider>
  );
};
