import { Box, Button, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { FileOpenOutlined } from "@mui/icons-material";
import { useGetComplaintAttachment } from "../../api/complaints/getComplaintAttachment";
import { LoadingButton } from "@mui/lab";
import { useEffect, useState } from "react";

export const ComplaintActivityLogItem = ({
  itemIndex,
  withBorderBottom,
  itemData,
  complaintID,
  activityLogItemData,
}) => {
  const { nameShortcut, email, title, description, date } =
    useGetActivityLogInfoToRenderBasedOnType({
      activityLogItemData,
      complaintID,
    });

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb={2.5}
      mb={2.5}
      borderBottom={
        withBorderBottom ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
    >
      {/* Comments */}
      <Box display="flex" gap={1}>
        {/* Ball */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="32px"
          height="32px"
          borderRadius="50%"
          bgcolor={primaryColors.brand[200]}
        >
          <Typography variant="text-sm" color={primaryColors.gray[600]}>
            {nameShortcut}
          </Typography>
        </Box>
        {/* All other Note Data */}
        <Box display="flex" flexDirection="column" width="100%">
          {/* Name, Date, and Email */}
          <Box display="flex" justifyContent="space-between">
            {/* Name and Email vertically */}
            <Box display="flex" flexDirection="column">
              <Typography variant="text-sm" color={primaryColors.base.black}>
                {title}
              </Typography>
              <Typography variant="text-sm" color={primaryColors.gray[500]}>
                {email}
              </Typography>
            </Box>

            {/* Date */}
            <Typography variant="text-xs" color={primaryColors.gray[500]}>
              {date}
            </Typography>
          </Box>
          <Box pt={2}>
            {description &&
              (typeof description !== "string" ? (
                description
              ) : (
                <Typography
                  variant="text-sm"
                  color={primaryColors.gray[500]}
                >{`"${description}"`}</Typography>
              ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const ACTIVITY_LOG_TYPES = {
  Resolved: "resolve",
  Unresolved: "unresolved",
  Archived: "archive",
  Unarchived: "unarchive",
  AssignedToUser: "assign",
  AssignedUserRemoved: "unassign",
  ClassifiedWithLabel: "classify",
  LabelClassificationRemoved: "declassify",
  AssignedToTeam: "add_related_team",
  AssignedTeamRemoved: "remove_related_teams",
  MRNUpdated: "update_mrn" /*  */,
  NoteAdded: "note",
  MentionAdded: "mention",
  FileAttached: "attach_file",
  SatisfactionSurveySubmittedFromClient: "satisfaction_survey_answer",
};

function useGetActivityLogInfoToRenderBasedOnType({
  activityLogItemData,
  complaintID,
}) {
  const [attachmentLinkClicked, setAttachmentLinkClicked] = useState(false);

  const { isFetching: isFetchingAttachment, data: attachmentServerResponse } =
    useGetComplaintAttachment({
      config: {
        enabled: attachmentLinkClicked,
      },
      params: {
        complaintID,
        fileID: activityLogItemData.attachment_id,
      },
    });

  const firstAndSecondName = activityLogItemData.user?.split(" ");
  // Handle the case the there's no last name in the username
  const firstNameFirstLetter =
    firstAndSecondName?.length > 0 ? firstAndSecondName[0][0] : "";
  const secondNameFirstLetter =
    firstAndSecondName?.length > 1 ? firstAndSecondName[1][0] : "";

  const nameShortcut = `${firstNameFirstLetter}${secondNameFirstLetter}`;

  useEffect(
    function openAttachmentLinkIfClickedInNewTabWhenReady() {
      if (
        attachmentServerResponse &&
        attachmentServerResponse.data &&
        attachmentLinkClicked
      ) {
        window.open(attachmentServerResponse.data, "_blank");
        setAttachmentLinkClicked(false);
      }
    },
    [attachmentServerResponse, attachmentLinkClicked]
  );

  switch (activityLogItemData.action) {
    case ACTIVITY_LOG_TYPES.Resolved: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Resolved by ${activityLogItemData.user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.Unresolved: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Unresolved by ${activityLogItemData.user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.Archived: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Archived by ${activityLogItemData.user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.Unarchived: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Unarchived by ${activityLogItemData.user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.AssignedToUser: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Assignee added by ${activityLogItemData.user}`,
        description: `Assignee: ${activityLogItemData.assigned_user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.AssignedUserRemoved: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Assignee removed by ${activityLogItemData.user}`,
        description: `Assignee: ${activityLogItemData.unassigned_user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.ClassifiedWithLabel: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Label Classification added by ${activityLogItemData.user}`,
        description: `Label: ${activityLogItemData.label}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.LabelClassificationRemoved: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Label Classification removed by ${activityLogItemData.user}`,
        description: `Label: ${activityLogItemData.label}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.AssignedToTeam: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Team assigned by ${activityLogItemData.user}`,
        description: `Team: ${activityLogItemData.team}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.AssignedTeamRemoved: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Team unassigned by ${activityLogItemData.user}`,
        description: `Team: ${activityLogItemData.team}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.MRNUpdated: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `MRN value updated by ${activityLogItemData.user}`,
        description: `MRN Value: ${activityLogItemData.mrn}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.MentionAdded: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Mention added by ${activityLogItemData.user}`,
        description: `Mentioned User: ${activityLogItemData.mentioned_user}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.NoteAdded: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email,
        title: `Note added by ${activityLogItemData.user}`,
        description: `${activityLogItemData.text}`,
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.FileAttached: {
      return {
        nameShortcut: nameShortcut,
        email: activityLogItemData.email ?? undefined,
        title: `File attached by ${activityLogItemData.user}`,
        description: (
          <LoadingButton
            title={activityLogItemData.attachment_name}
            loading={isFetchingAttachment}
            variant="text"
            sx={{
              textTransform: "none",
              color: primaryColors.base.black,
              textDecoration: "underline",
              width: "fit-content",
              justifyContent: "left",
            }}
            disableTouchRipple
            disableFocusRipple
            startIcon={<FileOpenOutlined />}
            onClick={function () {
              setAttachmentLinkClicked(true);
            }}
          >
            {activityLogItemData.attachment_name.length > 10
              ? "..." + activityLogItemData.attachment_name.slice(-30)
              : activityLogItemData.attachment_name}
          </LoadingButton>
        ),
        date: activityLogItemData.performed_at,
      };
    }
    case ACTIVITY_LOG_TYPES.SatisfactionSurveySubmittedFromClient: {
      return {
        nameShortcut: activityLogItemData.user ? nameShortcut : "--",
        title: `Client submitted satisfaction survey: `,
        description: `${activityLogItemData.answer}`,
        date: activityLogItemData.performed_at,
      };
    }
    default: {
      return {
        nameShortcut: "--",
        email: "email@domain.com",
        title: "new-action",
        description: activityLogItemData.action,
        date: "-",
      };
    }
  }
}
