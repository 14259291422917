import { Box, IconButton, Typography } from "@mui/material";

import { NOTIFICATIONS_TYPE } from "../../contexts/NotificationContext";
import { primaryColors } from "../../helpers/customColors";
import { Close, Warning, Error, CheckCircle, Info } from "@mui/icons-material";

export const NotificationComponentUI = ({
  title,
  description,
  type,
  extraDetailsToRender,
  closeNotification,
}) => {
  let notificationIcon = () => {
    switch (type) {
      case NOTIFICATIONS_TYPE.info:
        return (
          <Warning
            sx={{ color: primaryColors.warning[600] }}
            fontSize="small"
          />
        );

      case NOTIFICATIONS_TYPE.error:
        return (
          <Error sx={{ color: primaryColors.error[600] }} fontSize="small" />
        );

      case NOTIFICATIONS_TYPE.success:
        return (
          <CheckCircle
            sx={{ color: primaryColors.success[600] }}
            fontSize="small"
          />
        );
      case NOTIFICATIONS_TYPE.message:
        return (
          <Info sx={{ color: primaryColors.gray[600] }} fontSize="small" />
        );
      default:
        break;
    }
  };
  let notificationTitleColor = () => {
    switch (type) {
      case NOTIFICATIONS_TYPE.info:
        return primaryColors.warning[700];

      case NOTIFICATIONS_TYPE.error:
        return primaryColors.error[700];

      case NOTIFICATIONS_TYPE.success:
        return primaryColors.success[700];
      case NOTIFICATIONS_TYPE.message:
        return primaryColors.gray[700];
      default:
        break;
    }
  };
  let notificationDescriptionColor = () => {
    switch (type) {
      case NOTIFICATIONS_TYPE.info:
        return primaryColors.warning[600];

      case NOTIFICATIONS_TYPE.error:
        return primaryColors.error[600];

      case NOTIFICATIONS_TYPE.success:
        return primaryColors.success[600];
      case NOTIFICATIONS_TYPE.message:
        return primaryColors.gray[600];
      default:
        break;
    }
  };

  return (
    <Box
      display="flex"
      gap={1.5}
      justifyContent="start"
      width="100%"
      padding={1}
    //   bgcolor={primaryColors.base.white}
    >
      {notificationIcon()}
      <Box
        display="flex"
        justifyContent="space-between"
        width="100%"
        alignItems="start"
      >
        <Box display="flex" flexDirection="column" gap={1}>
          <Box display="flex" flexDirection="column">
            <Typography
              sx={{
                color: notificationTitleColor(),
              }}
              fontSize="14px"
            >
              {title}
            </Typography>
            <Typography
              sx={{
                whiteSpace: "pre-line",
                color: notificationDescriptionColor(),
              }}
              fontSize="14px"
            >
              {description}
            </Typography>
          </Box>
          {extraDetailsToRender}
        </Box>
        {closeNotification && (
          <IconButton aria-label="close" onClick={closeNotification}>
            <Close
              sx={{
                color: notificationDescriptionColor(),
              }}
              fontSize="small"
            />
          </IconButton>
        )}
      </Box>
    </Box>
  );
};
