import { useContext, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Tooltip } from "@mui/material";
import {
  valueChangeNotAllowedDemo,
  valueChangeNotAllowedGeneral,
} from "../../../helpers/constants";
import { Launch } from "@mui/icons-material";
import { Link } from "react-router-dom";
import PasswordModal from "./PasswordModal";
import { alterCurrentUserDetails, getCurrentUserDetails } from "../api";

const useStyles = makeStyles(() => ({
  formHeader: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
  },
  header: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "18px",
  },
  section: {
    margin: "20px",
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "300px",
    maxWidth: "100%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "0px",
  },
  resizeFont: {
    fontSize: "14px",
  },
  dropdownIcon: {
    color: COLORS.black,
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    height: "40px",
    // color: COLORS.white,
  },
  menu: {
    backgroundColor: COLORS.yellowBGTextField,
    border: "2px solid",
    borderColor: COLORS.meliorYellow,
    width: "300px",
    maxWidth: "100%",
    borderRadius: "10px",
    color: COLORS.black,
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
  },
  editButton: {
    fontSize: "16px",
    marginLeft: "10px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    marginTop: "10px",
    textTransform: "none",
    borderRadius: "10px",
    "&:disabled": {
      backgroundColor: COLORS.meliorYellowDisabled,
      color: "bcbcbc",
    },
  },
  doneIconButton: {
    marginTop: "5px",
    marginLeft: "10px",
    backgroundColor: COLORS.meliorYellow,
    color: "white",
  },
  uploadLogo: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    position: "relative",
  },
  changePassword: {
    fontSize: "16px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    position: "relative",
  },
  newTabLinkButton: {
    display: "flex",
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    padding: "0",
    paddingRight: "11px",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  newTabLink: {
    padding: "6px 16px",
    paddingRight: "6px",
    color: COLORS.black,
  },
}));

const SettingsLeft = ({ disableEdits }) => {
  const classes = useStyles();

  const [userDetails, setUserDetails] = useState(null);
  const [newFirstName, setNewFirstName] = useState("");
  const [newLastName, setNewLastName] = useState("");
  const [passwordModal, setPasswordModal] = useState({
    open: false,
  });

  const { isDemo, isFacilityManager } = useContext(AuthenticationContext);

  const passwordModalProps = {
    passwordModal: passwordModal,
    setPasswordModal: setPasswordModal,
  };

  const clearAllInputs = () => {
    setNewFirstName("");
    setNewLastName("");
  };

  const changeNames = async () => {
    let input = {};

    if (newFirstName !== userDetails.first_name) {
      input.first_name = newFirstName;
    }

    if (newLastName !== userDetails.last_name) {
      input.last_name = newLastName;
    }

    if (Object.keys(input).length) {
      alterCurrentUserDetails(input)
        .then((response) => {
          setUserDetails(response);
          clearAllInputs();
        })
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      getCurrentUserDetails()
        .then((response) => {
          if (mounted) {
            setUserDetails(response);
            setNewFirstName(response.first_name);
            setNewLastName(response.last_name);
          }
        })
        .catch((e) => console.log(e));
    }

    return function cleanup() {
      mounted = false;
    };
  }, []);

  return (
    <div>
      <h1 className={classes.formHeader}>User Settings</h1>

      <div className={classes.section}>
        <div>
          <Button
            variant="outlined"
            component="span"
            className={classes.changePassword}
            disabled={disableEdits}
            onClick={() => {
              setPasswordModal({ open: true });
            }}
          >
            Change Password
          </Button>
        </div>
        {/* first name field */}
        <h1 className={classes.header}>First Name</h1>
        <Tooltip
          title={
            isDemo
              ? valueChangeNotAllowedDemo
              : disableEdits
              ? valueChangeNotAllowedGeneral
              : "Type in the name you want"
          }
        >
          <TextField
            id="outlined-firstName-input"
            className={classes.textField}
            type="name"
            margin="normal"
            variant="outlined"
            placeholder={userDetails?.first_name ?? ""}
            value={newFirstName}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setNewFirstName(e.target.value);
            }}
          />
        </Tooltip>

        {/* Last name Field */}
        <h1 className={classes.header}>Last Name</h1>
        <Tooltip
          title={
            isDemo
              ? valueChangeNotAllowedDemo
              : disableEdits
              ? valueChangeNotAllowedGeneral
              : "Type in the name you want"
          }
        >
          <TextField
            id="outlined-firstName-input"
            className={classes.textField}
            type="name"
            margin="normal"
            variant="outlined"
            placeholder={userDetails?.last_name ?? ""}
            value={newLastName}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setNewLastName(e.target.value);
            }}
          />
        </Tooltip>

        <div>
          <Button
            variant="contained"
            component="span"
            className={classes.uploadLogo}
            disabled={
              disableEdits ||
              (newFirstName === userDetails?.first_name &&
                newLastName === userDetails?.last_name) ||
              newLastName === "Unspecified" ||
              newFirstName === "Unspecified"
            }
            onClick={() => {
              changeNames();
            }}
          >
            Confirm Changes
          </Button>
          {!isFacilityManager && (
            <Button
              className={classes.newTabLinkButton}
              variant="contained"
              endIcon={<Launch />}
            >
              <Link className={classes.newTabLink} to="/admin/issues">
                See facility issues
              </Link>
            </Button>
          )}
        </div>
      </div>

      <PasswordModal {...passwordModalProps}></PasswordModal>
    </div>
  );
};

export default SettingsLeft;
