import { Grid, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useState, useContext } from "react";
import { useChangeUserPassword } from "../../api";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";
import { FormContainer } from "./FormContainer";
import { PasswordConfirmationDialog } from "./PasswordConfirmationDialog";

export const ChangePasswordSection = () => {
  const { fireNotification } = useContext(NotificationContext);

  const [confirmationModal, setConfirmationModal] = useState({
    openDialog: false,
    title: "",
    description: "",
    callbackFunction: () => {},
  });
  const {
    mutateAsync: changePasswordMutation,
    isLoading: isChangePasswordLoading,
  } = useChangeUserPassword();

  const validationSchema = yup.object({
    password: yup
      .string()
      .required()
      .min(8, "Must have at least 8 characters")
      .matches(
        /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*\W)/,
        "Must have at least 8 characters. (Upper Case, Lower Case, Special Char, and Number)"
      ),
    confirm_password: yup
      .string()
      .required("Please make sure your passwords match")
      .oneOf(
        [yup.ref("password"), null],
        "Please make sure your passwords match"
      ),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { reset, handleSubmit, getValues, formState } = reactHookFormMethods;

  const changePasswordHandler = (currentPassword) => {
    setConfirmationModal({
      openDialog: false,
    });
    changePasswordMutation({
      new_password1: getValues("password"),
      new_password2: getValues("confirm_password"),
      old_password: currentPassword,
    })
      .then(() => {
        reset();
        fireNotification({
          title: "Changed successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch(() => {
        fireNotification({
          title: "The password you entered is incorrect, please try again",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      });
  };

  return (
    <FormProvider {...reactHookFormMethods}>
      <form
        method="POST"
        onSubmit={handleSubmit((passwordFormData) => {
          setConfirmationModal({
            openDialog: false,
          });
          setConfirmationModal({
            openDialog: true,
            title: "Confirmation",
            description: "Please enter current password to change it",
            callbackFunction: changePasswordHandler,
          });
        })}
      >
        <FormContainer
          formTitle={"Password"}
          formState={formState}
          submitting={isChangePasswordLoading}
          resetCallback={() => reset()}
        >
          <Grid
            container
            justifyContent="flex-start"
            direction="row"
            width={{ sm: "100%", lg: "660px", md: "660px", xl: "660px" }}
            mr={"50%"}
            spacing={2}
          >
            {/* form fields */}
            {ChangePasswordField(
              formState,
              "New Password",
              "password",
              "  Must have at least 8 characters. (Upper Case, Lower Case, Special Char, and Number)"
            )}

            {ChangePasswordField(
              formState,
              "Confirm New Password",
              "confirm_password",
              "Please make sure your passwords match"
            )}
          </Grid>
        </FormContainer>
      </form>
      {/* confirm changes by entering current password */}
      <PasswordConfirmationDialog {...confirmationModal}></PasswordConfirmationDialog>
    </FormProvider>
  );
};

const ChangePasswordField = (formState, Label, name, helperText) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.gray[700],
          marginBottom: "6px",
        }}
      >
        {Label}
      </Typography>
      <FormTextInput
        name={name}
        placeholder="********"
        type="password"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
      {/*helper text */}
      {!formState.dirtyFields[name] && (
        <Typography
          sx={{
            color: primaryColors.gray[500],
            fontWeight: fontWeightVariations.medium,
            fontSize: fontSizeVariations["text-sm"],
            marginTop: "6px",
          }}
        >
          {helperText}
        </Typography>
      )}
    </Grid>
  );
};
