import React, { useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";
import { debounce } from "lodash";
import { API_URL, melior } from "../../../helpers/constants";
import "./styles.css";
import { primaryColors, secondaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import {
  Box,
  Divider,
  IconButton,
  InputBase,
  Paper,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import { CheckBoxOutlined } from "@mui/icons-material";
import { IsLoadingContainer } from "../IsLoadingContainer";

export const SearchableMultiSelect = ({
  fetcherAPI,
  label = "Search",
  searchQueryParam,
  onSelectHandler,
  onRemoveHandler,
  placeholder,
  selectedOptionsProp,
  isLoadingSelectedOptionsProp = false,
  staticOptions,
  endIconProp,
  onCloseMenuHandler,
  temporarilyCancelSelection,
  resetOptions,
  filterOptionsHandler,
  isLoading = false,
  emptySearchValueHandler,
}) => {
    console.log(staticOptions, 'staticOptions')
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOptionsState, setSelectedOptionsState] = useState([]);

  const fetchOptions = async (searchQuery) => {
    setLoading(true);
    try {
      // Replace the URL with your actual API endpoint
      const response = await melior.get(
        `${API_URL}/${fetcherAPI}?${searchQueryParam}=${searchQuery}`
      );
      const newOptions = response.data.map(function getOptionOutOfResults(
        rawResult
      ) {
        return {
          label: rawResult.text,
          value: rawResult.id,
        };
      });
      setOptions([...newOptions]);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const debouncedFetch = debounce(fetchOptions, 300);

  const handleInputChange = (event) => {
    !staticOptions && debouncedFetch(event.target.value);

    if (event.target.value === "" || event.target.value === undefined) {
      emptySearchValueHandler();
    }
  };

//   const selectedOptionsValues = selectedOptionsState.map(
//     function getValueOutOfSelectedOption(selectedOption) {
//       return selectedOption.value;
//     }
//   );

//   const optionsBeforeFiltering =
//     staticOptions !== undefined ? staticOptions : options;

//   const filteredOptions = optionsBeforeFiltering.filter(
//     function filterOutOptionsAlreadySelected(optionToSelect) {
//       return !selectedOptionsValues.includes(optionToSelect.value);
//     }
//   );

  //   let filteredOptionsWithReset = resetOptions ? [...filteredOptions, {label: ""}]

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      sx={{
        opacity: isLoading ? 0.5 : 1,
        pointerEvents: isLoading ? "none" : "all",
        cursor: isLoading ? "progress" : "default",
        position: "relative",
      }}
    >
      {isLoading && (
        <CircularProgress
          size="30px"
          sx={{
            position: "absolute",
            top: "50%",
            right: "50%",
            zIndex: 1000,
          }}
        />
      )}
      <Autocomplete
        multiple
        open={open}
        onOpen={() => setOpen(true)}
        onClose={function closeMenuWithAdditionalActions() {
          setOpen(false);
          onCloseMenuHandler();
        }}
        options={staticOptions || []}
        filterOptions={filterOptionsHandler}
        loading={loading}
        loadingText="Loading..."
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        value={selectedOptionsState}
        onSearch
        onChange={(event, newSelectedOptions) => {
          const newlyAddedOption = newSelectedOptions.find(
            (value) => !selectedOptionsState.includes(value)
          );

          if (onSelectHandler && newlyAddedOption) {
            onSelectHandler({
              selectedOption: newlyAddedOption,
              closeMenu: () => setOpen(false),
            });
          }

          //   if (onRemoveHandler && selectedOptions.length > newSelectedOptions.length) {
          //     const removedOption = selectedOptions.find(
          //       (value) => !newSelectedOptions.includes(value)
          //     );
          //     onRemoveHandler(removedOption.value);
          //   }
          if (!temporarilyCancelSelection) {
            setSelectedOptionsState(
              newSelectedOptions.map(function getValueAndLabelOnly(
                optionFullData
              ) {
                return {
                  label: optionFullData.label,
                  value: optionFullData.value,
                };
              })
            );
          }
        }}
        renderInput={(params) => (
          <Box display="flex" flexDirection="column">
            <Typography
              variant={fontSizeVariations["text-lg"]}
              fontWeight={fontWeightVariations.bold}
            >
              {label}
            </Typography>
            <Paper
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                {...params}
                variant="outlined"
                // label={label}
                placeholder={placeholder ?? label}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading && (
                        <CircularProgress color="inherit" size={20} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                  clearable: true, // Make sure this is set to true
                  className: "hide-clear-button", // Add this class to hide the clear button
                }}
                onChange={handleInputChange}
                sx={{
                  bgcolor: primaryColors.base.white,
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderWidth: 0,
                    },
                    "&:hover fieldset": {
                      borderWidth: 0,
                    },
                    "&.Mui-focused fieldset": {
                      borderWidth: 0,
                    },
                  },
                }}
              />
              {endIconProp && (
                <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                  <Divider
                    sx={{ height: 28, m: 0.5, mr: 1 }}
                    orientation="vertical"
                  />
                  {endIconProp}
                </Box>
              )}
            </Paper>
          </Box>
        )}
        renderOption={(props, option) => (
          <li {...props}>
            {/* <Tooltip title={option.helperText}> */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="1"
                alignItems="start"
              >
                <Typography>{option.label}</Typography>
                <Typography variant="text-sm" color={primaryColors.gray[400]}>
                  {option.helperText}
                </Typography>
              </Box>
              {option.customActions && option.customActions}
            </Box>
            {/* </Tooltip> */}
          </li>
        )}
        // To hide the selected values from the inside of the search input
        renderTags={() => null}
        // renderTags={(value, getTagProps) =>
        //   value.map((option, index) => (
        //     <Chip
        //       key={index}
        //       variant="outlined"
        //       label={option.label}
        //       {...getTagProps({ index })}
        //       onDelete={() => {
        //         const removedOption = selectedOptions[index];
        //         setSelectedOptions((prevValues) => {
        //           const newValues = [...prevValues];
        //           newValues.splice(index, 1);
        //           if (onRemoveHandler) onRemoveHandler(removedOption.value);
        //           return newValues;
        //         });
        //       }}
        //     />
        //   ))
        // }
      />
      <Box display="flex" flexWrap="wrap" gap={0.5}>
        {isLoadingSelectedOptionsProp
          ? Array(5)
              .fill()
              .map(function renderSelectedOptionSkeleton() {
                return (
                  <Skeleton
                    width="80px"
                    height="25px"
                    sx={{ borderRadius: 5 }}
                    animation="pulse"
                  />
                );
              })
          : selectedOptionsProp.map((selectedOption, index) => (
              <Tooltip
                title={selectedOption.helperText}
                disableHoverListener={!selectedOption.helperText}
              >
                <Chip
                  key={selectedOption.value}
                  label={selectedOption.label}
                  sx={{
                    marginRight: "5px",
                    backgroundColor: secondaryColors.blue[200],
                    color: secondaryColors.blue[800],
                    fontWeight: "bold",
                    fontSize: "12px",
                  }}
                  onDelete={() => {
                    const updatedOptionsAfterRemoval =
                      selectedOptionsState.filter(
                        function filterOutRemovedOption(existingOption) {
                          return existingOption.value !== selectedOption.value;
                        }
                      );
                    onRemoveHandler(selectedOption.value);
                    setSelectedOptionsState([...updatedOptionsAfterRemoval]);
                    // unassignLabelToComplaintMutation.mutateAsync({
                    //   complaintID,
                    //   newLabelID: complaintLabelItem.id,
                    // });
                  }}
                />
              </Tooltip>
            ))}
      </Box>
    </Box>
  );
};
