import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const assignFacilityUserToComplaint = ({
  complaintID,
  newFacilityUserID,
}) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/assign`;

  return melior.post(requestURL, {
    assignees: [newFacilityUserID],
  });
};

export const useAssignFacilityUserToComplaint = ({ config } = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["complaint-activity-logs"], { type: "active" });
      queryClient
        .invalidateQueries(["complaints"], { type: "active" })
        .then(() => mutation.reset());
    },
    ...config,
    mutationFn: assignFacilityUserToComplaint,
  });

  return mutation;
};
