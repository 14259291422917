import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const updateReviewArchivalState = ({
  reviewID,
  newArchivalState,
  archivingComment,
}) => {
  let requestURL = `${API_URL}/reviews/${reviewID}/archive`;

  return melior.patch(requestURL, {
    archived: newArchivalState,
    comment: archivingComment,
  });
};

export const useUpdateReviewArchivalState = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reviews"], {
        type: "active",
      });
      queryClient.invalidateQueries(["reviews-with-complaints"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: updateReviewArchivalState,
  });
};
