import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormNumberInput = ({
  name,
  label,
  placeholder,
  width = undefined,
  useAsTelephone = false,
}) => {
  //   const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          type={useAsTelephone ? "tel" : "number"}
          error={!!error}
          onChange={onChange}
          value={value ? value : ""}
          label={label}
          placeholder={placeholder}
          variant="outlined"
          sx={{
            width: width ? width : "100%",
          }}
        />
      )}
    />
  );
};
