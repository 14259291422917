import { Box, Button, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { FileOpenOutlined } from "@mui/icons-material";
import { useGetComplaintAttachment } from "../../api/complaints/getComplaintAttachment";
import { LoadingButton } from "@mui/lab";

export const ActivityLogItem = ({ itemIndex, withBorderBottom, itemData }) => {
  const firstAndSecondName = itemData.user?.split(" ");
  // Handle the case the there's no last name in the username
  const firstNameFirstLetter =
    firstAndSecondName?.length > 0 ? firstAndSecondName[0][0] : "";
  const secondNameFirstLetter =
    firstAndSecondName?.length > 1 ? firstAndSecondName[1][0] : "";

    // const {isLoading, isFetching, refetch} = useGetComplaintAttachment();

  return (
    <Box
      display="flex"
      flexDirection="column"
      pb={2.5}
      mb={2.5}
      borderBottom={
        withBorderBottom ? `2px solid ${primaryColors.gray[200]}` : undefined
      }
    >
      {/* Comments */}
      <Box display="flex" gap={1}>
        {/* Ball */}
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="32px"
          height="32px"
          borderRadius="50%"
          bgcolor={primaryColors.brand[200]}
        >
          <Typography variant="text-sm" color={primaryColors.gray[600]}>
            {`${firstNameFirstLetter}${secondNameFirstLetter}`}
          </Typography>
        </Box>
        {/* All other Note Data */}
        <Box display="flex" flexDirection="column" width="100%">
          {/* Name, Date, and Email */}
          <Box display="flex" justifyContent="space-between">
            {/* Name and Email vertically */}
            <Box display="flex" flexDirection="column">
              <Typography variant="text-sm" color={primaryColors.base.black}>
                {itemData.title}
              </Typography>

              {itemData.attachment !== undefined ? (
                <LoadingButton
                  title={itemData.attachment.name}
                //   loading={isFetching}
                  variant="text"
                  sx={{
                    textTransform: "none",
                    color: primaryColors.base.black,
                    textDecoration: "underline",
                    width: "100%",
                    justifyContent: "left",
                  }}
                  disableTouchRipple
                  disableFocusRipple
                  startIcon={<FileOpenOutlined />}
                  onClick={function () {
                    // refetch()
                  }}
                >
                  {itemData.attachment.name.length > 10
                    ? "..." + itemData.attachment.name.slice(-30)
                    : itemData.attachment.name}
                </LoadingButton>
              ) : (
                <Typography variant="text-sm" color={primaryColors.gray[500]}>
                  {itemData.subtitle || "no-email@test.com"}
                </Typography>
              )}
            </Box>

            {/* Date */}
            <Typography variant="text-xs" color={primaryColors.gray[500]}>
              {itemData.date}
            </Typography>
          </Box>
          <Box pt={2}>
            {itemData.description &&
            typeof itemData.description === "string" ? (
              <Typography
                variant="text-sm"
                color={primaryColors.gray[500]}
              >{`"${itemData.description}"`}</Typography>
            ) : (
              itemData.description
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
