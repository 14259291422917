import { API_URL, melior } from "../../../helpers/constants";

export const submitComplaintInternally = async (reviewData) => {
  let requestURL = `${API_URL}/complaints`;

  await melior
    .post(requestURL, reviewData)
    .then(async (response) => {
      return response;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
