import { Box, Grid, Snackbar } from "@mui/material";
import { useContext } from "react";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_HORIZONTAL_POSITION,
  notificationsContextDefaultValues,
  NOTIFICATION_WIDTH,
} from "../contexts/NotificationContext";
import { primaryColors } from "../helpers/customColors";
import { AuthenticationContext } from "../features/login";
import { NotificationComponentUI } from "../reusable/components/NotificationComponentUI";

export const NotificationComponent = () => {
  const { notificationState, closeNotification } =
    useContext(NotificationContext);
  const { user } = useContext(AuthenticationContext);

  const {
    title,
    description,
    type,
    verticalPosition,
    horizontalPosition,
    target,
    isOpen,
    extraDetailsToRender,
    width,
  } = notificationState;

  // Hide admins-targeted notifications from unauthed uers.
  if (target === NOTIFICATIONS_TARGET.admin && !user) {
    return null;
  }

  let notificationBackgroundColor = () => {
    switch (type) {
      case NOTIFICATIONS_TYPE.info:
        return primaryColors.warning[25];

      case NOTIFICATIONS_TYPE.error:
        return primaryColors.error[25];

      case NOTIFICATIONS_TYPE.success:
        return primaryColors.success[25];

      default:
        return primaryColors.base.white;
    }
  };

  let notificationBorderColor = () => {
    switch (type) {
      case NOTIFICATIONS_TYPE.info:
        return primaryColors.warning[300];

      case NOTIFICATIONS_TYPE.error:
        return primaryColors.error[300];

      case NOTIFICATIONS_TYPE.success:
        return primaryColors.success[300];

      default:
        break;
    }
  };

  function computeNotificationWidth(notificationWidth) {
    switch (notificationWidth) {
      case NOTIFICATION_WIDTH.fullSize:
        return "90%";
      case NOTIFICATION_WIDTH.medium:
        return "45%";
      case NOTIFICATION_WIDTH.small:
        return "20%";
      default: {
        return "90%";
      }
    }
  }
  
  return (
    <Box>
      <Snackbar
        sx={{
          width: computeNotificationWidth(width),
          height: "auto",
          borderRadius: "8px",
          paddingBlock: "10px",
          boxShadow: 5,
          backgroundColor: notificationBackgroundColor(),
          border: notificationBorderColor(),
        }}
        anchorOrigin={{
          vertical:
            verticalPosition ||
            notificationsContextDefaultValues.verticalPosition,
          horizontal:
            horizontalPosition ||
            notificationsContextDefaultValues.horizontalPosition,
        }}
        open={isOpen}
        onClose={closeNotification}
      >
        <Grid
          container
          display={"flex"}
          justifyContent={"flex-start"}
          alignContent={"flex-start"}
        >
          <NotificationComponentUI
            title={title}
            description={description}
            type={type}
            extraDetailsToRender={extraDetailsToRender}
            closeNotification={closeNotification}
          />
        </Grid>
      </Snackbar>
    </Box>
  );
};
