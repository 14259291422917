export function renderReadableMilliseconds(milliseconds) {
  let readableTime = milliseconds || "-";
  let timeUnit = "";

  if (milliseconds) {
    if (milliseconds < minuteMilliseconds) {
      // Under a Minute
      const secondsCount = (milliseconds / secondMilliseconds).toFixed(0);
      readableTime = secondsCount;
      timeUnit = secondsCount === 1 ? "second" : "seconds";
    } else if (milliseconds < hourMilliseconds) {
      // Under an Hour
      const minutesCount = (milliseconds / minuteMilliseconds).toFixed(1);
      readableTime = minutesCount;
      timeUnit = minutesCount === 1 ? "minute" : "minutes";
    } else if (milliseconds < dayMilliseconds) {
      // Under a 24-hours
      const hoursCount = (milliseconds / hourMilliseconds).toFixed(1);
      readableTime = hoursCount;
      timeUnit = hoursCount === 1 ? "hour" : "hours";
    } else if (milliseconds < weekMilliseconds) {
      // Under a Week
      const daysCount = (milliseconds / dayMilliseconds).toFixed(1);
      readableTime = daysCount;
      timeUnit = daysCount === 1 ? "day" : "days";
    } else if (milliseconds < monthMilliseconds) {
      // Under a Month
      const weeksCount = (milliseconds / weekMilliseconds).toFixed(1);
      readableTime = weeksCount;
      timeUnit = weeksCount === 1 ? "week" : "weeks";
    } else if (milliseconds < yearMilliseconds) {
      // Under a Year
      const monthsCount = (milliseconds / monthMilliseconds).toFixed(1);
      readableTime = monthsCount;
      timeUnit = monthsCount === 1 ? "month" : "months";
    } else if (milliseconds < tenYearsMilliseconds) {
      // Under 10 Years
      const yearsCount = (milliseconds / yearMilliseconds).toFixed(1);
      readableTime = yearsCount;
      timeUnit = yearsCount === 1 ? "year" : "years";
    }
  }

  return { readableTime, timeUnit };
}

export function formatTime(milliseconds) {
  if (!milliseconds) return;
  let currentMilliseconds = milliseconds,
    formattedTime = {};

  if (currentMilliseconds >= dayMilliseconds) {
    formattedTime.days = Math.trunc(currentMilliseconds / dayMilliseconds);
    currentMilliseconds = currentMilliseconds % dayMilliseconds;
  }
  if (currentMilliseconds >= hourMilliseconds) {
    formattedTime.hours = Math.trunc(currentMilliseconds / hourMilliseconds);
    currentMilliseconds = currentMilliseconds % hourMilliseconds;
  }
  if (currentMilliseconds >= minuteMilliseconds) {
    formattedTime.minutes = Math.trunc(
      currentMilliseconds / minuteMilliseconds
    );
  }

  return formattedTime;
}

const secondMilliseconds = 1000;
const minuteMilliseconds = secondMilliseconds * 60;
export const hourMilliseconds = minuteMilliseconds * 60;
export const dayMilliseconds = hourMilliseconds * 24;
const weekMilliseconds = dayMilliseconds * 7;
const monthMilliseconds = weekMilliseconds * 4;
const yearMilliseconds = monthMilliseconds * 12;
const tenYearsMilliseconds = yearMilliseconds * 10;
