import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getComplaintActivityLogs = ({ complaintID }) => {
  const requestURL = `${API_URL}/complaints/${complaintID}/activity-log`;

  return melior.get(requestURL);
};

export const useGetComplaintActivityLogs = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["complaint-activity-logs", params],
    queryFn: () => getComplaintActivityLogs(params),
    keepPreviousData: true,
  });
};
