import { COLORS } from "../helpers/Colors";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: "normal",
    textTransform: "none",
    color: COLORS.meliorYellow,
    padding: "0px",
    margin: "0px",
  },
  backToHomeButton: {
    margin: theme.spacing(4),
    fontSize: 23,
    fontFamily: "Avenir-Heavy",
    color: COLORS.meliorYellow,
    textTransform: "none",
    borderRadius: "10px",
    borderColor: COLORS.meliorYellow,
    borderWidth: "3px",
  },
}));

const RestrictedPage = ({message}) => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Container maxWidth="md" style={{ height: "100vh" }}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <h1
            className={classes.font}
            style={{ fontSize: 90, padding: "25px 0" }}
          >
            Restricted Page
          </h1>
          <h1
            className={classes.font}
            style={{
              fontSize: 25,
              marginTop: "-25px",
              // color: COLORS.black
            }}
          >
            You can't access this page at the moment.
          </h1>
          <h1
            className={classes.font}
            style={{ fontSize: 20, marginTop: "30px", color: COLORS.black }}
          >
            {message}
          </h1>
        </Grid>
      </Container>
    </Container>
  );
};

export default RestrictedPage;
