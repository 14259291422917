import { makeStyles } from "@mui/styles";
import { COLORS } from "../../helpers/Colors";
import { Box, Grid, Typography } from "@mui/material";
import {
  InfoOutlined,
  CheckCircleOutline,
  ErrorOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import { primaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
const useStyles = makeStyles(() => ({
  body: {
    boxSizing: "border-box",
    borderRadius: "5px",
    fontSize: "14px",
  },
  icon: {
    margin: "0px 5px",
    fontSize: "1.5rem",
  },
}));

const UserErrorSuccessFormDisplay = (props) => {
  const classes = useStyles();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={0.5}
      alignItems="center"
      justifyContent="center"
      className={classes.body}
      style={{
        color:
          props.type === "error"
            ? primaryColors.error[600]
            : props.type === "disclaimer"
            ? primaryColors.warning[600]
            : props.color,
        background: props.bg
          ? props.bg
          : props.type === "error"
          ? COLORS.failRedGentleNew
          : COLORS.successGreenGentle,
        width: props.width,
        margin: props.margin || "10px auto",
        padding: props.padding || "10px 10px",
        fontSize: props.fontSize || "14px",
        border:
          props.type === "error"
            ? `1px solid ${primaryColors.error[300]}`
            : props.type === "disclaimer"
            ? `1px solid ${primaryColors.warning[300]}`
            : undefined,
      }}
    >
      <Box>
        {props.type === "error" ? (
          <ErrorOutlined className={classes.icon} />
        ) : props.type === "info" ? (
          <InfoOutlined className={classes.icon} />
        ) : props.type === "disclaimer" ? (
          <WarningOutlined className={classes.icon} />
        ) : (
          <CheckCircleOutline className={classes.icon} />
        )}
      </Box>
      <Typography
        sx={{
          wordBreak: "break-word",
          fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.medium,
          color: props.color ? props.color : undefined,
          textAlign: props.language ? "right" : "left",
        }}
      >
        {Array.isArray(props.message)
          ? props.message.filter(
              (messageObject) => messageObject.lang === "en"
            )[0].text || ""
          : props.message}
      </Typography>
    </Box>
  );
};

export default UserErrorSuccessFormDisplay;
