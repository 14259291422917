import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormTextarea = ({
  name,
  label,
  placeholder,
  rows,
  sx,
  showErrorMessage = true,
  disabled = false
}) => {
  //   const classes = useStyles();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={showErrorMessage && error ? error.message : null}
          label={label}
          placeholder={placeholder}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value ? value : ""}
          multiline
          rows={rows || 10}
          sx={sx}
          variant="outlined"
          disabled={disabled}
        />
      )}
    />
  );
};
