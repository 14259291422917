import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { Button, Grid } from "@mui/material";
import { COLORS } from "../../helpers/Colors";
import clsx from "clsx";
import { Warning } from "@mui/icons-material";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    maxWidth: "400px",
    backgroundColor: theme.palette.background.paper,
    border: `2px solid ${COLORS.meliorYellow}`,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    fontSize: "1.1rem",
    fontFamily: "Avenir-Medium",
    color: COLORS.brightBlack,
  },
  buttons: {
    marginTop: "20px",
  },
  safe: {
    background: COLORS.successGreenGentle,
    "&:hover": {
      background: COLORS.successGreen,
      color: COLORS.white,
    },
  },
  danger: {
    background: COLORS.failRedGentle,
    "&:hover": {
      background: COLORS.failRed,
      color: COLORS.white,
    },
  },
  neutral: {
    background: COLORS.greyGentle,
    "&:hover": {
      background: COLORS.grey,
      color: COLORS.white,
    },
  },
  button: {
    textTransform: "none",
    marginTop: "10px",
  },
  warningIcon: {
    color: COLORS.meliorYellow,
    height: "40px",
    width: "auto",
    margin: "5px",
  },
  tightWidth: {
    margin: "auto",
    width: "80%",
    minWidth: "150px",
  },
}));

const ConfirmActionModal = ({ question, onConfirm, openModal, closeModal }) => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleClose = () => {
    setOpen(false);
    closeModal();
  };
  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div>
        <Warning className={classes.warningIcon} />
      </div>
      <div className={classes.tightWidth}>{question}</div>
      <Grid container justifyContent="space-evenly" className={classes.buttons}>
        <Button
          className={clsx(
            classes[onConfirm.type?.toString() || "safe"],
            classes.button
          )}
          variant="contained"
          onClick={onConfirm.function}
        >
          {onConfirm.text || "Confirm"}
        </Button>
        <Button
          className={clsx(classes.neutral, classes.button)}
          variant="contained"
          onClick={handleClose}
        >
          Cancel
        </Button>
      </Grid>
    </div>
  );

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        {body}
      </Modal>
    </>
  );
};
export default ConfirmActionModal;
