import { COLORS } from "../../helpers/Colors";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@mui/styles";

import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  font: {
    fontWeight: "normal",
    textTransform: "none",
    color: COLORS.meliorYellow,
    padding: "0px",
    margin: "0px",
  },
  backToHomeButton: {
    margin: theme.spacing(4),
    marginTop: "30px",
    fontSize: 23,
    fontFamily: "Avenir-Heavy",
    color: COLORS.meliorYellow,
    textTransform: "none",
    borderRadius: "10px",
    borderColor: COLORS.meliorYellow,
    borderWidth: "3px",
  },
}));

export const NoAccessPage = () => {
  const classes = useStyles();

  return (
    <Container maxWidth="xl">
      <Container maxWidth="md" style={{ height: "100vh" }}>
        <Grid
          container
          justifyContent="center"
          direction="column"
          alignItems="center"
          style={{ height: "100%" }}
        >
          <h1
            className={classes.font}
            style={{ fontSize: 160, padding: "25px 0" }}
          >
            Opps ... !
          </h1>
          <h1
            className={classes.font}
            style={{
              fontSize: 25,
              marginTop: "-25px",
              // color: COLORS.black
            }}
          >
            You don't have access
          </h1>
          <h1
            className={classes.font}
            style={{ fontSize: 20, marginTop: "30px", color: COLORS.black }}
          >
            Ask your facility manager if they could grant you access to see this
            page
          </h1>
          <NavLink
            to="/"
            end
            className={classes.backToHomeButton}
            style={{ color: COLORS.brightRed }}
          >
            Go to Home Page!
          </NavLink>
        </Grid>
      </Container>
    </Container>
  );
};
