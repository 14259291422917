import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import { TableRow, TableCell, IconButton, Tooltip } from "@mui/material";
import { Warning, Done, Edit, Delete, Close } from "@mui/icons-material";
import ResponsibilityList from "./ResponsibilityList";
import ResponsibilityListMenu from "./ResponsibilityListMenu";
import RoleChip from "./RoleChip";
import clsx from "clsx";
import { isBranchManager, isFacilityManager } from "../../../helpers/user";
import {
  actionNotAllowedDemo,
  actionNotAllowedGeneral,
} from "../../../helpers/constants";
import ConfirmActionModal from "../ConfirmActionModal";

const useStyles = makeStyles({
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.silver,
    },
  },
  fixedWidth: {
    minWidth: "160px",
    maxWidth: "160px",
  },
  button: {
    cursor: "pointer",
  },
  warning: {
    color: COLORS.failRed,
  },
  safe: {
    color: COLORS.successGreen,
  },
  info: {
    color: COLORS.meliorYellow,
  },
});

const Row = ({
  row,
  type,
  user,
  deleteUser,
  assignUserNewResponsibility,
  disableEdits,
  isDemo,
}) => {
  const classes = useStyles();

  const [assigning, setAssigning] = useState({
    active: false,
    responsibility: [],
  });

  const [openModal, setOpenModal] = useState(false);

  const toggleassigning = () => {
    if (assigning.active) setAssigning({ active: false, responsibility: [] });
    else setAssigning({ ...assigning, active: true });
  };

  let userUnassigned = true;

//   if (
//     // user has branches
//     (!!row["branches"] && !!row["branches"].length > 0) ||
//     // user has divisions
//     (!!row["divisions"] && !!row["divisions"].length > 0)
//   )
//     userUnassigned = false;

  return (
    <TableRow className={classes.row}>
      <TableCell className={classes.fixedWidth} component="th" scope="row">
        {isDemo ? row.username : row.email || row.username}
      </TableCell>
      <TableCell align="center">
        <RoleChip user={row} />
      </TableCell>
      <TableCell align="center">
        {/* Warning Statement: user is unassigned */}
        {userUnassigned && !assigning.active ? (
          <p> User is unassigned </p>
        ) : // Assigning User
        userUnassigned && assigning.active ? (
          <ResponsibilityListMenu
            user={row}
            object={assigning}
            setObject={setAssigning}
            pool={isFacilityManager(row) ? { items: user["branches"] } : null}
          />
        ) : (
          // Normal User Responsibility list
          <ResponsibilityList user={row} />
        )}
      </TableCell>
      {user && (
        <TableCell align="right" className={classes.fixedWidth}>
          {/* Unassigned User: warning [not a button] */}
          {userUnassigned && !assigning.active && (
            <Tooltip
              title={`Warning: User Unassigned${
                type === "Invited" ? ", Assign later upon user signup." : ""
              }`}
              position="top"
            >
              <span>
                <IconButton disabled={true} size="large">
                  <Warning className={classes.info} />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {/* Unassigned User: warning [button] */}
          {userUnassigned && !assigning.active && type === "Normal" && (
            <Tooltip
              title={`Assign User to ${
                isBranchManager(row) ? "branches" : "divisions"
              }`}
              position="top"
            >
              <span>
                <IconButton onClick={toggleassigning} size="large">
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {/* Unassigned User: done Assigning [button] */}
          {userUnassigned && assigning.active && type === "Normal" && (
            <Tooltip
              title={`${
                assigning.responsibility.length === 0
                  ? "Assign Responsibility to proceed"
                  : "Done"
              }`}
              position="top"
            >
              <span>
                <IconButton
                  disabled={assigning.responsibility.length === 0}
                  className={clsx(classes.safe, classes.button)}
                  onClick={() =>
                    assignUserNewResponsibility(
                      isBranchManager(row),
                      row.id,
                      type,
                      assigning.responsibility
                    )
                  }
                  size="large">
                  <Done />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {/* Unassigned User: Cancel Assigning [button] */}
          {userUnassigned && assigning.active && type === "Normal" && (
            <Tooltip title="Cancel" position="top">
              <span>
                <IconButton onClick={() => toggleassigning()} className={classes.button} size="large">
                  <Close />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {/* Delete User */}
          {(isFacilityManager || isDemo) && (
            <Tooltip
              title={
                isDemo
                  ? actionNotAllowedDemo
                  : disableEdits
                  ? actionNotAllowedGeneral
                  : `Delete ${type === "Invited" ? "Invite" : "User"}`
              }
              position="top"
            >
              <span>
                <IconButton
                  disabled={user["id"] == row["id"] || disableEdits}
                  className={classes.warning}
                  onClick={() => setOpenModal(true)}
                  size="large">
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
      )}
      <TableCell />
      <ConfirmActionModal
        question={`Are you sure you want to delete ${row.email}?`}
        onConfirm={{
          text: "Delete",
          type: "danger",
          function: () =>
            deleteUser({ id: row.id, type: type, currUser: user }),
        }}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </TableRow>
  );
};
export default Row;
