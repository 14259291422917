import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import SingleLinkModalBody from "./SingleLinkModalBody";
import SingleQRCodeModalBody from "./SingleQRCodeModalBody";
import BulkSendSMSModalBody from "./BulkSendSMSModalBody";
import { BulkGenerateLinksModalBody } from "./BulkGenerateLinksModalBody";
import { SURVEY_TYPES } from ".";

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "85%",
    maxWidth: "600px",
    minHeight: "100px",
    borderRadius: "5px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 2, 5, 2),
    outline: "none",
    paddingLeft: "10px",
    backgroundColor: COLORS.white,
    opacity: "0.93",
  },
  content: {
    overflowY: "auto",
  },
  closeButton: {
    margin: "10px",
    marginRight: "auto",
  },
  closeButtonArea: {
    width: "100%",
    textAlign: "end",
    maxHeight: "30px",
  },
}));

const LinkGeneratorModal = ({
  id,
  surveyVariant,
  close,
  branch,
  division,
}) => {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    close();
    setOpen(false);
  };
  useEffect(() => {
    id > -1 ? handleOpen() : handleClose();
  }, [id]);

  return (
    <div>
      <Modal
        aria-labelledby="link generator"
        aria-describedby="link generator IO"
        open={open}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <Grid className={classes.content}>
            <Grid
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <div className={classes.closeButtonArea}>
                <IconButton
                  className={classes.closeButton}
                  size="small"
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </div>
              {!error.isError ? null : (
                <UserErrorSuccessFormDisplay
                  color={COLORS.brightBlack}
                  type="error"
                  message={error.errorMessage}
                  width="100%"
                  margin="auto"
                />
              )}
              {id === SURVEY_TYPES.ONE_LINK.id && (
                <SingleLinkModalBody
                  branchId={branch?.id}
                  divisionId={division?.id}
                  setError={setError}
                  surveyVariant={surveyVariant}
                />
              )}
              {id === SURVEY_TYPES.ONE_QR.id && (
                <SingleQRCodeModalBody
                  branchName={branch?.name}
                  divisionName={division?.name}
                  branchId={branch?.id}
                  divisionId={division?.id}
                  setError={setError}
                  surveyVariant={surveyVariant}
                />
              )}
              {id === SURVEY_TYPES.BULK_SMS.id && (
                <BulkSendSMSModalBody
                  branchId={branch?.id}
                  divisionId={division?.id}
                  setError={setError}
                  surveyVariant={surveyVariant}
                />
              )}
              {id === SURVEY_TYPES.BULK_LINKS.id && (
                <BulkGenerateLinksModalBody
                  branchName={branch?.name}
                  divisionName={division?.name}
                  branchId={branch?.id}
                  divisionId={division?.id}
                  setError={setError}
                  surveyVariant={surveyVariant}
                />
              )}
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};
export default LinkGeneratorModal;
