import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { Grid } from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import LoadingInModal from "./LoadingInModal";
import CopyTextToClipboard from "../../../reusable/components/CopyTextToClipboard";
import { createSingleLink } from "../api";

const useStyles = makeStyles(() => ({
  body: {
    color: COLORS.brightBlack,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    margin: "15px 0",
  },
  link: {
    padding: "20px",
    borderRadius: "10px",
    width: "70%",
    maxWidth: "900px",
    wordBreak: "break-all",
    background: COLORS.silver,
    border: `1px solid ${COLORS.meliorYellow}`,
  },
  copyIcon: {
    margin: "5px",
    borderRadius: "5px",
  },
}));

const SingleLinkModalBody = ({ branchId, divisionId, setError , surveyVariant}) => {
  const classes = useStyles();
  const [generatedLink, setGeneratedLink] = useState("");

  useEffect(() => {
    generateLink();
  }, []);

  const generateLink = () => {
    createSingleLink({
      branchId: branchId,
      divisionId: divisionId,
      type: surveyVariant
    })
      .then((response) => {
        setGeneratedLink(response.link);
        setError({
          isError: false,
          errorMessage: "",
        });
      })
      .catch(() =>
        setError({
          isError: true,
          errorMessage:
            "We couldn't generate a link for you now, check your internet connection and try again",
        })
      );
  };

  return (
    <>
      {!generatedLink ? (
        <LoadingInModal />
      ) : (
        <div className={classes.body}>
          <Grid container justifyContent="center">
            <div className={classes.link}>{generatedLink}</div>
            <CopyTextToClipboard text={generatedLink} />
          </Grid>
        </div>
      )}
    </>
  );
};
export default SingleLinkModalBody;
