import { melior, URL } from "../../../helpers/constants";

export const verifyToken = (token, type) => {
  let requestURL = `${URL}/signup/token/verify/`;
  if (type === "invite") requestURL = `${URL}/user/invitation/token/verify/`;

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, token)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
