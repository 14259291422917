import { GenericDialog } from "./GenericDialog";

export function ConfirmationDialog({
  title,
  subtitle,
  contentText,
  triggerButton,
  children,
  submitButtonText,
  onConfirmationCallback,
  isDone,
  isSubmitting,
  isHidden,
}) {
  return (
    <GenericDialog
      dialogTitle={title}
      dialogSubtitle={subtitle}
      dialogContentText={contentText}
      triggerButton={triggerButton}
      submitButtonText="Yes"
      closeButtonText="No"
      isDone={isDone}
      isSubmitting={isSubmitting}
      onSubmitCallback={onConfirmationCallback}
      isHidden={isHidden}
    >
      {children}
    </GenericDialog>
  );
}
