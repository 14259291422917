import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const changeFacilityInfo = ({ facility_name, facility_logo }) => {
  let requestURL = `${API_URL}/facility/info/change`;
  var bodyFormData = new FormData();
  bodyFormData.append("facility_name", facility_name);
  if (facility_logo) bodyFormData.append("facility_logo", facility_logo);

  return melior.patch(requestURL, bodyFormData);
};

export const useChangeFacilityInfo = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: changeFacilityInfo,
  });
};
