import { Box } from "@mui/material";
import {
  BillingCards,
  SUBSCRIPTION_PLANS,
} from "../components/billing/BillingCards";
import { InvoicesTable } from "../components/billing/InvoicesTable";
import { useContext } from "react";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";

export const BillingSubScreen = () => {
  const { user } = useContext(AuthenticationContext);

  const userOnFreePlan = user?.plan === SUBSCRIPTION_PLANS.free;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <BillingCards currentSubscriptionPlan={user?.plan} />
      <InvoicesTable userOnFreePlan={userOnFreePlan} />
    </Box>
  );
};

export const PAYMENT_METHODS = {
  offline: "Offline",
  online: "Online",
};
