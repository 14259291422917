import { Box, Grid, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useEffect, useState, useContext } from "react";
import {
  useAlterCurrentUserDetails,
  useGetCurrentUserDetails,
} from "../../api";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import LoadingPage from "../../../../layout/LoadingPage";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";
import { FormContainer } from "./FormContainer";
import { PasswordConfirmationDialog } from "./PasswordConfirmationDialog";

export const MemberInformationSection = () => {
  const { fireNotification } = useContext(NotificationContext);
  const [isUsernameUnique, setIsUsernameUnique] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState({
    openDialog: false,
    title: "",
    description: "",
    callbackFunction: () => {},
  });

  const { data: userDetailsResponse, isLoading: isLoadingUserDetails } =
    useGetCurrentUserDetails();
  const alterCurrentUserDetailsMutation = useAlterCurrentUserDetails();

  const validationSchema = yup.object({
    email: yup.string().email("email is not valid"),
    first_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "name can only contain letters"),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "name can only contain letters"),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    // defaultValues: userData,
  });
  const { reset, handleSubmit, getValues, formState } = reactHookFormMethods;

  const updateProfileInfoHandler = (currentPassword) => {
    setConfirmationModal({
      openDialog: false,
    });
    let memberData = getValues();
    if (!formState.dirtyFields.username) delete memberData.username;

    if (!formState.dirtyFields.email) delete memberData.email;
    // add current password for verification
    else memberData.password = currentPassword;

    alterCurrentUserDetailsMutation
      .mutateAsync(memberData)
      .then(() => {
        fireNotification({
          title: "Changed successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
      })
      .catch((error) => {
        if (error.response.data.username) setIsUsernameUnique(false);
        else if (error.response.data.password) {
          fireNotification({
            title: "Your password was entered incorrectly",
            description: "please, enter it again",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            target: NOTIFICATIONS_TARGET.everyone,
          });
        } else {
          fireNotification({
            title: "An error occurred",
            description: "please, try agarin later",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            target: NOTIFICATIONS_TARGET.everyone,
          });
        }
      });
  };

  const resetHandler = () => {
    setIsUsernameUnique(true);
    reset();
  };

  useEffect(() => {
    reset(userDetailsResponse?.data);
  }, [reset, userDetailsResponse]);

  return (
    <Box>
      {isLoadingUserDetails ? (
        <LoadingPage />
      ) : (
        <FormProvider {...reactHookFormMethods}>
          <form
            method="PATCH"
            onSubmit={handleSubmit(() => {
              if (formState.dirtyFields.email) {
                //case 1: need confirmation
                setConfirmationModal({ openDialog: false });
                setConfirmationModal({
                  openDialog: true,
                  title: "Confirmation",
                  description: "Please enter current password to change email",
                  callbackFunction: updateProfileInfoHandler,
                });
              } else {
                //case 2: don't need confirmation
                updateProfileInfoHandler(null);
              }
            })}
          >
            <FormContainer
              formTitle={"Member Information"}
              formState={formState}
              submitting={alterCurrentUserDetailsMutation.isLoading}
              resetCallback={resetHandler}
            >
              <Grid
                container
                justifyContent="flex-start"
                direction="row"
                width={{ sm: "100%", lg: "660px", md: "660px", xl: "660px" }}
                mr={"50%"}
                spacing={2}
              >
                {/* form fields */}
                {MemberInformationTextField(
                  "First Name",
                  "first_name",
                  "Enter your first name"
                )}
                {MemberInformationTextField(
                  "Last Name",
                  "last_name",
                  "Enter your last name"
                )}
                {MemberInformationTextField(
                  "Email",
                  "email",
                  "Enter your facility email"
                )}
                {MemberInformationUsernameField(isUsernameUnique)}
              </Grid>
            </FormContainer>
          </form>
          {/* confirm changes by entering old password */}
          <PasswordConfirmationDialog {...confirmationModal}></PasswordConfirmationDialog>
        </FormProvider>
      )}
    </Box>
  );
};

const MemberInformationTextField = (label, name, placeholder, children) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.gray[700],
          marginBottom: "6px",
        }}
      >
        {label}
      </Typography>
      <FormTextInput
        name={name}
        placeholder={placeholder}
        type="text"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
      {children}
    </Grid>
  );
};

const MemberInformationUsernameField = (isUsernameUnique) => {
  return (
    <Grid item xs={12} md={6}>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.gray[700],
          marginBottom: "6px",
        }}
      >
        Username
      </Typography>
      <FormTextInput
        name="username"
        //   value={userData.username}
        error={!isUsernameUnique}
        placeholder="Enter a username"
        type="text"
        sx={{
          color: primaryColors.gray[500],
          backgroundColor: primaryColors.base.white,
          border: `1px solid ${primaryColors.gray[300]}`,
          borderRadius: "8px",
        }}
      />
      {/* username helper text */}
      {!isUsernameUnique && (
        <Typography
          sx={{
            color: primaryColors.error[600],
            fontWeight: fontWeightVariations.medium,
            fontSize: fontSizeVariations["text-sm"],
            marginTop: "6px",
          }}
        >
          This Username is not unique
        </Typography>
      )}
    </Grid>
  );
};
