import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getLabelsTree = () => {
  const requestURL = `${API_URL}/labels/tree`;

  return melior.get(requestURL);
};

export const useGetLabelsTree = ({ config }) => {
  return useQuery({
    ...config,
    queryKey: ["labels-tree"],
    queryFn: () => getLabelsTree(),
    keepPreviousData: true,
  });
};
