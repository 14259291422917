import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { IconButton, Tooltip } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";
import { RunChartCheckbox } from "./RunChartCheckbox";

const useStyles = makeStyles(() => ({
  tooltipParagraph: {
    margin: 0,
    padding: "2px",
    color: COLORS.brightBlack,
    fontSize: "14px",
  },
}));

const QUESTION_COLORS = [
  "#4e537e",
  "#923a88",
  "#bb115b",
  "#a95d37",
  "#4e7e6c",
  "#4e537e99",
  "#923a8899",
  "#bb115b99",
  "#a95d3799",
  "#4e7e6c99",
  "#4e537e44",
  "#923a8844",
  "#bb115b44",
  "#a95d3744",
  "#4e7e6c44",
];

export const RunChart = (props) => {
  const classes = useStyles();

  const [processedData, setProcessedData] = useState(null);
  const [dataForChart, setDataForChart] = useState(null);
  const [questions, setQuestions] = useState(null);
  const [checkedQuestions, setCheckedQuestion] = useState(null);

  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}aa`;

  const CustomTooltip = (props) => {
    let tooltip = [];
    if (!!props?.payload[0]?.payload) {
      tooltip.push(
        <p
          key={props?.payload[0]?.payload?.date}
          className={classes.tooltipParagraph}
        >
          Date: {props?.payload[0]?.payload?.date}
        </p>
      );
      checkedQuestions.map((question) => {
        return tooltip.push(
          <p
            key={question.text}
            className={classes.tooltipParagraph}
            style={{ color: question.color }}
          >
            {question.text}: {props?.payload[0]?.payload[question.text]}
          </p>
        );
      });
      tooltip.push(
        <p key={"Total entries"} className={classes.tooltipParagraph}>
          Total entries:{" "}
          {props?.payload[0]?.payload[`${checkedQuestions[0].text}-total`]}
        </p>
      );
    }
    return tooltip;
  };

  useEffect(() => {
    const processData = () => {
      let processedData = {};
      let questions = [];
      props.data.map((question, index) => {
        questions.push({
          text: question.text,
          color: QUESTION_COLORS[index % 15],
        });
        return question.data.points?.map((questionAverage) => {
          let date = moment(
            questionAverage["review__submitted_at__date"]
          ).format("M/D/YY");
          if (!!processedData[date]) {
            processedData[date][`${question.text}-total`] =
              questionAverage.entries_count;
            return (processedData[date][question.text] =
              questionAverage.avg.toFixed(1));
          }
          return (processedData[date] = {
            [question.text]: questionAverage.avg.toFixed(1),
            [`${question.text}-total`]: questionAverage.entries_count,
          });
        });
      });
      setQuestions(questions);
      setCheckedQuestion(questions);
      setProcessedData(processedData);
    };
    if (!!props.data?.length) processData();
  }, [props.data]);

  useEffect(() => {
    const processData = () => {
      let finalData = [];
      Object.keys(processedData).map((recordDate) =>
        finalData.push({ date: recordDate, ...processedData[recordDate] })
      );
      setDataForChart(finalData);
    };
    if (!!processedData) processData();
  }, [processedData]);

  const formatYAxis = (value) => {
    let choice = props.data[0].data.scale.filter(
      (scale) => parseInt(scale.weight) === value
    )[0];
    return choice?.text;
  };

  return (
    <>
      {!!dataForChart?.length && (
        <span>
          <Tooltip title={`Save as PNG`}>
            <IconButton
              onClick={() =>
                downloadAsPNG(`RC${props.scaleCount}`, `Run Chart`)
              }
              style={{
                padding: "10",
                marginLeft: "98%",
                transform: "rotate(90deg)",
              }}
            >
              <KeyboardTabIcon />
            </IconButton>
          </Tooltip>
          <div id={`RC${props.scaleCount}`}>
            <ResponsiveContainer height={props.scaleCount === 3 ? 200 : 300}>
              <LineChart data={dataForChart}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis
                  width={110}
                  tickFormatter={formatYAxis}
                  ticks={props?.data[0].data?.scale?.map((scale) =>
                    parseInt(scale.weight)
                  )}
                  tickCount={props.scaleCount + 1}
                  type="number"
                />
                <RechartsTooltip content={<CustomTooltip />} />
                {checkedQuestions.map((question) => (
                  <Line
                    strokeWidth={3}
                    key={`linefor${question.text}`}
                    type="monotone"
                    dataKey={question.text}
                    stroke={question.color}
                  />
                ))}
              </LineChart>
            </ResponsiveContainer>
            {/* Checkboxes */}
            {!!dataForChart?.length &&
              questions.map((question) => (
                <div
                  key={`chartCheckBoxfor${question.text}`}
                  style={{ textAlign: "start", display: "block" }}
                >
                  <RunChartCheckbox
                    color={question.color}
                    checked={
                      !!checkedQuestions.filter(
                        (checkedQuestion) =>
                          checkedQuestion.text === question.text
                      ).length
                    }
                    uncheck={() =>
                      setCheckedQuestion(
                        checkedQuestions?.filter(
                          (checkedQuestion) =>
                            checkedQuestion.text !== question.text
                        )
                      )
                    }
                    check={() =>
                      setCheckedQuestion([...checkedQuestions, question])
                    }
                    label={question.text}
                    disabled={
                      !!checkedQuestions.filter(
                        (checkedQuestion) =>
                          checkedQuestion.text === question.text
                      ).length && checkedQuestions.length === 1
                    }
                  />
                </div>
              ))}
          </div>
        </span>
      )}
    </>
  );
};