import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const markComplaintAsSeen = ({ complaintID }) => {
  let requestURL = `${API_URL}/complaints/${complaintID}`;

  return melior.patch(requestURL, {
    seen: true,
  });
};

export const useMarkComplaintAsSeen = ({ config } = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      queryClient
        .invalidateQueries(["complaints"], { type: "active" })
        .then(() => mutation.reset());
    },
    ...config,
    mutationFn: markComplaintAsSeen,
  });

  return mutation;
};
