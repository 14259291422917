import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
  Cell,
} from "recharts";
import { primaryColors } from "../../../helpers/customColors";

export const RevampedVerticalBarChart = ({
  data,
  labelPosition,
  YAxisScale,
  valueType,
  getBarColor,
  showTooltip,
  barSize,
  rotateXAxisTick,
}) => {
  const CustomTooltip = ({ payload = [], active }) => {
    if (active) {
      return (
        <div
          style={{
            margin: 0,
            border: "1px solid",
            borderColor: primaryColors.gray[100],
            backgroundColor: primaryColors.base.white,
            paddingBottom: "0%",
            overflowWrap: "break-word",
            whiteSpace: "pre-wrap",
            opacity: "0.7",
            width: "auto",
          }}
        >
          <p
            style={{
              margin: "5px",
              color: primaryColors.base.black,
            }}
          >
            {payload[0]?.payload.tooltipValue}
          </p>
        </div>
      );
    }

    return null;
  };
  function formatXAxisLabelValue({ valueType, value }) {
    switch (valueType) {
      case "plain_number":
        return value?.toFixed(1);
      case "percentage":
        return `${value?.toFixed(0)}%`;
      case "time":
        return `${value} hours`;
      default:
        return null;
    }
  }
  const renderCustomizedLabel = ({ viewBox, value }) => {
    const { x, y, width } = viewBox;
    if (width === 0) return;
    const formattedValue = formatXAxisLabelValue({ valueType, value });
    return (
      <text
        x={x + 25}
        y={value < 50 || labelPosition === "outside" ? y + -5 : y + 20}
        fontSize="15px"
        fontFamily="Nunito"
        textAnchor="middle"
        fill={
          value < 50 || labelPosition === "outside"
            ? primaryColors.base.black
            : primaryColors.base.white
        }
      >
        {value && formattedValue}
      </text>
    );
  };
  function formatYAxis(value) {
    switch (valueType) {
      case "plain_number":
        return value;
      case "percentage":
        return `${value}%`;
      case "time":
        return `${value} hours`;
      default:
        return null;
    }
  }
  function getBranchTickData(dataPointId) {
    return data?.find((dataPoint) => dataPoint.id === dataPointId);
  }
  const renderCustomizedTick = ({ x, y, textAnchor, payload }) => {
    let dataPoint = getBranchTickData(payload.value);
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = getComputedStyle(document.body).font;
    let circleTextGap = context.measureText(dataPoint.name).width / 2 + 12;
    return rotateXAxisTick ? (
      <g transform={`translate(${x},${y})`}>
        <g x={0} y={0} transform="rotate(25)">
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor="start"
            fill="#666"
            fontSize="14px"
          >
            <title>{dataPoint.fullName}</title>
            {dataPoint.name}
          </text>
          <circle
            cx={-10}
            cy={10}
            r={5}
            fill={dataPoint.color || primaryColors.gray[100]}
          />
        </g>
      </g>
    ) : (
      [
        <text x={x} y={y + 15} textAnchor={textAnchor}>
          <title>{dataPoint.fullName}</title>
          {dataPoint.name}
        </text>,
        <circle
          cx={x - circleTextGap}
          cy={y + 10}
          r={5}
          fill={dataPoint.color || primaryColors.gray[100]}
        />,
      ]
    );
  };

  return (
    <ResponsiveContainer>
      <ComposedChart
        data={data}
        width="100%"
        height={`${300}px`}
        margin={{
          top: 20,
          right: 40,
          bottom: 40,
          left: 0,
        }}
      >
        <CartesianGrid stroke={primaryColors.gray[100]} vertical={false} />
        <XAxis
          dataKey="id"
          type="category"
          axisLine={false}
          tickLine={rotateXAxisTick ? true : false}
          tick={renderCustomizedTick}
        />

        {showTooltip && <Tooltip content={<CustomTooltip />} />}
        <YAxis
          ticks={YAxisScale}
          tickFormatter={formatYAxis}
          tickCount={YAxisScale?.length}
          axisLine={false}
          tickLine={false}
        />
        <Bar
          dataKey="value"
          barSize={barSize || 50}
          style={{}}
          radius={[8, 8, 0, 0]}
        >
          {data?.map((entry, index) => (
            <Cell key={index} fill={getBarColor(entry.value)} />
          ))}
          <LabelList content={renderCustomizedLabel} />
        </Bar>
      </ComposedChart>
    </ResponsiveContainer>
  );
};
