import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";

import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

import { IconButton } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";

const useStyles = makeStyles(() => ({
  tooltip: {
    margin: 0,
    border: "1px solid",
    borderColor: COLORS.silver,
    backgroundColor: COLORS.white,
    paddingBottom: "0%",
    overflowWrap: "break-word",
    opacity: "0.7",
    width: "150px",
  },
  tooltipTitle: {
    margin: "5px",
    color: COLORS.brightBlack,
  },
}));

const CustomTooltip = ({ payload, active }) => {
  const classes = useStyles();

  if (active) {
    return (
      <div className={classes.tooltip}>
        <p className={classes.tooltipTitle}>
          Total reviews: {`${payload[0].payload.count}`}
          {` (${payload[0].payload.percentage}%)`}
        </p>
      </div>
    );
  }

  return null;
};

export const BarChart = (props) => {
  const [finalData, setFinalData] = useState([]);

  useEffect(() => {
    let finalData = [];
    props.data.map((source, i) => {
      let record = {
        name: source.name,
        count: parseInt(source.value.toFixed(1)),
        percentage: ((parseInt(source.value) / props.total) * 100).toFixed(1),
      };
      finalData.push(record);
    });
    setFinalData(finalData);
  }, [props]);

  return (
    <div style={{ width: "100%", height: "400px" }}>
      <IconButton
        onClick={() => downloadAsPNG(`BC${props.title}`, `${props.title}-BC`)}
        style={{
          padding: "10",
          marginLeft: "88%",
          transform: "rotate(90deg)",
        }}
        size="large">
        <KeyboardTabIcon />
      </IconButton>
      <ResponsiveContainer id={`BC${props.title}`}>
        <ComposedChart
          layout="vertical"
          data={finalData}
          margin={{
            top: 20,
            right: 60,
            bottom: 40,
            left: 20,
          }}
        >
          <CartesianGrid stroke={COLORS.silver} />
          <XAxis type="number" domain={[0, 100]} />
          <YAxis dataKey="name" type="category" />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="percentage"
            barSize={20}
            fill={COLORS.brightRed}
            background={{ fill: COLORS.silver }}
          />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};