import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import { BarChart, NPSChart, PieChartMultipleValues, PieChartPercentage, RunChart } from "../../../reusable/components/charts";
import { ColorsLegend } from "../../../reusable/components/ColorsLegend";

const useStyles = makeStyles(() => ({
  body: {
    marginTop: "20px",
    padding: "40px 0",
  },
  section: {
    marginTop: "40px",
  },
}));

const InternalProgressView = (props) => {
  const classes = useStyles();
  
  return (
    <Grid className={classes.body} container direction="column">
      {/* RUN CHARTS 5 SCALE */}
      <Grid className={classes.section} container direction="row">
        <Grid item xs={11} key={`RC5S`}>
          <RunChart scaleCount={5} data={props.data["RC5S"]} />
        </Grid>
      </Grid>

      {/* RUN CHARTS 3 SCALE */}
      <Grid className={classes.section} container direction="row">
        <Grid item xs={11} key={`RC3S`}>
          <RunChart scaleCount={3} data={props.data["RC3S"]} />
        </Grid>
      </Grid>

      {/* PIE CHARTS PERCENTAGES */}
      <Grid className={classes.section} columns={12} container direction="row">
        {props.data["PCP"]?.map((PCPchart) => (
          <Grid item xs={12} sm={6} lg={3} key={`PCP${PCPchart.text}`}>
            <PieChartPercentage data={PCPchart.data} title={PCPchart.text} />
          </Grid>
        ))}
      </Grid>

      {/* PIE CHARTS MULTIPLE VALUES */}
      <Grid className={classes.section} columns={12} container direction="row">
        {props.data["PCMV"]?.map((PCMVchart) => (
          <Grid item xs={12} sm={6} lg={6} key={`PCMV${PCMVchart.text}`}>
            <ColorsLegend colors={PCMVchart.data.colors} />
            <PieChartMultipleValues
              data={PCMVchart.data.data}
              colors={PCMVchart.data.colors}
              title={PCMVchart.text}
            />
          </Grid>
        ))}
      </Grid>

      {/* NPS */}
      <Grid className={classes.section} columns={12} container direction="row">
        {props.data["NPS"]?.map((NPSchart) => (
          <Grid item xs={12} sm={6} lg={6} key={`NPS${NPSchart.text}`}>
            <NPSChart data={NPSchart.data} title={NPSchart.text} />
          </Grid>
        ))}
      </Grid>

      {/* Bar CHARTS */}
      <Grid className={classes.section} columns={12} container direction="row">
        {props.data["BC"]?.map((BCchart) => (
          <Grid item xs={12} sm={6} lg={6} key={`BC${BCchart.text}`}>
            <BarChart
              data={BCchart.data}
              title={BCchart.text}
              total={props.data["reviewsCount"]}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default InternalProgressView;
