import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getReviewNotes = ({ reviewID }) => {
  const requestURL = `${API_URL}/reviews/${reviewID}/notes`;

  return melior.get(requestURL);
};

export const useGetReviewNotes = ({ params, config }) => {
  return useQuery({
    ...config,
    queryKey: ["review-notes", params],
    queryFn: () => getReviewNotes(params),
    keepPreviousData: true,
  });
};
