import { API_URL, melior } from "../../../helpers/constants";

export const validateOnlineSurveyToken = (token) => {
  const requestURL = `${API_URL}/feedback-invitation/verify/`;

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, { token: token })
      .then(async (response) => {
        melior.defaults.headers.common["Authorization"] = "Bearer " + token;
        melior.defaults.headers.common["Content-Type"] = "application/json";
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
