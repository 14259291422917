import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const changeUserPassword = (passwordData) => {
  let requestURL = `${API_URL}/password/change/`;
  
  return melior.post(requestURL, passwordData);
};

export const useChangeUserPassword = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: changeUserPassword,
  });
};
