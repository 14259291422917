import { API_URL, melior } from "../../../helpers/constants";

export const getComplaints = async (complaintsQueryStrings) => {
  const {
    date_from,
    date_to,
    branches,
    divisions,
    templates,
    questions,
    pagination,
    resolved,
    bookmarked,
    complaintIDs
  } = complaintsQueryStrings;

  const requestURL = `${API_URL}/complaints`;

  return await melior
    .get(requestURL, {
      params: {
        ...complaintsQueryStrings,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
