import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const addComplaintNote = ({ complaintID, newNoteText }) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/note`;

  return melior.post(requestURL, {
    text: newNoteText,
  });
};

export const useAddComplaintNote = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["complaint-activity-logs"], { type: "active" });
    },
    ...config,
    mutationFn: addComplaintNote,
  });
};
