import { makeStyles } from "@mui/styles";
import { Grid, Typography } from "@mui/material";

const useStyles = makeStyles(() => ({
  body: {
    fontSize: "14px",
    margin: "15px 0",
  },
  legendRow: {
    flexBasis: "fit-content",
  },
  legendSquare: {
    height: "11px",
    width: "11px",
    margin: "5px",
  },
  legendCircle: {
    height: "8px",
    width: "8px",
    borderRadius: "8px",
    margin: "5px",
  },
}));

export const ColorsLegend = ({ colors, shape }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={classes.body}
      columnGap="13px"
    >
      {Object.keys(colors).map((colorKey) => (
        <Grid
          container
          alignItems="center"
          key={colorKey}
          className={classes.legendRow}
        >
          <div
            style={{ backgroundColor: colors[colorKey] }}
            className={
              shape === "circle" ? classes.legendCircle : classes.legendSquare
            }
          />
          <Typography variant="text-sm">{colorKey}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
