import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const updatePaymentCardInfo = () => {
  let requestURL = `${API_URL}/billing/update-card`;

  return melior.post(requestURL);
};

export const useUpdatePaymentCardInfo = ({ config } = {}) => {
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: () => {
      fireNotification({
        title: "Payment Card Update requested successfully.",
        description:
          "Please fill in the necessary info in the opened tab to complete the process.",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: () => {
      fireNotification({
        title:
          "Failed to request an update for the Payment Card info, please try again later.",
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: updatePaymentCardInfo,
  });
};
