import { Box, Skeleton, Typography } from "@mui/material";
import { RevampedLandingPageCard } from "../../../revamped-landing-page/components/RevampedLandingPage";
import { fontWeightVariations } from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { RevampedVerticalBarChart } from "../../../../reusable/components/charts";
import { getCSATBarColor } from "../../../revamped-landing-page/components/AllBranchesQuestionBarChart";
import { useGetComplaintsClassification } from "../../api/complaints-overview";
import { DynamicFilters } from "../shared/DynamicFilters";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import {
  getLowestLevelSelectedFilter,
  useDynamicFiltersState,
} from "../../hooks/useDynamicFiltersState";
import { useEffect } from "react";

export const ComplaintsClassificationBarChart = ({
  startDate,
  endDate,
  branches,
  divisons,
  relatedTeams,
  complaintsClassificationDropdowns,
}) => {
  const { selectedFiltersState, updateSelectedFilters, replaceFilterState } =
    useDynamicFiltersState({
      isEachFilterIndependent: false,
      serverFiltersData: complaintsClassificationDropdowns,
    });

  const {
    data: complaintsClassificationServerResponse,
    isFetching: isFetchingClassification,
  } = useGetComplaintsClassification({
    params: {
      startDate,
      endDate,
      branches,
      divisons,
      relatedTeams,
      labelsIDs: getLowestLevelSelectedFilter({
        selectedFiltersState,
        filtersData: complaintsClassificationDropdowns,
      }),
    },
    config: { staleTime: 120000 },
  });

  useEffect(
    function updateFilterData() {
      if (complaintsClassificationDropdowns) {
        replaceFilterState({
          newFiltersData: complaintsClassificationDropdowns,
        });
      }
    },
    [complaintsClassificationDropdowns, replaceFilterState]
  );

  return (
    <RevampedLandingPageCard
      showSeparator={true}
      labelHeight="30px"
      label={
        <Typography
          variant="text-lg"
          fontWeight={fontWeightVariations.medium}
          color={primaryColors.gray[900]}
        >
          Complaints Classification
        </Typography>
      }
    >
      <Box display="flex" flexDirection="column" height="400px" gap={2}>
        <IsLoadingContainer
          size={10}
          isLoading={!complaintsClassificationDropdowns}
        >
          <DynamicFilters
            selectedFiltersState={selectedFiltersState}
            updateSelectedFilters={updateSelectedFilters}
            addShowAllOption={true}
          />
        </IsLoadingContainer>
        {isFetchingClassification ? (
          <SkeletonForComplaintsBarChart />
        ) : (
          <RevampedVerticalBarChart
            data={complaintsClassificationServerResponse?.data.map(
              function prepareBarChartData(complaintData, index) {
                const isDataPointsMoreThanSixOrTextLong =
                  complaintsClassificationServerResponse?.data.length > 6 ||
                  complaintData.text.length > 15;
                const getTrimmedName = (name) => {
                  return name.length > 12 ? `${name.slice(0, 12)}..` : name;
                };

                return {
                  id: index,
                  name: isDataPointsMoreThanSixOrTextLong
                    ? getTrimmedName(complaintData.text)
                    : complaintData.text,
                  fullName: complaintData.text,
                  tooltipValue: complaintData.text,
                  value: complaintData.percentage,
                  color: complaintData.color,
                };
              }
            )}
            showTooltip={true}
            // barSize={"auto"}
            // rotateXAxisTick={true}
            valueType={"percentage"}
            YAxisScale={[0, 20, 40, 60, 80, 100]}
            getBarColor={getCSATBarColor}
          />
        )}
      </Box>
    </RevampedLandingPageCard>
  );
};
const SkeletonForComplaintsBarChart = () => {
  return (
    <Skeleton
      variant="rectangular"
      width={"100%"}
      height={400}
      sx={{ borderRadius: 5 }}
    />
  );
};
