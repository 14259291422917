import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useContext } from "react";
import { useGiveFeedback } from "../../api";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { FormProvider, useWatch } from "react-hook-form";
import * as yup from "yup";
import {
  FormDropdown,
  FormTextarea,
} from "../../../../reusable/components/form-inputs/controlled";

export const GiveFeedback = () => {
  const { fireNotification } = useContext(NotificationContext);

  const validationSchema = yup.object({
    feedbackMessage: yup.string().required().max(1000),
    feedbackType: yup.string().required("Please select a request"),
  });

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    useFormProps: {
      mode: "onChange",
    },
    defaultValues: {
      feedbackMessage: "",
      feedbackType: "",
    },
  });
  const { reset, handleSubmit, control, formState } = reactHookFormMethods;

  const {
    mutateAsync: giveFeedbackMutation,
    isLoading: isGiveFeedbackLoading,
  } = useGiveFeedback();

  const submitFeedbackHandler = (feedbackData) => {
    giveFeedbackMutation(feedbackData)
      .then(() => {
        fireNotification({
          title: "Thank you for your feedback.",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
        reset();
      })
      .catch(() => {
        fireNotification({
          title: "An error occurred, please try again",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
      });
  };

  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="POST" onSubmit={handleSubmit(submitFeedbackHandler)}>
        <Box>
          {/* Card Header */}
          <Box
            bgcolor={primaryColors.base.white}
            paddingBottom="20px"
            marginBottom="20px"
            borderTop="8px solid"
            sx={{
              borderImage: "linear-gradient(45deg, #FFB029 0%, #FFE7BD 100%)",
              borderImageSlice: "1",
            }}
          >
            {/* <Box
              width={"100%"}
              height={"8px"}
              sx={{
                backgroundImage:
                  "linear-gradient(45deg, #FFB029 0%, #FFE7BD 100%)",
              }}
            ></Box> */}
            <Box
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
              padding={"28px 24px"}
            >
              <Typography
                sx={{
                  color: primaryColors.gray[900],
                  fontSize: fontSizeVariations["display-xs"],
                  fontWeight: fontWeightVariations.bold,
                }}
              >
                Give us feedback
              </Typography>
              <Button
                type="submit"
                disabled={!formState.isValid || isGiveFeedbackLoading}
                sx={{
                  backgroundColor:
                    formState.isValid && !isGiveFeedbackLoading
                      ? primaryColors.warning[500]
                      : primaryColors.warning[200],
                  padding: "10px 16px",
                  borderRadius: "8px",
                }}
              >
                <Typography
                  sx={{
                    textTransform: "capitalize",
                    color: primaryColors.base.white,
                    fontSize: fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                >
                  {isGiveFeedbackLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    "Submit"
                  )}
                </Typography>
              </Button>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              width="25%"
              paddingLeft="24px"
            >
              <Typography
                sx={{
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations.bold,
                  color: primaryColors.gray[700],
                }}
              >
                Improvements request
              </Typography>
              <FormDropdown
                name="feedbackType"
                label="Select a request"
                options={feedbackTypes}
              ></FormDropdown>
            </Box>
          </Box>
          <Box>
            <FormTextarea
              showErrorMessage={false}
              name="feedbackMessage"
              placeholder="A few sentences is all it takes! We will be forever grateful. Thank you in advance for helping us out! "
              rows={7}
              sx={{
                marginBlock: "6px",
                backgroundColor: primaryColors.base.white,
                width: "100%",
                border:
                  useWatch({ control, name: "feedbackMessage" })?.length > 1000
                    ? `1px solid ${primaryColors.error[300]}`
                    : `1px solid ${primaryColors.gray[300]}`,
                borderRadius: "8px",
                "& fieldset": { border: "none" },
              }}
            ></FormTextarea>
            {/* letters count */}
            <LettersCounter control={control} />
          </Box>
        </Box>
      </form>
    </FormProvider>
  );
};
const LettersCounter = ({ control }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-end"
      alignContent="flex-end"
    >
      <Typography
        sx={{
          color:
            useWatch({ control, name: "feedbackMessage" })?.length > 1000
              ? primaryColors.error[500]
              : primaryColors.gray[500],
          fontSize: fontSizeVariations["text-sm"],
          fontWeight: fontWeightVariations.medium,
        }}
      >
        {useWatch({ control, name: "feedbackMessage" })?.length > 1000
          ? " Maximum characters exceeded "
          : ""}
        {useWatch({ control, name: "feedbackMessage" })?.length}/1000
      </Typography>
    </Box>
  );
};
const feedbackTypes = [
  { value: "request_a_call", label: "Request a call" },
  { value: "suggestions", label: "Suggestions" },
  { value: "bug_reporting ", label: "Bug reporting" },
];
