import { useContext } from "react";
import {
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Error from "@mui/icons-material/Error";
import { primaryColors } from "../../../../helpers/customColors";
import { BranchHeaderContext } from "../../../../contexts/BranchHeaderContext";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles(() => ({
  body: {
    display: "inline-flex",
  },
  saveAsFormControl: {
    height: "30px",
    borderRadius: "10px",
    alignSelf: "center",
    fontFamily: "Avenir-Medium",
    padding: "0px",
  },
  saveAsMenu: {
    width: "125px",
    height: "30px",
    backgroundColor: primaryColors.brand[600],
    borderRadius: "7px",
    textAlign: "center",
    color: primaryColors.base.black,
    textTransform: "none",
    fontWeight: "normal",
    fontFamily: "Avenir-Medium",
    fontSize: "14px",
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    height: "30px",
    padding: "20px 15px",
  },
  dropdownIcon: {
    color: primaryColors.base.black,
  },
  noPadding: {
    paddingTop: "0px",
    paddingBottom: "0px",
  },
  progress: {
    width: "30px",
    height: "30px",
    marginLeft: "10px",
    color: primaryColors.brand[600],
  },
  errorContainer: {
    display: "inline-flex",
  },
  error: {
    width: "25px",
    height: "30px",
    marginLeft: "10px",
    color: primaryColors.error[600],
  },
  errorMessage: {
    margin: "5px",
    fontFamily: "Avenir-Medium",
    fontSize: "14px",
  },
}));

const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  PaperProps: {
    style: {
      maxHeight: 280,
      width: 150,
    },
  },
};

const SaveAs = ({
  selectedReviewsIDs,
  disabled,
  label = "Export",
  queryStringsForAPI,
  questionsDataToRender,
}) => {
  const classes = useStyles();

  const { downloadMethodHandleChangeRevamp, downloading, downloadingError } =
    useContext(BranchHeaderContext);

  return (
    <div className={classes.body}>
      <LoadingButton
        loading={downloading}
        variant="contained"
        sx={{ height: 31, textTransform: "capitalize", color: primaryColors.base.white }}
        onClick={function () {
          downloadMethodHandleChangeRevamp({
            event: { target: { value: "Excel" } },
            queryStringsForAPI: {
              ...queryStringsForAPI,
              ids: selectedReviewsIDs.join(","),
            },
            entityToExportFrom: "Reviews",
            questionsDataToRender,
          });
        }}
        disabled={downloading || disabled}
      >
        {label}
      </LoadingButton>
      {downloadingError?.isError ? (
        <div className={classes.errorContainer}>
          <Error className={classes.error}></Error>
          <p className={classes.errorMessage}>
            {downloadingError?.errorMessage}
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default SaveAs;
