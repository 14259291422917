import { API_URL, melior } from "../../../helpers/constants";

export const addFacility = (facilityData, token) => {
  let requestURL = `${API_URL}/facility`;
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };
  
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, facilityData, config)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
