import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const updateToOnlinePaymentMethod = () => {
  let requestURL = `${API_URL}/billing/change-payment-method`;

  return melior.post(requestURL);
};

export const useUpdateToOnlinePaymentMethod = ({ config } = {}) => {
  const { fireNotification } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["billing-details-summary"], {
        type: "active",
      });
      fireNotification({
        title: "Online Payment method successfully chosen",
        description:
          "Please fill in the necessary info in the opened tab to complete the migration to Online payment.",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: function errorInUpdatingToOnlinePaymentMethodHandler() {
      fireNotification({
        title: "Failed to update to Online payment method",
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: updateToOnlinePaymentMethod,
  });
};
