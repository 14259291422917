import { Grid, MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useContext } from "react";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { COLORS } from "../../../helpers/Colors";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";

export const MenuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
};

const useStyles = makeStyles((theme) => ({
  page: {
    marginTop: "30px",
  },
  menu: {
    color: COLORS.brightBlack,
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    width: "60%",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: "250px",
    },
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    height: "50px",
  },
}));

export const BranchesAndDivisionsSelectMenu = ({
  divisions,
  selectedDivisionID,
  selectedBranchID,
  CBopen,
  setCBOpen,
  CDopen,
  setCDOpen,
  error,
  setSelectedBranchID,
  setSelectedDivisionID,
}) => {
  const classes = useStyles();

  const { user } = useContext(AuthenticationContext);

  return (
    <Grid
      container
      justifyContent="space-evenly"
      style={{
        width: "100%",
      }}
    >
      {!error.isError ? null : (
        <UserErrorSuccessFormDisplay
          color={COLORS.brightBlack}
          type="error"
          message={error.errorMessage}
          width="100%"
          margin="auto"
        />
      )}
      <FormControl sx={{ width: "fit-content" }}>
        <InputLabel id="branch-select-menu">Branch</InputLabel>

        <Select
          labelId="branch-select-menu"
          label={"Branch"}
          MenuProps={MenuProps}
          disableUnderline={true}
          className={classes.menu}
          open={CBopen}
          onClose={() => setCBOpen(false)}
          onOpen={() => setCBOpen(true)}
          value={selectedBranchID}
          onChange={(event) => {
            setSelectedBranchID(event.target.value);
          }}
          inputProps={{
            classes: {
              icon: classes.dropdownIcon,
            },
          }}
        >
          <MenuItem className={classes.menuItem} disabled value={0}>
            Choose Branch
          </MenuItem>
          {user.branches.map((branch) => (
            <MenuItem
              key={branch.id}
              value={branch.id}
              className={classes.menuItem}
            >
              {branch.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {divisions[selectedBranchID] === undefined ? (
        <div className={classes.menu} />
      ) : (
        <FormControl
          sx={{ width: "fit-content" }}
          style={
            divisions[selectedBranchID].length === 1
              ? { visibility: "hidden" }
              : { visibility: "visible" }
          }
        >
          <InputLabel id="division-select-menu">Division</InputLabel>

          <Select
            labelId="division-select-menu"
            label={"Division"}
            MenuProps={MenuProps}
            disableUnderline={true}
            className={classes.menu}
            open={CDopen}
            onClose={() => setCDOpen(false)}
            onOpen={() => setCDOpen(true)}
            value={selectedDivisionID}
            onChange={(event) => setSelectedDivisionID(event.target.value)}
            inputProps={{
              classes: {
                icon: classes.dropdownIcon,
              },
            }}
          >
            <MenuItem className={classes.menuItem} disabled value={0}>
              Choose Division
            </MenuItem>

            {divisions[selectedBranchID].map((division) => (
              <MenuItem
                key={division.id}
                value={division.id}
                className={classes.menuItem}
              >
                {division.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Grid>
  );
};
