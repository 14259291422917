import { useContext, useEffect } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import LoadingPage from "../../../../layout/LoadingPage";

import { COLORS } from "../../../../helpers/Colors";

import { makeStyles } from "@mui/styles";
import { BranchContext } from "../../../../contexts/BranchContext";
import ExternalProgressView from "./ExternalProgressView";

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontWeight: "normal",
  },
  menuItem: {
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
    height: "50px",
  },
  menu: {
    textAlign: "center",
    // marginLeft: "15px",
    color: COLORS.black,
    textTransform: "none",
    fontWeight: "normal",
    fontSize: "16px",
  },
  menuItemLink: {
    color: "inherit",
    textDecoration: "none",
    fontSize: "16px",
    "&.active": {
      fontFamily: "Avenir-Heavy",
    },
  },
  dropdownIcon: {
    color: COLORS.meliorYellow,
  },
  statsText: {
    fontSize: "16px",
    fontFamily: "Avenir-Medium",
  },
}));

export const ExternalProgressPage = ({ data, error, loading }) => {
  const classes = useStyles();

  const { changeHeaderBranch } = useContext(BranchContext);

  useEffect(() => changeHeaderBranch("all"), []);

  return (
      <Grid
        container
        justifyContent="flex-start"
        direction="column"
        alignItems="flex-start"
        className={classes.body}
      >
        {/* start progress view */}
        {error.isError ? (
          <Grid container item xs={12} justifyContent="flex-start">
            <p
              style={{
                textAlign: "center",
                color: COLORS.failRed,
              }}
            >
              {error.errorMessage}
            </p>
          </Grid>
        ) : loading ? (
          <LoadingPage />
        ) : (
          <ExternalProgressView data={data} />
        )}
      </Grid>
  );
};