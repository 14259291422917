import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const updateBillingEmail = ({ newBillingEmail }) => {
  let requestURL = `${API_URL}/billing/change-email`;

  return melior.patch(requestURL, {
    email: newBillingEmail,
  });
};

export const useUpdateBillingEmail = ({ config } = {}) => {
  const { fireNotification } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["billing-details-summary"], {
        type: "active",
      });
      fireNotification({
        title: "Billing Email Updated Successfully.",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: () => {
      fireNotification({
        title:
          "Failed to update the Billing Email, please try again later.",
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: updateBillingEmail,
  });
};
