import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip,
  Legend,
  Cell,
} from "recharts";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";

const needle = ({ cx, cy, innerRadius, outerRadius, value = 0, color }) => {
  const RADIAN = Math.PI / 180;
  //   const cx = 215;
  //   const cy = 130;

  const ang = 180 * (1 - value);
  const length = (innerRadius + 1.5 * outerRadius) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 15;
  const x0 = cx;
  const y0 = cy - 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return [
    // <circle
    //   cx={x0}
    //   cy={y0}
    //   style={{
    //     position: "absolute",
    //     zIndex: "9999",
    //   }}
    //   r={r}
    //   fill={color}
    //   stroke="none"
    // />,
    <path
      style={{
        position: "absolute",
        zIndex: "9999",
      }}
      d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
      stroke="#none"
      fill={color}
    />,
  ];
};
const renderCustomizedLabel = ({
  cx,
  cy,
  labelInsideChart,
  showNeedle,
  showChartScale,
  innerRadius,
  outerRadius,
  value,
}) => {
  return (
    <>
      {showNeedle &&
        needle({
          cx,
          cy,
          value,
          innerRadius,
          outerRadius,
          color: primaryColors.base.black,
        })}

      {showChartScale && (
        <>
          <text
            x={cx - 140}
            y={cy + 20}
            textAnchor="middle"
            fontFamily="Nunito"
            fontSize="12px"
            fontWeight={fontWeightVariations.regular}
            fill={primaryColors.gray[900]}
          >
            -100
          </text>
          <circle
            cx={cx}
            cy={cy - 135}
            r="10"
            fill={primaryColors.base.white}
            stroke="none"
          />
          <text
            x={cx}
            y={cy - 130}
            textAnchor="middle"
            fontFamily="Nunito"
            fontSize="12px"
            fontWeight={fontWeightVariations.regular}
            fill={primaryColors.gray[900]}
          >
            0
          </text>
          <text
            x={cx + 140}
            y={cy + 20}
            textAnchor="middle"
            fontFamily="Nunito"
            fontSize="12px"
            fontWeight={fontWeightVariations.regular}
            fill={primaryColors.gray[900]}
          >
            100
          </text>
        </>
      )}

      {/* text inside chart */}
      <rect
        x={cx - 140 / 2}
        y={cy - 60}
        width={140}
        height={70}
        fill={primaryColors.base.white}
        rx={40}
      />
      <text
        x={cx}
        y={cy}
        textAnchor="middle"
        fontFamily="Nunito"
        fontSize="56px"
        fontWeight={fontWeightVariations.semiBold}
        fill={primaryColors.gray[900]}
      >
        {labelInsideChart.mainText}
        <tspan
          fontFamily="Nunito"
          fontSize="24px"
          fontWeight={fontWeightVariations.medium}
          fill={primaryColors.gray[900]}
        >
          {labelInsideChart.secondaryText}
        </tspan>
      </text>
    </>
  );
};
const useStyles = makeStyles(() => ({
  displayOnHover: {
    opacity: 0,
  },
  root: {
    width: "100%",
    // height: 300,
    "&:hover $displayOnHover": {
      opacity: 1,
    },
  },
}));

export const CircularChartMini = ({
  chartDataInsights,
  titleOverChart,
  labelInsideChart,
  showNeedle,
  value, //value for needle to point at
  showChartScale,
  reverseChartDirection, //for NPS
}) => {
  const classes = useStyles();
  //chart dimensions
  const innerRadius = 120;
  const outerRadius = 150;

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      {titleOverChart && (
        <h2
          style={{
            fontSize: "1rem",
            fontWeight: "normal",
            textAlign: "center",
            margin: 0,
          }}
        >
          {titleOverChart}
        </h2>
      )}
      <Grid
        id={`NPS${titleOverChart}`}
        container
        justifyContent="space-around"
        alignItems="center"
        height="300px"
        className="grid-98"
      >
        <ResponsiveContainer width={"100%"}>
          <PieChart>
            <Pie
              overflow="hidden"
              data={chartDataInsights}
              dataKey="value"
              isAnimationActive={false}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              startAngle={reverseChartDirection ? 180 : 0}
              endAngle={reverseChartDirection ? 0 : 180}
              cornerRadius={25}
              paddingAngle={-10}
              labelLine={false}
              label={(x) =>
                renderCustomizedLabel({
                  ...x,
                  labelInsideChart,
                  showChartScale,
                  showNeedle,
                  innerRadius,
                  outerRadius,
                  value,
                })
              }
            >
              {chartDataInsights.map(function addInsightColor(insight, index) {
                return <Cell key={index} fill={insight.color} />;
              })}
            </Pie>
            {/* <Tooltip content={<CustomTooltip />} /> */}
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
