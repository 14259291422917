import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllBranchesWithoutPagination = () => {
  const queryData = {
    params: {
      no_pagination: true,
    },
  };
  const requestURL = `${API_URL}/branches`;

  return melior.get(requestURL, queryData);
};

export const useGetAllBranchesWithoutPagination = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["all-branches-without-pagination"],
    queryFn: () => getAllBranchesWithoutPagination(),
    keepPreviousData: true,
  });
};