import { Grid, Typography, Button, Select, MenuItem, Box } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useContext } from "react";
import { useDeactivateUserProfile } from "../../api";

import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { NotificationComponentUI } from "../../../../reusable/components/NotificationComponentUI";
import {
  ChangePasswordSection,
  FacilityInformationSection,
  MemberInformationSection,
} from "./index";
import { PlaformsSection } from "./PlatformsSection";

export const Profile = () => {
  const { fireNotification } = useContext(NotificationContext);
  const { isFacilityManager } = useContext(AuthenticationContext);

  const deactivateUserProfileMutation = useDeactivateUserProfile();

  //deactivate profile handler
  const deactivateProfileHandler = () => {
    deactivateUserProfileMutation.mutateAsync().catch((error) =>
      fireNotification({
        title: "An error occurred",
        description: "please, try agarin later",
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.everyone,
      })
    );
  };
  return (
    <Box display="flex" flexDirection="column" gap="20px">
      {isFacilityManager && <FacilityInformationSection />}
      <MemberInformationSection />
      <ChangePasswordSection />
      {isFacilityManager && <PlaformsSection />}
      {/* Languages */}
      <Grid
        container
        justifyContent="flex-start"
        direction="column"
        padding="20px 0px"
        borderBottom={`1px solid ${primaryColors.gray[200]}`}
        gap="6px"
      >
        <Typography
          sx={{
            color: primaryColors.gray[700],
            fontWeight: fontWeightVariations.medium,
            fontSize: fontSizeVariations["text-sm"],
          }}
        >
          Languages
        </Typography>
        <Select
          id="language-select"
          value={"en"}
          inputProps={{ readOnly: true }}
          // onChange={handleChange}
          sx={{
            width: { sm: "100%", lg: "320px", md: "320px", xl: "320px" },
            borderRadius: "8px",
            color: primaryColors.gray[700],
          }}
        >
          <MenuItem value={"en"}>English</MenuItem>
        </Select>
      </Grid>
      {/* Deactivate Profile */}
      <Grid
        container
        justifyContent="flex-start"
        alignItems="flex-start"
        direction="column"
        sx={{
          padding: "20px 0px",
          borderBottom: `1px solid ${primaryColors.gray[200]}`,
          gap: "28px",
        }}
      >
        <Button
          onClick={deactivateProfileHandler}
          disabled={
            deactivateUserProfileMutation.isLoading ||
            deactivateUserProfileMutation.status === "success"
          }
          sx={{
            padding: "8px 14px",
          }}
        >
          <Typography
            sx={{
              color:
                deactivateUserProfileMutation.isLoading ||
                deactivateUserProfileMutation.status === "success"
                  ? primaryColors.gray[300]
                  : primaryColors.error[600],
              fontWeight: fontWeightVariations.bold,
              fontSize: fontSizeVariations["text-sm"],
              fontFamily: "Nunito",
              textTransform: "initial",
            }}
          >
            Deactivate my profile
          </Typography>
        </Button>
        {/* warning msg */}
        <Box
          borderRadius="8px"
          border={`1px solid ${primaryColors.warning[300]}`}
          padding="12px"
          width="95%"
        >
          <NotificationComponentUI
            title={"This can be temporary."}
            description={
              "You will receive an email to confirm your decision. This is not end your subscription or payments and the facility will continue to be charged.\n\n If you need to delete your facility account, please send us an email at info@mymelior.com. We’ll take care of it for you. Thanks! "
            }
            type={NOTIFICATIONS_TYPE.info}
          />
        </Box>
      </Grid>
    </Box>
  );
};
