import { ScreenContainer } from "../../../layout/ScreenContainer";
import { LinkGenerator } from "../components";

export const LinkGeneratorScreen = () => {
  return (
    <ScreenContainer>
      <LinkGenerator />
    </ScreenContainer>
  );
};
