import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const updateComplaintResolveStatus = ({ complaintID, resolved }) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/resolve`;

  return melior.patch(requestURL, {
    resolved: resolved,
  });
};

export const useUpdateComplaintResolveStatus = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["complaints"], {
        type: "active",
      });queryClient.invalidateQueries(["complaint-activity-logs"], {
        type: "active",
      });
      queryClient.invalidateQueries(["reviews-with-complaints"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: updateComplaintResolveStatus,
  });
};
