import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import { getNavigatableSectionSlug } from "../../../../helpers/General";
import {
  BarChart3D,
  NPSChart,
  NPSVerticalBarChart,
  PaginatedRepresentation,
  PieChartMultipleValues,
  PieChartPercentage,
  RUN_CHART_TYPES,
  RunChart3D,
  StackedBarChart,
  VerticalBarChart,
} from "../../../../reusable/components/charts";
import { ColorsLegend } from "../../../../reusable/components/ColorsLegend";

const ExternalProgressView = (props) => {
  return (
    <Grid container direction="column">
      {props?.data?.questions?.map((question) => (
        <Box
          id={getNavigatableSectionSlug(question.question_text)}
          width="100%"
        >
          <Grid item sm={12} key={question.text}>
            <PaginatedRepresentation
              title={question.text}
              subTitle={question.question_text}
              representationNames={question.representations.map(
                (representation) => representation.name
              )}
            >
              {question?.representations?.map((representation) => {
                if (representation.name.includes("RC")) {
                  return representation.name === "RC3S" ? (
                    <RunChart3D
                      key={`${question.text}${representation.name}`}
                      chartType={RUN_CHART_TYPES.RC3S}
                      data={representation.branches.data.map(
                        function prepareDataForNewYAxis(branchData) {
                          return {
                            ...branchData,
                            points: branchData.points.map(
                              function alterPointData(pointData) {
                                return {
                                  ...pointData,
                                  avgForTooltip: pointData.avg,
                                  avg: pointData.avg + 2,
                                };
                              }
                            ),
                          };
                        }
                      )}
                      scale={representation.branches.scale.map(
                        function setYAxisTickPosition(scaleData) {
                          return {
                            ...scaleData,
                            yAxisPosition: scaleData.weight + 2,
                          };
                        }
                      )}
                      question={question.text}
                    />
                  ) : (
                    <RunChart3D
                      key={`${question.text}${representation.name}`}
                      chartType={RUN_CHART_TYPES.RC5S}
                      data={representation.branches.data}
                      scale={representation.branches.scale}
                      question={question.text}
                    />
                  );
                }
                if (representation.name === "BC") {
                  return (
                    <BarChart3D
                      key={`${question.text}${representation.name}`}
                      data={representation.branches}
                      question={question.text}
                    />
                  );
                }
                if (representation.name === "VBC") {
                  return (
                    <VerticalBarChart
                      key={`${question.text}${representation.name}`}
                      data={representation.branches}
                      question={question.text}
                    />
                  );
                }
                if (representation.name === "SBC") {
                  return (
                    <Grid>
                      <ColorsLegend colors={representation.branches.colors} />

                      <StackedBarChart
                        key={`${question.text}${representation.name}`}
                        data={representation.branches}
                        question={question.text}
                      />
                    </Grid>
                  );
                }
                if (representation.name === "PCMV") {
                  return (
                    <Grid
                      // className={classes.section}
                      columns={12}
                      container
                      direction="row"
                      key={`${question.text}${representation.name}`}
                    >
                      <ColorsLegend colors={representation.branches.colors} />
                      {representation.branches.data.map((branch) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            lg={3}
                            key={`PCMV${branch.name}`}
                          >
                            <>
                              <PieChartMultipleValues
                                key={`${question.text}${representation.name}${branch.name}`}
                                data={branch.data}
                                title={branch.name}
                                colors={representation.branches.colors}
                                question={question.text}
                              />
                            </>
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                }
                if (representation.name === "PCP") {
                  return (
                    <Grid
                      // className={classes.section}
                      columns={12}
                      container
                      direction="row"
                      key={`${question.text}${representation.name}`}
                    >
                      {representation.branches.map((branch) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={3}
                            lg={3}
                            key={`PCP${branch.name}`}
                          >
                            <PieChartPercentage
                              key={`${question.text}${representation.name}${branch.name}`}
                              data={branch.data}
                              title={branch.name}
                              question={question.text}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>
                  );
                }
                if (representation.name === "NPS") {
                  return [
                    <Grid
                      // className={classes.section}
                      container
                      direction="row"
                      key={`${question.text}${representation.name}`}
                    >
                      {representation.branches.map((branch) => {
                        return (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            lg={6}
                            key={`NPS${branch.name}`}
                          >
                            <NPSChart
                              key={`${question.text}${representation.name}${branch.name}`}
                              data={branch.data}
                              title={branch.name}
                              question={question.text}
                            />
                          </Grid>
                        );
                      })}
                    </Grid>,
                    <NPSVerticalBarChart
                      key={`${question.text}${representation.name}VBC`}
                      data={representation.branches}
                      question={question.text}
                    />,
                  ];
                }
                return null;
              })}
            </PaginatedRepresentation>
          </Grid>
        </Box>
      ))}
    </Grid>
  );
};

export default ExternalProgressView;
