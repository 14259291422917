import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { primaryColors } from "../../../../helpers/customColors";
import { useState } from "react";

export const FormImageInput = ({
  name,
  width = undefined,
  resetImagePreview,
}) => {
  const { control, setValue } = useFormContext();
  const [imagePreview, setImagePreview] = useState();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          gap={"20px"}
        >
          {/* Image Preview */}
          <Grid
            container
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            <div
              style={{
                width: "250px",
                maxWidth: "100%",
              }}
            >
              <img
                style={{ maxWidth: "100%" }}
                alt="Your facility logo"
                src={value instanceof File ? imagePreview : value}
              />
            </div>
          </Grid>

          {/* Image Input */}
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
          >
            <input
              accept="image/*"
              id="raised-button-file"
              type="file"
              style={{ display: "none" }}
              onChange={(event) => {
                setImagePreview(URL.createObjectURL(event.target.files[0]));
                setValue(name, event.target.files[0], {
                  shouldTouch: true,
                  shouldDirty: true,
                });
              }}
            />
            <label htmlFor="raised-button-file">
              <Button
                variant="contained"
                component="span"
                sx={{
                  backgroundColor: primaryColors.warning[500],
                  padding: "10px 16px",
                  borderRadius: "8px",
                  width: "100px",
                }}
              >
                <Typography
                  sx={{
                    color: primaryColors.base.white,
                    textTransform: "capitalize",
                    fontSize: fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                >
                  Upload
                </Typography>
              </Button>
            </label>
          </Grid>
        </Grid>
      )}
    />
  );
};
