import { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import Mic from "@mui/icons-material/Mic";
import Stop from "@mui/icons-material/Stop";
import Play from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";
import Rerecord from "@mui/icons-material/Replay";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Send from "@mui/icons-material/Send";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { useContext } from "react";

const MicRecorder = require("mic-recorder-to-mp3");
const recorder = new MicRecorder({
  bitRate: 128,
});

export const VoiceNoteLayout = ({ language, layoutProps, submitAnswer }) => {
  const [recording, setRecording] = useState({
    isRecording: false,
    recordingFile: null,
  });
  const [playing, setPlaying] = useState({
    isPlaying: false,
    playFile: null,
  });
  const [recordDuration, setRecordDuration] = useState(0);

  const { fireNotification } = useContext(NotificationContext);

  const startRecording = () => {
    if (recording.isRecording === false) {
      // start recording
      recorder
        .start()
        .then(() => {
          setRecording({ isRecording: true, recordingFile: null });
        })
        .catch(() => {
          setRecording({ isRecording: false, recordingFile: null });
          fireNotification({
            title:
              "We couldn't start the recorder, Make sure mic access is granted from your browser",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        });
    }
  };
  const stopRecording = () => {
    if (recording.isRecording === true) {
      recorder
        .stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const file = new File(buffer, "voice_recording", {
            type: blob.type,
            lastModified: Date.now(),
          });
          setRecording({ isRecording: false, recordingFile: file });
        })
        .catch(() => {
          fireNotification({
            title: "We could not retrieve your recording, Please try again",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        });
    }
  };
  const playRecord = () => {
    if (!recording.recordingFile) return;
    if (!playing.isPlaying) {
      const player = new Audio(URL.createObjectURL(recording.recordingFile));
      player.play();
      setPlaying({ isPlaying: true, playFile: player });
    }
  };
  const pauseRecord = () => {
    if (!playing.playFile) return;
    if (playing.isPlaying) {
      playing.playFile.pause();
      setPlaying({ ...playing, isPlaying: false });
    }
  };
  const reRecord = () => {
    if (playing.isPlaying) pauseRecord();

    if (playing.playFile) {
      setPlaying({ isPlaying: false, playFile: null });
    }
    setRecordDuration(0);
    setRecording({ isRecording: false, recordingFile: null });
  };
  const encodeRecording = (record = recording.recordingFile) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      reader.onload = function (event) {
        var data = event.target.result.split(",");
        resolve(data[1]);
      };
      reader.readAsDataURL(record);
    });
  };

  const formatRecordDuration = (seconds) => {
    const date = new Date(0); // Create a date object with the initial epoch time
    date.setSeconds(seconds); // Set the seconds value

    const minutes = date.getUTCMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const formattedSeconds =
      date.getUTCSeconds() < 10
        ? `0${date.getUTCSeconds()}`
        : `${date.getUTCSeconds()}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  };

  const submitRecordHandler = () => {
    encodeRecording()
      .then((encodedRecord) => {
        submitAnswer(encodedRecord); //send record
      })
      .catch((error) => {
        fireNotification({
          title: "We could not retrieve your recording, Please try again",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
      });
  };

  useEffect(() => {
    let interval;
    if (recording.isRecording) {
      interval = setInterval(() => {
        setRecordDuration((prevSeconds) => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(interval);
      //   if submit icon is hiden then submit the recording once the file is saved
      if (recording.recordingFile && layoutProps?.removeSubmitIcon)
        submitRecordHandler();
    }
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording]);

  return (
    <Grid
      container
      display="flex"
      flex-direction="row"
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      height="58px"
      borderRadius="5px"
      bgcolor={primaryColors.gray[50]}
    >
      <Grid item xs={2}>
        {!recording.recordingFile ? (
          <Button onClick={!recording.isRecording ? startRecording : null}>
            {!recording.isRecording ? <Mic /> : <RecordVoiceOver />}
          </Button>
        ) : (
          <Button
            sx={{ width: "38px", height: "auto" }}
            onClick={!playing.isPlaying ? playRecord : pauseRecord}
          >
            {!playing.isPlaying ? <Play /> : <Pause />}
          </Button>
        )}
      </Grid>
      <Grid item xs={4} display="flex" gap="5%" paddingX="5%">
        {formatRecordDuration(recordDuration)}
        {recording.recordingFile && (
          <Rerecord
            sx={{
              width: "24px",
              height: "auto",
              color: secondaryColors.blueSecondary[500],
            }}
            onClick={reRecord}
          />
        )}
      </Grid>
      <Grid item xs={2}>
        {recording.isRecording && !recording.recordingFile && (
          <Stop
            sx={{
              width: "38px",
              height: "auto",
              color: secondaryColors.blueSecondary[500],
            }}
            onClick={stopRecording}
          />
        )}
        {!recording.isRecording &&
          recording.recordingFile &&
          !layoutProps?.removeSubmitIcon && (
            <Send
              sx={{
                width: "24px",
                height: "auto",
                color: secondaryColors.blueSecondary[500],
              }}
              onClick={submitRecordHandler}
            />
          )}
      </Grid>
    </Grid>
  );
};
