import { useEffect, useState } from "react";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import { RunChartCheckbox } from "./RunChartCheckbox";

const useStyles = makeStyles(() => ({
  tooltipParagraph: {
    margin: 0,
    padding: "2px",
    color: COLORS.brightBlack,
    fontSize: "14px",
  },
}));

export const RunChart3D = ({ chartType, data, scale }) => {
  const classes = useStyles();

  const [processedData, setProcessedData] = useState(null);
  const [dataForChart, setDataForChart] = useState(null);
  const [branches, setBranches] = useState(null);
  const [checkedBranches, setCheckedBranches] = useState(null);

  const CustomTooltip = (props) => {
    let tooltip = [];
    if (!!props?.payload[0]?.payload) {
      tooltip.push(
        <p
          key={props?.payload[0]?.payload?.date}
          className={classes.tooltipParagraph}
        >
          Date: {props?.payload[0]?.payload?.date}
        </p>
      );
      checkedBranches.map((branch) => {
        const avgValue =
          chartType === RUN_CHART_TYPES.RC3S
            ? props?.payload[0]?.payload[`${branch.name}-tooltip`]
            : props?.payload[0]?.payload[branch.name];
        return tooltip.push(
          <p
            key={branch.name}
            className={classes.tooltipParagraph}
            style={{ color: branch.color }}
          >
            {branch.name}:{" "}
            {!!props?.payload[0]?.payload[branch.name]
              ? `Average: ${avgValue} out of ${
                  props?.payload[0]?.payload[`${branch.name}-total`]
                } entries`
              : "0 entries"}
          </p>
        );
      });
    }
    return tooltip;
  };

  const getRandomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}aa`;
  useEffect(() => {
    const processData = () => {
      let processedData = {};
      let branches = [];
      data.map((branch) => {
        branches.push({
          name: branch.name,
          color: branch.color ?? getRandomColor(),
          id: branch.id,
        });
        return branch.points?.map((questionAverage) => {
          let date = moment(
            questionAverage["review__submitted_at__date"]
          ).format("M/D/YY");
          if (!!processedData[date]) {
            processedData[date][`${branch.name}-total`] =
              questionAverage.entries_count;

            //the actual value(in case of RC3S)
            if (chartType === RUN_CHART_TYPES.RC3S)
              processedData[date][`${branch.name}-tooltip`] =
                questionAverage.avgForTooltip.toFixed(1);

            return (processedData[date][branch.name] = // value used for representation on the modified y axis(in case of RC3S)
              questionAverage.avg.toFixed(1));
          }
          return (processedData[date] = {
            [branch.name]: questionAverage.avg.toFixed(1),
            [`${branch.name}-total`]: questionAverage.entries_count,
            [`${branch.name}-tooltip`]:
              chartType === RUN_CHART_TYPES.RC3S
                ? questionAverage.avgForTooltip.toFixed(1)
                : undefined,
          });
        });
      });
      setBranches(branches);
      setCheckedBranches(branches);
      setProcessedData(processedData);
    };
    if (!!data?.length) processData();
  }, [data]);

  useEffect(() => {
    const processData = () => {
      let finalData = [];
      Object.keys(processedData).map((recordDate) =>
        finalData.push({ date: recordDate, ...processedData[recordDate] })
      );
      finalData.sort((objectWithDate1, objectWithDate2) =>
        moment(objectWithDate1.date).isBefore(moment(objectWithDate2.date))
          ? -1
          : 1
      );
      setDataForChart(finalData);
    };
    if (!!processedData) processData();
  }, [processedData]);

  const formatYAxis = (value) => {
    let choice;
    if (chartType === RUN_CHART_TYPES.RC3S)
      choice = scale.filter(
        (scale) => parseInt(scale.yAxisPosition) === value
      )[0];
    else choice = scale.filter((scale) => parseInt(scale.weight) === value)[0];
    return choice?.text;
  };
  return (
    <>
      {!!dataForChart?.length && (
        <span>
          <ResponsiveContainer
            width="100%"
            height={chartType === RUN_CHART_TYPES.RC3S ? 200 : 300}
          >
            <LineChart
              margin={{
                top: 20,
                right: 100,
                bottom: 40,
                left: 20,
              }}
              data={dataForChart}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" />
              <YAxis
                width={110}
                tickFormatter={formatYAxis}
                ticks={scale?.map((scale) => {
                  if (chartType === RUN_CHART_TYPES.RC3S) {
                    return scale.yAxisPosition;
                  } else return parseInt(scale.weight);
                })}
                tickCount={scale.length + 1}
                type="number"
              />
              <Tooltip content={<CustomTooltip />} />
              {checkedBranches.map((question) => (
                <Line
                  strokeWidth={2}
                  key={`linefor${question.name}`}
                  type="monotone"
                  dataKey={question.name}
                  stroke={question.color}
                />
              ))}
            </LineChart>
          </ResponsiveContainer>

          {/* Checkboxes */}
          <Box width="fit-content" mx="auto">
            {!!dataForChart?.length &&
              branches.map((branch) => (
                <div
                  key={`chartCheckBoxfor${branch.name}`}
                  style={{ textAlign: "center", display: "inline-block" }}
                >
                  {/* //TODO: here're the check boxes of branches */}
                  {/* //TODO: reflect selected branches or divisions from insights filters here */}
                  <RunChartCheckbox
                    color={branch.color}
                    checked={
                      !!checkedBranches.filter(
                        (checkedBranch) => checkedBranch.name === branch.name
                      ).length
                    }
                    uncheck={() =>
                      setCheckedBranches(
                        checkedBranches?.filter(
                          (checkedBranches) =>
                            checkedBranches.name !== branch.name
                        )
                      )
                    }
                    check={() =>
                      setCheckedBranches([...checkedBranches, branch])
                    }
                    label={branch.name}
                    disabled={
                      !!checkedBranches.filter(
                        (checkedBranch) => checkedBranch.name === branch.name
                      ).length && checkedBranches.length === 1
                    }
                    //   quickNavLink={
                    //     <Link to={`/branches/${branch.id}/progress`} target="_blank">
                    //       <IconButton
                    //         title={`View Progress Data for ${branch.name} Branch`}
                    //         aria-label="view branch progress data"
                    //       >
                    //         <OpenInNew />
                    //       </IconButton>
                    //     </Link>
                    //   }
                  />
                </div>
              ))}
          </Box>
        </span>
      )}
    </>
  );
};

export const RUN_CHART_TYPES = {
  RC3S: "RC3S",
  RC5S: "RC5S",
};
