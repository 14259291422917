import * as yup from "yup";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import AddIcon from "@mui/icons-material/Add";
import {
  GenericDialog,
  SUBMIT_BUTTON_TYPES,
} from "../../../../reusable/components/GenericDialog";
import { IconButton } from "@mui/material";
import { useUpdateBranch } from "../../api/branches-divisions/updateBranch";
import { primaryColors } from "../../../../helpers/customColors";
import { BranchForm } from "./BranchForm";
import { useCreateBranchDivision } from "../../api/branches-divisions/createBranchDivision";
import { useEffect } from "react";
import { useMemo } from "react";

export const EditBranchDialog = ({ branchData, maxDivisionsLimit }) => {
  const validationSchema = yup.object({
    name: yup.string().required("Required"),
    // divisions: yup.array().of(yup.object().shape({ name: yup.string() })),
  });

  const editBranchFormDefaultValues = useMemo(() => {
    return {
      ...branchData,
      divisions: branchData.divisions.map(function addDatabaseIDForEachDivision(
        divisionObject
      ) {
        return {
          ...divisionObject,
          id: undefined,
          idDB: divisionObject.id,
        };
      }),
    };
  }, [branchData]);

  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: editBranchFormDefaultValues,
  });
  const { reset, handleSubmit, control, watch } = reactHookFormMethods;

  const updateBranchMutation = useUpdateBranch();
  const createBranchDivisionMutation = useCreateBranchDivision();

  const branchName = watch("name");

  useEffect(
    function resetFormUponChangeInBranchData() {
      reset({ ...editBranchFormDefaultValues });
    },
    [branchData, editBranchFormDefaultValues, reset]
  );

  return (
    <GenericDialog
      dialogTitle="Edit Branch"
      dialogSubtitle={
        "Make it easier by adding the division as well. That way, everything will be in one place and more convenient for you."
      }
      maxWidth="xs"
      triggerButton={
        <IconButton
          aria-label="delete"
          color="primary"
          title={"Add/Edit Divisions | Edit Branch"}
          sx={{
            bgcolor: primaryColors.brand[500],
            color: primaryColors.base.white,
            borderRadius: 2,
            ":hover": {
              bgcolor: primaryColors.brand[500],
              color: primaryColors.base.white,
            },
          }}
        >
          <AddIcon />
        </IconButton>
      }
      triggerButtonDisplay="inline"
      submitButtonType={SUBMIT_BUTTON_TYPES.submit}
      disableSubmitCondition={branchName === ""}
      submitButtonText={"Save Edits"}
      onSubmitCallback={handleSubmit((updatedBranchData) => {
        const divisionsToCreate = [];

        const updatedBranchReadyToSubmit = {
          id: updatedBranchData.id,
          name: updatedBranchData.name,
          divisions: updatedBranchData.divisions
            .filter(function filterOutNewDivision(divisionObjectForRHK) {
              if (divisionObjectForRHK.idDB === undefined) {
                divisionsToCreate.push({ name: divisionObjectForRHK.name });
                return false;
              } else {
                return true;
              }
            })
            .map(function mapDivisionBackToOriginalForm(divisionObjectForRHK) {
              return {
                ...divisionObjectForRHK,
                idDB: undefined,
                id: divisionObjectForRHK.idDB,
              };
            }),
        };
        updateBranchMutation.mutateAsync(updatedBranchReadyToSubmit);
        divisionsToCreate.forEach(function createDivisionHandler(
          divisionToCreate
        ) {
          createBranchDivisionMutation.mutateAsync({
            branchID: updatedBranchData.id,
            branchName: updatedBranchData.name,
            newDivisionName: divisionToCreate.name,
          });
        });
        // invitationHandler(invitationData);
      })}
      closeButtonText={"Cancel"}
      isSubmitting={updateBranchMutation.isLoading}
      isDone={
        updateBranchMutation.isSuccess && createBranchDivisionMutation.isSuccess
      }
    >
      <BranchForm
        control={control}
        reactHookFormMethods={reactHookFormMethods}
        maxDivisionsLimit={maxDivisionsLimit}
        editMode
      />
    </GenericDialog>
  );
};
