import { API_URL, melior } from "../../../../helpers/constants";

export const changeUserPassword = (passwordData) => {
  let requestURL = `${API_URL}/password/change/`;
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, passwordData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
