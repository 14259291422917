import { useState } from "react";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import CopyToClipboard from "react-copy-to-clipboard";
import { COLORS } from "../../helpers/Colors";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const useStyles = makeStyles(() => ({
  copyIcon: {
    margin: "5px",
    borderRadius: "5px",
  },
  activeCopyIcon: {
    margin: "5px",
    borderRadius: "5px",
    color: COLORS.successGreen,
  },
}));

const CopyTextToClipboard = ({ text }) => {
  const classes = useStyles();
  const [clicked, setClicked] = useState(false);
  const setClick = () => setClicked(true);

  return (
    <CopyToClipboard
      style={{
        padding: 10,
      }}
      text={text}
    >
      <IconButton
        className={clicked ? classes.activeCopyIcon : classes.copyIcon}
        size="small"
        onClick={setClick}
      >
        <FileCopyIcon />
      </IconButton>
    </CopyToClipboard>
  );
};

export default CopyTextToClipboard;
