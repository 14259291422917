import { useCallback, useReducer } from "react";

export const useListAPIsStateManagement = (props) => {
  const { initialState } = props;

  const [apiListArgsState, dispatchAPIListArgsState] = useReducer(
    updateAPIArgsStateReducer,
    { ...defaultListState, ...initialState }
  );

  //   const debouncedSearchText = useDebouncedValue(apiListArgsState.q);

  const updatePageHandler = useCallback((newPage) => {
    if (!isNaN(newPage))
      dispatchAPIListArgsState({
        type: "UPDATE_PAGE",
        payload: newPage,
      });
  }, []);

  const initiateSearchHandler = ({ type, value }) =>
    dispatchAPIListArgsState({
      type: "UPDATE-SEARCH-DATA",
      payload: { search_type: type, search_text: value },
    });

  function resetSearchFilterState() {
    dispatchAPIListArgsState({
      type: "RESET_SEARCH_DATA",
    });
  }

    const updatePageSizeHandler = (pageSizeArg) =>
      dispatchAPIListArgsState({
        type: "UPDATE_PAGE_SIZE",
        payload: pageSizeArg,
      });

  return {
    apiListArgsState,
    // debouncedSearchText,
    dispatchAPIListArgsState,
    updatePageHandler,
    initiateSearchHandler,
    resetSearchFilterState,
    updatePageSizeHandler,
    // searchQueryHandler,
  };
};

const updateAPIArgsStateReducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_PAGE": {
      return {
        ...state,
        page: action.payload,
      };
    }
    case "UPDATE-SEARCH-DATA":
      return {
        ...state,
        search_type: action.payload.search_type,
        search_text: action.payload.search_text,
        page: 1,
      };
    case "RESET_SEARCH_DATA":
      return {
        ...state,
        search_type: defaultListState.search_type,
        search_text: defaultListState.search_text,
        page: 1,
      };
    case "UPDATE_PAGE_SIZE": {
      return {
        ...state,
        pageSize: action.payload,
        page: 1,
      };
    }
    case "ADDITIONAL_ACTIONS": {
      return {
        ...state,
        ...action.additionalPayload,
        page: 1,
      };
    }
    default: {
      return state;
    }
  }
};

const defaultListState = {
  page: 1,
  pageSize: 10,
  search_text: "",
  search_type: "",
};
