import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from "@mui/material";
import { DateRangePickerInput } from "../../../../reusable/components/DateRangePickerInput";
import { ColoredMultiSelectWithInfo } from "../../../../reusable/components/form-inputs/uncontrolled/ColoredMultiSelectWithInfo";
import moment from "moment";
import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  brandsColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import {
  complaintStatus,
  overallViewSelecterFiltersInitialState,
} from "./useOverallViewFiltersState";
import { useGetAllBranchesWithoutPagination } from "../../../settings/api/branches-divisions/getAllBranchesWithoutPagination";
import { useGetAllQuestions } from "../../api/reviews/getAllQuestions";
import { useGetListOfFacilityUsersToMention } from "../../api/getListOfFacilityUsersToMention";
import {
  ReputationResponsePlatforms,
  ReputationResponseStatuses,
} from "../../types/reputation-reponses";
import { renderReputationResponseStars } from "../reputation-responses/ReputationResponsesTablePage";
import { useGetTeams } from "../../api/getTeams";

function MyListSubheader(props) {
  return <ListSubheader sx={{ fontWeight: "bold", fontSize: "16px" }} />;
}

MyListSubheader.muiSkipListHighlight = true;

const useStyles = makeStyles((theme) => ({
  selectMenuPaper: {
    maxHeight: 400,
  },
}));

export const Filtration = ({
  dateRangeFilter,
  channelsFilter,
  questionsFilter,
  branchesAndDivisionsFilter,
  branchesOnlyFilter,
  withCommentFilter,
  complaintStatusFilter,
  reputationResponseStatusFilter,
  complaintAssigneesFilter,
  archivedFilter,
  bookmarkedFilter,
  reputationResponsePlatformFilter,
  reputationResponseRatingFilter,
  relatedTeamsFilter,
}) => {
  const classes = useStyles();

  const [
    branchesAndDivisionsFilterOptions,
    setBranchesAndDivisionsFilterOptions,
  ] = useState();

  const { data: allBranchesResponse, isLoading: isLoadingAllBranches } =
    useGetAllBranchesWithoutPagination();

  const { data: allQuestionsResponse } = useGetAllQuestions();

  const englishQuestions = allQuestionsResponse?.data.filter(
    function getEnglishQuestionsOnly(questionsLanguage) {
      return questionsLanguage.lang === "en";
    }
  )[0].questions;

  const { data: facilityUsersToMentionResponse } =
    useGetListOfFacilityUsersToMention({
      config: { enabled: complaintAssigneesFilter !== undefined },
    });

  const { data: relatedTeamsResponse } = useGetTeams({
    config: { enabled: relatedTeamsFilter !== undefined },
  });

  const defaultStartDate = overallViewSelecterFiltersInitialState.date_from;
  const selectedStartDate = dateRangeFilter?.selectedDateRange?.date_from;
  const selectedStartDateIsDefault = selectedStartDate === defaultStartDate;

  useEffect(
    function prepAndSetBranchesAndDivisionsFilterOptions() {
      if (allBranchesResponse && !isLoadingAllBranches) {
        const tempBranchesAndDivisionsFilterOptions = [];

        allBranchesResponse.data.forEach(function getBranchesAsFilterOptions(
          branchData
        ) {
          tempBranchesAndDivisionsFilterOptions.push({
            id: branchData.id,
            isBranch: true,
            name: branchData.name,
          });

          if (branchData.divisions.length > 0) {
            branchData.divisions.forEach(function getDivisionsAsFilterOptions(
              divisionData
            ) {
              tempBranchesAndDivisionsFilterOptions.push({
                id: divisionData.id,
                name: divisionData.name,
              });
            });
          }
        });

        setBranchesAndDivisionsFilterOptions([
          ...tempBranchesAndDivisionsFilterOptions,
        ]);
      }
    },
    [allBranchesResponse, isLoadingAllBranches]
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box display="flex" alignItems="center" gap={1} flexWrap="wrap">
        {/* Start: Date Range Filter */}
        <DateRangePickerInput
          key={1}
          callbackHandler={dateRangeFilter.onChange}
          resetDateHandler={dateRangeFilter.onReset}
          defaultDate={{
            startDate: dateRangeFilter?.selectedDateRange?.date_from
              ? moment(
                  dateRangeFilter.selectedDateRange.date_from,
                  "YYYY-MM-DD"
                ).toDate()
              : undefined,
            endDate: dateRangeFilter?.selectedDateRange?.date_to
              ? moment(
                  dateRangeFilter.selectedDateRange.date_to,
                  "YYYY-MM-DD"
                ).toDate()
              : undefined,
          }}
        />
        {/* End Date Range Filter */}

        {/* Start: Divisions Filter */}
        {branchesAndDivisionsFilter && (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
            key={2}
          >
            <InputLabel
              sx={{
                position: "absolute",
                top:
                  branchesAndDivisionsFilter.selectedDivisions.length === 0 &&
                  "-9px",
                fontSize: "0.9rem",
              }}
              id="select-divisions-label"
            >
              Select Divisions
            </InputLabel>
            {branchesAndDivisionsFilterOptions && (
              <Select
                labelId="select-divisions-label"
                sx={{ height: 35.5 }}
                multiple
                input={<OutlinedInput label={"Select Divisions"} />}
                value={branchesAndDivisionsFilter.selectedDivisions}
                renderValue={(selected) => {
                  return (
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {selected.length} Selected
                    </Typography>
                  );
                }}
                onChange={branchesAndDivisionsFilter.onChange}
                MenuProps={{ classes: { paper: classes.selectMenuPaper } }}
              >
                {branchesAndDivisionsFilterOptions.map(
                  function renderDivisionsOptionsGroup(branchDivOption, index) {
                    return (
                      <MenuItem
                        aria-invalid={branchDivOption.isBranch}
                        key={index}
                        value={
                          branchDivOption.isBranch ? "" : branchDivOption.id
                        }
                        sx={{
                          pointerEvents: branchDivOption.isBranch
                            ? "none"
                            : undefined,
                          paddingLeft: branchDivOption.isBranch ? undefined : 2,
                        }}
                      >
                        {!branchDivOption.isBranch && (
                          <Checkbox
                            size="small"
                            checked={branchesAndDivisionsFilter.selectedDivisions?.includes(
                              branchDivOption.id
                            )}
                          />
                        )}
                        <ListItemText
                          primary={branchDivOption.name}
                          sx={{
                            fontSize: "14px",
                            fontWeight: branchDivOption.isBranch ? "bold" : "",
                          }}
                        />
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            )}
          </FormControl>
        )}
        {/* End: Divisions Filter */}

        {/* Start: Questions Filter */}
        {questionsFilter && englishQuestions !== undefined && (
          <ColoredMultiSelectWithInfo
            key={3}
            label="Select Questions"
            labelSingular="Question"
            width={180}
            options={[
              {
                label: "Show All",
                value: "-",
                resetOption: true,
              },
              ...englishQuestions.map(function returnQuestionOption(
                questionData
              ) {
                return {
                  label: questionData.text,
                  value: questionData.id,
                };
              }),
            ]}
            selectedValues={questionsFilter?.selectedQuestions}
            onChangeHandler={questionsFilter?.onChange}
          />
        )}
        {/* End: Questions Filter */}

        {/* Start: Channels Filter */}
        {channelsFilter && (
          <ColoredMultiSelectWithInfo
            key={4}
            label="Select Channel"
            labelSingular="Channel"
            width={180}
            options={[
              {
                label: "Show All",
                value: channelsDict.All,
                resetOption: true,
              },
              { label: "On-Site", value: channelsDict["On-Site"] },
              { label: "Outbound", value: channelsDict.Outbound },
              {
                label: "Personalized Link",
                value: channelsDict["Personalized Link"],
              },
              { label: "QR Code", value: channelsDict["QR Code"] },
              { label: "General Link", value: channelsDict["General Link"] },
            ]}
            selectedValues={channelsFilter?.selectedChannels}
            onChangeHandler={channelsFilter?.onChange}
          />
        )}
        {/* End: Channels Filter */}

        {/* Start: With Comment Filter */}
        {withCommentFilter && (
          <FormControl
            key={5}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  withCommentFilter.withComment === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="with-comment-field-label"
            >
              Comment Filter
            </InputLabel>
            <Select
              label="Comment Filter"
              labelId="with-comment-field-label"
              id="with-comment-field"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={withCommentFilter.withComment}
              onChange={function updateWithCommentValueHandler(event) {
                withCommentFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={undefined}>Show All</MenuItem>
              <MenuItem value={true}>With Comment</MenuItem>
              <MenuItem value={false}>Without Comment</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: With Comment Filter */}

        {/* Start: Complaint Status Filter */}
        {complaintStatusFilter && (
          <FormControl
            key={6}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  complaintStatusFilter.status === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="complaint-status-filter-label"
            >
              Status
            </InputLabel>
            <Select
              label="Status"
              labelId="complaint-status-filter-label"
              id="complaint-status-filter"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={complaintStatusFilter.status}
              onChange={function updateComplaintStatusValueHandler(event) {
                complaintStatusFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={complaintStatus.All}>Show All</MenuItem>
              <MenuItem value={complaintStatus.Unattended}>Unattended</MenuItem>
              <MenuItem value={complaintStatus.Seen}>Seen</MenuItem>
              <MenuItem value={complaintStatus.Resolved}>Resolved</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: Complaint Status Filter */}

        {/* Start: Complaints Assignees */}
        {complaintAssigneesFilter !== undefined &&
          facilityUsersToMentionResponse?.data && (
            <ColoredMultiSelectWithInfo
              key={7}
              label="Select Assignees"
              labelSingular="Assignee"
              width={180}
              options={[
                {
                  label: "Show All",
                  value: "",
                  resetOption: true,
                },
                ...facilityUsersToMentionResponse.data.map(
                  function returnAssigneeOption(complaintAssigneeData) {
                    return {
                      label: complaintAssigneeData.username,
                      value: complaintAssigneeData.id,
                    };
                  }
                ),
              ]}
              selectedValues={complaintAssigneesFilter.selectedAssignees}
              onChangeHandler={complaintAssigneesFilter.onChange}
            />
          )}
        {/* End: Complaints Assignees */}

        {/* Start: Archived Filter */}
        {archivedFilter && (
          <FormControl
            key={8}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top: archivedFilter.archived === undefined ? "-9px" : undefined,
              }}
              id="archived-field-label"
            >
              Archived Status
            </InputLabel>
            <Select
              label="Archived Status"
              labelId="archived-field-label"
              id="archived-field"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={archivedFilter.archived}
              onChange={function updateArchivedValueHandler(event) {
                archivedFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={undefined}>Show All</MenuItem>
              <MenuItem value={true}>Archived</MenuItem>
              <MenuItem value={false}>Unarchived</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: Archived Filter */}

        {/* Start: Branches Only Filter */}
        {branchesOnlyFilter && (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
            key={9}
          >
            <InputLabel
              sx={{
                position: "absolute",
                top: branchesOnlyFilter.selectedBranches.length === 0 && "-9px",
                fontSize: "0.9rem",
              }}
              id="select-branches-label"
            >
              Select Branches
            </InputLabel>
            {branchesAndDivisionsFilterOptions && (
              <Select
                labelId="select-branches-label"
                sx={{ height: 35.5 }}
                multiple
                input={<OutlinedInput label={"Select Branches"} />}
                value={branchesOnlyFilter.selectedBranches}
                renderValue={(selected) => {
                  return (
                    <Typography sx={{ fontSize: "0.9rem" }}>
                      {selected.length} Selected
                    </Typography>
                  );
                }}
                onChange={branchesOnlyFilter.onChange}
                MenuProps={{ classes: { paper: classes.selectMenuPaper } }}
              >
                {branchesAndDivisionsFilterOptions
                  .filter(function isBranchOption(branchDivOption) {
                    return branchDivOption.isBranch === true;
                  })
                  .map(function renderDivisionsOptionsGroup(
                    branchOption,
                    index
                  ) {
                    return (
                      <MenuItem key={index} value={branchOption.id}>
                        <Checkbox
                          size="small"
                          checked={branchesOnlyFilter.selectedBranches?.includes(
                            branchOption.id
                          )}
                        />
                        <ListItemText
                          primary={branchOption.name}
                          sx={{
                            fontSize: "14px",
                          }}
                        />
                      </MenuItem>
                    );
                  })}
              </Select>
            )}
          </FormControl>
        )}
        {/* End: Branches Only Filter */}

        {/* Start: Bookmarked Filter */}
        {bookmarkedFilter && (
          <FormControl
            key={10}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  bookmarkedFilter.bookmarked === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="bookmarked-field-label"
            >
              Starred Status
            </InputLabel>
            <Select
              label="Starred Status"
              labelId="bookmarked-field-label"
              id="bookmarked-field"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={bookmarkedFilter.archived}
              onChange={function updateStarredValueHandler(event) {
                bookmarkedFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={undefined}>Show All</MenuItem>
              <MenuItem value={true}>Starred</MenuItem>
              <MenuItem value={false}>Not Starred</MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: Bookmarked Filter */}

        {/* Start: Reputation Response Platform Filter */}
        {reputationResponsePlatformFilter && (
          <FormControl
            key={11}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  reputationResponsePlatformFilter.platform === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="reputation-response-platform-field-label"
            >
              Platform
            </InputLabel>
            <Select
              label="Platform"
              labelId="reputation-response-platform-field-label"
              id="reputation-response-platform-field"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={reputationResponsePlatformFilter.platform}
              onChange={function updateReputationResponsePlatformValueHandler(
                event
              ) {
                reputationResponsePlatformFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={undefined}>Show All</MenuItem>
              <MenuItem value={ReputationResponsePlatforms.Google}>
                Google
              </MenuItem>
              <MenuItem value={ReputationResponsePlatforms.TripAdvisor}>
                Tripadvisor
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: Reputation Response Platform Filter */}

        {/* Start: Reputation Response Rating Filter */}
        {reputationResponseRatingFilter && (
          <FormControl
            key={12}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  reputationResponseRatingFilter.rating === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="reputation-response-rating-field-label"
            >
              Rating
            </InputLabel>
            <Select
              label="Rating"
              labelId="reputation-response-rating-field-label"
              id="reputation-response-rating-field"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={reputationResponseRatingFilter.rating}
              onChange={function updateReputationResponseRatingValueHandler(
                event
              ) {
                reputationResponseRatingFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={undefined}>Show All</MenuItem>
              <MenuItem value={0}>
                {renderReputationResponseStars(
                  0,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
              <MenuItem value={1}>
                {renderReputationResponseStars(
                  1,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
              <MenuItem value={2}>
                {renderReputationResponseStars(
                  2,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
              <MenuItem value={3}>
                {renderReputationResponseStars(
                  3,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
              <MenuItem value={4}>
                {renderReputationResponseStars(
                  4,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
              <MenuItem value={5}>
                {renderReputationResponseStars(
                  5,
                  ReputationResponsePlatforms.Google
                )}
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: Reputation Response Platform Filter */}

        {/* Start: ReputationResponse Status Filter */}
        {reputationResponseStatusFilter && (
          <FormControl
            key={13}
            sx={{ width: 170, position: "relative" }}
            focused={false}
          >
            <InputLabel
              sx={{
                position: "absolute",
                fontSize: "0.9rem",
                top:
                  reputationResponseStatusFilter.status === undefined
                    ? "-9px"
                    : undefined,
              }}
              id="reputation-response-status-filter-label"
            >
              Status
            </InputLabel>
            <Select
              label="Status"
              labelId="reputation-response-status-filter-label"
              id="reputation-response-status-filter"
              sx={{ height: "35px", fontSize: "0.9rem" }}
              value={reputationResponseStatusFilter.status}
              onChange={function updateReputationResponseStatusValueHandler(
                event
              ) {
                reputationResponseStatusFilter.onChange(event.target.value);
              }}
              autoWidth
            >
              <MenuItem value={ReputationResponseStatuses.All}>
                Show All
              </MenuItem>
              <MenuItem value={ReputationResponseStatuses.Unattended}>
                Unattended
              </MenuItem>
              <MenuItem value={ReputationResponseStatuses.Seen}>Seen</MenuItem>
              <MenuItem value={ReputationResponseStatuses.Answered}>
                Answered
              </MenuItem>
            </Select>
          </FormControl>
        )}
        {/* End: ReputationResponse Status Filter */}
        
        {/* Start: Related Teams Filter */}
        {relatedTeamsFilter !== undefined &&
          relatedTeamsResponse !== undefined &&
          relatedTeamsResponse.data && (
            <ColoredMultiSelectWithInfo
              key={14}
              label="Select Teams"
              labelSingular="Team"
              width={180}
              options={[
                {
                  label: "Show All",
                  value: "",
                  resetOption: true,
                },
                ...relatedTeamsResponse.data.map(function returnAssigneeOption(
                  relatedTeam
                ) {
                  return {
                    label: relatedTeam.name,
                    value: relatedTeam.id,
                  };
                }),
              ]}
              selectedValues={relatedTeamsFilter.selectedRelatedTeams}
              onChangeHandler={relatedTeamsFilter.onChange}
            />
          )}
        {/* End: Related Teams Filter */}

        <Box display="flex" alignItems="center" gap={2}>
          {/* Put Filter Badges Here */}
        </Box>
      </Box>
      <Box display="flex" flexWrap={"wrap"} gap={2}>
        {dateRangeFilter?.selectedDateRange?.date_from &&
          dateRangeFilter?.selectedDateRange?.date_to && (
            <Chip
              label={`From ${moment(
                dateRangeFilter?.selectedDateRange?.date_from
              ).format("DD/MMM, YYYY")} | To ${moment(
                dateRangeFilter?.selectedDateRange?.date_to
              ).format("DD/MMM, YYYY")}`}
              sx={{
                backgroundColor: secondaryColors.blue[50],
                color: secondaryColors.blue[700],
                ...fontSizeVariations["text-sm"],
                fontWeight: fontWeightVariations.medium,
              }}
              onDelete={
                selectedStartDateIsDefault
                  ? undefined
                  : function resetDateOnClick() {
                      dateRangeFilter?.resetDateRange();
                    }
              }
            />
          )}
        {branchesAndDivisionsFilter &&
          branchesAndDivisionsFilter.selectedDivisions &&
          branchesAndDivisionsFilter.selectedDivisions.length > 0 &&
          branchesAndDivisionsFilter.selectedDivisions[0] !== "" &&
          branchesAndDivisionsFilter.selectedDivisions.map(
            function renderFilterChipForDivision(divisionID, index) {
              return (
                <Chip
                  key={index}
                  label={
                    branchesAndDivisionsFilterOptions.find(
                      function getDivisionOptionByID(divisionOption) {
                        return (
                          !divisionOption.isBranch &&
                          +divisionOption.id === +divisionID
                        );
                      }
                    )?.name
                  }
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeBranchOnClick() {
                    branchesAndDivisionsFilter.removeSpecificDivision(
                      divisionID
                    );
                  }}
                />
              );
            }
          )}
        {questionsFilter &&
          questionsFilter.selectedQuestions &&
          questionsFilter.selectedQuestions.length > 0 &&
          questionsFilter.selectedQuestions[0] !== "" &&
          questionsFilter.selectedQuestions.map(
            function renderFilterChipForQuestion(selectedQuestionID, index) {
              return (
                <Chip
                  key={index}
                  label={`${englishQuestions
                    ?.find(function findQuestionByID(questionToFind) {
                      return +selectedQuestionID === +questionToFind.id;
                    })
                    ?.text.slice(0, 30)}...`}
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeQuestionOnClick() {
                    questionsFilter.removeSpecificQuestion(selectedQuestionID);
                  }}
                />
              );
            }
          )}
        {channelsFilter?.selectedChannels &&
          channelsFilter?.selectedChannels.length > 0 &&
          channelsFilter?.selectedChannels[0] !== "" &&
          channelsFilter?.selectedChannels.map(
            function renderFilterChipForChannel(selectedChannel, index) {
              return (
                <Chip
                  key={index}
                  label={channelKeyToLabel[selectedChannel]}
                  sx={{
                    backgroundColor: secondaryColors.blue[50],
                    color: secondaryColors.blue[700],
                    ...fontSizeVariations["text-sm"],
                    fontWeight: fontWeightVariations.medium,
                  }}
                  onDelete={function removeBranchOnClick() {
                    channelsFilter?.removeSpecificChannel(selectedChannel);
                  }}
                />
              );
            }
          )}
      </Box>
    </Box>
  );
};

export const channelsDict = {
  All: "",
  "On-Site": "on-site",
  Outbound: "outbound",
  "Personalized Link": "personalized-link",
  "QR Code": "qr-code",
  "General Link": "general-link",
};

export const channelKeyToLabel = {
  "on-site": "On-Site",
  outbound: "Outbound",
  "personalized-link": "Personalized Link",
  "qr-code": "QR Code",
  "general-link": "General Link",
};
