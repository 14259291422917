import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

export const triggerEmailUserMentionInReview = ({ reviewID, mentionText, mentionedUserID }) => {
  let requestURL = `${API_URL}/reviews/${reviewID}/mention`;

  return melior.post(requestURL, {
    text: mentionText,
    mentioned_user: mentionedUserID,
  });
};

export const useTriggerEmailForUserMentionInReview = ({ config } = {}) => {

  return useMutation({
    ...config,
    mutationFn: triggerEmailUserMentionInReview,
  });
};
