import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import { Avatar, Chip } from "@mui/material";
import {
  isBranchManager,
  isFacilityManager,
  isDivisionManager,
  isAppOnly,
  isFeedbackOnly,
} from "../../../helpers/user";
import { COLORS } from "../../../helpers/Colors";

const useStyles = makeStyles({
  chip: {
    fontFamily: "Avenir-Heavy",
    margin: "center",
  },
  avatar: {
    background: COLORS.white,
  },
  facilityManagerChip: {
    background: COLORS.facilityManagerChip,
  },
  branchManagerChip: {
    background: COLORS.branchManagerChip,
  },
  divisionManagerChip: {
    background: COLORS.divisionManagerChip,
  },
  appOnlyChip: {
    background: COLORS.appOnlyChip,
  },
  feedbackOnlyChip: {
    background: COLORS.feedbackOnlyChip,
  },
});

const RoleChip = ({ user }) => {
  const classes = useStyles();

  return (
    <Chip
      className={clsx(
        classes.chip,
        isBranchManager(user)
          ? classes.branchManagerChip
          : isFacilityManager(user)
          ? classes.facilityManagerChip
          : isDivisionManager(user)
          ? classes.divisionManagerChip
          : isAppOnly(user)
          ? classes.appOnlyChip
          : isFeedbackOnly(user)
          ? classes.feedbackOnlyChip
          : null
      )}
      label={user.role.replace("_", " ")}
      avatar={
        <Avatar className={classes.avatar}>{user.role[0].toUpperCase()}</Avatar>
      }
    />
  );
};

export default RoleChip;
