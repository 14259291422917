import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATION_WIDTH,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const attachFileToComplaint = ({ complaintID, formData }) => {
  const config = {
    headers: {
      "Content-Type": "multipart/form-data",
      //   ...formData.getHeaders(), // This is crucial for Axios to recognize it as form data
    },
  };

  let requestURL = `${API_URL}/complaints/${complaintID}/attach-file`;

  return melior.post(requestURL, formData, config);
};

export const useAttachFileToComplaint = ({ config, a } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["complaint-activity-logs"], {
        type: "active",
      });
      fireNotification({
        title: "Uploaded successfully",
        description: `File has been attached successfully to Complaint #${variables.complaintID}`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        target: NOTIFICATIONS_TARGET.admin,
        width: NOTIFICATION_WIDTH.small,
      });
    },
    onError: function fileAttachementToComplaintFailureHandler() {
      fireNotification({
        title: "Failed to upload",
        description: `Please try again later.`,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        target: NOTIFICATIONS_TARGET.admin,
        width: NOTIFICATION_WIDTH.small,
      });
    },
    ...config,
    mutationFn: attachFileToComplaint,
  });
};
