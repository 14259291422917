import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Archive, Unarchive } from "@mui/icons-material";
import { useUpdateComplaintArchivalState } from "../../api/complaints/updateComplaintArchivalState";

export const ArchiveComplaint = ({ complaintRecord }) => {
  const updateComplaintArchivalStateMutation =
    useUpdateComplaintArchivalState();

  return (
    <Tooltip
      title={complaintRecord.archived === true ? "Un-Archive" : "Archive"}
      placement="top"
    >
      {updateComplaintArchivalStateMutation.isLoading ? (
        <CircularProgress size="30px" />
      ) : (
        <IconButton
          sx={{ height: "50px", width: "40px" }}
          aria-label="star"
          onClick={(e) => {
            updateComplaintArchivalStateMutation.mutateAsync({
              complaintID: complaintRecord.id,
              newArchivalState: !complaintRecord.archived,
            });
            e.stopPropagation();
          }}
        >
          {complaintRecord.archived === true ? (
            <Unarchive
              sx={{
                //   color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          ) : (
            <Archive
              sx={{
                //   color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
