import { CircularProgress, IconButton, Tooltip } from "@mui/material";
import { Star, StarBorder } from "@mui/icons-material";
import { useToggleBookmarkStatus } from "../../api/complaints/toggleBookmarkStatus";
import { primaryColors } from "../../../../helpers/customColors";

export const StarComplaint = ({ complaintRecord }) => {
  const toggleBookmarkStatusMutation = useToggleBookmarkStatus();

  return (
    <Tooltip
      title={complaintRecord.bookmarked === true ? "Un-star" : "Star"}
      placement="top"
    >
      {toggleBookmarkStatusMutation.isLoading ? (
        <CircularProgress size="30px" />
      ) : (
        <IconButton
          sx={{ height: "50px", width: "40px" }}
          aria-label="star"
          onClick={(e) => {
            toggleBookmarkStatusMutation.mutateAsync({
              complaintID: complaintRecord.id,
              currentBookmarkStatus: complaintRecord.bookmarked,
            });
            e.stopPropagation();
          }}
        >
          {complaintRecord.bookmarked === true ? (
            <Star
              sx={{
                color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          ) : (
            <StarBorder
              sx={{
                color: primaryColors.brand[600],
                height: "30px",
                width: "32px",
              }}
            />
          )}
        </IconButton>
      )}
    </Tooltip>
  );
};
