import { API_URL, melior } from "../../../../helpers/constants";

export const giveFeedback = (feedbackData) => {
  let requestURL = `${API_URL}/give-feedback`;
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, {comment:feedbackData})
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
