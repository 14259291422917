import {
  Box,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import {
  UsersToMentionListMenu,
  getMentionUsernameTag,
  mentionedUsernameIsValid,
  startedMentioning,
} from "./UsersToMentionList";
import { primaryColors } from "../../../../helpers/customColors";
import { Send } from "@mui/icons-material";
import { useEffect, useState } from "react";

export const AddNewNote = ({
  mutationCallback,
  facilityUsersToMention,
  isSubmitting,
}) => {
  const {
    newNoteText,
    setNewNoteText,

    mentionedUserID,
    setMentionedUserID,

    showUsersToMentionListMenu,
    setShowUsersToMentionListMenu,
  } = useUsersToMentionListState({ facilityUsersToMention });

  function replacePartialMentionWithFullMention(facilityUser) {
    const mentionText = getMentionUsernameTag(newNoteText);

    const updatedNewNoteTextWithMentionedUser = newNoteText.replace(
      // To replace the mention text in case user wrote any, or replace the @ if it is what only exists
      mentionText !== "" ? mentionText : "@",
      `@${facilityUser.username} `
    );

    setNewNoteText(updatedNewNoteTextWithMentionedUser);
    setMentionedUserID(facilityUser.id);
  }

  function submitNote(newNoteText) {
    // Start: cancel @ char if the entered mention does not exists in the facility list of users
    let updatedNewNoteText = newNoteText;

    const mentionedUserText = getMentionUsernameTag(newNoteText);

    if (mentionedUserID === undefined) {
      updatedNewNoteText = newNoteText.replace(
        mentionedUserText,
        mentionedUserText.replace("@", "")
      );
    }
    // End: cancel @ char if the entered mention does not exists in the facility list of users

    mutationCallback({
      text: updatedNewNoteText,
      callbackOnSuccess: function clearNewNoteTextState() {
        setNewNoteText("");
      },
      mentionedUserID,
    });
  }

  const filteredFacilityUsers = facilityUsersToMention.filter(
    function matchFacilityUsersWithMentionText(facilityUser) {
      const mentionText = getMentionUsernameTag(newNoteText).replace("@", "");

      if (
        // facilityUser.name.toLowerCase().includes(mentionText.toLowerCase()) ||
        facilityUser.username.toLowerCase().includes(mentionText.toLowerCase())
      ) {
        return true;
      } else {
        return false;
      }
    }
  );

  return (
    <Box display="flex" flexDirection="column" gap={1} width="100%">
      <UsersToMentionListMenu
        showUsersToMentionListMenu={showUsersToMentionListMenu}
        setShowUsersToMentionListMenu={setShowUsersToMentionListMenu}
        filteredFacilityUsers={filteredFacilityUsers}
        replacePartialMentionWithFullMention={
          replacePartialMentionWithFullMention
        }
      />
      <FormControl
        sx={{
          width: "auto",
          bgcolor: primaryColors.base.white,
        }}
        variant="filled"
        //   color={primaryColors.base.black}
      >
        {/* <InputLabel htmlFor="outlined-adornment-password">Note</InputLabel> */}
        <OutlinedInput
          id="outlined-adornment-password"
          type={"text"}
          placeholder="Add your note here..."
          value={newNoteText}
          onChange={function updateNewNoteState(event) {
            setNewNoteText(event.target.value);
          }}
          onKeyDown={function (keyEvent) {
            if (
              keyEvent.code === "Enter" &&
              showUsersToMentionListMenu &&
              filteredFacilityUsers.length > 0
            ) {
              // Pressed enter while the mention menu is open, then select first mention automatically
              replacePartialMentionWithFullMention(filteredFacilityUsers[0]);
            }

            if (!isSubmitting && keyEvent.code === "Enter" && !showUsersToMentionListMenu) {
              submitNote(newNoteText);
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              {isSubmitting ? (
                <CircularProgress size={24} />
              ) : (
                <IconButton
                  onClick={function () {
                    submitNote(newNoteText);
                  }}
                  edge="end"
                  size="large"
                >
                  <Send sx={{color: primaryColors.brand[600]}} />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
      </FormControl>
    </Box>
  );
};

const useUsersToMentionListState = ({ facilityUsersToMention }) => {
  const [newNoteText, setNewNoteText] = useState();
  const [mentionedUserID, setMentionedUserID] = useState();
  const [showUsersToMentionListMenu, setShowUsersToMentionListMenu] =
    useState(false);

  useEffect(
    function showOrHideUsersList() {
      // not just checking the @ char to prevent having it attached to a preceeding string
      if (startedMentioning(newNoteText) && mentionedUserID === undefined) {
        setShowUsersToMentionListMenu(true);
      } else {
        setShowUsersToMentionListMenu(false);
      }
    },
    [mentionedUserID, newNoteText]
  );

  useEffect(
    function removeMentionedUserIfRemovedFromNote() {
      if (mentionedUserID !== undefined && !newNoteText?.includes("@")) {
        setMentionedUserID(undefined);
      }
    },
    [mentionedUserID, newNoteText, setMentionedUserID]
  );

  useEffect(
    // Register the mnetioned user if not clicked from the auto complete menu
    function registerMentionedUserIfWrittenByMemory() {
      const mentionedUsername = getMentionUsernameTag(newNoteText).replace(
        "@",
        ""
      );

      const { matchedFacilityUser, mentionedUsernameValid } =
        mentionedUsernameIsValid({
          mentionedUsername,
          listOfFacilityUsers: facilityUsersToMention,
        });

      if (mentionedUsernameValid && mentionedUserID === undefined) {
        setMentionedUserID(matchedFacilityUser.id);
      }

      if (mentionedUserID !== undefined && !mentionedUsernameValid) {
        setMentionedUserID(undefined);
      }
    },
    [facilityUsersToMention, mentionedUserID, newNoteText, setMentionedUserID]
  );

  return {
    newNoteText,
    setNewNoteText,

    mentionedUserID,
    setMentionedUserID,

    showUsersToMentionListMenu,
    setShowUsersToMentionListMenu,
  };
};
