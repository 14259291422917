import { Grid, Button, Typography, Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../helpers/Colors";
import { primaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import { ReactComponent as Logo } from "../../assets/melior-logo-black.svg";
import UserErrorSuccessFormDisplay from "../../reusable/components/UserErrorSuccessFormDisplay";
import { AntSwitch } from "./OnlineSurveyWelcomeCard";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import { useReactHookFormWithYup } from "../../reusable/hooks";
import { PhoneNumberUtil } from "google-libphonenumber";
import {
  FormPhoneNumberInput,
  FormTextInput,
  FormTextarea,
  FormVoiceNoteInput,
} from "../../reusable/components/form-inputs/controlled";
import { nigeriaPhoneRegex } from "../outbound-feedback/components/SharedFeedbackInputs";

const phoneNumberUtil = PhoneNumberUtil.getInstance();

const useStyles = makeStyles(() => ({
  //   body: {
  //     padding: "20px",
  //     height: "100%",
  //     // marginTop: "10px",
  //     fontSize: "1.4rem",
  //     textAlign: "center",
  //     color: COLORS.newBlack,
  //   },
  bodyBeginning: {
    marginTop: "15px",
  },
  logo: {
    width: "100px",
    marginRight: "auto",
  },
  question: {
    fontSize: "20px",
    fontWeight: "bold",
    margin: "0",
    textAlign: "center",
    color: COLORS.newBlack,
    width: "100%",
  },
  text: {
    margin: "5px",
    fontSize: "20px",
    fontWeight: "bold",
    wordBreak: "break-all",
  },
  commentTextField: {
    width: "100%",
    borderRadius: "20px",
  },
  micButton: {
    width: "30%",
    borderRadius: "50%",
  },
  micIcon: {
    width: "38px",
    height: "auto",
  },
  sideToSideButton: {
    width: "60px",
    borderRadius: "50%",
  },
  sideToSideIcon: {
    width: "38px",
    height: "auto",
    color: COLORS.meliorYellow,
  },
  micNeutral: {
    color: COLORS.meliorNewYellowHeavy,
  },
  micRecording: {
    color: COLORS.successGreen,
    borderRadius: "50%",
    animation: "$pulse",
    animationDuration: "1.5s",
    animationIterationCount: "infinite",
    animationTimingFunction: "linear",
  },
  "@keyframes pulse": {
    "0%": {
      boxShadow: `0px 0px 5px 0px ${COLORS.successGreenGentle}`,
    },
    "65%": {
      boxShadow: `0px 0px 5px 13px ${COLORS.successGreenGentle}`,
    },
    "90%": {
      boxShadow: `0px 0px 5px 13px ${COLORS.successGreen}`,
    },
  },
}));

export const OnlineSurveyInputCard = (props) => {
  const classes = useStyles();

  const { what_could_be_better, leave_voice_note, leave_name_and_phone } =
    props.dynamicText.feedback_and_contacts;

  const fieldExist = (fieldName) => {
    const fieldRecord = props.fields?.filter(
      (field) => field.name === fieldName
    );
    return !!fieldRecord?.length;
  };

  const isRequiredField = (fieldName) => {
    if (props.isOts) return false;
    const fieldRecord = props.fields?.filter(
      (field) => field.name === fieldName
    );
    return fieldExist(fieldName) && fieldRecord[0].required !== false;
  };

  const submitInput = (inputCardData) => {
    const { comment_audio, comment_text, ...contacts } = inputCardData;
    // add + to have a the same format as android
    if (contacts?.phone) contacts.phone = `+${contacts.phone}`;
    props.nextPage({ contacts, comment_audio, comment_text });
  };

  const get_disclaimer_text = () => {
    try {
      if (props.language) {
        if (Array.isArray(props.disclaimer)) {
          const arabic_disclaimer = props.disclaimer.filter(
            (item) => item.lang === "ar"
          );
          return arabic_disclaimer[0].text;
        } else {
          return props.disclaimer.ar;
        }
      } else {
        if (Array.isArray(props.disclaimer)) {
          const english_disclaimer = props.disclaimer.filter(
            (item) => item.lang === "en"
          );
          return english_disclaimer[0].text;
        } else {
          return props.disclaimer.en;
        }
      }
    } catch (error) {
      return "";
    }
  };
  const disclaimer_text_value = get_disclaimer_text();
  const validationSchema = yup.object({
    name: yup.string().when("isNameFieldRequired", {
      is: isRequiredField("name"),
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    email: yup.string().when("isEmailFieldRequired", {
      is: isRequiredField("email"),
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.optional(),
    }),
    phone: yup
      .string()
      .when("isPhoneFieldRequired", {
        is: isRequiredField("phone"),
        then: (schema) => schema.required("Required"),
        otherwise: (schema) => schema.optional(),
      })
      .test(
        "isValidPhoneNumber",
        "please enter a valid phone number",
        (value) => {
          if (isRequiredField("phone")) {
            try {
              if (props.facilityCountry === "NG")
                return value?.match(nigeriaPhoneRegex);
              else
                return phoneNumberUtil.isValidNumber(
                  phoneNumberUtil.parse(value, props.facilityCountry)
                );
            } catch (error) {
              console.error(error);
            }
          } else return true;
        }
      ),
    comment_text: yup.string().optional(),
  });
  const reactHookFormMethods = useReactHookFormWithYup({ validationSchema });
  const { handleSubmit, formState } = reactHookFormMethods;
  const isFormSubmittedWithErrors =
    Object.keys(formState.errors).length !== 0 && formState.isSubmitted;
  return (
    <FormProvider {...reactHookFormMethods}>
      <form method="POST" onSubmit={handleSubmit(submitInput)}>
        <style>
          {`
          textarea::placeholder {
            font-family: ${
              props.language ? "BalooBhaijaan2" : "Nunito"
            }, sans-serif;
          }
          
          input::placeholder {
            font-family: ${
              props.language ? "BalooBhaijaan2" : "Nunito"
            }, sans-serif;
          }
        `}
        </style>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="fit-content"
          gap={"18px"}
          padding={"20px"}
          textAlign="center"
        >
          {isFormSubmittedWithErrors && (
            <UserErrorSuccessFormDisplay
              color={primaryColors.base.black}
              type="error"
              message={
                !formState.errors
                  ? props.error.errorMessage
                  : props.language
                  ? "من فضلك إملأ الخانات المطلوبة"
                  : "Please fill all required fields."
              }
              width="95%"
              margin="auto"
            />
          )}
          {props.error.isError && (
            <UserErrorSuccessFormDisplay
              color={primaryColors.base.black}
              type="error"
              message={props.error.errorMessage}
              width="95%"
              margin="auto"
            />
          )}
          {/* Start: What could be Better */}
          <Box
          // mt="32px"
          >
            <p
              className={classes.question}
              style={{
                fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                fontWeight: fontWeightVariations.semiBold,
                fontSize: fontSizeVariations["text-xl"],
                color: primaryColors.gray[900],
                width: "100%",
                textAlign: props.language ? "right" : "left",
                marginBottom: "16px",
              }}
              dir="auto"
            >
              {props.language
                ? what_could_be_better.ar
                : what_could_be_better.en}
            </p>
            <FormTextarea
              name="comment_text"
              placeholder={
                props.language
                  ? what_could_be_better.placeholder.ar
                  : what_could_be_better.placeholder.en
              }
              rows={props.language ? 4 : 3}
              sx={{
                fontSize: fontSizeVariations["text-md"],
                fontWeight: fontWeightVariations.medium,
                color: primaryColors.gray[900],
                borderRadius: "8px",
                width: "100%",
              }}
            />
          </Box>
          {/* End: What could be Better */}
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems={props.language ? "end" : "start"}
            width="100%"
          >
            <p
              className={classes.question}
              dir="auto"
              style={{
                fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                fontWeight: fontWeightVariations.semiBold,
                fontSize: fontSizeVariations["text-xl"],
                color: primaryColors.gray[900],
                width: "auto",
              }}
            >
              {props.language ? leave_voice_note.ar : leave_voice_note.en}
            </p>
            <FormVoiceNoteInput name="comment_audio" />
          </Box>
          {/* start: contact info fields */}
          {props.fields?.length > 0 && !props.isOts && (
            <>
              <p
                className={classes.question}
                style={{
                  fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                  fontWeight: fontWeightVariations.semiBold,
                  fontSize: fontSizeVariations["text-xl"],
                  color: primaryColors.gray[900],
                  width: "100%",
                  textAlign: props.language ? "right" : "left",
                }}
              >
                {props.language
                  ? leave_name_and_phone.ar
                  : leave_name_and_phone.en}
              </p>
              {fieldExist("name") && (
                <FormTextInput
                  name="name"
                  placeholder={props.language ? "الإسم" : "Name"}
                  type="text"
                  sx={{
                    fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                    fontSize: fontSizeVariations["text-md"],
                    fontWeight: fontWeightVariations.medium,
                    color: primaryColors.gray[900],
                    borderRadius: "8px",
                    width: "100%",
                  }}
                />
              )}
              {fieldExist("email") && (
                <FormTextInput
                  name="email"
                  placeholder={props.language ? "البريد الإلكتروني" : "Email"}
                  type="text"
                  sx={{
                    fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                    fontSize: fontSizeVariations["text-md"],
                    fontWeight: fontWeightVariations.medium,
                    color: primaryColors.gray[900],
                    borderRadius: "8px",
                    width: "100%",
                  }}
                />
              )}
              {fieldExist("phone") && (
                <FormPhoneNumberInput
                  name="phone"
                  country={props.facilityCountry}
                />
              )}
            </>
          )}
          {/* end:  contact info fields*/}
          {props.disclaimer && (
            <UserErrorSuccessFormDisplay
              style={{
                background: "transparent",
                width: "90%",
              }}
              fontSize={fontSizeVariations["text-sm"]}
              bg={primaryColors.warning[25]}
              color={primaryColors.warning[700]}
              type="disclaimer"
              message={disclaimer_text_value}
              language={props.language}
            />
          )}
          <Button
            type="submit"
            disabled={props.submitting}
            sx={{
              background:
                props.submitting || !formState.isValid
                  ? primaryColors.gray[100]
                  : primaryColors.brand[500],
              height: "48px",
              borderRadius: "8px",
              padding: "10px 18px",
              width: "100%",
              "&:hover": {
                background: primaryColors.brand[500],
              },
            }}
          >
            {props.submitting ? (
              <CircularProgress size={20} />
            ) : (
              <Typography
                sx={{
                  fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
                  fontSize: fontSizeVariations["text-md"],
                  fontWeight: fontWeightVariations.medium,
                  color: primaryColors.base.black,
                  textTransform: "none",
                }}
              >
                {props.language ? "إبعت" : "Submit"}
              </Typography>
            )}
          </Button>
        </Box>
      </form>
    </FormProvider>
  );
};
