import { melior } from "../../../../helpers/constants";

export const alterCurrentUserDetails = (data) => {
  return new Promise((resolve, reject) => {
    melior
      .patch("/user/details", data)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
