import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getIssues = () => {
  const requestURL = `${API_URL}/complaint-issues`;
  return melior.get(requestURL);
};

export const useGetIssues = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["issues"],
    queryFn: () => getIssues(),
    keepPreviousData: true,
  });
};
