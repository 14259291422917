import { useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import * as yup from "yup";
import {
  SharedFeedbackInputs,
  getSharedReviewComplaintYupValidations,
} from "./SharedFeedbackInputs";
import { v4 as uuidv4 } from "uuid";
import { useFeedback } from "../hooks/useFeedback";
import { useReactHookFormWithYup } from "../../../reusable/hooks";
import { submitComplaintInternally, getComplaintIssues } from "../api";
import { useContext } from "react";
import { AuthenticationContext } from "../../login";
import { primaryColors } from "../../../helpers/customColors";
import { fontWeightVariations } from "../../../helpers/customFont";
import moment from "moment";

const DEFAULT_ISSUE = "others";
const FORM_FIELDS_NAMES = {
  complaintIssue: "issue",
};

const Complaint = ({
  divisionID,
  externalDefaultComplaintText,
  externalDefaultUsername,
  externalDefaultTimestamp,
  callbackHandlerAfterSubmission,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [complaintIssuesOptions, setComplaintIssuesOptions] = useState([]);
  const { feedbackUI, setSuccess, setError } = useFeedback();

  const { user } = useContext(AuthenticationContext);

  const validationSchema = yup.object({
    ...getSharedReviewComplaintYupValidations({
      country: user.profile.country,
      makeTextCommentRequired: true,
    }),
    issue: yup.number(),
    // comment_text: commentTextBaseValidations.required("Required"),
    // name: yup.string().required("Required"),
    // phone: yup
    //   .string()
    //   .required("Required")
    //   .test(
    //     "isValidPhoneNumber",
    //     "please enter a valid phone number",
    //     (value) =>
    //       phoneNumberUtil.isValidNumber(
    //         phoneNumberUtil.parse(value, user.profile.country)
    //       )
    //   ),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    useFormProps: { mode: "onChange" },
    defaultValues: {
      comment_text: externalDefaultComplaintText,
      name: externalDefaultUsername,
      customSubmissionDate: moment(externalDefaultTimestamp).toISOString(),
    },
  });
  const { reset, handleSubmit, setValue, formState, resetField } =
    reactHookFormMethods;

  useEffect(() => {
    reset();
  }, [divisionID, reset]);

  // Get complaint issues on mount
  useEffect(() => {
    getComplaintIssues()
      .then((complaintIssuesResponse) => {
        const preparedComplaintIssuesOptions = prepareComplaintIssuesOptions(
          complaintIssuesResponse
        );
        setComplaintIssuesOptions(preparedComplaintIssuesOptions);
        // Set the default option
        setValue(
          FORM_FIELDS_NAMES.complaintIssue,
          getDefaultOptionIDByLabel(
            preparedComplaintIssuesOptions,
            DEFAULT_ISSUE
          )
        );
      })
      .catch((error) => {
        console.error("complaint-issues-failed", error);
      });
  }, [setValue]);

  return (
    <FormProvider {...reactHookFormMethods}>
      <form
        method="POST"
        onSubmit={handleSubmit(async (complaintFormData) => {
          setSubmitting(true);

          const preparedData = prepareComplaintFormData({
            complaintFormData,
            divisionID,
          });
          await submitComplaintInternally(preparedData)
            .then((data) => {
              setSubmitting(false);
              setSuccess(true);

              callbackHandlerAfterSubmission && callbackHandlerAfterSubmission();

              reset();
            })
            .catch((error) => {
              setSubmitting(false);
              setError(true);
            });
        })}
        style={{
          width: "100%",
        }}
      >
        {/* {complaintIssuesOptions.length > 1 && ( */}
          <Box display="flex" flexDirection="column" px="1rem" gap="24px">
            <SharedFeedbackInputs
              resetField={resetField}
              country={user.profile.country}
            />
            <Box
              display="flex"
              justifyContent="flex-end"
              mt="2rem"
              width="100%"
            >
              <Button
                type="button"
                variant="outlined"
                onClick={function resetFormInputs() {
                  reset();
                }}
                disabled={submitting || !formState.isDirty}
                sx={{
                  borderRadius: "8px",
                  border: `1px solid ${
                    submitting || !formState.isDirty
                      ? primaryColors.gray[200]
                      : primaryColors.gray[300]
                  }`,
                  marginRight: "1rem",
                  textTransform: "capitalize",
                  color:
                    submitting || !formState.isDirty
                      ? primaryColors.gray[300]
                      : primaryColors.base.black,
                }}
              >
                Reset
              </Button>
              <Button
                type="submit"
                disabled={submitting || !formState.isValid}
                sx={{
                  borderRadius: "8px",
                  backgroundColor:
                    submitting || !formState.isValid
                      ? primaryColors.brand[200]
                      : primaryColors.brand[500],
                }}
              >
                <Typography
                  variant="text-sm"
                  fontFamily="Nunito"
                  textTransform="capitalize"
                  fontWeight={fontWeightVariations.medium}
                  color={primaryColors.base.white}
                >
                  {submitting ? <CircularProgress size={20} /> : "Submit"}
                </Typography>
              </Button>
            </Box>
            {feedbackUI}
          </Box>
        {/* )} */}
      </form>
    </FormProvider>
  );
};

export const prepareComplaintIssuesOptions = (
  complaintIssuesResponse,
  availableOnly = true
) => {
  let filteredComplaintIssuesRaw = complaintIssuesResponse;

  if (availableOnly) {
    filteredComplaintIssuesRaw = complaintIssuesResponse.filter(
      (complaintIssue) => {
        return complaintIssue.available === true;
      }
    );
  }

  const preparedAvailableIssuesOptions = filteredComplaintIssuesRaw.map(
    (availableComplaintIssue) => {
      return {
        label: availableComplaintIssue.name,
        value: availableComplaintIssue.id,
      };
    }
  );

  return preparedAvailableIssuesOptions;
};

const getDefaultOptionIDByLabel = (options, labelToFind) => {
  let defaultValue = undefined;

  if (options.length === 1 && options.length !== 0) {
    defaultValue = options[0].value;
  } else {
    const defaultOption = options.filter((option) => {
      if (option.label === labelToFind) return true;
    })[0];
    defaultValue = defaultOption?.value;
  }

  return defaultValue;
};

const prepareComplaintFormData = ({ complaintFormData, divisionID }) => {
  complaintFormData.phone = `+${complaintFormData.phone}`;
  return {
    ...complaintFormData,
    contacts: {
      name: complaintFormData.name,
      phone: `${complaintFormData.phone}`,
    },
    comment_audio: null,
    division: divisionID,
    nonce: uuidv4(),
    submitted_at: complaintFormData.customSubmissionDate
      ? complaintFormData.customSubmissionDate
      : new Date().toISOString(),
  };
};

export default Complaint;
