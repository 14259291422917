import { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../helpers/Colors";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";

import { BranchContext } from "../../../../contexts/BranchContext";
import ShiftRows from "./ShiftRows";

const useStyles = makeStyles(theme => ({
  backgroundContainer: {
    padding: "0px",
    marginTop: "2%"
  },
  pageHeader: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "30px",
    marginTop: "35px",
    marginLeft: "10px"
  },
  header: {
    fontFamily: "Avenir-Heavy",
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "24px",
    marginLeft: "10px"
  },
  title: {
    fontFamily: "Avenir-Medium",
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
    marginRight: "6%",
    marginBttom: "0px"
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "80%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "0%"
  },
  resizeFont: {
    fontSize: 18
  },
  timeTextField: {
    background: COLORS.yellowBGTextField,
    width: "38%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "5%"
  },
  dropdownIcon: {
    color: "red"
  }
}));

export const EditBranch = () => {
  const classes = useStyles();
  const { settingsBranch } = useContext(BranchContext);
  const [branchName, setNewBranchName] = useState("");

  return (
    <div>
      <Container maxWidth="xl">
        <h1 className={classes.pageHeader}>
          Dashboard Settings -
          <span style={{ color: COLORS.meliorYellow }}> {settingsBranch} </span>
        </h1>
        {/* <h1 className={classes.header}>{settingsBranch}</h1> */}
      </Container>
      <Container maxWidth="lg" className={classes.backgroundContainer}>
        <Grid
          container
          direction="row"
          justifyContent="center"
        >
          <Grid
            item
            xs={11}
            md={5}
            lg={5}
            container
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            <h1 className={classes.title}> Division Name</h1>
            <TextField
              className={classes.textField}
              margin="normal"
              variant="outlined"
              placeholder={settingsBranch}
              value={branchName}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={e => {
                setNewBranchName(e.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={11}
            md={7}
            lg={7}
            container
            justifyContent="flex-start"
            direction="column"
            alignItems="flex-start"
          >
            <Grid
              container
              justifyContent="flex-start"
              direction="row"
              alignItems="flex-start"
            >
              <h1 className={classes.title}>Shifts</h1>
              <h1 className={classes.title} style={{ marginLeft: "8%" }}>
                From
              </h1>
              <h1 className={classes.title} style={{ marginLeft: "30%" }}>
                To
              </h1>
            </Grid>
            <ShiftRows />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};