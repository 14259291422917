import { useContext, useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import { COLORS } from "../helpers/Colors";

import { NavLink } from "react-router-dom";
import { IconButton, Tooltip } from "@mui/material";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { AuthenticationContext } from "../features/login/contexts/AuthenticationContext";
import { BranchContext } from "../contexts/BranchContext";

// ICONS
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import HomeIcon from "@mui/icons-material/Home";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuIcon from "@mui/icons-material/Menu";
import SettingsIcon from "@mui/icons-material/Settings";
import AdminPanelIcon from "@mui/icons-material/Build";
import { Message, Visibility } from "@mui/icons-material";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import { primaryColors } from "../helpers/customColors";
import { FEEDBACK_ONLY, FACILITY_MANAGER, DEMO } from "../helpers/constants";

const useStyles = makeStyles(() => ({
  smallMenuDiv: {
    width: "30px",
    height: "65px",
    display: "inline-flex",
    alignItems: "center",
    marginRight: "30px",
  },
  smallMenuButton: {
    height: "55px",
    width: "55px",
    color: primaryColors.brand[500],
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid",
    borderColor: COLORS.silver,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledInnerMenu = withStyles({
  paper: {
    border: "1px solid",
    borderColor: COLORS.silver,
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));
const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: primaryColors.brand[500],
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const SmallScreenNavMenu = ({ forSMSUsersOnly }) => {
  const classes = useStyles();
  const { logout } = useContext(AuthenticationContext);

  const { setFirebaseInactive } = useContext(AuthenticationContext);

  const { changeHeaderBranch, setOnBranch } = useContext(BranchContext);
  let { user, isFacilityManager } = useContext(AuthenticationContext);

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElInner, setAnchorElInner] = useState(null);

  // Small Menu Functions
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClickInner = (event) => {
    setAnchorElInner(event.currentTarget);
  };

  const handleCloseInnerAndOuter = (target) => {
    if (target !== "nothing") {
      setOnBranch(true);
      changeHeaderBranch(target);
    }
    setAnchorElInner(null);
    setAnchorEl(null);
  };

  const useLogout = async () => {
    setOnBranch(false);
    if (localStorage.getItem("firebasePermission") === "allow") {
      await setFirebaseInactive();
    }
    handleClose();
    logout();
  };

  return (
    <div className={classes.smallMenuDiv}>
      {/* small screen nav menu */}
      <Tooltip title="Open Menu" position="top">
        <IconButton
          className={classes.smallMenuButton}
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          onClick={handleClick}
          size="medium"
        >
          <MenuIcon />
        </IconButton>
      </Tooltip>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!forSMSUsersOnly && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/"
              end
              style={{
                width: "100%",

                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </NavLink>
          </StyledMenuItem>
        )}

        <NavLink to="/overall-view" style={{ color: "inherit" }}>
          <StyledMenuItem sx={{ display: "flex" }}>
            <ListItemIcon>
              <Visibility />
            </ListItemIcon>
            <ListItemText primary="Overall View" />
          </StyledMenuItem>
        </NavLink>
        {/* Distribution Page is not allowed for feedback_only users */}
        {user.role !== FEEDBACK_ONLY && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/link-generator"
              style={{
                width: "100%",
                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary="Link Generator" />
            </NavLink>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={handleClose}>
          <NavLink
            to="/outbound"
            style={{
              width: "100%",
              textDecoration: "none",
              color: COLORS.brightBlack,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <PermPhoneMsgIcon />
            </ListItemIcon>
            <ListItemText primary="Outbound" />
          </NavLink>
        </StyledMenuItem>
        {/* Link Generator Page is not allowed for feedback_only users */}
        {user.role !== FEEDBACK_ONLY && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/link-generator"
              style={{
                width: "100%",
                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <Message />
              </ListItemIcon>
              <ListItemText primary="Link Generator" />
            </NavLink>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={handleClose}>
          <NavLink
            to="/outbound"
            style={{
              width: "100%",
              textDecoration: "none",
              color: COLORS.brightBlack,
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <ListItemIcon>
              <PermPhoneMsgIcon />
            </ListItemIcon>
            <ListItemText primary="Outbound" />
          </NavLink>
        </StyledMenuItem>
        {!forSMSUsersOnly && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/settings"
              end
              style={{
                width: "100%",

                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </NavLink>
          </StyledMenuItem>
        )}
        {!forSMSUsersOnly && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/revamped-settings"
              end
              style={{
                width: "100%",

                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText primary="Revamped Settings" />
            </NavLink>
          </StyledMenuItem>
        )}
        {(user?.role === FACILITY_MANAGER || user?.role === DEMO) && (
          <StyledMenuItem onClick={handleClose}>
            <NavLink
              to="/facility-management"
              end
              style={{
                width: "100%",

                textDecoration: "none",
                color: COLORS.brightBlack,
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              <ListItemIcon>
                <AdminPanelIcon />
              </ListItemIcon>
              <ListItemText primary="Facility Management" />
            </NavLink>
          </StyledMenuItem>
        )}
        <StyledMenuItem onClick={useLogout}>
          <ListItemIcon>
            <KeyboardTabIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default SmallScreenNavMenu;
