import { API_URL, melior } from "../../../helpers/constants";

export const sendBulkSMS = ({
  divisionID,
  branchID,
  templateID,
  numbersWithVisitDate,
  scheduled_date,
  type
}) => {
  const requestURL = `${API_URL}/feedback-invitation/send-sms`;

  const requestData = {
    branch_id: branchID,
    template_id: templateID,
    data: numbersWithVisitDate,
    scheduled_date,
    type
  };

  console.log(requestData, "requestData");
  if (divisionID && divisionID !== 0) {
    requestData.division_id = divisionID;
  }
  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, requestData)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
