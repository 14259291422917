import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ReviewComplaint } from "../components/ReviewComplaint";

export const OutboundFeedbackScreen = () => {
  return (
    <ScreenContainer>
      <ReviewComplaint />
    </ScreenContainer>
  );
};
