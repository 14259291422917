
import { Box,Typography } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { InvitationDialogButton } from "./InvitationDialogButton";
export const NoMembersPage = ()=>{
return(

    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="fit-content"
      padding="40px 1% 48px"
    >
      <Box padding="0% 38%" mb="24px" textAlign="center">
        <Typography
          sx={{
            fontSize: fontSizeVariations["text-md"],
            fontWeight: fontWeightVariations.semiBold,
            color: primaryColors.gray[900],
          }}
        >
          Start by adding new members
        </Typography>
        <Typography
          sx={{
            fontSize: fontSizeVariations["text-sm"],
            fontWeight: fontWeightVariations.medium,
            color: primaryColors.gray[500],
          }}
        >
          Get your team on board and let the journey begin! There are various
          roles available, so you can assign each member to a specific branch
          and division.
        </Typography>
      </Box>
      <InvitationDialogButton />
    </Box>
);


}