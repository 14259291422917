import { API_URL, melior } from "../../../helpers/constants";

export const getBranchProgressData = async (
  branchProgressQueryStrings,
  branchID
) => {
  const { date_from, date_to, divisions } = branchProgressQueryStrings;

  const requestURL = `${API_URL}/branches/${branchID}/progress`;

  return await melior
    .get(requestURL, {
      params: {
        ...branchProgressQueryStrings,
      },
    })
    .then(async (response) => {
      return response.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
};
