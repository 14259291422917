import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import { NOTIFICATIONS_HORIZONTAL_POSITION, NOTIFICATIONS_TARGET, NOTIFICATIONS_TYPE, NOTIFICATIONS_VERTICAL_POSITION, NotificationContext } from "../../../../contexts/NotificationContext";

export const sendSatisfactionSurveySMS = ({ complaintID }) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/satisfaction-survey/send-sms`;

  return melior.post(requestURL);
};

export const useSendSatisfactionSurveySMS = ({ config } = {}) => {
    const {fireNotification} = useContext(NotificationContext)
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["complaints"], {
        type: "active",
      });
      queryClient.invalidateQueries(["complaint-activity-logs"], {
        type: "active",
      });
      queryClient.invalidateQueries(["reviews-with-complaints"], {
        type: "active",
      });

      fireNotification({
        title: "Satisfaction Survey SMS has been sent successfully",
          type: NOTIFICATIONS_TYPE.success,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
          horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
          target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: function (error){
        console.error("Failed to send Satisfaction Survey SMS", error)
        
      fireNotification({
        title: "Satisfaction Survey SMS has not been sent, please try again later.",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
          horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
          target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: sendSatisfactionSurveySMS,
  });
};
