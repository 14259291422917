import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import {
  gradientColorsData,
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import { GenericDialog } from "../../../../reusable/components/GenericDialog";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useState } from "react";
import { PAYMENT_METHODS } from "../../screens/BillingSubScreen";
import { useGetBillingDetailsSummary } from "../../api/billing/getBillingDetailsSummary";
import moment from "moment";
import { useUpdateBillingEmail } from "../../api/billing/updateBillingEmail";
import { useUpdateToOnlinePaymentMethod } from "../../api/billing/updateToOnlinePaymentMethod";
import { useGetAllowedSubscriptionPlans } from "../../api/billing/getSubscriptionPlans";
import { IsLoadingContainer } from "../../../../reusable/components/IsLoadingContainer";
import { useUpgradeSubscriptionPlan } from "../../api/billing/upgradeSubscriptionPlan";
import { useRequestUpgradeToEnterprisePlan } from "../../api/billing/requestUpgradeToEnterprisePlan";
import { useUpdatePaymentCardInfo } from "../../api/billing/updatePaymentCardInfo";
import { useUpgradePaymentPlanHook } from "../../../../reusable/hooks/useUpgradePaymentPlanHook";

export const SUBSCRIPTION_PLANS = {
  free: "Free",
  standard: "Standard",
  premium: "Premium",
  enterprise: "Enterprise",
};

export const BillingCards = ({ currentSubscriptionPlan }) => {
  const nextSubscriptionPlan = getNextSubscriptionPlan(currentSubscriptionPlan);

  const { data: billingDetailsSUmmaryResponse } = useGetBillingDetailsSummary();
  const requestUpgradeToEnterprisePlanMutation =
    useRequestUpgradeToEnterprisePlan();
  const updatePaymentCardInfoMutation = useUpdatePaymentCardInfo();

  const updatePaymentCardInfoDialog = (
    <CardActionDialog
      dialogTitle="Payment Card Info Card Dialog Title"
      triggerButtonLabel={"Update Card Info"}
      onSubmitCallback={function () {
        updatePaymentCardInfoMutation
          .mutateAsync()
          .then(function goToUpdatePaymentCardPage(response) {
            window.open(response.data.url, "_blank");
          });
      }}
      isSubmitting={updatePaymentCardInfoMutation.isLoading}
      isDone={updatePaymentCardInfoMutation.isSuccess}
    >
      Update Payment Card Info Dialog Content
    </CardActionDialog>
  );

  function renderUpgradeActionElement(nextSubscriptionPlanArg) {
    switch (nextSubscriptionPlanArg) {
      case undefined: {
        // We're at the top tier plan, Enterprise Plan.
        return null;
      }
      case SUBSCRIPTION_PLANS.enterprise: {
        // We're at Premium Plan, and upon clicking to upgrade to enterprise, send an email.
        return (
          <IsLoadingContainer
            isLoading={requestUpgradeToEnterprisePlanMutation.isLoading}
          >
            <BillinActionButton
              triggerButtonLabel={`Request Upgrade to ${SUBSCRIPTION_PLANS.enterprise} Plan`}
              onClickHandler={function sendEmailToUpgradeToEnterprisePlan() {
                requestUpgradeToEnterprisePlanMutation.mutateAsync();
              }}
            />
          </IsLoadingContainer>
        );
      }
      default: {
        // We're at either Free or Standard Plans
        return (
          <UpgradePaymentPlanDialog
            nextSubscriptionPlan={nextSubscriptionPlan}
          />
        );
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <BillingCard
            title={
              currentSubscriptionPlan === SUBSCRIPTION_PLANS.free
                ? "You've been with us since"
                : "Next invoice issued date"
            }
            body={
              currentSubscriptionPlan === SUBSCRIPTION_PLANS.free
                ? moment(billingDetailsSUmmaryResponse?.data.created_at).format(
                    "ll"
                  )
                : moment(
                    billingDetailsSUmmaryResponse?.data.next_invoice_date
                  ).format("ll")
            }
            backgroundColor={gradientColorsData.yellow.bg}
            textColor={gradientColorsData.yellow.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Invoice total"
            body={billingDetailsSUmmaryResponse?.data.invoice_total}
            backgroundColor={gradientColorsData.green.bg}
            textColor={gradientColorsData.green.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total Branches"
            body={billingDetailsSUmmaryResponse?.data.current_total_branches}
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total Divisions"
            body={billingDetailsSUmmaryResponse?.data.current_total_divisions}
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={3}>
          <BillingCard
            title={
              currentSubscriptionPlan === SUBSCRIPTION_PLANS.free
                ? "Free Plan"
                : `${billingDetailsSUmmaryResponse?.data.current_plan} plan - ${billingDetailsSUmmaryResponse?.data.renewal_type} renewal`
            }
            body={
              currentSubscriptionPlan === SUBSCRIPTION_PLANS.free
                ? "Give it a try!"
                : "July 4, 2023"
            }
            backgroundColor={gradientColorsData.yellow.bg}
            textColor={gradientColorsData.yellow.text}
            action={renderUpgradeActionElement(nextSubscriptionPlan)}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Total active users today"
            body={billingDetailsSUmmaryResponse?.data.current_total_users.toString()}
            backgroundColor={gradientColorsData.green.bg}
            textColor={gradientColorsData.green.text}
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Payment Method"
            body={
              PAYMENT_METHODS[
                billingDetailsSUmmaryResponse?.data.current_payment_method
              ] === PAYMENT_METHODS.offline
                ? "Offline"
                : "Online"
            }
            backgroundColor={gradientColorsData.gray.bg}
            textColor={gradientColorsData.gray.text}
            action={
              PAYMENT_METHODS[
                billingDetailsSUmmaryResponse?.data.current_payment_method
              ] === PAYMENT_METHODS.offline ? (
                <UpdatePaymentMethodDialog
                  currentSubscriptionPlan={currentSubscriptionPlan}
                />
              ) : (
                updatePaymentCardInfoDialog
              )
            }
          />
        </Grid>
        <Grid item xs={3}>
          <BillingCard
            title="Invoice sent to"
            body={billingDetailsSUmmaryResponse?.data.billing_email}
            backgroundColor={gradientColorsData.blue.bg}
            textColor={gradientColorsData.blue.text}
            action={<UpdateBillingEmailDialog />}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const BillingCard = ({ title, body, action, backgroundColor, textColor }) => {
  const slicedBodyText = body ? body.toString().substring(0, 17) : "";
  return (
    <Box
      display="flex"
      flexDirection="column"
      borderRadius={3}
      boxShadow={2}
      height="140px"
      //   height="fit-content"
      p="24px"
      sx={{
        background: backgroundColor,
      }}
    >
      <Typography
        variant="text-md"
        fontWeight="bold"
        color={textColor}
        sx={{ mb: "20px" }}
      >
        {title}
      </Typography>
      <Typography
        // variant="display-md"
        fontWeight="bold"
        // 2.3vw is just to force longer body content to fit in one line
        sx={{ mb: "12px", fontSize: "2.3vw" }}
      >
        {`${slicedBodyText}${slicedBodyText.length >= 17 ? "..." : ""}`}
      </Typography>
      {action && action}
    </Box>
  );
};

export const UpgradePaymentPlanDialog = ({
  nextSubscriptionPlan = undefined,
  triggerButton,
}) => {
  const {
    isLoadingAllowedSubscriptionPlansResponse,
    allowedSubscriptionPlansResponse,
    selectedNewPaymentPlan,
    onSubmitPlanUpgradeHandler,
    upgradeSubscriptionPlanMutation,
    planUpgradeOptions,
  } = useUpgradePaymentPlanHook();

  return (
    <IsLoadingContainer isLoading={isLoadingAllowedSubscriptionPlansResponse}>
      {allowedSubscriptionPlansResponse &&
        !isLoadingAllowedSubscriptionPlansResponse && (
          <CardActionDialog
            dialogTitle="Upgrade your subscription"
            dialogSubtitle="Choose your period"
            triggerButton={triggerButton ? triggerButton : undefined}
            triggerButtonLabel={`Upgrade to ${nextSubscriptionPlan} Plan`}
            selectedNewPaymentPlan={selectedNewPaymentPlan}
            onSubmitCallback={onSubmitPlanUpgradeHandler}
            isSubmitting={upgradeSubscriptionPlanMutation.isLoading}
            isDone={upgradeSubscriptionPlanMutation.isSuccess}
          >
            {planUpgradeOptions}
          </CardActionDialog>
        )}
    </IsLoadingContainer>
  );
};

const UpdatePaymentMethodDialog = ({ currentSubscriptionPlan }) => {
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    PAYMENT_METHODS.offline
  );

  const updateToOnlinePaymentMethodMutation = useUpdateToOnlinePaymentMethod();

  return (
    <CardActionDialog
      dialogTitle="Payment Method"
      dialogSubtitle={
        currentSubscriptionPlan === SUBSCRIPTION_PLANS.standard
          ? "If you change the payment method, you'll only have to enter your card details once. Then, depending on the period, the payment will be automatically withdrawn."
          : undefined
      }
      triggerButtonLabel={"Change Payment Method"}
      onSubmitCallback={function onSubmitPaymentMethodHandler() {
        if (selectedPaymentMethod === PAYMENT_METHODS.online) {
          updateToOnlinePaymentMethodMutation
            .mutateAsync()
            .then(function successfullMigrationRequestHandler(response) {
              window.open(response.data.url, "_blank");
            });
        }
      }}
      isSubmitting={updateToOnlinePaymentMethodMutation.isLoading}
      isDone={updateToOnlinePaymentMethodMutation.isSuccess}
      disabled={currentSubscriptionPlan === SUBSCRIPTION_PLANS.free}
    >
      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={selectedPaymentMethod}
          onChange={function updatePaymentMethodHandler(e) {
            setSelectedPaymentMethod(e.target.value);
          }}
        >
          <FormControlLabel
            value={PAYMENT_METHODS.offline}
            control={<Radio />}
            label={PAYMENT_METHODS.offline}
          />
          <FormControlLabel
            value={PAYMENT_METHODS.online}
            control={<Radio />}
            label={PAYMENT_METHODS.online}
          />
        </RadioGroup>
      </FormControl>
    </CardActionDialog>
  );
};

const UpdateBillingEmailDialog = () => {
  const [newEmail, setNewEmail] = useState();
  const [isFaultyEmail, setIsFaultyEmail] = useState(false);

  const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const updateBillingEmailMutation = useUpdateBillingEmail();

  return (
    <CardActionDialog
      dialogTitle="Change billing contact"
      dialogSubtitle="Please enter your facility email"
      triggerButtonLabel="Change Email"
      maxWidth="xs"
      onSubmitCallback={function onSubmitNewEmailHandler() {
        console.log(`Change to new email ${newEmail}`);
        updateBillingEmailMutation.mutateAsync({ newBillingEmail: newEmail });
      }}
      isSubmitting={updateBillingEmailMutation.isLoading}
      isDone={updateBillingEmailMutation.isSuccess}
    >
      <Box pt={2}>
        <TextField
          id="outlined-controlled"
          label="New Email"
          value={newEmail}
          fullWidth
          error={isFaultyEmail}
          onChange={(event) => {
            if (emailRegex.test(event.target.value)) {
              setIsFaultyEmail(false);
            } else {
              setIsFaultyEmail(true);
            }
            setNewEmail(event.target.value);
          }}
        />
      </Box>
    </CardActionDialog>
  );
};

export const CardActionDialog = ({
  dialogTitle,
  dialogSubtitle,
  triggerButtonLabel,
  triggerButton = undefined,
  selectedNewPaymentPlan,
  maxWidth = "sm",
  onSubmitCallback,
  children,
  isSubmitting,
  isDone,
  disabled,
}) => {
  return (
    <GenericDialog
      dialogTitle={dialogTitle}
      dialogSubtitle={dialogSubtitle}
      maxWidth={maxWidth}
      submitButtonText="Confirm"
      closeButtonText="Cancel"
      triggerButton={
        triggerButton ? (
          triggerButton
        ) : (
          <Button
            variant="text"
            sx={{
              width: "fit-content",
              color: secondaryColors.blueLight[500],
              fontSize: fontSizeVariations["text-md"],
              fontWeight: fontWeightVariations.bold,
              textTransform: "capitalize",
              padding: 0,
            }}
            disabled={disabled}
          >
            {triggerButtonLabel}
          </Button>
        )
      }
      onSubmitCallback={onSubmitCallback}
      isSubmitting={isSubmitting}
      isDone={isDone}
    >
      {children}
    </GenericDialog>
  );
};

const BillinActionButton = ({
  disabled,
  triggerButtonLabel,
  onClickHandler,
}) => {
  return (
    <Button
      variant="text"
      onClick={onClickHandler}
      sx={{
        width: "fit-content",
        color: secondaryColors.blueLight[500],
        fontSize: fontSizeVariations["text-md"],
        fontWeight: fontWeightVariations.bold,
        textTransform: "capitalize",
        padding: 0,
      }}
      disabled={disabled}
    >
      {triggerButtonLabel}
    </Button>
  );
};

function getNextSubscriptionPlan(currentSubscriptionPlan) {
  let nextSubscriptionPlan = undefined;

  switch (currentSubscriptionPlan) {
    case SUBSCRIPTION_PLANS.free: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.standard;
      break;
    }
    case SUBSCRIPTION_PLANS.standard: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.premium;
      break;
    }
    case SUBSCRIPTION_PLANS.premium: {
      nextSubscriptionPlan = SUBSCRIPTION_PLANS.enterprise;
      break;
    }
    case SUBSCRIPTION_PLANS.enterprise: {
      nextSubscriptionPlan = undefined;
      break;
    }
    default: {
      nextSubscriptionPlan = currentSubscriptionPlan;
      break;
    }
  }

  return nextSubscriptionPlan;
}

function useRenderPlansRadioButtons({
  selectedNewPaymentPlan,
  setSelectedNewPaymentPlan,
  allowedSubscriptionPlans,
}) {
  return allowedSubscriptionPlans ? (
    <Box display="flex" flexDirection="column" gap={3}>
      {allowedSubscriptionPlans.map(function (plan) {
        return (
          <PlanRadioButton
            planName={plan.name}
            planRenewalType={plan.subscription_type}
            planPricing={plan.description}
            radioInputName="subscription_plan"
            radioInputValue={JSON.stringify(plan)}
            selectedNewPaymentPlan={selectedNewPaymentPlan}
            setSelectedNewPaymentPlan={setSelectedNewPaymentPlan}
          />
        );
      })}
    </Box>
  ) : null;
}

function PlanRadioButton({
  planName,
  planRenewalType,
  planPricing,
  radioInputName,
  radioInputValue,
  selectedNewPaymentPlan,
  setSelectedNewPaymentPlan,
}) {
  const isSelected = selectedNewPaymentPlan === radioInputValue;
  return (
    <Button
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start",
        width: "100%",
        gap: 2,
        padding: 2,
        border: `1px solid ${
          isSelected ? primaryColors.brand[200] : primaryColors.gray[200]
        }`,
        borderRadius: 3,
        bgcolor: isSelected ? primaryColors.brand[50] : undefined,
      }}
      onClick={function () {
        setSelectedNewPaymentPlan(radioInputValue);
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="start" width="80%">
        <Typography
          sx={{ textTransform: "none" }}
          color={
            isSelected ? primaryColors.brand[800] : primaryColors.gray[600]
          }
          variant="text-lg"
          fontWeight="bold"
        >
          {planName}
        </Typography>
        <Typography
          sx={{ textTransform: "none" }}
          color={
            isSelected ? primaryColors.brand[700] : primaryColors.gray[700]
          }
          variant="text-md"
        >
          {planRenewalType}
        </Typography>
        <Typography
          sx={{ textTransform: "none", paddingTop: 2 }}
          color={
            isSelected ? primaryColors.brand[600] : primaryColors.gray[600]
          }
          variant="text-md"
        >
          {planPricing}
        </Typography>
      </Box>
      <Radio
        sx={{ padding: 0 }}
        checked={selectedNewPaymentPlan === radioInputValue}
        onChange={function (e) {
          setSelectedNewPaymentPlan(e.target.value);
        }}
        value={radioInputValue}
        name={radioInputName}
        inputProps={{ "aria-label": "A" }}
      />
    </Button>
  );
}
