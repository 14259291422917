import { useEffect, useState, useRef } from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
  Legend,
  Cell,
} from "recharts";
import { Box, Grid, LinearProgress, Tooltip } from "@mui/material";
import { withStyles, makeStyles } from "@mui/styles";

import { IconButton } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";

const SuccessPercentageBar = withStyles((props, x) => {
  return {
    colorPrimary: { background: COLORS.greyGentle },
    bar1Determinate: {
      background: COLORS.successGreen,
    },
  };
})(LinearProgress);

const FailPercentageBar = withStyles({
  bar1Determinate: {
    background: COLORS.failRed,
  },
  colorPrimary: { background: COLORS.greyGentle },
})(LinearProgress);

const NeutralPercentageBar = withStyles({
  bar1Determinate: {
    background: COLORS.meliorYellow,
  },
  colorPrimary: { background: COLORS.greyGentle },
})(LinearProgress);

const renderCustomizedLabel = ({ cx, cy, text }) => {
  return (
    <>
      <text
        x={cx}
        y={cy - 25}
        textAnchor="middle"
        fill={COLORS.titleGrey}
        style={{ letterSpacing: "1.5px", fontSize: "16px" }}
      >
        {text}
      </text>
      <text x={cx} y={cy + 15} textAnchor="middle" fill={COLORS.titleGrey}>
        NPS
      </text>
    </>
  );
};

const getNPSColor = (text) => {
  return text === "promoters"
    ? COLORS.successGreen
    : text === "passives"
    ? COLORS.meliorYellow
    : text === "detractors"
    ? COLORS.failRed
    : COLORS.greyDisabled;
};

const capitalizeFirstLetter = (text) =>
  text[0].toUpperCase() + text.substring(1);

const SuccessLabelWithPercentage = ({ label, percentage, rawVal }) => (
  <Grid>
    <p>
      {capitalizeFirstLetter(label)}{" "}
      <span style={{ fontSize: "12px" }}>
        {rawVal} ({percentage.toFixed(1)}%)
      </span>
    </p>
    <SuccessPercentageBar variant="determinate" value={percentage} />
  </Grid>
);

const FailLabelWithPercentage = ({ label, percentage, rawVal }) => (
  <Grid>
    <p>
      {capitalizeFirstLetter(label)}{" "}
      <span style={{ fontSize: "12px" }}>
        {rawVal} ({percentage.toFixed(1)}%)
      </span>
    </p>
    <FailPercentageBar variant="determinate" value={percentage} />
  </Grid>
);

const NeutralLabelWithPercentage = ({ label, percentage, rawVal }) => (
  <Grid>
    <p>
      {capitalizeFirstLetter(label)}{" "}
      <span style={{ fontSize: "12px" }}>
        {rawVal} ({percentage.toFixed(1)}%)
      </span>
    </p>
    <NeutralPercentageBar variant="determinate" value={percentage} />
  </Grid>
);

const useStyles = makeStyles(() => ({
  displayOnHover: {
    opacity: 0,
  },
  root: {
    width: "100%",
    // height: 300,
    "&:hover $displayOnHover": {
      opacity: 1,
    },
  },
}));

export const NPSChart = (props) => {
  const classes = useStyles();
  const { miniVersion = false } = props;

  const [dataForChart, setDataForChart] = useState([]);
  const controlsRef = useRef();

  useEffect(() => {
    if (!props?.data) return;
    let NPSdata = [];
    NPSdata.push({ name: "promoters", value: props.data.promoters });
    NPSdata.push({ name: "passives", value: props.data.passives });
    NPSdata.push({ name: "detractors", value: props.data.detractors });
    setDataForChart(NPSdata);
  }, [props.data]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
    >
      <h2
        style={{
          fontSize: "1rem",
          fontWeight: "normal",
          textAlign: "center",
          margin: 0,
        }}
      >
        {props.title}
      </h2>
      {!miniVersion && (
        <Tooltip
          title={`Save as PNG`}
          className={classes.displayOnHover}
          ref={controlsRef}
        >
          <IconButton
            onClick={() =>
              downloadAsPNG(`NPS${props.title}`, `${props.title}-NPS`)
            }
            style={{
              padding: "10",
              transform: "rotate(90deg)",
              marginTop: "-15px",
            }}
            size="large"
          >
            <KeyboardTabIcon />
          </IconButton>
        </Tooltip>
      )}
      <Grid
        id={`NPS${props.title}`}
        style={{ height: "100%" }}
        container
        justifyContent="space-around"
        alignItems="flex-start"
      >
        {!miniVersion && (
          <Grid style={{ width: "30%" }}>
            <SuccessLabelWithPercentage
              label="promoters"
              percentage={
                !!props.data.promoters && !!props.data.total_count
                  ? (props.data.promoters / props.data.total_count) * 100
                  : 0
              }
              rawVal={props.data.promoters}
            />
            <NeutralLabelWithPercentage
              label="passives"
              percentage={
                !!props.data.passives && !!props.data.total_count
                  ? (props.data.passives / props.data.total_count) * 100
                  : 0
              }
              rawVal={props.data.passives}
            />
            <FailLabelWithPercentage
              label="detractors"
              percentage={
                !!props.data.detractors && !!props.data.total_count
                  ? (props.data.detractors / props.data.total_count) * 100
                  : 0
              }
              rawVal={props.data.detractors}
            />
          </Grid>
        )}

        <ResponsiveContainer
          height={miniVersion ? 200 : 220}
          width={miniVersion ? "100%" : "50%"}
        >
          <PieChart>
            <Pie
              data={dataForChart}
              isAnimationActive={false}
              cx="50%"
              cy="50%"
              innerRadius={65}
              outerRadius={75}
              startAngle={-30}
              endAngle={210}
              labelLine={false}
              label={(x) =>
                renderCustomizedLabel({
                  ...x,
                  text: `${parseFloat(props.data.value)?.toFixed(1)}`,
                })
              }
              dataKey="value"
            >
              {dataForChart.map((entry, index) => (
                <Cell key={`nps${index}`} fill={getNPSColor(entry.name)} />
              ))}
            </Pie>
            <RechartsTooltip />
            <Legend />
          </PieChart>
        </ResponsiveContainer>
      </Grid>
    </Grid>
  );
};
