import { ScreenContainer } from "../../../layout/ScreenContainer";
import { ForgotPasswordPage } from "../components/ForgotPasswordPage";

export const ForgotPasswordScreen = () => {
  return (
    <ScreenContainer>
      <ForgotPasswordPage />
    </ScreenContainer>
  );
};
