// import { TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import { useEffect } from "react";

export const FormTextInput = ({
  name,
  label,
  value,
  placeholder,
  type,
  //   endAdornment,
  width = undefined,
  sx,
}) => {
  //   const classes = useStyles();
  const { control } = useFormContext();

  // password visibility controller
  const [showPassword, setShowPassword] = useState(false);
  const [currentType, setCurrentType] = useState(type);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  useEffect(() => {
    type == "password" && showPassword
      ? setCurrentType("text")
      : type == "password" && !showPassword
      ? setCurrentType("password")
      : setCurrentType("text");
  }, [showPassword, type]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          size="small"
          error={!!error}
          onChange={onChange}
          value={value ? value : ""}
          placeholder={placeholder}
          type={currentType}
          label={label}
          variant="outlined"
          InputProps={{
            endAdornment:
              type == "password" ? (
                <InputAdornment position="end">
                  <IconButton onClick={handleClickShowPassword} edge="end">
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : undefined,
          }}
          style={{
            width: width ? width : "100%",
          }}
          sx={sx}
        />
      )}
    />
  );
};
