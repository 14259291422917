import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const toggleBookmarkStatus = ({ reviewID, currentBookmarkStatus }) => {
  let requestURL = `${API_URL}/reviews/` + reviewID;

  return melior.patch(requestURL, {
    bookmarked: !currentBookmarkStatus,
  });
};

export const useToggleBookmarkStatus = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reviews"], { type: "active" });
      queryClient.invalidateQueries(["reviews-with-complaints"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: toggleBookmarkStatus,
  });
};
