import AudioPlayer from "react-modular-audio-player";
import { makeStyles } from "@mui/styles";
import pauseIcon from "../../assets/pause_for_audio_component.png";
import playIcon from "../../assets/play_for_audio_component.png";

import { Grid } from "@mui/material";
import { COLORS } from "../../helpers/Colors";

const useStyles = makeStyles(() => ({
  audio: {
    "& .audio-player": {
      justifyContent: "center",
    },
    "& .audio-player-volume": {
      display: "none",
    },
  },
}));
const AudioPlayerComponent = ({ src, size = true, hideSeeking }) => {
  // const play
  const classes = useStyles();

  let playlist = [{ src }];

  return (
    <Grid container justifyContent="center" className={classes.audio}>
      <AudioPlayer
        audioFiles={playlist}
        playerWidth="100%"
        hideSeeking={hideSeeking !== undefined ? hideSeeking : !size}
        hideRewind={true}
        hideName={true}
        hideForward={true}
        hideLoop={true}
        pauseIcon={pauseIcon}
        pauseHoverIcon={pauseIcon}
        playIcon={playIcon}
        playHoverIcon={playIcon}
        hideTime={!size}
        fontFamily="Avenir-Heavy"
        fontSize="12px"
        iconSize="2rem"
        color={COLORS.meliorYellow}
      />
    </Grid>
  );
};

export default AudioPlayerComponent;
