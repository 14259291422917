import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import { NOTIFICATION_WIDTH, NotificationContext, NOTIFICATIONS_HORIZONTAL_POSITION, NOTIFICATIONS_TARGET, NOTIFICATIONS_TYPE, NOTIFICATIONS_VERTICAL_POSITION } from "../../../../contexts/NotificationContext";

export const replyToReputationResponse = ({
  reputationResponseID,
  replyText,
}) => {
  let requestURL = `${API_URL}/reputation/${reputationResponseID}/reply`;

  return melior.post(requestURL, {
    comment: replyText,
  });
};

export const useReplyToReputationResponse = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } =
    useContext(NotificationContext);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reputation-responses"], {
        type: "active",
      });
      queryClient.invalidateQueries(["reputation-response-activity-logs"], {
        type: "active",
      });

      fireNotification({
        title: "A reply has been added successfuly ",
        description:
          "A reply has been added and will be reflected here shortly",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
        target: NOTIFICATIONS_TARGET.admin,
        horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
        width: NOTIFICATION_WIDTH.small,
      });
    },
    onError: ({error}) => {
        fireNotification({
            title: "Failed to add a reply, please try again later",
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.bottom,
            target: NOTIFICATIONS_TARGET.admin,
            horizontalPosition: NOTIFICATIONS_HORIZONTAL_POSITION.left,
            width: NOTIFICATION_WIDTH.small,
          });
    },
    ...config,
    mutationFn: replyToReputationResponse,
  });
};
