import { Box, TextField } from "@mui/material";

export const LongTextLayout = ({ language, layoutProps, submitAnswer }) => {
  return (
    <TextField
      label={layoutProps.label}
      placeholder={layoutProps.placeholder}
      size="medium"
      variant="outlined"
      required={layoutProps.required}
      multiline
      rows={7}
      fullWidth
      sx={{
        "& .MuiInputBase-root": {
          "& input": {
            textAlign: language ? "right" : "left",
            fontFamily: language ? "BalooBhaijaan2" : "Nunito",
          },
        },
      }}
      onChange={(event) => {
        if (event.target.value) submitAnswer(event.target.value);
        else submitAnswer(null);
      }}
    />
  );
};
