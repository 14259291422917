import { Box } from "@mui/material";
import OnlineSurveyThankYouCard from "./OnlineSurveyThankYouCard";
import { OnlineSurveyWelcomeCard } from "./OnlineSurveyWelcomeCard";

const OnlineSurveyGreetingsCard = (props) => {
  return (
    <Box height="fit-content">
      {props.type === "welcome" ? (
        <OnlineSurveyWelcomeCard {...props} />
      ) : (
        <OnlineSurveyThankYouCard {...props} />
      )}
    </Box>
  );
};

export default OnlineSurveyGreetingsCard;
