import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { initializeFirebase } from "./firebase"
ReactDOM.render(<App />, document.getElementById("root"))
initializeFirebase()


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
