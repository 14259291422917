import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const getReputationComparisonTable = ({
  startDate,
  endDate,
  branches,
}) => {
  const requestURL = `${API_URL}/reputation-overview/branches-comparison`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
    },
  });
};

export const useGetReputationComparisonTable = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["reputation-comparison-table", params],
    queryFn: () => getReputationComparisonTable(params),
    keepPreviousData: true,
  });
};
