import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../../helpers/constants";

export const getBusinessProfileViews = ({ startDate, endDate, branches }) => {
  const requestURL = `${API_URL}/reputation-overview/profile-views`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
    },
  });
};

export const useGetBusinessProfileViews = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["business-profile-views", params],
    queryFn: () => getBusinessProfileViews(params),
    keepPreviousData: true,
  });
};
