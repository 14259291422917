export const COLORS = {
  white: "#fff",
  black: "#000",

  // meliorYellow: "#f0bc00",
  meliorYellow: "#ffcf3e",

  meliorNewYellowHeavy: "#FFB029",
  meliorNewYellowLight: "#FFF7EA",
  meliorNewYellowLightText: "#B57D1D",

  // meliorYellowGentle: "#f0bc0066",
  meliorYellowGentle: "#ffcf3e66",

  brightBlack: "#262222",
  newBlack: "101828",
  brightRed: "#ea846c",

  meliorYellowDisabled: "#c79e0a",
  greyDisabled: "#d3d3d3",

  // Fail and Success
  successGreen: "#1db401",
  successGreenGentle: "#1db40144",
  failRed: "#ba3616",
  failRedNew: "#B42318",
  failRedGentle: "#ba361644",
  failRedGentleNew: "#FFFBFA",
  failRedGentleBorderNew: "#FDA29B",
  warning: "#f0ad4e",

  // Satisfaction Graph lines
  OSBlue: "#71a3cc",
  RHAGreen: "#569943",
  CCIPurple: "#c964ed",
  MSYellow: "#ead96c",

  //Feedbacks graph
  excellent: "#5cb99a",
  good: "#83d1be",
  average: "#a5ded2",
  needImprovement: "#eeada1",

  //
  bad: "#e8775f",

  // Settings
  yellowBGTextField: "#fbf1c8",

  greyLightestNew: "#EAECF0",
  greyGentle: "#89898944",
  grey: "#898989",
  darkGrey: "#0000008a",
  silver: "#e9e9e9",
  titleGrey: "#666666",

  //purple
  facilityManagerChip: "#4601b444",
  //baby blue
  branchManagerChip: "#01b49344",
  // pink
  divisionManagerChip: "#b401b444",
  // dark green
  appOnlyChip: "#46901444",
  // salmon red
  feedbackOnlyChip: "#fa870244",

  archive: "#C48080",
  unarchive: "#A4CBA5",

  greenYesBoldNew: "#12B76A",
  lightRedNoBoldNew: "#FEE4E2",

  lightRedNPSNew: "#FECDCA",
  lightGreenNPSNew: "#A6F4C5",
  boldRedNPSNew: "#F04438",
  boldGreenNPSNew: "#12B76A",
  lightGreyNew: "#E4E7EC",
};
