import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getComplaintsClassificationDropdowns = () => {
  const requestURL = `${API_URL}/complaints-overview/classification-dropdowns`;
  return melior.get(requestURL);
};

export const useGetComplaintsClassificationDropdowns = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["complaints-classification-dropdowns"],
    queryFn: () => getComplaintsClassificationDropdowns(),
    keepPreviousData: true,
  });
};
