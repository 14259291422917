import { useParams } from "react-router-dom";
// import UserSettings from "../components/UserSettings";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { ProfileSettingsSubScreen } from "./ProfileSettingsSubScreen";
import { BranchesAndDivisionsSubScreen } from "./BranchesAndDivisionsSubScreen";
import { MembersSubScreen } from "./MembersSubScreen";
import { BillingSubScreen } from "./BillingSubScreen";
import { GiveFeedbackSubScreen } from "./GiveFeedbackSubScreen";
import { MeliorTabsRevamped } from "../../../reusable/components/MeliorTabsRevamped";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";

export const RevampedGeneralSettingsScreen = () => {
  const { revampedSettingsTab } = useParams();

  return (
    <MeliorTabsRevamped
    width="52rem"
      rootRouteParam="revamped-settings"
      dynamicRouteParam={revampedSettingsTab}
      tabsData={[
        {
          label: "Profile",
          routeParam: "profile",
          tabContent: <ProfileSettingsSubScreen />,
          permitted: true,
        },
        {
          label: "Branches & Divisions",
          routeParam: "branches-and-divisions",
          tabContent: <BranchesAndDivisionsSubScreen />,
          permitted: true,
        },
        {
          label: "Members",
          routeParam: "members",
          tabContent: <MembersSubScreen />,
          permitted: true,
        },
        {
          label: "Billing",
          routeParam: "billing",
          tabContent: <BillingSubScreen />,
          permitted: true,
        },
        {
          label: "Give Feedback",
          routeParam: "give-feedback",
          tabContent: <GiveFeedbackSubScreen />,
          permitted: true,
        },
      ]}
    />
  );
};
