import { Tab, Tabs } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ScreenForTabs from "../screens/ScreenForTabs";

const useStyles = makeStyles(() => ({
  tabs: {
    width: "100%",
  },
  tab: {
    width: "100%",
    fontFamily: "Avenir-Black",
    textTransform: "uppercase",
    transition: "0.5s",
    fontSize: "16px",
  },
  linkIcon: {
    width: "80%",
    margin: "10px auto",
    height: "30px",
    display: "block",
  },
}));

/**
 * @param {Object[]} tabsData Tabs and Their Content and routes
 * @param {string} tabsData[].label Tab Label
 * @param {import("react").ReactNode} tabsData[].icon Tab Icon
 * @param {string} tabsData[].routeParam Tab Route Param
 * @param {import("react").ReactNode} tabsData[].tabContent Tab COntent
 * @param {string} rootRouteParam Page's root Route Param
 * @param {string} dynamicRouteParam Page's dynamic Route Param (from useParams())
 * @returns
 */
export const MeliorTabs = ({
  tabsData,
  rootRouteParam,
  dynamicRouteParam,
  customMessage
}) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const tabRouteParamToTabIndex = {};
  const tabIndexToTabRouteParam = {};

  // Mappers
  tabsData.forEach((tabData, index) => {
    // Map RouteParam to its corresponding Tab Index
    tabRouteParamToTabIndex[tabData.routeParam] = index;
    // Map Tab Index to its corresponding Route Param
    tabIndexToTabRouteParam[index] = tabData.routeParam;
  });

  //Set the default Tab index from the one coming from the current Route Param.
  const [currentTabIndex, setCurrentTabIndex] = useState(
    tabRouteParamToTabIndex[dynamicRouteParam]
  );

  const handleTabChange = (event, newTabIndex) => {
    navigate(`/${rootRouteParam}/${tabIndexToTabRouteParam[newTabIndex]}`);
    setCurrentTabIndex(newTabIndex);
  };

  const tabs = (
    <Tabs
      value={currentTabIndex}
      onChange={handleTabChange}
      aria-label="icon position tabs example"
      className={classes.tabs}
      textColor="primary"
      indicatorColor="primary"
      TabIndicatorProps={{
        style: {
          height: "4px",
        },
      }}
      variant="fullWidth"
    >
      {tabsData.map((tabData, index) => {
        return (
          <Tab
            key={index}
            icon={<tabData.icon className={classes.linkIcon} />}
            label={<span className={classes.tab}>{tabData.label}</span>}
            disableRipple
          />
        );
      })}
    </Tabs>
  );

  const tabsContent = tabsData.filter(
    (tabData) => tabIndexToTabRouteParam[currentTabIndex] === tabData.routeParam
  )[0].tabContent;

  return <ScreenForTabs tabs={tabs} tabsContent={tabsContent} customMessage={customMessage} />;
};
