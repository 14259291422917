import { Box, Container, Typography } from "@mui/material";
import { ReportsHubPage } from "../components/ReportsHubPage";

export const ReportsHubScreen = () => {
  return (
    <Container maxWidth={false} sx={{mt: 4}}>
      <Box display="flex" flexDirection="column" gap={5} mt={2}>
        <ReportsHubPage />
      </Box>
    </Container>
  );
};
