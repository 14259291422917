import { Box } from "@mui/material";
import { AllMembersTable } from "../components/members/AllMembersTable";

export const MembersSubScreen = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <AllMembersTable />
    </Box>
  );
};
