export function setCookie(cname, cvalue, exdays = 0) {
  var d = new Date();

  // if(exdays > 0)
  // Always set the expiry time for survey submission cookie at 24 hours
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000);
  // else
  //     d.setHours(23, 59, 59, 999);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/online-survey/";
}

export function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  console.log(decodedCookie, "decodedCookie");
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return false;
}
