import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getBillingDetailsSummary = () => {
  const requestURL = `${API_URL}/billing`;

  return melior.get(requestURL);
};

export const useGetBillingDetailsSummary = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["billing-details-summary"],
    queryFn: () => getBillingDetailsSummary(),
    keepPreviousData: true,
  });
};
