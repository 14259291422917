import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";

export const DynamicFilters = ({
  selectedFiltersState,
  updateSelectedFilters,
  addShowAllOption,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="center"
    >
      {selectedFiltersState?.filtersData?.map(function renderDynamicFilter(
        filterData,
        index
      ) {
        return (
          <FormControl
            sx={{ m: 1, minWidth: 200, position: "relative" }}
            focused={false}
          >
            {selectedFiltersState[filterData.dropdownName] === "" && (
              <InputLabel
                sx={{
                  position: "absolute",
                  top: "-9px",
                  fontSize: "0.9rem",
                }}
                id={`select-label-${index}-${filterData.dropdownName}`}
              >
                {filterData?.dropdownName}
              </InputLabel>
            )}
            <Select
              key={index}
              name={filterData.dropdownName}
              labelId={`select-label-${index}-${filterData.dropdownName}`}
              sx={{ height: 35.5 }}
              value={selectedFiltersState[filterData.dropdownName]}
              onChange={updateSelectedFilters}
              disabled={
                filterData.dropdownOptions?.length === 0 ||
                filterData.disableDropdown
              }
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 235,
                    // width: 250,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              {addShowAllOption && <MenuItem value={"all"}>Show All</MenuItem>}
              {filterData.dropdownOptions?.map(function renderFilterOption(
                optionData
              ) {
                return (
                  <MenuItem key={optionData.value} value={optionData.value}>
                    {optionData.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};
