import { ComplaintsOverview } from "../components/complaints-overview/ComplaintsOverview";

export const ComplaintsOverViewSubScreen = ({
  selectedFiltersState,
  branchesAndDivisionsData,
}) => {
  return (
    <ComplaintsOverview
      selectedFiltersState={selectedFiltersState}
      branchesAndDivisionsData={branchesAndDivisionsData}
    />
  );
};
