import { useContext, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import {
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import { Add, Remove, RepeatRounded } from "@mui/icons-material";
import MenuProps from "./MenuProps";
import { BRANCH_MANAGER } from "../../../helpers/constants";
import { SettingsContext } from "../../../contexts/SettingsContext";

const useStyles = makeStyles({
  selectedNewInviteResponsibiity: {
    color: COLORS.meliorYellow,
    fontFamily: "Avenir-Heavy",
  },
  progress: {
    color: COLORS.meliorYellow,
    width: "30px",
    height: "30px",
  },
  noPadding: {
    padding: "0px",
  },
  repeat: {
    color: COLORS.meliorYellow,
    width: "30px",
    height: "30px",
    margin: "5px",
  },
});

const isBranchManager = (user) => {
  return user?.role === BRANCH_MANAGER;
};

const ResponsibilityListMenu = ({ user, object, setObject, pool }) => {
  const classes = useStyles();
  const { branches, getBranches, branchRetrievalError, loadingBranches } =
    useContext(SettingsContext);

  useEffect(() => {
    if (!!pool || !!branches) return;
    getBranches();
  }, [pool, branches, getBranches]);

  const getBranchDivisionsMenu = (branches, responsibilityList) => {
    let cells = [];
    if (!branches?.items) return;
    branches.items.map((branch) =>
      branch.divisions.map((division) =>
        cells.push(
          <MenuItem
            dense
            key={division.id}
            value={division.id}
            classes={{
              selected: classes.selectedNewInviteResponsibiity,
            }}
          >
            {responsibilityList.includes(division.id) ? (
              <Remove className={classes.menuItemIcon} />
            ) : (
              <Add className={classes.menuItemIcon} />
            )}
            {branch.name} - {division.name}
          </MenuItem>
        )
      )
    );
    return cells;
  };

  const getBranchesMenu = (branches, responsibilityList) => {
    let cells = [];
    if (!branches?.items) return;
    branches.items.map((branch) =>
      cells.push(
        <MenuItem
          dense
          key={branch.id}
          value={branch.id}
          classes={{ selected: classes.selectedNewInviteResponsibiity }}
        >
          {responsibilityList.includes(branch.id) ? (
            <Remove className={classes.menuItemIcon} />
          ) : (
            <Add className={classes.menuItemIcon} />
          )}
          {branch.name}
        </MenuItem>
      )
    );
    return cells;
  };

  const assignResponsibilty = (event) => {
    setObject({
      ...object,
      responsibility: event.target.value,
    });
  };

  // Branch manager and pool exist
  return isBranchManager(user) && object ? (
    <Select
      id="create-responsibility-list"
      value={object.responsibility}
      renderValue={() => "Responsibility List"}
      displayEmpty={true}
      MenuProps={MenuProps}
      multiple
      onChange={assignResponsibilty}
    >
      {getBranchesMenu(pool || branches, object.responsibility)}
    </Select>
  ) : branches ? (
    <Select
      id="create-responsibility-list"
      value={object.responsibility}
      renderValue={() => "Responsibility List"}
      displayEmpty={true}
      MenuProps={MenuProps}
      multiple
      onChange={assignResponsibilty}
    >
      {getBranchDivisionsMenu(branches, object.responsibility)}
    </Select>
  ) : loadingBranches ? (
    <CircularProgress className={classes.progress} />
  ) : branchRetrievalError.isError ? (
    <Tooltip title="Try again" position="top">
      <IconButton
        className={classes.noPadding}
        onClick={() => getBranches(true)}
        size="large">
        <RepeatRounded className={classes.repeat} />
      </IconButton>
    </Tooltip>
  ) : null;
};

export default ResponsibilityListMenu;
