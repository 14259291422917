import domtoimage from "dom-to-image";
import fileDownload from "js-file-download";
import AudioPlayerComponent from "../reusable/components/AudioPlayerComponent";

export const isEmpty = (obj) => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

export const isEmptyOrSpaces = (str) => {
  return str.toString() === null || str.toString().match(/^ *$/) !== null;
};

export const renderAudio = (url, large = true) => (
  <div style={{ overflow: "hidden" }}>
    <AudioPlayerComponent src={url} size={large} />
  </div>
);

export const getNavigatableSectionSlug = (title) => {
  const trimmedLowerCasedTitle = title.toLowerCase().trim();
  const sluggedTitle = trimmedLowerCasedTitle
    .replaceAll("- ", "-")
    .replaceAll(" ", "-");

  return sluggedTitle;
};

export const downloadAsPNG = (id, fileName, excludeRef) => {
  const x = document.getElementById(`${id}`);
  if (!x) return;
  domtoimage
    .toBlob(x, {
      bgcolor: "white",
      width: x.offsetWidth,
      height: x.offsetHeight + 40,
      quality: 1,
      style: { paddingTop: "20px" },
      filter: (node) => node !== excludeRef?.current,
    })
    .then(function (blob) {
      fileDownload(blob, `${fileName}.png`);
    });
};

export const getKeyByValueInObject = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
  
};

export const objectsDeeplyEqual = (firstObject, secondObject) => {
    const stringifiedFirstObject = JSON.stringify(firstObject);
    const stringifiedSecondObject = JSON.stringify(secondObject);
  
    return stringifiedFirstObject === stringifiedSecondObject;
  };