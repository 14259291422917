import { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Button, Slider, Typography, Box } from "@mui/material";
import { withStyles } from "@mui/styles";
import ArrowLeftIcon from "@mui/icons-material/KeyboardBackspace";

import {
  ANSWER_LAYOUT_TYPES,
  renderAnswerLayout,
} from "./helper-functions/AnswerLayoutPicker";
import { COLORS } from "../../helpers/Colors";
import { primaryColors } from "../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../helpers/customFont";
import Lottie from "react-lottie";
import { getAnimation } from "./api";
import { animationAndLogoDimentions } from "./OnlineSurveyWelcomeCard";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

const useStyles = makeStyles((theme) => ({
  question: {
    fontSize: "1.1rem",
    marginBottom: "1rem",

    color: COLORS.brightBlack,
  },
  footerButton: {
    textTransform: "none",
    height: "40px",
    fontSize: "14px",
    borderRadius: "10px",
    color: COLORS.brightBlack,
  },
  text: {
    fontSize: "14px",
  },
  icon: {
    margin: "auto",
    width: "50%",
    maxWidth: "50%",
    height: "auto",
  },
  skipIcon: {
    margin: "0px 8px",
    fontSize: "1.3rem",
  },
}));

const ColoredSlider = withStyles({
  root: {
    color: (props) => props.colorhex,
  },
})((props) => <Slider color="primary" {...props} />);

const OnlineSurveyQuestionCard = (props) => {
  const classes = useStyles();
  const {
    required: questionIsRequired,
    skip_optional_question_text: skipTextForBtn,
  } = props.data;

  const [animationFile, setAnimationFile] = useState(null);
  const [answerInput, setAnswerInput] = useState(null);

  const goNext = (answer) => {
    if (answerInput !== null) setAnswerInput(null);
    if (answer)
      props.nextPage({
        answerInfo: { answer, question: props.data.id },
        questionType: props.data["answer_type"],
      });
    else props.nextPage({ questionType: props.data["answer_type"] });
  };

  const cardFooter = () => (
    <Grid
      container
      justifyContent="space-between"
      width="94%"
      height="45px"
      alignItems="center"
    >
      <Button onClick={props.prevPage} className={classes.footerButton}>
        <ArrowLeftIcon />
        {props.language ? "السابق" : "Back"}
      </Button>
      <p className={classes.text} dir="auto">
        {props.language ? "صفحة" : "Page"} {props.currPage + 1}{" "}
        {props.language ? "من" : "of"} {props.totalPages}
      </p>
      {/* <Button className={classes.footerButton}>
          {props.language ? "اغلق" : "Close"}
          <CloseIcon />
        </Button> */}
      <div style={{ width: "70px" }}></div>
    </Grid>
  );

  const getFile = (fileURL) => {
    getAnimation(fileURL)
      .then((response) => {
        setAnimationFile(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderAnimation = ({ mediaType, mediaLink, animationFile }) => {
    if (mediaType && mediaLink) {
      if (mediaType === "animation") {
        return (
          <Lottie
            options={{ animationData: animationFile }}
            width={animationAndLogoDimentions.width}
            // height={animationAndLogoDimentions.height}
            height={"148px"}
          />
        );
      } else if (mediaType === "svg") {
        return (
          <img
            src={mediaLink}
            style={{
              //   height: animationAndLogoDimentions.height,
              height: "148px",
              width: animationAndLogoDimentions.width,
            }}
            alt="animationSVG"
          />
        );
      }
    }
  };
  const SkipButton = () => {
    return (
      <Button
        onClick={() => goNext(undefined)}
        sx={{
          fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
          fontSize: fontSizeVariations["text-md"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.brand[800],
          backgroundColor: primaryColors.brand[50],
          height: "44px",
          padding: "10px 16px",
          textTransform: "none",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: primaryColors.brand[50],
          },
        }}
      >
        {skipTextForBtn ? skipTextForBtn : "Skip"}
        <KeyboardArrowRightIcon className={classes.skipIcon} />
      </Button>
    );
  };
  const NextButton = () => {
    return (
      <Button
        onClick={() => {
          goNext(answerInput);
        }}
        disabled={answerInput === null}
        sx={{
          fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
          fontSize: fontSizeVariations["text-md"],
          fontWeight: fontWeightVariations.medium,
          color: primaryColors.base.white,
          backgroundColor:
            answerInput !== null
              ? primaryColors.brand[500]
              : primaryColors.gray[50],
          height: "44px",
          padding: "10px 16px",
          textTransform: "none",
          borderRadius: "8px",
          "&:hover": {
            backgroundColor: primaryColors.brand[500],
          },
        }}
      >
        {props.language ? "التالي" : "Next"}
        <KeyboardArrowRightIcon className={classes.skipIcon} />
      </Button>
    );
  };

  useEffect(() => {
    if (!props.data["media_link"]) return;
    if (props.data["media_type"] === "animation") {
      getFile(props.data["media_link"]);
    }
    // reset state for input answer
    setAnswerInput(null);
  }, [props.data]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      py={"20px"}
      px="15px"
      height="fit-content"
      gap={"16px"} //gap between animation and question
      textAlign="center"
    >
      <Box display="flex" width="100%" justifyContent="center">
        {renderAnimation({
          mediaType: props.data.media_type,
          mediaLink: props.data.media_link,
          animationFile,
        })}
      </Box>

      <Box display="flex" flexDirection="column">
        <Typography
          className={classes.question}
          sx={{
            color: primaryColors.gray[900],
            fontFamily: props.language ? "BalooBhaijaan2" : "Nunito",
            fontWeight: fontWeightVariations.semiBold,
            fontSize: fontSizeVariations["text-xl"],
            textAlign: props.language ? "right" : "left",
          }}
        >
          {props.language ? props.data.text : props.data.text}
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          height="405px"
        >
          {renderAnswerLayout({
            layoutType: props.data["answer_type"],
            language: props.language,
            layoutProps: {
              ...props.data["details"],
              choices: props.data["choices"],
            },
            submitAnswer:
              props.data["answer_type"] ===
                ANSWER_LAYOUT_TYPES.multiple_choices ||
              props.data["answer_type"] === ANSWER_LAYOUT_TYPES.nps ||
              props.data["answer_type"] === ANSWER_LAYOUT_TYPES.voice_note
                ? goNext
                : setAnswerInput,
          })}

          {/* skip & next buttons */}
          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            justifyContent="center"
            gap={"5px"} //gap between next & skip buttons
          >
            {!questionIsRequired && SkipButton()}
            {(props.data["answer_type"] === ANSWER_LAYOUT_TYPES.short_text ||
              props.data["answer_type"] === ANSWER_LAYOUT_TYPES.long_text ||
              props.data["answer_type"] === ANSWER_LAYOUT_TYPES.numbers ||
              props.data["answer_type"] === ANSWER_LAYOUT_TYPES.phone_number) &&
              NextButton()}
          </Box>
        </Box>
      </Box>
      {/* {cardFooter()} */}
    </Box>
  );
};
export default OnlineSurveyQuestionCard;
