import { useContext, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Grid, Container, Button, Tooltip, TextField } from "@mui/material";
import { CancelOutlined } from "@mui/icons-material";
import clsx from "clsx";
import { COLORS } from "../../../helpers/Colors";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import ButtonGroupSelect from "../../../reusable/components/ButtonGroupSelect";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import { suggestQuestion } from "../api";

const useStyles = makeStyles({
  container: {
    padding: "30px",
    position: "relative",
  },
  submitQuestion: {
    textTransform: "none",
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    borderRadius: "10px",
    marginTop: "10px",
    marginLeft: "10px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: COLORS.meliorYellowGentle,
    },
  },
  cancelSuggestQuestion: {
    float: "right",
    position: "absolute",
    right: "10px",
    top: "90px",
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "500px",
    maxWidth: "100%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "0px",
  },
  secondaryTextField: {
    marginLeft: "15px",
  },
  resizeFont: {
    fontSize: "14px",
  },
  formHeader: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
  },
  section: {
    margin: "10px",
  },
  header: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "16px",
  },
});

const CHOICE_BAG_OPTIONS = [
  { value: "yes_no", name: "Yes - No" },
  { value: "good_neutral_bad", name: "Good - Neutral - Bad" },
  {
    value: "excellent_good_average_needImprovement_bad",
    name: "Excellent - Good - Average - Need Improvement - Bad",
  },
  { value: "scale_1_5", name: "Scale from 1 to 5" },
  { value: "scale_1_10", name: "Scale from 1 to 10" },
  { value: "customizedText", name: "Text choices" },
];

const SuggestQuestion = ({ cancelEdit, disableEdits, setTempMessage }) => {
  const classes = useStyles();

  const [error, setError] = useState(false);
  const { user } = useContext(AuthenticationContext);
  const [questionText, setQuestionText] = useState("");
  const [questionExtraNotes, setQuestionExtraNotes] = useState("");
  const [questionChoiceType, setQuestionChoiceType] = useState("yes_no");
  const [questionChoiceTypeTextChoices, setQuestionChoiceTypeTextChoices] =
    useState("");

  return (
    <Container className={classes.container} maxWidth="xl">
      <Grid className={classes.body}>
        <Grid container justifyContent="center">
          {!error.isError ? null : (
            <UserErrorSuccessFormDisplay
              color={COLORS.white}
              type="error"
              message={error.errorMessage}
              width="90%"
              margin="10px auto"
              padding="15px 15px"
              bg={COLORS.failRed}
            />
          )}
        </Grid>
        <h1 className={classes.formHeader}>Suggest a question</h1>
        <Button
          className={classes.cancelSuggestQuestion}
          onClick={() => {
            cancelEdit();
          }}
        >
          <CancelOutlined />
        </Button>
        <UserErrorSuccessFormDisplay
          bg={COLORS.meliorYellowGentle}
          type="info"
          message={`Every question suggested goes through a screening process. We'll let
            you know as soon as the process is done.`}
          width="100%"
          margin="10px auto"
        />
        <div className={classes.section}>
          <h1 className={classes.header}>Question text</h1>
          <TextField
            id="newQuestionText"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={questionText}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setQuestionText(e.target.value);
            }}
          />
        </div>
        <div className={classes.section}>
          <h1 className={classes.header}>Preferred choice type</h1>
          <ButtonGroupSelect
            options={CHOICE_BAG_OPTIONS}
            setSelectedOption={setQuestionChoiceType}
            name="Select choice bag"
          />
          {questionChoiceType === "customizedText" && (
            <TextField
              id="newQuestionChoiceTypeTextChoices"
              className={clsx(classes.textField, classes.secondaryTextField)}
              placeholder={
                "Type in possible text choices separated by commas (lorem, ipsum,...)"
              }
              margin="normal"
              variant="outlined"
              value={questionChoiceTypeTextChoices}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={(e) => {
                setQuestionChoiceTypeTextChoices(e.target.value);
              }}
            />
          )}
        </div>
        <div className={classes.section}>
          <h1 className={classes.header}>Additional comments</h1>
          <TextField
            id="newQuestionExtraNotes"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={questionExtraNotes}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setQuestionExtraNotes(e.target.value);
            }}
          />
        </div>
        <Tooltip title="Submit question for review">
          <span>
            <Button
              variant="contained"
              component="span"
              className={classes.submitQuestion}
              disabled={!questionText.trim().length || disableEdits}
              onClick={() => {
                suggestQuestion({
                  text: questionText,
                  notes: {
                    "Choice type": questionChoiceType,
                    "Choice type text choices": questionChoiceTypeTextChoices,
                    "Quetsion extra notes": questionExtraNotes,
                    userEmail: user.email,
                    username: user.username,
                  },
                })
                  .then(() => {
                    cancelEdit();
                    setError({ isError: false, errorMessage: "" });
                    setTempMessage(
                      "We have received your question, We'll let you know once we review it!"
                    );
                  })
                  .catch(() => {
                    setError({
                      isError: true,
                      errorMessage:
                        "Network Error, We couldn't submit the question",
                    });
                  });
              }}
            >
              Done
            </Button>
          </span>
        </Tooltip>
      </Grid>
    </Container>
  );
};

export default SuggestQuestion;
