import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Box,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { FormTextInput } from "../../../reusable/components/form-inputs/controlled/FormTextInput";
import {
  FormTextarea,
  FormRadioInput,
  FormPhoneNumberInput,
  FormNumberInput,
  FormVoiceNoteInput,
} from "../../../reusable/components/form-inputs/controlled";
import { useEffect, useState } from "react";
import { ANSWER_LAYOUT_TYPES } from "../../../helpers/constants";

export const ReviewAnswerInputLayout = ({
  question,
  answerInput,
  resetField,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const [layoutType, setLayoutType] = useState();
  const errorMessage = errors[answerInput.name]
    ? errors[answerInput.name].message
    : null;

  //pick answer layout
  const renderAnswerInputLayout = () => {
    switch (layoutType) {
      case ANSWER_LAYOUT_TYPES.multiple_choices:
        return (
          <FormRadioInput
            name={answerInput.name}
            options={question.choices.map((choiceRawData, index) => {
              return { value: choiceRawData.id, label: choiceRawData.text };
            })}
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.medium,
            }}
            width="100%"
            required={question.required}
          />
        );

      case ANSWER_LAYOUT_TYPES.nps:
        return (
          <FormRadioInput
            name={answerInput.name}
            options={question.choices.map((choiceRawData, index) => {
              return { value: choiceRawData.id, label: choiceRawData.text };
            })}
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-sm"],
              fontWeight: fontWeightVariations.medium,
              width: "20%",
            }}
            width="300px"
            required={question.required}
          />
        );

      case ANSWER_LAYOUT_TYPES.short_text:
        return (
          <FormTextInput
            name={answerInput.name}
            placeholder={question.details.placeholder}
            type="text"
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-md"],
              fontWeight: fontWeightVariations.medium,
              color: primaryColors.gray[900],
              borderRadius: "8px",
              width: "100%",
              backgroundColor: primaryColors.base.white
            }}
          />
        );

      case ANSWER_LAYOUT_TYPES.long_text:
        return (
          <FormTextarea
            name={answerInput.name}
            placeholder={question.details.placeholder}
            rows={5}
            sx={{
              fontFamily: "Nunito",
              fontSize: fontSizeVariations["text-md"],
              fontWeight: fontWeightVariations.medium,
              color: primaryColors.gray[900],
              borderRadius: "8px",
              width: "100%",
              backgroundColor: primaryColors.base.white
            }}
          />
        );
      case ANSWER_LAYOUT_TYPES.numbers:
        return (
          <FormNumberInput
            name={answerInput.name}
            placeholder={question.details.placeholder}
          />
        );
      case ANSWER_LAYOUT_TYPES.phone_number:
        return (
          <FormPhoneNumberInput
            name={answerInput.name}
            country={question.details.country}
          />
        );
      case ANSWER_LAYOUT_TYPES.voice_note:
        return (
          <Grid container width="100%">
            <Grid item xs={12}>
              <FormVoiceNoteInput name={answerInput.name} />
            </Grid>
            <Grid container justifyContent="flex-end">
              <Button
                onClick={function changeLayout() {
                  resetField(answerInput.name);
                  setLayoutType(ANSWER_LAYOUT_TYPES.short_text);
                }}
              >
                <Typography
                  variant="text-sm"
                  fontFamily="Nunito"
                  textTransform="capitalize"
                  fontWeight={fontWeightVariations.medium}
                  color={primaryColors.gray[500]}
                >
                  Type the answer
                </Typography>
              </Button>
            </Grid>
          </Grid>
        );
      default:
        break;
    }
  };

  useEffect(
    function setLayoutDefaultValue() {
      setLayoutType(question.answer_type);
    },
    [question.answer_type]
  );

  return (
    <FormControl component="fieldset">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        paddingBottom="24px"
        margin="1px"
        borderBottom={`1px solid ${primaryColors.gray[200]}`}
      >
        <Grid
          container
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid container xs={12} md={5} display="flex" alignItems="center">
            <Grid
              display="flex"
              flexDirection={"column"}
              justifyContent="flex-start"
              className="labelBox"
              width="100%"
              gap={"5px"}
            >
              <FormLabel
                component="legend"
                sx={{
                  color: primaryColors.gray[900],
                  fontFamily: "Nunito",
                  fontWeight: fontWeightVariations.medium,
                  fontSize: fontSizeVariations["text-md"],
                }}
              >
                {question.required && question.text && (
                  <span style={{ color: primaryColors.error[500] }}>
                    *&nbsp;
                  </span>
                )}
                {question.text}
              </FormLabel>
              <Typography
                variant="text-lg"
                fontWeight={fontWeightVariations.semiBold}
                fontFamily={"Nunito"}
                color={primaryColors.gray[900]}
              >
                {question.question_text}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            md={6}
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            width="60%"
          >
            {/* form input here based on answer type filed value of question*/}
            {renderAnswerInputLayout()}
          </Grid>

          {/* <Grid item xs={12} display="block">
            <FormHelperText style={{ color: "red" }}>
              {errorMessage ? errorMessage : ""}
            </FormHelperText>
          </Grid> */}
        </Grid>
      </Box>
    </FormControl>
  );
};
