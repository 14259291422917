import {
  Snackbar,
  SnackbarContent,
  IconButton,
  Slide,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { COLORS } from "../../../helpers/Colors";

const UserSnackBar = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={props.autoHide ?? 4000}
      onClose={props.closeSnackBar}
      TransitionComponent={Slide}
    >
      <SnackbarContent
        message={props.message}
        style={{
          backgroundColor: props.backgroundColor,
          fontFamily: "Avenir-Medium",
          fontSize: "16px",
          color: COLORS.black,
        }}
        action={
          <>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={props.closeSnackBar}
              size="large">
              <CloseIcon />
            </IconButton>
          </>
        }
      />
    </Snackbar>
  );
};

export default UserSnackBar;
