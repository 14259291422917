import { Button, Typography, Box } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import { fontWeightVariations } from "../../../../helpers/customFont";

export const NPSLayout = ({ language, layoutProps, submitAnswer }) => {
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="end"
      height="fit-content"
      gap={1}
    >
      {layoutProps.choices?.map((choice, index) => {
        const firstChoice = index === 0;
        const lastChoice = index === layoutProps.choices.length - 1;

        return (
          <Box display="flex" flexDirection="column" mt="20px">
            {firstChoice && (
              <Typography
                variant="text-xs"
                sx={{
                  fontFamily: language ? "BalooBhaijaan2" : "Nunito",
                  fontWeight: fontWeightVariations.semiBold,
                  color: primaryColors.gray[800],
                  width: "100%",
                  textAlign: language ? "right" : "left",
                  textTransform: "none",
                }}
              >
                {language ? "سيئة جدا" : "Least Likely"}
              </Typography>
            )}
            {lastChoice && (
              <Typography
                variant="text-xs"
                sx={{
                  fontFamily: language ? "BalooBhaijaan2" : "Nunito",
                  fontWeight: fontWeightVariations.semiBold,
                  color: primaryColors.gray[800],
                  width: "100%",
                  textAlign: language ? "right" : "left",
                  textTransform: "none",
                }}
              >
                {language ? "ممتاز" : "Most Likely"}
              </Typography>
            )}
            <Button
              variant="contained"
              key={choice.id}
              onClick={() => submitAnswer(choice.id)}
              sx={{
                height: "56px",
                width: "56px",
                left: "0px",
                top: "22px",
                borderRadius: "8px",
                padding: "12px 20px",
                position: "unset",
                bgcolor: getChoiceColor(index),
                "&:hover": {
                  bgcolor: getChoiceColor(index),
                },
              }}
              size="small"
            >
              <Typography
                variant="display-xs"
                sx={{
                  fontFamily: language ? "BalooBhaijaan2" : "Nunito",
                  fontWeight: fontWeightVariations.semiBold,
                  color: NPSChoicesColors.text,
                  width: "100%",
                  textAlign: "center",
                  textTransform: "none",
                }}
              >
                {choice.text}
              </Typography>
            </Button>
          </Box>
        );
      })}
    </Box>
  );
};

const getChoiceColor = (choiceIndex) => {
  if (choiceIndex >= 0 && choiceIndex < 6) return NPSChoicesColors.npsRed;
  else if (choiceIndex >= 6 && choiceIndex < 8)
    return NPSChoicesColors.npsLightGreen;
  else if (choiceIndex >= 8) return NPSChoicesColors.npsGreen;
};

const NPSChoicesColors = {
  text: "#212121",
  npsGreen: " #D3F1D2",
  npsLightGreen: "#EAF4E6",
  npsRed: "#FFDDE0",
};
