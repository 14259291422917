import { createContext, useState, useContext } from "react";

import { AuthenticationContext } from "../features/login/contexts/AuthenticationContext";

export const BranchContext = createContext();

const BranchContextProvider = (props) => {
  const [settingsBranch, setSettingsBranch] = useState("0");
  const [currBranchInfo, setCurrBranchInfo] = useState({});

  return (
    <BranchContext.Provider
      value={{
        settingsBranch,
        setSettingsBranch,
        currBranchInfo,
        setCurrBranchInfo,
      }}
    >
      {props.children}
    </BranchContext.Provider>
  );
};

export { BranchContextProvider };
