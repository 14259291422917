import { useContext, useEffect, useState } from "react";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import { getBranchInfo } from "../../overall-view/api";

export const useSelectBranchAndDivision = () => {
  const { user } = useContext(AuthenticationContext);

  const [CBopen, setCBOpen] = useState(false);
  const [CDopen, setCDOpen] = useState(false);
  const [error, setError] = useState({
    isError: false,
    errorMessage: "",
  });
  const [selectedBranchID, setSelectedBranchID] = useState(0);
  const [divisions, setDivisions] = useState({});
  const [selectedDivisionID, setSelectedDivisionID] = useState(0);
  const [templateID, setTemplateID] = useState(undefined);

  //Template ID: Update the selected Template ID according to the currently selected Division/Branch
  useEffect(() => {
    if (selectedDivisionID !== 0 && divisions[selectedBranchID] !== undefined) {
      divisions[selectedBranchID].forEach((division) => {
        //Update template ID if related to the selected branch
        if (divisions[selectedBranchID].template !== null) {
          setTemplateID(divisions[selectedBranchID].template.id);
          //Update Template ID if related to the selected division
        } else if (
          division.id === selectedDivisionID &&
          division.template !== undefined
        ) {
          setTemplateID(division.template.id);
        }
      });
    }
  }, [selectedDivisionID, setTemplateID, divisions, selectedBranchID]);

  useEffect(() => {
    if (selectedBranchID === 0) {
      return;
    }
    if (!user.branches.length) {
      setError({
        isError: true,
        errorMessage: "You don't have enough permissions to proceed.",
      });
      return;
    }
    setError({
      isError: false,
      errorMessage: "",
    });
    if (divisions[selectedBranchID]) {
      if (divisions[selectedBranchID].length === 1) {
        setSelectedDivisionID(divisions[selectedBranchID][0].id);
      } else {
        setSelectedDivisionID(0);
      }
      return;
    }
    const newDivisions = { ...divisions };
    getBranchInfo(selectedBranchID)
      .then((selectedBranchData) => {
        newDivisions[selectedBranchID] = selectedBranchData.divisions;
        newDivisions[selectedBranchID].template = selectedBranchData.template;
        //Template ID: Update the selected Template ID according to the currently selected Branch
        // if (selectedBranchData.template !== null && selectedBranchData.template !== undefined) {
        //   setTemplateID(selectedBranchData.template.id);
        // }
        if (!selectedBranchData.divisions.length) {
          setError({
            isError: true,
            errorMessage: "You don't have enough permissions to proceed.",
          });
          return;
        }
        setDivisions(newDivisions);
        setError({
          isError: false,
          errorMessage: "",
        });

        if (selectedBranchData.divisions.length === 1) {
          setSelectedDivisionID(selectedBranchData.divisions[0].id);
        } else {
          setSelectedDivisionID(0);
        }
      })
      .catch(() =>
        setError({
          isError: true,
          errorMessage: "Netword Error, please try again later",
        })
      );
  }, [selectedBranchID]);

  return {
    divisions,
    selectedDivisionID,
    selectedBranchID,
    templateID,
    CBopen,
    setCBOpen,
    CDopen,
    setCDOpen,
    error,
    setSelectedBranchID,
    setSelectedDivisionID,
  };
};
