import { useContext, useEffect, useRef, useState } from "react";
import { makeStyles, withStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Grid, IconButton, TextField, Tooltip } from "@mui/material";

import { Close, Edit, Delete, Done, Add, DeviceHub } from "@mui/icons-material";
import clsx from "clsx";
import { BlockPicker } from "react-color";
import { COLORS } from "../../../../helpers/Colors";
import {
  actionNotAllowedDemo,
  actionNotAllowedGeneral,
} from "../../../../helpers/constants";
import ConfirmActionModal from "../../../../reusable/components/ConfirmActionModal";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import { SettingsContext } from "../../../../contexts/SettingsContext";
import UserErrorSuccessFormDisplay from "../../../../reusable/components/UserErrorSuccessFormDisplay";
import LoadingPage from "../../../../layout/LoadingPage";
import { ColorSwap } from "../../../../reusable/components/ColorSwap";
import { createEntity, deleteEntity, editEntity } from "../../api";

const useStyles = makeStyles({
  TableContainer: {
    maxWidth: "90vw",
    overflow: "auto",
    border: `1px solid ${COLORS.meliorYellowGentle} `,
    maxHeight: "600px",
  },
  table: {
    minWidth: "650px",
    overflow: "auto",
  },
  fixedWidth: {
    minWidth: "160px",
    maxWidth: "160px",
  },
  warning: {
    color: COLORS.failRed,
  },
  safe: {
    color: COLORS.successGreen,
  },
  row: {
    "&:nth-of-type(odd)": {
      backgroundColor: COLORS.silver,
    },
  },
  selectedRow: {
    backgroundColor: COLORS.meliorYellowGentle,
  },
  absolute: {
    position: "absolute !important",
  },
});

const AddIcon = withStyles({
  root: (props) => ({
    color: COLORS.white,
    fontSize: "30px",
    background: props.disabled ? COLORS.greyDisabled : COLORS.meliorYellow,
    borderRadius: "50%",
  }),
})(Add);

const BRANCH_COLORS = [
  "#d9e3f0",
  "#f47373",
  "#697689",
  "#37d67a",
  "#2ccce4",
  "#555555",
  "#dce775",
  "#ff8a65",
  "#ba68c8",
  "#f15025",
  "#007ea7",
  "#042a2b",
  "#f3d053",
  "#031a6b",
  "7d7e8c",
];

const TableHeader = ({
  type,
  showActions,
  setNew,
  setEdited,
  isMax,
  isDemo,
  disableEdits,
}) => (
  <TableHead>
    <TableRow
      style={{ borderBottom: `2px solid ${COLORS.meliorYellowGentle} ` }}
    >
      <TableCell style={{ fontSize: "1.1rem", fontFamily: "Avenir-Heavy" }}>
        {type === "Branch" ? "Branch" : "Department"} Name
      </TableCell>
      {type === "Division" ? null : (
        <TableCell
          style={{ fontSize: "1.1rem", fontFamily: "Avenir-Heavy" }}
          align="center"
        >
          Number of Departments
        </TableCell>
      )}
      {type === "Division" ? null : (
        <TableCell
          style={{ fontSize: "1.1rem", fontFamily: "Avenir-Heavy" }}
          align="center"
        >
          Branch Color
        </TableCell>
      )}
      {!showActions ? null : (
        <TableCell
          style={{
            fontSize: "1.1rem",
            fontFamily: "Avenir-Heavy",
            marginRight: "15px",
          }}
          align="right"
        >
          <p style={{ marginRight: "10px" }}>Actions</p>
        </TableCell>
      )}
      {!showActions ? null : (
        <TableCell align="right" style={{ width: "30px" }}>
          <Tooltip
            title={
              isDemo
                ? actionNotAllowedDemo
                : disableEdits
                ? actionNotAllowedGeneral
                : `New ${type}`
            }
            position="top"
          >
            <span>
              <IconButton
                disabled={isMax || disableEdits}
                onClick={() => {
                  setEdited({ active: -1, name: "" });
                  setNew({ name: "", active: true });
                }}
                size="large"
              >
                <AddIcon disabled={isMax || disableEdits} />
              </IconButton>
            </span>
          </Tooltip>
        </TableCell>
      )}
    </TableRow>
  </TableHead>
);

const NewEntityRow = ({
  type,
  setNewBranch,
  setNewDivision,
  newBranch,
  newDivision,
  classes,
  createNewEntity,
}) => {
  const newFunction = type === "Branch" ? setNewBranch : setNewDivision;
  const newValue = type === "Branch" ? newBranch : newDivision;

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          type="name"
          id="branch1"
          value={newValue.name}
          onChange={(e) => newFunction({ ...newValue, name: e.target.value })}
        />
      </TableCell>
      {type === "Division" ? null : <TableCell align="center">1</TableCell>}
      {type === "Division" ? null : (
        <TableCell align="center">
          <BlockPicker
            value={newValue.color}
            color={newValue.color}
            onChange={(color) => {
              newFunction({ ...newValue, color: color.hex });
            }}
            className={classes.absolute}
            colors={BRANCH_COLORS}
          />
        </TableCell>
      )}
      <TableCell align="right">
        <Tooltip title={`Create ${type}`} position="top">
          <IconButton
            className={classes.safe}
            onClick={() => createNewEntity({ type })}
            size="large"
          >
            <Done />
          </IconButton>
        </Tooltip>
        <Tooltip title="Cancel" position="top">
          <IconButton
            onClick={() => newFunction({ name: "", active: false })}
            size="large"
          >
            <Close />
          </IconButton>
        </Tooltip>
      </TableCell>
      <TableCell style={{ width: "10px" }} />
    </TableRow>
  );
};

const Row = ({
  row,
  index,
  type,
  setEditedBranch,
  setEditedDivision,
  editedBranch,
  editedDivision,
  setNewBranch,
  setNewDivision,
  classes,
  isFacilityManager,
  isBranchManager,
  confirmEdits,
  deleteEntity,
  goToDivision,
  forbidDelete,
  activeBranch,
  disableEdits,
  isDemo,
}) => {
  const editFunction = type === "Branch" ? setEditedBranch : setEditedDivision;
  const editedValue = type === "Branch" ? editedBranch : editedDivision;
  const newFunction = type === "Branch" ? setNewBranch : setNewDivision;
  const [openModal, setOpenModal] = useState(false);

  return (
    <TableRow
      className={clsx(
        row.id !== activeBranch ? classes.row : classes.selectedRow
      )}
    >
      <TableCell className={classes.fixedWidth} component="th" scope="row">
        {parseInt(editedValue.acive) === row.id ? (
          <TextField
            type="name"
            id="branch1"
            value={editedValue.name}
            onChange={(e) =>
              editFunction({ ...editedValue, name: e.target.value })
            }
          />
        ) : (
          row.name
        )}
      </TableCell>

      {type === "Division" ? null : (
        <TableCell align="center">{row.divisions.length}</TableCell>
      )}

      {type === "Division" ? null : (
        <TableCell align="center">
          {parseInt(editedValue.acive) === row.id ? (
            <BlockPicker
              value={editedValue.color ?? "#eee"}
              color={editedValue.color ?? "#eee"}
              onChange={(color) => {
                editFunction({ ...editedValue, color: color.hex });
              }}
              className={classes.absolute}
              colors={BRANCH_COLORS}
            />
          ) : (
            <ColorSwap color={row.color ?? "#eee"} />
          )}
        </TableCell>
      )}

      {!isFacilityManager && !isBranchManager && !isDemo ? null : (
        <TableCell align="right" className={classes.fixedWidth}>
          {/* ACCESS 
        facility manager and branch managers only */}
          {parseInt(editedValue.acive) !== row.id ? (
            <Tooltip
              title={
                isDemo
                  ? actionNotAllowedDemo
                  : disableEdits
                  ? actionNotAllowedGeneral
                  : `Edit ${type}`
              }
              position="top"
            >
              <span>
                <IconButton
                  disabled={disableEdits}
                  onClick={() => {
                    editFunction({
                      acive: row.id,
                      name: row.name,
                      color: row.color ?? "#eee",
                    });
                    newFunction({ acive: false, name: "" });
                  }}
                  size="large"
                >
                  <Edit />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <>
              <Tooltip title="Save" position="top">
                <IconButton
                  className={classes.safe}
                  onClick={() => confirmEdits({ row: row, type: type })}
                  size="large"
                >
                  <Done />
                </IconButton>
              </Tooltip>
              <Tooltip title="Cancel" position="top">
                <IconButton
                  onClick={() => {
                    editFunction({ active: -1, name: "" });
                  }}
                  size="large"
                >
                  <Close />
                </IconButton>
              </Tooltip>
            </>
          )}
          {type === "Division" ? null : (
            <Tooltip title="Go To Departments" position="top">
              <IconButton
                onClick={() => goToDivision({ id: row.id, index: index })}
                size="large"
              >
                <DeviceHub />
              </IconButton>
            </Tooltip>
          )}

          {/* ACCESS 
        facility manager*/}
          {!(isFacilityManager || isDemo) ? null : (
            <Tooltip
              title={
                isDemo
                  ? actionNotAllowedDemo
                  : forbidDelete
                  ? actionNotAllowedGeneral
                  : `Delete ${type === "Branch" ? "Branch" : "Department"}`
              }
              position="top"
            >
              <span>
                <IconButton
                  disabled={forbidDelete}
                  className={classes.warning}
                  onClick={() => setOpenModal(true)}
                  size="large"
                >
                  <Delete />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </TableCell>
      )}

      {!isFacilityManager && !isBranchManager && !isDemo ? null : (
        <TableCell style={{ width: "10px" }} />
      )}

      <ConfirmActionModal
        question={`Are you sure you want to delete ${row.name}?`}
        onConfirm={{
          text: "Delete",
          type: "danger",
          function: () => deleteEntity({ id: row.id, type: type }),
        }}
        openModal={openModal}
        closeModal={() => setOpenModal(false)}
      />
    </TableRow>
  );
};

export const BranchSettings = ({ disableEdits }) => {
  const classes = useStyles();
  const { user, isFacilityManager, isBranchManager, isDemo } = useContext(
    AuthenticationContext
  );
  const { branches, getBranches, loadingBranches } =
    useContext(SettingsContext);

  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [divisions, setDivisions] = useState({ active: -1, items: [] });

  const [editedBranch, setEditedBranch] = useState({
    active: -1,
    name: "",
    color: "#000000",
  });
  const [newBranch, setNewBranch] = useState({
    active: false,
    name: "",
    color: "#000000",
  });

  const [editedDivision, setEditedDivision] = useState({
    active: -1,
    name: "",
  });

  const [newDivision, setNewDivision] = useState({
    active: false,
    name: "",
  });

  const myRef = useRef(null);

  const executeScroll = () =>
    myRef &&
    myRef.current &&
    myRef.current.scrollIntoView({ behavior: "smooth" });

  useEffect(() => {
    // Handles url change when branch is selected and geting the divsisions table in view
    if (!divisions) return;
    if (divisions.active !== -1) {
      window.history.pushState(
        "",
        "",
        `/settings/branches?branch=${divisions.active}`
      );
      executeScroll();
    }
  }, [divisions]);

  useEffect(() => {
    if (!user) return;
    if (branches) {
      if (divisions.active !== -1 || window.location.href.includes("branch=")) {
        let id = divisions.active;
        if (divisions.active === -1)
          id = window.location.href.split("branch=")[1];

        let openBranch = branches.items.filter(
          (branch) => branch["id"] === parseInt(id)
        );
        if (openBranch.length !== 0) {
          setDivisions({
            active: id,
            items: openBranch[0]["divisions"],
          });
        } else {
          setDivisions({ active: -1, items: [] });
        }
      }
      setError({ isError: false });
    } else getAllBranchesHandler();
  }, [user]);

  useEffect(() => {
    if (!branches) return;
    if (divisions.active !== -1 || window.location.href.includes("branch=")) {
      let id = divisions.active;
      if (divisions.active === -1)
        id = window.location.href.split("branch=")[1];

      let openBranch = branches.items.filter(
        (branch) => branch["id"] === parseInt(id)
      );
      if (openBranch.length !== 0) {
        setDivisions({
          active: id,
          items: openBranch[0]["divisions"],
        });
      } else {
        setDivisions({ active: -1, items: [] });
      }
    }
  }, [branches]);

  const getAllBranchesHandler = () => {
    getBranches(true);
  };

  const goToDivision = ({ id, index }) => {
    if (index)
      setDivisions({
        items: branches.items[index]["divisions"],
        active: id,
      });
    else {
      let openBranch = branches.items.filter((branch) => branch["id"] === id);
      if (openBranch.length !== 0) {
        setDivisions({
          active: id,
          items: openBranch[0]["divisions"],
        });
      }
    }
  };

  const deleteEntityHandler = ({ id, type }) => {
    if (type === "Division" && divisions.items.length === 1) {
      setError({
        isError: true,
        errorMessage:
          "You can't delete the last division in your branch, Create a a new one then delete this one",
      });
      return;
    }
    if (type === "Branch" && branches.items.length === 1) {
      setError({
        isError: true,
        errorMessage:
          "You can't delete the last branch in your facility, Create a a new one then delete this one",
      });
      return;
    }
    deleteEntity({ id: id, type: type })
      .then(() => {
        getAllBranchesHandler();
        setError({ isError: false });
      })
      .catch(() =>
        setError({
          isError: false,
          errorMessage: "Network error, please try again later",
        })
      );
  };

  const confirmEdits = ({ row, type }) => {
    const editedName =
      type === "Branch" ? editedBranch.name : editedDivision.name;
    const editedColor = editedBranch.color;

    const editFunction =
      type === "Branch" ? setEditedBranch : setEditedDivision;

    if (type === "Division") {
      if (editedName.trim() === "" || editedName.trim() === row.name) {
        editFunction({ active: -1, name: "" });
        return;
      }
    }
    if (type === "Branch") {
      if (
        (editedName.trim() === "" || editedName.trim() === row.name) &&
        editedColor.trim() === row.color
      ) {
        editFunction({ active: -1, name: "" });
        return;
      }
    }
    editEntity({
      id: row.id,
      color: editedColor,
      newName: editedName,
      profile: user.profile.id,
      type: type,
    })
      .then(() => {
        editFunction({ active: -1, name: "" });
        getAllBranchesHandler();
        setError({ isError: false });
      })
      .catch(() => {
        setError({
          isError: true,
          errorMessage: "Network Error, please try again later",
        });
      });
  };

  const createNewEntity = ({ type }) => {
    const newValue = type === "Branch" ? newBranch : newDivision;
    const newFunction = type === "Branch" ? setNewBranch : setNewDivision;
    const max_amount =
      type === "Branch"
        ? branches.items.length >= branches["branches_max_count"]
        : divisions.items.length >= branches["divisions_max_count"];

    if (max_amount) {
      setError({
        isError: true,
        errorMessage: `Max ${type} count achieved, Upgrade to proceed`,
      });
      return;
    }

    if (newValue.name.trim() === "") {
      setError({
        isError: true,
        errorMessage: "You can't create a branch with no name",
      });
      return;
    }

    // Color is required for New Branches only. Not Divisions.
    if (newValue.color === undefined && type === "Branch") {
      setError({
        isError: true,
        errorMessage: "You can't create a branch without a Color",
      });
      return;
    }

    let payload = {
      type: type,
      name: newValue.name,
      name_ar: newValue.name,
    };
    if (type === "Branch") {
      payload.id = user.profile.id;
      payload.color = newValue.color;
    } else payload.id = divisions.active;

    createEntity(payload)
      .then(() => {
        getAllBranchesHandler();
        newFunction({ active: false, name: "" });
        setError({ isError: false });
      })
      .catch((error) => {
        setError({
          isError: true,
          errorMessage: error.response.data.detail,
        });
      });
  };

  return (
    <Grid container justifyContent="center">
      {!error.isError ? null : (
        <UserErrorSuccessFormDisplay
          color={COLORS.white}
          type="error"
          message={error.errorMessage}
          width="90%"
          margin="10px auto"
          padding="15px 15px"
          bg={COLORS.failRed}
        />
      )}
      {loadingBranches || !branches ? (
        <div>
          <LoadingPage height="70vh" />
        </div>
      ) : (
        <TableContainer className={classes.TableContainer}>
          {/*           
            BRANCHES TABLE
          */}
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHeader
              showActions={isFacilityManager || isBranchManager || isDemo}
              setNew={setNewBranch}
              setEdited={setEditedBranch}
              type="Branch"
              isMax={branches.items.length >= branches["branches_max_count"]}
              disableEdits={disableEdits}
              isDemo={isDemo}
            />
            <TableBody>
              {!isFacilityManager &&
              !isBranchManager ? null : !newBranch.active ? null : (
                <NewEntityRow
                  type="Branch"
                  setNewBranch={setNewBranch}
                  setNewDivision={setNewDivision}
                  newBranch={newBranch}
                  newDivision={newDivision}
                  classes={classes}
                  createNewEntity={createNewEntity}
                />
              )}
              {branches.items.map((row, index) => (
                <Row
                  key={row.id}
                  index={index}
                  row={row}
                  type="Branch"
                  setEditedBranch={setEditedBranch}
                  setEditedDivision={setEditedDivision}
                  editedBranch={editedBranch}
                  editedDivision={editedDivision}
                  setNewBranch={setNewBranch}
                  setNewDivision={setNewDivision}
                  classes={classes}
                  isFacilityManager={isFacilityManager}
                  isBranchManager={isBranchManager}
                  confirmEdits={confirmEdits}
                  deleteEntity={deleteEntityHandler}
                  goToDivision={goToDivision}
                  forbidDelete={branches.items.length === 1 || disableEdits}
                  activeBranch={parseInt(divisions.active)}
                  disableEdits={disableEdits}
                  isDemo={isDemo}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {loadingBranches || divisions.active === -1 ? null : (
        <div
          ref={myRef}
          style={{ marginTop: "50px", minHeight: "400px", width: "90vw" }}
        >
          {/*           
            DIVISIONS TABLE
          */}
          <TableContainer className={classes.TableContainer}>
            <Table
              className={classes.table}
              size="small"
              aria-label="a dense table"
            >
              <TableHeader
                showActions={isFacilityManager || isBranchManager || isDemo}
                setNew={setNewDivision}
                setEdited={setEditedDivision}
                type="Division"
                isMax={
                  divisions.items.length >= branches["divisions_max_count"]
                }
                disableEdits={disableEdits}
                isDemo={isDemo}
              />
              <TableBody>
                {!isFacilityManager &&
                !isBranchManager ? null : !newDivision.active ? null : (
                  <NewEntityRow
                    type="Division"
                    setNewBranch={setNewBranch}
                    setNewDivision={setNewDivision}
                    newBranch={newBranch}
                    newDivision={newDivision}
                    classes={classes}
                    createNewEntity={createNewEntity}
                  />
                )}

                {divisions.items.map((row) => (
                  <Row
                    key={row.id}
                    row={row}
                    type="Division"
                    setEditedBranch={setEditedBranch}
                    setEditedDivision={setEditedDivision}
                    editedBranch={editedBranch}
                    editedDivision={editedDivision}
                    setNewBranch={setNewBranch}
                    setNewDivision={setNewDivision}
                    classes={classes}
                    isFacilityManager={isFacilityManager}
                    isBranchManager={isBranchManager}
                    confirmEdits={confirmEdits}
                    deleteEntity={deleteEntityHandler}
                    goToDivision={goToDivision}
                    forbidDelete={divisions.items.length === 1 || disableEdits}
                    disableEdits={disableEdits}
                    isDemo={isDemo}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </Grid>
  );
};
