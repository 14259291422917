import {
  MultipleChoiceLayout,
  LongTextLayout,
  NPSLayout,
  NumbersLayout,
  PhoneNumberLayout,
  ShortTextLayout,
  VoiceNoteLayout,
} from "../components/answer-layouts";

const renderMultipleChoices = ({ language, layoutProps, submitAnswer }) => (
  <MultipleChoiceLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderNPSChoices = ({ language, layoutProps, submitAnswer }) => (
  <NPSLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderShortTextAnswerLayout = ({
  language,
  layoutProps,
  submitAnswer,
}) => (
  <ShortTextLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderLongTextAnswerLayout = ({
  language,
  layoutProps,
  submitAnswer,
}) => (
  <LongTextLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderNumbersAnswerLayout = ({ language, layoutProps, submitAnswer }) => (
  <NumbersLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderPhoneNumberAnswerLayout = ({
  language,
  layoutProps,
  submitAnswer,
}) => (
  <PhoneNumberLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);
const renderVoiceNoteAnswerLayout = ({
  language,
  layoutProps,
  submitAnswer,
}) => (
  <VoiceNoteLayout
    language={language}
    layoutProps={layoutProps}
    submitAnswer={submitAnswer}
  />
);

export const renderAnswerLayout = ({
  layoutType,
  language,
  layoutProps,
  submitAnswer,
}) => {
  switch (layoutType) {
    case ANSWER_LAYOUT_TYPES.multiple_choices: {
      return renderMultipleChoices({ language, layoutProps, submitAnswer });
    }
    case ANSWER_LAYOUT_TYPES.nps: {
      return renderNPSChoices({ language, layoutProps, submitAnswer });
    }
    case ANSWER_LAYOUT_TYPES.short_text: {
      return renderShortTextAnswerLayout({
        language,
        layoutProps,
        submitAnswer,
      });
    }
    case ANSWER_LAYOUT_TYPES.long_text: {
      return renderLongTextAnswerLayout({
        language,
        layoutProps,
        submitAnswer,
      });
    }
    case ANSWER_LAYOUT_TYPES.numbers: {
      return renderNumbersAnswerLayout({ language, layoutProps, submitAnswer });
    }
    case ANSWER_LAYOUT_TYPES.phone_number: {
      return renderPhoneNumberAnswerLayout({
        language,
        layoutProps,
        submitAnswer,
      });
    }
    case ANSWER_LAYOUT_TYPES.voice_note: {
      return renderVoiceNoteAnswerLayout({
        language,
        layoutProps,
        submitAnswer,
      });
    }
    default:
      break;
  }
};

export const ANSWER_LAYOUT_TYPES = {
  multiple_choices: "multiple_choices",
  nps: "nps",
  short_text: "short_text",
  long_text: "long_text",
  numbers: "numbers_only",
  phone_number: "phone_number",
  voice_note: "voice_note",
};
