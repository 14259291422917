import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const deactivateUserProfile = () => {
  let requestURL = `${API_URL}/user/deactivate`;
  return melior.post(requestURL);
};

export const useDeactivateUserProfile = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["current-user-details"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: deactivateUserProfile,
  });
};
