import { useRef } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../helpers/Colors";
import {
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import { useState } from "react";
const useStyles = makeStyles(() => ({
  mainButton: {
    width: "500px",
    background: COLORS.yellowBGTextField,
    maxWidth: "100%",
    borderRadius: "10px",
    border: "1px solid !important",
    borderColor: `${COLORS.meliorYellow} !important`,
    marginTop: "0px",
    textTransform: "none",
    padding: "15px",
    justifyContent: "start",
  },
  arrowDropButton: {
    background: COLORS.yellowBGTextField,
  },
  menuItem: {
    padding: "14px 10px",
  },
}));

const ButtonGroupSelect = ({ options, setSelectedOption, name }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedOption(options[index]?.value);
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button className={classes.mainButton}>
          {options[selectedIndex].name}
        </Button>
        <Button
          size="small"
          aria-controls={open ? { name } : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label={name}
          aria-haspopup="menu"
          onClick={handleToggle}
          className={classes.arrowDropButton}
        >
          <ArrowDropDownCircleOutlined />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id={name}>
                  {options.map((option, index) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={option.value}
                      selected={index === selectedIndex}
                      onClick={() => handleMenuItemClick(index)}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ButtonGroupSelect;
