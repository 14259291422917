import { Grid, IconButton } from "@mui/material";
import { useRef } from "react";
import {
  PieChart,
  Pie,
  ResponsiveContainer,
  Cell,
  Tooltip as RechartsTooltip,
} from "recharts";
import Tooltip from "@mui/material/Tooltip";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";

const RADIAN = Math.PI / 180;

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const distanceFromCenter = percent > 0.1 ? 0.5 : percent > 0.05 ? 0.7 : 0.8;
  const radius = innerRadius + (outerRadius - innerRadius) * distanceFromCenter;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  let text = percent ? `${(percent * 100).toFixed(0)}%` : "";

  return (
    <text
      style={{ fontFamily: "Avenir-Heavy" }}
      x={x}
      y={y}
      fill={COLORS.brightBlack}
      textAnchor="middle"
      dominantBaseline="central"
      fontSize={percent > 0.1 ? "16px" : percent > 0.05 ? "12px" : "10px"}
    >
      {text}
    </text>
  );
};

const getRandomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}77`;

const useStyles = makeStyles(() => ({
  displayOnHover: {
    opacity: 0,
  },
  root: {
    "&:hover $displayOnHover": {
      opacity: 1,
    },
  },
}));

export const PieChartMultipleValues = (props) => {
  const classes = useStyles();
  const controlsRef = useRef();

  return (
    <Grid
      style={{ width: "100%", height: 310 }}
      container
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      className={classes.root}
      id={`${props.question ?? ""}${props.title}PCMV`}
    >
      <h2
        style={{
          fontSize: "1rem",
          fontWeight: "normal",
          textAlign: "center",
          margin: 0,
        }}
      >
        {props.title}
      </h2>
      <Tooltip
        title={`Save as PNG`}
        className={classes.displayOnHover}
        ref={controlsRef}
      >
        <IconButton
          onClick={() =>
            downloadAsPNG(
              `${props.question ?? ""}${props.title}PCMV`,
              `${props.question ?? ""}${props.title} PCMV`,
              controlsRef
            )
          }
          style={{
            padding: "10",
            transform: "rotate(90deg)",
            marginTop: "-15px",
          }}
          size="large">
          <KeyboardTabIcon />
        </IconButton>
      </Tooltip>
      <ResponsiveContainer height={260}>
        <PieChart>
          <Pie
            data={props.data}
            cx="50%"
            cy="50%"
            labelLine={false}
            label={renderCustomizedLabel}
            isAnimationActive={false}
            dataKey="value"
          >
            {props.data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={props.colors?.[entry.name] ?? getRandomColor()}
              />
            ))}
          </Pie>
          <RechartsTooltip />
        </PieChart>
      </ResponsiveContainer>
    </Grid>
  );
};