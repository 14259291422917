import moment from "moment";
import { useCallback, useReducer } from "react";

export const useInsightsFiltersState = ({
  branchesData,
  divisionsData,
  platformsData,
  relatedTeamsData,
}) => {
  const [selectedFiltersState, dispatchToSelectedFilters] = useReducer(
    filtersReducer,
    getInsightsFiltersInitialState({
      branchesData,
      divisionsData,
      platformsData,
      relatedTeamsData,
    })
  );
  const replaceFilterState = useCallback(
    ({ branchesData, divisionsData, platformsData, relatedTeamsData }) => {
      dispatchToSelectedFilters({
        type: filtersActions.REPLACE_FILTER_STATE,
        payload: {
          branchesData,
          divisionsData,
          platformsData,
          relatedTeamsData,
        },
      });
    },
    []
  );
  const updateSelectedDateRange = ({ from, to }) => {
    dispatchToSelectedFilters({
      type: filtersActions.UPDATE_DATE_RANGE,
      payload: {
        startDate: moment(from).format("YYYY-MM-DD"),
        endDate: moment(to).format("YYYY-MM-DD"),
      },
    });
  };
  const resetSelectedDateRange = () => {
    dispatchToSelectedFilters({
      type: filtersActions.RESET_DATE_RANGE,
      payload: {},
    });
  };
  const updateSelectedBranches = (event) => {
    const isAllBranchesSelected =
      selectedFiltersState.branches.length ===
      selectedFiltersState.branchesDropdownOptions.length;

    // show all option is selected
    if (event.target.value[event.target.value.length - 1] === "all") {
      if (isAllBranchesSelected) {
        //unselect all
        dispatchToSelectedFilters({
          type: filtersActions.UPDATE_BRANCHES,
          payload: {
            selectedBranches: [],
            divisionsData,
          },
        });
      } else {
        //select all
        dispatchToSelectedFilters({
          type: filtersActions.SELECT_ALL_BRANCHES,
          payload: {
            branchesData,
          },
        });
      }
    } else {
      //show all option is not selected
      dispatchToSelectedFilters({
        type: filtersActions.UPDATE_BRANCHES,
        payload: {
          selectedBranches: event.target.value,
          divisionsData,
        },
      });
    }
  };
  const removeSpecificBranch = (branchID) => {
    dispatchToSelectedFilters({
      type: filtersActions.REMOVE_BRANCH,
      payload: {
        branchID,
        divisionsData,
      },
    });
  };
  const updateSelectedDivisions = (event) => {
    const isAllDivisionsSelected =
      selectedFiltersState.divisions.length ===
      selectedFiltersState.divisionsDropdownOptions.length;
    if (event.target.value[event.target.value.length - 1] === "all") {
      // show all option is selected
      if (isAllDivisionsSelected) {
        // unselect all
        dispatchToSelectedFilters({
          type: filtersActions.UPDATE_DIVISIONS,
          payload: {
            selectedDivisions: [],
            divisionsData,
            branchesData,
          },
        });
      } else {
        //select all
        dispatchToSelectedFilters({
          type: filtersActions.SELECT_ALL_DIVISIONS,
          payload: {
            divisionsData,
          },
        });
      }
    } else {
      // show all option is not selected
      dispatchToSelectedFilters({
        type: filtersActions.UPDATE_DIVISIONS,
        payload: {
          selectedDivisions: event.target.value,
          divisionsData,
          branchesData,
        },
      });
    }
  };
  const removeSpecificDivision = (divisionID) => {
    dispatchToSelectedFilters({
      type: filtersActions.REMOVE_DIVISION,
      payload: {
        divisionID,
        branchesData,
        divisionsData,
      },
    });
  };

  const updateSelectedRelatedTeams = (event) => {
    const isAllRelatedTeamsSelected =
      selectedFiltersState.relatedTeams.length ===
      selectedFiltersState.relatedTeamsOptions.length;

    // show all option is selected
    if (event.target.value[event.target.value.length - 1] === "all") {
      if (isAllRelatedTeamsSelected) {
        //unselect all
        dispatchToSelectedFilters({
          type: filtersActions.UPDATE_RELATED_TEAMS,
          payload: {
            relatedTeams: [],
          },
        });
      } else {
        //select all
        dispatchToSelectedFilters({
          type: filtersActions.SELECT_ALL_RELATED_TEAMS,
          payload: {
            relatedTeamsData,
          },
        });
      }
    } else {
      //show all option is not selected.
      dispatchToSelectedFilters({
        type: filtersActions.UPDATE_RELATED_TEAMS,
        payload: {
          relatedTeams: event.target.value,
        },
      });
    }
  };

  const removeSpecificRelatedTeam = (relatedTeamIDToRemove) => {
    dispatchToSelectedFilters({
      type: filtersActions.REMOVE_RELATED_TEAM,
      payload: {
        relatedTeamIDToRemove,
      },
    });
  };
  const updateSelectedPlatforms = (event) => {
    dispatchToSelectedFilters({
      type: filtersActions.UPDATE_PLATFORMS,
      payload: {
        selectedPlatforms: event.target.value,
      },
    });
  };
  const removeSpecificPlatform = (platformID) => {
    dispatchToSelectedFilters({
      type: filtersActions.REMOVE_PLATFORM,
      payload: {
        platformID,
      },
    });
  };

  return {
    selectedFiltersState,
    replaceFilterState,
    updateSelectedDateRange,
    resetSelectedDateRange,
    updateSelectedBranches,
    removeSpecificBranch,
    updateSelectedDivisions,
    removeSpecificDivision,
    updateSelectedPlatforms,
    removeSpecificPlatform,
    updateSelectedRelatedTeams,
    removeSpecificRelatedTeam,
  };
};

const getInsightsFiltersInitialState = ({
  branchesData = [],
  divisionsData = [],
  platformsData = [],
  relatedTeamsData = [],
}) => {
  return {
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    branches: [],
    divisions: [],
    platforms: [],
    relatedTeams: [],
    branchesDropdownOptions: branchesData,
    divisionsDropdownOptions: divisionsData,
    platformsDropdownOptions: platformsData,
    relatedTeamsOptions: relatedTeamsData,
  };
};

function getDivisionsOfSelectedBranches({
  divisionsData,
  selectedBranchesIDs,
}) {
  // filter the divisons of selected branches to divisions array
  if (selectedBranchesIDs.length === 0) return divisionsData;
  else
    return divisionsData?.filter(function isParentBranchSelected(divisionData) {
      return selectedBranchesIDs.includes(divisionData.branch);
    });
}

function highlightBranchesOfSelectedDivisons({
  divisionsData,
  branchesData,
  selectedDivisionsIDs,
}) {
  let highlightedBrancheData = branchesData?.map(
    function setHighlightFieldValueFalse(branchData) {
      delete branchData.highlight;
      return branchData;
    }
  );
  for (
    let currentDivisionIndex = 0;
    currentDivisionIndex < selectedDivisionsIDs?.length;
    currentDivisionIndex++
  ) {
    // get branch Id of selected division
    const currentDivisionParentBranch = divisionsData?.find(
      function isDivisionSelected(divisionData) {
        return divisionData.id === selectedDivisionsIDs[currentDivisionIndex];
      }
    ).branch;
    // get branch index of selected division in array of branches
    const parentBranchIndex = highlightedBrancheData.findIndex(
      function isChildDivisionSelected(branchData) {
        return branchData.id === currentDivisionParentBranch;
      }
    );
    // highlight the branch
    highlightedBrancheData[parentBranchIndex].highlight = true;
  }
  return highlightedBrancheData;
}

const filtersReducer = (selectedFiltersState, action) => {
  switch (action.type) {
    case filtersActions.REPLACE_FILTER_STATE: {
      return {
        ...selectedFiltersState,
        ...getInsightsFiltersInitialState({
          branchesData: action.payload.branchesData,
          divisionsData: action.payload.divisionsData,
          platformsData: action.payload.platformsData,
          relatedTeamsData: action.payload.relatedTeamsData,
        }),
      };
    }
    case filtersActions.UPDATE_DATE_RANGE: {
      return {
        ...selectedFiltersState,
        startDate: action.payload.startDate,
        endDate: action.payload.endDate,
      };
    }
    case filtersActions.RESET_DATE_RANGE: {
      const filtersInitialState = getInsightsFiltersInitialState({
        branchesData: selectedFiltersState.branchesDropdownOptions,
        divisionsData: selectedFiltersState.divisionsDropdownOptions,
      });
      return {
        ...selectedFiltersState,
        startDate: filtersInitialState.startDate,
        endDate: filtersInitialState.endDate,
      };
    }
    case filtersActions.UPDATE_BRANCHES: {
      return {
        ...selectedFiltersState,
        branches: action.payload.selectedBranches,
        divisions: [],
        divisionsDropdownOptions: getDivisionsOfSelectedBranches({
          divisionsData: action.payload.divisionsData,
          selectedBranchesIDs: action.payload.selectedBranches,
        }),
      };
    }
    case filtersActions.REMOVE_BRANCH: {
      const newSelectedBranches = selectedFiltersState.branches.filter(
        function isBranchRemoved(existingBranchID) {
          return existingBranchID !== action.payload.branchID;
        }
      );
      return {
        ...selectedFiltersState,
        branches: newSelectedBranches,
        divisions: [],
        divisionsDropdownOptions: getDivisionsOfSelectedBranches({
          divisionsData: action.payload.divisionsData,
          selectedBranchesIDs: newSelectedBranches,
        }),
      };
    }
    case filtersActions.SELECT_ALL_BRANCHES: {
      return {
        ...selectedFiltersState,
        branches: action.payload.branchesData.map(function selectBranch(
          branchData
        ) {
          return branchData.id;
        }),
        divisions: [],
      };
    }
    case filtersActions.UPDATE_DIVISIONS: {
      return {
        ...selectedFiltersState,
        divisions: action.payload.selectedDivisions,
        branches: [],
        branchesDropdownOptions: highlightBranchesOfSelectedDivisons({
          divisionsData: action.payload.divisionsData,
          branchesData: action.payload.branchesData,
          selectedDivisionsIDs: action.payload.selectedDivisions,
        }),
      };
    }
    case filtersActions.REMOVE_DIVISION: {
      const newSelectedDivisions = selectedFiltersState.divisions.filter(
        function isDivisionRemoved(existingDivisonID) {
          return existingDivisonID !== action.payload.divisionID;
        }
      );
      return {
        ...selectedFiltersState,
        divisions: newSelectedDivisions,
        branches: [],
        branchesDropdownOptions: highlightBranchesOfSelectedDivisons({
          divisionsData: action.payload.divisionsData,
          branchesData: action.payload.branchesData,
          selectedDivisionsIDs: newSelectedDivisions,
        }),
      };
    }
    case filtersActions.SELECT_ALL_DIVISIONS: {
      return {
        ...selectedFiltersState,
        branches: [],
        divisions: selectedFiltersState.divisionsDropdownOptions.map(
          function selectDivision(divisionData) {
            return divisionData.id;
          }
        ),
      };
    }
    case filtersActions.UPDATE_RELATED_TEAMS: {
      return {
        ...selectedFiltersState,
        relatedTeams: action.payload.relatedTeams,
      };
    }
    case filtersActions.REMOVE_RELATED_TEAM: {
      const newRelatedTeams = selectedFiltersState.relatedTeams.filter(
        function relatedTeamToRemoveExists(existingRelatedTeamID) {
          return existingRelatedTeamID !== action.payload.relatedTeamIDToRemove;
        }
      );
      return {
        ...selectedFiltersState,
        relatedTeams: newRelatedTeams,
      };
    }
    case filtersActions.SELECT_ALL_RELATED_TEAMS: {
      return {
        ...selectedFiltersState,
        relatedTeams: selectedFiltersState.relatedTeamsOptions.map(
          function getRelatedTeamIDToSelect(relatedTeamsData) {
            return relatedTeamsData.id;
          }
        ),
      };
    }
    case filtersActions.UPDATE_PLATFORMS: {
      return {
        ...selectedFiltersState,
        platforms: action.payload.selectedPlatforms,
      };
    }
    case filtersActions.REMOVE_PLATFORM: {
      const newSelectedPlatforms = selectedFiltersState.platforms.filter(
        function isPlatformRemoved(existingPlatformID) {
          return existingPlatformID !== action.payload.platformID;
        }
      );
      return {
        ...selectedFiltersState,
        platforms: newSelectedPlatforms,
      };
    }
    default:
      return selectedFiltersState;
  }
};

const filtersActions = {
  REPLACE_FILTER_STATE: "replace_filter_state",
  UPDATE_DATE_RANGE: "update_date_range",
  RESET_DATE_RANGE: "reset_date_range",
  UPDATE_BRANCHES: "update_branches",
  REMOVE_BRANCH: "remove_branch",
  SELECT_ALL_BRANCHES: "select_all_branches",
  UPDATE_DIVISIONS: "update_divisions",
  REMOVE_DIVISION: "remove_division",
  SELECT_ALL_DIVISIONS: "select_all_divisions",
  UPDATE_RELATED_TEAMS: "update_related_teams",
  REMOVE_RELATED_TEAM: "remove_related_team",
  SELECT_ALL_RELATED_TEAMS: "select_all_related_teams",
  UPDATE_PLATFORMS: "update_platforms",
  REMOVE_PLATFORM: "remove_platform",
};

export function markSelectedBranches({ branchesData, selectedBranchesIDs }) {
  const markedBranchesData = branchesData?.map(function markSelectedBranch(
    branchData
  ) {
    if (selectedBranchesIDs?.includes(branchData.id))
      return { ...branchData, selected: true };
    else return { ...branchData, selected: false };
  });
  return {
    markedBranchesData,
    isAllBranchesSelected:
      selectedBranchesIDs.length === 0 ||
      selectedBranchesIDs?.length === branchesData?.length,
  };
}
export function markSelectedDivisions({ divisionsData, selectedDivisionsIDs }) {
  const markedDivisionsData = divisionsData?.map(function markSelectedDivision(
    divisionData
  ) {
    if (selectedDivisionsIDs?.includes(divisionData.id))
      return { ...divisionData, selected: true };
    else return { ...divisionData, selected: false };
  });
  return {
    markedDivisionsData,
    isAllDivisionsSelected:
      selectedDivisionsIDs?.length === divisionsData?.length,
  };
}
export function splitBranchesAndDivisionsDataIntoSeprateLists(
  BranchesAndDivisionsDataIntoSeprateLists
) {
  let branches = [],
    divisions = [];

  for (
    let currentBranchIndex = 0;
    currentBranchIndex < BranchesAndDivisionsDataIntoSeprateLists.data.length;
    currentBranchIndex++
  ) {
    const currentBranch =
      BranchesAndDivisionsDataIntoSeprateLists.data[currentBranchIndex];
    branches.push({
      id: currentBranch.id,
      name: currentBranch.name,
    });

    // push divisions data into divisions list
    for (
      let currentDivisionIndex = 0;
      currentDivisionIndex < currentBranch.divisions.length;
      currentDivisionIndex++
    ) {
      divisions.push({
        id: currentBranch.divisions[currentDivisionIndex].id,
        name: currentBranch.divisions[currentDivisionIndex].name,
        branch: currentBranch.id,
      });
    }
  }
  return {
    branches,
    divisions,
  };
}
