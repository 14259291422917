import { Box } from "@mui/material";
import { useFormContext } from "react-hook-form";
import * as yup from "yup";
import { AgnosticFormCard } from "../../../../reusable/components/AgnosticFormCard";
import { FormNumberInput, FormTextarea, FormTextInput } from "../../../../reusable/components/form-inputs/controlled";
import { PROFILE_IDS } from "../../../../helpers/constants";

const FORM_FIELDS_NAMES = {
  commentText: "comment_text",
  name: "name",
  phone: "phone",
};

const commentTextMaxChars = 500;

const egyPhoneRegex = /^01[0125][0-9]{8}$/gm;
const nigeriaPhoneRegex = /^(\d{13})?$/gm;

export function getRelativePhoneNumberRegex(accountID){
    switch(accountID){
        case PROFILE_IDS.Nigeria:{
            return nigeriaPhoneRegex;
        }
        default: {
            return egyPhoneRegex
        }
    }
}

export const sharedReviewComplaintYupValidations = {
  name: yup.string().required("Required"),
//   phone: yup
//     .string()
//     .required("Required")
//     .matches(nigeriaPhoneRegex, "Phone number is not valid"),
};

export const commentTextBaseValidations = yup
  .string()
  .max(commentTextMaxChars, "Exceeded Max Characters (500)");

export const SharedFeedbackInputs = () => {
  const { watch } = useFormContext();

  const latestCommentText = watch(["comment_text"]);

  const commentTextCounter = latestCommentText[0]
    ? ` - (${latestCommentText[0].length} / ${commentTextMaxChars})`
    : "";

  return (
    <AgnosticFormCard>
      <FormTextarea
        name={FORM_FIELDS_NAMES.commentText}
        label={`What could be better?${commentTextCounter}`}
      />
      <Box display="flex" justifyContent="space-between" mt="1rem">
        <FormTextInput name={FORM_FIELDS_NAMES.name} label="Name" width="45%" />
        <FormNumberInput
          name={FORM_FIELDS_NAMES.phone}
          label="Phone"
          width="45%"
          useAsTelephone
        />
      </Box>
    </AgnosticFormCard>
  );
};
