import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const deleteDivision = ({ id }) => {
  let requestURL = `${API_URL}/divisions/${id}`;
    
  return melior.delete(requestURL);
};

export const useDeleteDivision = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["branch-details"], { type: "active" });
      fireNotification({
        title: "Division Deleted Successfully",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error) => {
        console.log(error.response, 'onError error.response')
      fireNotification({
        title: "Failed to update the Division, please try again later",
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: deleteDivision,
  });
};
