import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { BillingCards } from "../components/billing/BillingCards";
import { InvoicesTable } from "../components/billing/InvoicesTable";
import { useState } from "react";

export const BillingSubScreen = () => {
  const [currentPlan, setCurrentPlan] = useState(SUBSCRIPTION_PLANS.free);
  const [planRenewalType, setPlanRenewalType] = useState(PLAN_RENEWAL_TYPES.monthly);
  const [currentPaymentMethod, setCurrentPaymentMethod] = useState(
    PAYMENT_METHODS.offline
  );

  const updateCurrentPlanHandler = (event) => {
    setCurrentPlan(event.target.value);
  };

  const updatePlanRenewalTypeHandler = (event) => {
    setPlanRenewalType(event.target.value);
  };

  const updateCurrentPaymentMethodHandler = (event) => {
    setCurrentPaymentMethod(event.target.value);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Box display="flex" gap={4}>
        <FormControl>
          <InputLabel id="current-plan-select-menu">Plan</InputLabel>
          <Select
            labelId="current-plan-select-menu"
            value={currentPlan}
            label="Plan"
            onChange={updateCurrentPlanHandler}
          >
            <MenuItem value={SUBSCRIPTION_PLANS.free}>
              {SUBSCRIPTION_PLANS.free}
            </MenuItem>
            <MenuItem value={SUBSCRIPTION_PLANS.standard}>
              {SUBSCRIPTION_PLANS.standard}
            </MenuItem>
            <MenuItem value={SUBSCRIPTION_PLANS.premium}>
              {SUBSCRIPTION_PLANS.premium}
            </MenuItem>
            <MenuItem value={SUBSCRIPTION_PLANS.enterprise}>
              {SUBSCRIPTION_PLANS.enterprise}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="plan-renewal-type-select-menu">Renewal Type</InputLabel>
          <Select
            labelId="plan-renewal-type-select-menu"
            value={planRenewalType}
            label="Renewal Type"
            onChange={updatePlanRenewalTypeHandler}
          >
            <MenuItem value={PLAN_RENEWAL_TYPES.monthly}>
              {PLAN_RENEWAL_TYPES.monthly}
            </MenuItem>
            <MenuItem value={PLAN_RENEWAL_TYPES.quarterly}>
              {PLAN_RENEWAL_TYPES.quarterly}
            </MenuItem>
            <MenuItem value={PLAN_RENEWAL_TYPES.yearly}>
              {PLAN_RENEWAL_TYPES.yearly}
            </MenuItem>
          </Select>
        </FormControl>

        <FormControl>
          <InputLabel id="payment-method-select-menu">
            Payment Method
          </InputLabel>
          <Select
            labelId="payment-method-select-menu"
            value={currentPaymentMethod}
            label="Payment Method"
            onChange={updateCurrentPaymentMethodHandler}
          >
            <MenuItem value={PAYMENT_METHODS.offline}>
              {PAYMENT_METHODS.offline}
            </MenuItem>
            <MenuItem value={PAYMENT_METHODS.online}>
              {PAYMENT_METHODS.online}
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
      <BillingCards
        currentPlan={currentPlan}
        planRenewalType={planRenewalType}
        currentPaymentMethod={currentPaymentMethod}
      />
      <InvoicesTable currentPlan={currentPlan} />
    </Box>
  );
};

export const SUBSCRIPTION_PLANS = {
    free: "Free",
    standard: "Standard",
    premium: "Premium",
    enterprise: "Enterprise",
  };

  export const PLAN_RENEWAL_TYPES = {
    monthly: "Monthly",
    quarterly: "Quarterly",
    yearly: "Yearly",
  };

export const PAYMENT_METHODS = {
  offline: "Offline",
  online: "Online",
};
