import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const toggleBookmarkStatus = ({
  reputationResponseID,
  currentBookmarkStatus,
}) => {
  let requestURL = `${API_URL}/reputation/` + reputationResponseID;
    console.log(reputationResponseID, 'reputationResponseID')
  return melior.patch(requestURL, {
    bookmarked: !currentBookmarkStatus,
  });
};

export const useToggleBookmarkStatus = ({ config } = {}) => {
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["reputation-responses"], {
        type: "active",
      });
      queryClient.invalidateQueries(["reputation-responses-activity-logs"], {
        type: "active",
      });
    },
    ...config,
    mutationFn: toggleBookmarkStatus,
  });
};
