import { melior } from "../../../helpers/constants";

export const suggestQuestion = (payload) => {
  let requestURL = `/question-request`;

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, payload)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
