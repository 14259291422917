import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    maxWidth: "1500px",
    display: "flex",
    justifyContent: "space-between",
    height: "100px",
  },
}));

const ScreenForTabs = ({ tabs, tabsContent, customMessage }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} container justifyContent="center" paddingBottom={"3rem"}>
      <Grid
        style={{
          maxWidth: "1500px",
          flexGrow: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
        container
        item
        xs={10}
        justifyContent="space-between"
      >
        <Paper square className={classes.root}>
          {tabs}
        </Paper>
        {customMessage}
        {tabsContent}
      </Grid>
    </Grid>
  );
};

export default ScreenForTabs;
