import { API_URL, melior } from "../../../helpers/constants";

export const submitReviewInternally = async (reviewData) => {
  let requestURL = `${API_URL}/reviews`;

  reviewData["answers"] = reviewData.choiceAnswers;
  reviewData["input_answers"] = reviewData.inputAnswers;
  reviewData["voice_answers"] = reviewData.voiceAnswers;

  delete reviewData.choiceAnswers;
  delete reviewData.inputAnswers;
  delete reviewData.voiceAnswers;

  await melior.post(requestURL, reviewData).then(async (response) => {
    return response;
  });
};
