import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const upgradeSubscriptionPlan = ({ planID, renewalType }) => {
  let requestURL = `${API_URL}/upgrade-package`;

  return melior.post(requestURL, {
    plan: planID,
    subscription: renewalType,
  });
};

export const useUpgradeSubscriptionPlan = ({ config } = {}) => {
  const { fireNotification } = useContext(NotificationContext);
  const queryClient = useQueryClient();

  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["billing-details-summary"], {
        type: "active",
      });
      fireNotification({
        title: `Successfully requested update to (${variables.renewalType} ${variables.planName}) Subscription Plan`,
        description:
          "Please fill in the necessary info in the opened tab to complete the process.",
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: () => {
      fireNotification({
        title: "Failed to upgrade Subscription plan, please try again later.",

        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: upgradeSubscriptionPlan,
  });
};
