import { API_URL, melior } from "../../../helpers/constants";

export const getQuestions = () => {
  const requestURL = `${API_URL}/questions`;
  return new Promise((resolve, reject) => {
    melior
      .get(requestURL)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
