import {
  Box,
  Collapse,
  IconButton,
} from "@mui/material";
import { Alert } from "@mui/lab";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";


export const useFeedback = () => {
  const [error, setError] = useState(undefined);
  const [success, setSuccess] = useState(undefined);

  // Error alert stays in place until removed manually or in Success state  
  useEffect(() => {
    if(success)
        setError(undefined);
        setTimeout(() => {
            setSuccess(undefined)
        }, 2000)
  }, [success]);

  const feedbackUI = (
    <Box mt="2rem">
      <Collapse in={success}>
        <Alert
          severity="success"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setSuccess(undefined);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Submitted Successfully!
        </Alert>
      </Collapse>
      <Collapse in={error}>
        <Alert
          severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setError(undefined);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          Something went wrong!
        </Alert>
      </Collapse>
    </Box>
  );

  return {
    setSuccess,
    setError,

    feedbackUI,
  };
};
