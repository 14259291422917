export function whichBrowser() {
  if (isFirefox()) {
    return BROWSERS.Firefox;
  } else if (isEdge()) {
    return BROWSERS.Edge;
  } else if (isIE()) {
    return BROWSERS.IE;
  } else if (isOpera()) {
    return BROWSERS.Opera;
  } else if (isVivaldi()) {
    return BROWSERS.Vivalid;
  } else if (isChrome()) {
    return BROWSERS.Chrome;
  } else if (isSafari()) {
    return BROWSERS.Safari;
  } else {
    return "Unknown";
  }
}

function agentHas(keyword) {
  return navigator.userAgent.toLowerCase().search(keyword.toLowerCase()) > -1;
}

function isIE() {
  return !!document.documentMode;
}

function isSafari() {
  return (
    (!!window.ApplePaySetupFeature || !!window.safari) &&
    agentHas("Safari") &&
    !agentHas("Chrome") &&
    !agentHas("CriOS")
  );
}

function isChrome() {
  return agentHas("CriOS") || agentHas("Chrome") || !!window.chrome;
}

function isFirefox() {
  return agentHas("Firefox") || agentHas("FxiOS") || agentHas("Focus");
}

function isEdge() {
  return agentHas("Edg");
}

function isOpera() {
  return agentHas("OPR");
}

function isVivaldi() {
  return agentHas("Vivaldi");
}

export const BROWSERS = {
  Firefox: "Firefox",
  Edge: "Edge",
  IE: "IE",
  Opera: "Opera",
  Vivalid: "Vivalid",
  Chrome: "Chrome",
  Safari: "Safari",
};
