import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const saveGoogleTokens = ({ tokensData }) => {
  const requestURL = `${API_URL}/save-google-tokens`;
  return melior.post(requestURL, tokensData);
};

export const useSaveGoogleTokens = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: saveGoogleTokens,
  });
};
