import { Box, Grid, Switch, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import { primaryColors } from "../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../helpers/customFont";
import { ReactComponent as Logo } from "../../../assets/melior-logo-black.svg";

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 36,
    height: 20,
    padding: 0,
    display: "flex",
  },
  switchBase: {
    padding: "2px",
    color: primaryColors.base.white,
    "&$checked": {
      transform: "translateX(16px)",
      color: primaryColors.base.white,
      "& + $track": {
        opacity: 1,
        backgroundColor: primaryColors.gray[100],
        borderColor: primaryColors.gray[100],
      },
    },
  },
  thumb: {
    width: 16,
    height: 16,
    boxShadow: "none",
  },
  track: {
    border: `1px solid ${primaryColors.gray[100]}`,
    borderRadius: 12,
    height: "auto",
    opacity: 1,
    backgroundColor: primaryColors.brand[500],
  },
  checked: {},
}))(Switch);

export const SurveyHeader = ({ language, setLanguage }) => {
  const changeLanguage = (event) => {
    setLanguage(event.target.checked);
  };
  return (
    <Box
      display="flex"
      flexDirection={"row"}
      justifyContent="space-between"
      alignItems="space-between"
      height="fit-content"
      padding="15px 20px"
      bgcolor={primaryColors.base.white}
    >
      <Logo
        style={{
          width: "96px",
          margin: "0 10px",
          height: "fit-content",
        }}
      />
      <Grid
        container
        alignItems="center"
        justifyContent={"center"}
        gap={1}
        width="96px"
      >
        <Typography
          variant="text-sm"
          sx={{
            fontWeight: fontWeightVariations.semiBold,
            color: primaryColors.base.black,
          }}
        >
          EN
        </Typography>

        <AntSwitch
          checked={Boolean(language)}
          onChange={changeLanguage}
          name="language"
        />

        <Typography
          variant="text-sm"
          sx={{
            fontWeight: fontWeightVariations.semiBold,
            color: primaryColors.base.black,
          }}
        >
          AR
        </Typography>
      </Grid>
    </Box>
  );
};
