import { useContext, useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import CloseIcon from "@mui/icons-material/Close";

import clsx from "clsx";
import { AuthenticationContext } from "../../login/contexts/AuthenticationContext";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { Modal, Tooltip } from "@mui/material";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import {
  valueChangeNotAllowedDemo,
  valueChangeNotAllowedGeneral,
} from "../../../helpers/constants";
import { changeFacilityLogo, changeFacilityName, getRemainingDaysinSubscription } from "../api";
import { deactivateFacility } from "../api/deactivateFacility";

const useStyles = makeStyles((theme) => ({
  formHeader: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
  },
  paper: {
    position: "absolute",
    width: "400px",
    minHeight: "250px",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
    padding: theme.spacing(2, 2, 2, 2),
    outline: "none",
    paddingLeft: "10px",
    backgroundColor: COLORS.white,
    opacity: "1",
  },
  header: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "18px",
  },
  section: {
    margin: "20px",
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "300px",
    maxWidth: "100%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "0px",
  },
  resizeFont: {
    fontSize: "14px",
  },
  facilityLogo: {
    borderRadius: "20px",
  },
  logoButton: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
  },
  uploadLogo: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
  },
  deactivate: {
    fontSize: "16px",
    background: COLORS.failRed,
    fontWeight: "normal",
    color: COLORS.white,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
    "&:hover": {
      background: COLORS.failRed,
    },
  },
  note: {
    fontSize: "12px",
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
  },
  input: {
    display: "none",
  },
  content: {
    background: COLORS.white,
  },
  deactivateModalButton: {
    padding: "10px 20px",
    color: COLORS.white,
    transform: "none",
  },
  warning: {
    background: COLORS.failRed,
    "&:hover": {
      background: COLORS.failRed,
    },
  },
  safe: {
    background: COLORS.successGreen,
    "&:hover": {
      background: COLORS.successGreen,
    },
  },
  editFacilityName: {
    display: "block",
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    maxWidth: "170px",
  },
  newTabLinkButton: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    padding: "0",
    paddingRight: "11px",
    width: "60%",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  newTabLink: {
    padding: "6px 16px",
    paddingRight: "6px",
    color: COLORS.black,
  },
}));

export const MainSettingsRight = ({ disableEdits }) => {
  const classes = useStyles();
  const { user, setUserProfile, getUser, isDemo } = useContext(
    AuthenticationContext
  );
  const [newFacilityName, setNewFacilityName] = useState(
    user.profile["facility_name"]
  );
  const [facilityLogo, setFacilityLogo] = useState(
    user.profile["facility_logo"]
  );
  const [deactivatedModalOpen, setDeactivatedModalOpen] = useState(false);

  useEffect(() => {
    setFacilityLogo(user.profile["facility_logo"]);
  }, [user]);

  const changeFacilityNameHandler = () => {
    changeFacilityName(newFacilityName)
      .then((data) => {
        setUserProfile(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const uploadNewLogo = async (file) => {
    setFacilityLogo("Loading");
    const response = await changeFacilityLogo(file[0]);
    getUser();
  };

  return (
    <div>
      <DeactivateModal
        classes={classes}
        isOpen={deactivatedModalOpen}
        setIsOpen={setDeactivatedModalOpen}
      />
      <h1 className={classes.formHeader}>Facility Settings</h1>
      <div className={classes.section}>
        <h1 className={classes.header}>Facility Name</h1>
        <Tooltip
          title={
            isDemo
              ? valueChangeNotAllowedDemo
              : disableEdits
              ? valueChangeNotAllowedGeneral
              : "Type in the name you want"
          }
        >
          <TextField
            id="facilityName"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            placeholder={user.profile["facility_name"]}
            value={newFacilityName}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setNewFacilityName(e.target.value);
            }}
          />
        </Tooltip>
        <Button
          variant="contained"
          component="span"
          className={classes.editFacilityName}
          disabled={
            disableEdits || newFacilityName === user["profile"]["facility_name"]
          }
          onClick={() => {
            changeFacilityNameHandler();
          }}
        >
          Save Changes
        </Button>
        <h1 className={classes.header}> Facility Logo </h1>
        <Grid container justifyContent="space-between" direction="row">
          {/* Logo Part */}
          {facilityLogo === "Loading" ? (
            <CircularProgress
              style={{
                margin: "20px 0px 20px 25%",
                color: COLORS.meliorYellow,
              }}
            />
          ) : (
            <Grid
              container
              justifyContent="flex-start"
              direction="column"
              alignItems="flex-start"
            >
              <div
                style={{
                  width: "250px",
                  maxWidth: "100%",
                }}
              >
                <img
                  style={{ maxWidth: "100%" }}
                  alt="Your facility logo"
                  className={classes.facilityLogo}
                  src={facilityLogo}
                />
              </div>
            </Grid>
          )}
          {/* Remove Logo Part */}
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="flex-start"
          >
            <input
              disabled={disableEdits}
              accept="image/*"
              className={classes.input}
              id="raised-button-file"
              type="file"
              onChange={(e) => {
                uploadNewLogo(e.target.files);
              }}
            />
            <label htmlFor="raised-button-file">
              <Button
                disabled={disableEdits}
                variant="contained"
                component="span"
                className={classes.uploadLogo}
              >
                Upload new Logo
              </Button>
            </label>
          </Grid>
          <Button
            disabled={disableEdits}
            variant="contained"
            component="span"
            className={classes.deactivate}
            style={{ marginTop: "15px" }}
            onClick={() => {
              setDeactivatedModalOpen(true);
            }}
          >
            Deactivate Account
          </Button>
        </Grid>
      </div>
    </div>
  );
};

const DeactivateModal = ({ classes, isOpen, setIsOpen }) => {
  const getModalStyle = () => {
    const top = 25;
    const left = 30;

    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(${top}%, ${left}%)`,
    };
  };
  const [modalStyle] = useState(getModalStyle);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [remainingDaysDetails, setRemainingDaysDetails] = useState({
    years: 0,
    months: 0,
    weeks: 0,
    days: 0,
  });

  const [gettingPassword, setGettingPassword] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ isError: false, errorMessage: "" });
  const [deactivated, setDeactivated] = useState(false);
  const { logout } = useContext(AuthenticationContext);

  const getRemainingDaysDetails = (days) => {
    let yearsCount = parseInt(days / 365);
    let monthsCount = parseInt((days % 365) / 30);
    let weeksCount = parseInt(((days % 365) % 30) / 7);
    let daysCount = ((days % 365) % 30) % 7;
    setRemainingDaysDetails({
      years: yearsCount,
      months: monthsCount,
      weeks: weeksCount,
      days: daysCount,
    });
  };

  const handleOpen = () => {
    setOpen(true);
    setLoading(true);
    getRemainingDaysinSubscription()
      .then((response) => {
        getRemainingDaysDetails(response.days);
        setLoading(false);
        setError({ isError: false });
      })
      .catch((error) => {
        let status = error && error.response && error.response.status;
        if (!status) return;

        status == 401
          ? setError({
              isError: true,
              errorMessage:
                "Unauthorized, Only the facility manager can deactovate a facility",
            })
          : setError({
              isError: true,
              errorMessage: "Netword Error, please try again later",
            });
        setLoading(false);
      });
  };

  const deactivate = () => {
    if (password === "" || password.length < 8)
      setError({
        isError: true,
        errorMessage: "You need to type your password to deactivate",
      });
    setLoading(true);
    deactivateFacility(password)
      .then(() => {
        setLoading(false);
        setDeactivated(true);
        setError({ isError: false });
        setTimeout(() => {
          logout();
        }, 1500);
      })
      .catch((error) => {
        let status = error && error.response && error.response.status;
        if (!status) return;
        status == 400
          ? setError({
              isError: true,
              errorMessage: "Incorrect Password, please try again",
            })
          : status == 401
          ? setError({
              isError: true,
              errorMessage:
                "Unauthorized, Only the facility manager can deactovate a facility",
            })
          : setError({
              isError: true,
              errorMessage: "Netword Error, please try again later",
            });
        setLoading(false);
      });
  };

  const handleClose = (source) => {
    setOpen(false);
    if (source !== "outside") setIsOpen(false);
    setPassword("");
    setGettingPassword(false);
    setError({ isError: false });
    setLoading(false);
  };

  useEffect(() => {
    isOpen === true ? handleOpen() : handleClose("outside");
  }, [isOpen]);

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <div style={modalStyle} className={classes.paper}>
        <Grid
          className={classes.content}
          item
          xs={12}
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <div
            style={{
              width: "100%",
              textAlign: "end",
              maxHeight: "10px",
              marginBottom: "20px",
            }}
          >
            <IconButton
              style={{ margin: "0px", marginRight: "auto" }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </Grid>
        {deactivated ? (
          <div>
            <UserErrorSuccessFormDisplay
              color={COLORS.white}
              message="Account Deactivated"
              type="success"
              width="90%"
              margin="60px auto"
              padding="15px 15px"
              bg={COLORS.successGreen}
            />
            <p style={{ fontSize: "0.7rem", textAlign: "center" }}>
              <b>Logging you out...</b>
            </p>
          </div>
        ) : loading ? (
          <Grid
            container
            justifyContent="center"
            direction="column"
            alignItems="center"
            style={{ height: "200px" }}
          >
            <CircularProgress style={{ color: COLORS.meliorYellow }} />
          </Grid>
        ) : gettingPassword ? (
          <div style={{ textAlign: "center" }}>
            {!error.isError ? null : (
              <UserErrorSuccessFormDisplay
                color={COLORS.white}
                type="error"
                message={error.errorMessage}
                width="90%"
                margin="auto"
                padding="15px 15px"
                bg={COLORS.failRed}
              />
            )}
            <p style={{ fontSize: "1.3rem" }}>
              Type your password to deactivate
            </p>
            <TextField
              id="password"
              type="password"
              className={classes.textField}
              margin="normal"
              variant="outlined"
              value={password}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            />
            <Button
              variant="contained"
              component="span"
              className={classes.deactivate}
              onClick={() => {
                deactivate();
              }}
              style={{ marginTop: "30px", width: "95%" }}
            >
              Deactivate Account
            </Button>
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "0px 5px" }}>
            {!error.isError ? (
              <div style={{ marginTop: "20px" }}>
                <p style={{ margin: "10px", fontSize: "1.3rem" }}>
                  You are about to deactivate your account
                </p>
                <p style={{ margin: "10px" }}>
                  You still have{" "}
                  <b>
                    {!remainingDaysDetails.years
                      ? null
                      : remainingDaysDetails.years + " year(s) "}
                    {!remainingDaysDetails.months
                      ? null
                      : remainingDaysDetails.months + " month(s) "}
                    {!remainingDaysDetails.weeks
                      ? null
                      : remainingDaysDetails.weeks + " week(s) "}
                    {!remainingDaysDetails.days
                      ? null
                      : remainingDaysDetails.days + " day(s) "}
                  </b>{" "}
                  left in your subscription
                </p>
                <p style={{ margin: "20px" }}>
                  <b>Are you sure you want to deactivate your account?</b>
                </p>
                <Grid
                  item
                  xs={12}
                  container
                  justifyContent="space-between"
                  style={{
                    posiiton: "absolute",
                    bottom: "0",
                    marginBottom: "15px",
                    padding: "0px 10px",
                  }}
                >
                  <Button
                    variant="contained"
                    className={clsx(
                      classes.deactivateModalButton,
                      classes.warning
                    )}
                    onClick={() =>
                      !error.isError
                        ? setGettingPassword(true)
                        : setGettingPassword(false)
                    }
                  >
                    Yes
                  </Button>
                  <Button
                    variant="contained"
                    className={clsx(
                      classes.deactivateModalButton,
                      classes.safe
                    )}
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
              </div>
            ) : (
              <div>
                <UserErrorSuccessFormDisplay
                  color={COLORS.white}
                  type="error"
                  message={error.errorMessage}
                  width="90%"
                  margin="auto"
                  padding="15px 15px"
                  bg={COLORS.failRed}
                />
                <Button
                  className={clsx(classes.deactivate, classes.safe)}
                  style={{ margin: "30px 0px", width: "90%" }}
                  onClick={handleClose}
                >
                  Go Back
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};
