import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../../helpers/constants";

export const getBusinessProfileSearches = ({
  startDate,
  endDate,
  branches,
}) => {
  const requestURL = `${API_URL}/reputation-overview/searches`;
  return melior.get(requestURL, {
    params: {
      date_from: startDate,
      date_to: endDate,
      ...(branches?.length > 0 ? { branches: branches?.join(",") } : {}),
    },
  });
};

export const useGetBusinessProfileSearches = ({ config, params } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["business-profile-searches", params],
    queryFn: () => getBusinessProfileSearches(params),
    keepPreviousData: true,
  });
};
