import {
  ALL_USER_ROLES,
  ALL_USER_ROLES_BUT_FEEDBACK_ONLY,
  FEEDBACK_ONLY,
  PROFILE_IDS,
} from "../helpers/constants";
import { useContext } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";
import { v4 as uuidv4 } from "uuid";
import { getCookie } from "../helpers/cookies";
import jwt from "jwt-decode";
import {
  allButFeedbackOnlyUsersProtectedRoutes,
  allUsersProtectedRoutes,
  publicRoutes,
  redirectionRules,
} from "./routesAndRedirectionRulesData";
import { LandingPageScreen } from "../features/landing-page";
import { OnlineSurveyController } from "../features/online-survey";
import { AuthenticationContext, LoginPageScreen } from "../features/login";
import { TabbedLandingPageScreen } from "../features/tabbed-landing-page";

export const AppRoutes = () => {
  const { user } = useContext(AuthenticationContext);
  const [queryParams, setQueryParams] = useSearchParams(
    window.location.hash.substring(1)
  );
  const location = useLocation();

  const onlineSurveyVisitedCheck = () => {
    let visited = false;

    if (location.pathname.includes("online-survey")) {
      const info = jwt(window.location.pathname.split("/")[2]);
      console.log(info, "infooooooooo");
      console.log(getCookie(info.jti), "coookie");
      if (getCookie(info.jti)) {
        visited = true;
      }
    }

    return visited;
  };

  // Update Redirection Rules based on User Role
  let updatedRedirectionRules = updateDynamicRedirectionRules(
    redirectionRules,
    user
  );

  return (
    <Routes>
      {/* Dynamic Routes (Authed or Unnauthed) */}
      {getLandingPageDynamicRoute({
        user,
        googleOAuthCode: queryParams.get("code"),
      })}

      {/* Routes requiring preparation */}
      {prepareAndAddOnlineSurveyRoute({
        onlineSurveyVisited: onlineSurveyVisitedCheck(),
      })}

      {/* Public Routes For Authorized or Unautorized Users */}
      {publicRoutes.map((publicRoute) => addPublicRoute({ ...publicRoute }))}

      {/* Protected Routes for All Authorized Users Roles */}
      {allUsersProtectedRoutes.map((protectedRoute) =>
        addProtectedRoute({ ...protectedRoute, allowedRoles: ALL_USER_ROLES })
      )}

      {/* Protected Routes for all Authorized Users Roles except feedback_only */}
      {allButFeedbackOnlyUsersProtectedRoutes.map((protectedRoute) =>
        addProtectedRoute({
          ...protectedRoute,
          allowedRoles: ALL_USER_ROLES_BUT_FEEDBACK_ONLY,
        })
      )}

      {/* Redirections */}
      {updatedRedirectionRules.map((redirectionRule) =>
        addRedirectionRule({ ...redirectionRule })
      )}
    </Routes>
  );
};

// Start: Route Helpers
const addPublicRoute = ({ path, element }) => {
  return <Route key={0} path={path} element={element} />;
};

const addProtectedRoute = ({ path, element, restrictable, allowedRoles }) => {
  return (
    <Route
      key={0}
      path={path}
      element={
        <ProtectedRoute
          allowedRoles={allowedRoles}
          path={path}
          restrictable={restrictable}
        >
          {element}
        </ProtectedRoute>
      }
    />
  );
};

const addRedirectionRule = ({ from, to }) => {
  return <Route key={0} path={from} element={<Navigate to={to} />} />;
};
// Start: Route Helpers

// Start: Dynamic Routes Functions
const getLandingPageDynamicRoute = ({ user, googleOAuthCode }) => {
  let landingPageRoute;

  if (!user) {
    // Login form for Unauthorized Users.
    landingPageRoute = addPublicRoute({
      path: "/",
      element: <LoginPageScreen />,
    });
  } else if (user.role === FEEDBACK_ONLY) {
    // Feedback page Redirection Rule for Authorized Feedback Only Users.
    landingPageRoute = addRedirectionRule({
      from: "/",
      to: "/outbound",
    });
  } else if (googleOAuthCode) {
    localStorage.setItem("googleOAuthCode", googleOAuthCode);

    landingPageRoute = addRedirectionRule({
      from: "/",
      to: "/settings",
    });
  } else {
    // Home Page Redirection Rule for Authorized Users (Except feedback_only Users)
    landingPageRoute = addRedirectionRule({
      from: "/",
      to: "/overview",
    });
  }

  return landingPageRoute;
};

const prepareAndAddOnlineSurveyRoute = ({ onlineSurveyVisited }) => {
  const nonce = uuidv4();
  let onlineSurveyRoute;

  onlineSurveyRoute = addPublicRoute({
    path: "online-survey/*",
    element: (
      <OnlineSurveyController nonce={nonce} visited={onlineSurveyVisited} />
    ),
  });

  return onlineSurveyRoute;
};

// End: Dynamic Routes Functions
const updateDynamicRedirectionRules = (redirectionRules, user) => {
  const mapAndUpdateRedirectionRule = (
    redirectionFromToMatch,
    redirectTo,
    latestRedirectionRules
  ) => {
    return latestRedirectionRules.map((redirectionRule, index) => {
      if (redirectionRule.from === redirectionFromToMatch) {
        return {
          ...redirectionRule,
          to: redirectTo,
        };
      } else {
        return redirectionRule;
      }
    });
  };

  let updatedRedirectionRulesTemp = [...redirectionRules];
  if (user) {
    updatedRedirectionRulesTemp = mapAndUpdateRedirectionRule(
      "/overall-view",
      "/overall-view/reviews",
      updatedRedirectionRulesTemp
    );
  }

  return updatedRedirectionRulesTemp;
};
