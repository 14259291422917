/* eslint-disable react/jsx-no-duplicate-props */
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Modal from "@mui/material/Modal";
import { Grid } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { Button, IconButton } from "@mui/material";

import TextField from "@mui/material/TextField";
import { COLORS } from "../../../helpers/Colors";
import { changePassword } from "../api";

const getModalStyle = () => {
  const top = 50;
  const left = 55;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
};
const useStyles = makeStyles((theme) => ({
  formRow: {
    width: "100%",
    margin: "10px 0px",
  },
  paper: {
    position: "absolute",
    width: "430px",
    borderRadius: "20px",
    boxShadow: theme.shadows[3],
    padding: "30px 40px",
    outline: "none",
    backgroundColor: COLORS.white,
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "200px",
    borderRadius: "10px",
    border: "1px solid",
    height: "35px",
    borderColor: COLORS.meliorYellow,
    marginTop: "30px",
  },
  header: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "18px",
    marginTop: "20px",
    paddingTop: "18px",
  },
  resizeFont: {
    fontSize: "14px",
    padding: "10px",
  },
  changePassword: {
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    textTransform: "none",
    borderRadius: "10px",
    marginTop: "50px",
    textAlign: "center",
    position: "relative",
  },
}));

const PasswordModal = (props) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reTypePassword, setReTypePassword] = useState("");

  const [modalStyle] = useState(getModalStyle);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (source) => {
    setOpen(false);
    if (source !== "outside") props.setPasswordModal({ open: false });
    setOldPassword("");
    setNewPassword("");
    setReTypePassword("");
  };

  useEffect(() => {
    props.passwordModal.open === true ? handleOpen() : handleClose("outside");
  }, [props.passwordModal]);

  const handleSubmit = async () => {
    const input = {
      new_password1: newPassword,
      new_password2: reTypePassword,
      old_password: oldPassword,
    };

    changePassword(input)
      .then(() => {
        handleClose();
      })
      .catch((e) => {
        console.log(e);
      });
  };
  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <div style={modalStyle} className={classes.paper}>
          <div style={{ width: "100%", textAlign: "end" }}>
            <IconButton
              style={{ margin: "0px", marginRight: "auto" }}
              size="small"
              onClick={handleClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Grid container className={classes.formRow} justifyContent="space-between">
            <h1 className={classes.header}>Old Password</h1>

            <TextField
              id="outlined-firstName-input"
              className={classes.textField}
              type="password"
              margin="normal"
              variant="outlined"
              value={oldPassword}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={(e) => {
                setOldPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid container className={classes.formRow} justifyContent="space-between">
            {/* Last name Field */}
            <h1 className={classes.header}>New Password</h1>

            <TextField
              id="outlined-firstName-input"
              className={classes.textField}
              type="password"
              margin="normal"
              variant="outlined"
              placeholder={newPassword}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />
          </Grid>
          <Grid container className={classes.formRow} justifyContent="space-between">
            {/* Last name Field */}
            <h1 className={classes.header}>Re-type New Password</h1>

            <TextField
              id="outlined-firstName-input"
              className={classes.textField}
              type="password"
              margin="normal"
              variant="outlined"
              placeholder={reTypePassword}
              InputProps={{ classes: { input: classes.resizeFont } }}
              onChange={(e) => {
                setReTypePassword(e.target.value);
              }}
            />
          </Grid>
          <Grid container className={classes.formRow} justifyContent="space-between">
            <Button
              variant="contained"
              component="span"
              className={classes.changePassword}
              onClick={() => {
                handleSubmit();
              }}
            >
              Change Password
            </Button>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};
export default PasswordModal;
