import { useContext } from "react";
import { useParams } from "react-router-dom";
import { AuthenticationContext } from "../../login";
import { FEEDBACK_ONLY } from "../../../helpers/constants";
import { RevampedReviewsSubScreen } from "./RevampedReviewsSubScreen";
import { RevampedComplaintsSubScreen } from "./RevampedComplaintsSubScreen";
import { MeliorTabsRevamped } from "../../../reusable/components/MeliorTabsRevamped";
import { ScreenContainer } from "../../../layout/ScreenContainer.jsx";
import { ReputationResponsesSubScreen } from "./ReputationResponsesSubScreen.jsx";

export const RevampedOverallViewScreen = () => {
  const { revampedOverallViewTab } = useParams();

  const { user } = useContext(AuthenticationContext);
  const isFeedbackOnlyUser = user.role === FEEDBACK_ONLY;

  return (
    <ScreenContainer>
      <MeliorTabsRevamped
        rootRouteParam="overall-view"
        dynamicRouteParam={revampedOverallViewTab}
        tabsData={[
          {
            label: "Reviews",
            routeParam: "reviews",
            tabContent: (
              <RevampedReviewsSubScreen
                enableArchiveFunctionality={!isFeedbackOnlyUser}
                archived={false}
                starred={false}
              />
            ),
            permitted: true,
          },
          {
            label: "Complaints",
            routeParam: "complaints",
            tabContent: (
              <RevampedComplaintsSubScreen
                enableArchiveFunctionality={!isFeedbackOnlyUser}
                archived={false}
                starred={false}
              />
            ),
            permitted: true,
          },
          //   {
          //     label: "Archived",
          //     routeParam: "archived",
          //     tabContent: <RevampedArchivedResponsesSubScreen />,
          //     permitted: !isFeedbackOnlyUser,
          //   },
          //   {
          //     label: "Starred",
          //     routeParam: "starred",
          //     tabContent: <RevampedStarredResponsesSubScreen />,
          //     permitted: true,
          //   },

          {
            label: "Reputation",
            routeParam: "reputation",
            tabContent: <ReputationResponsesSubScreen />,
            permitted: true,
          },
        ]}
      />
    </ScreenContainer>
  );
};
