import { Box, Container, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import LoadingPage from "../../../layout/LoadingPage";
import { COLORS } from "../../../helpers/Colors";
import { isEmpty } from "../../../helpers/General";
import { DateRangePickerInput } from "../../../reusable/components/DateRangePickerInput";
import { ColoredMultiSelectWithInfo } from "../../../reusable/components/form-inputs/uncontrolled/ColoredMultiSelectWithInfo";
import InternalProgressView from "../components/InternalProgressView";
import { AuthenticationContext } from "../../login";
import { getBranchInfo, getBranchProgressData } from "../api";
import { PaymentStatuses } from "../../payment-funnel";

export const SingleBranchProgressScreen = () => {
  const { branchID } = useParams();

  const { user } = useContext(AuthenticationContext);

  const [renderAsSkeleton, setRenderAsSkeleton] = useState(false);
  const [currentBranchData, setCurrentBranchData] = useState(undefined);
  const [loadingProgressData, setLoadingProgressData] = useState(true);
  const [branchProgressData, setBranchProgressData] = useState(undefined);
  const [divisionsOptions, setDivisionsOptions] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState({
    date_from: undefined,
    date_to: undefined,
  });
  const [selectedDivisions, setSelectedDivisions] = useState([]);

  const updateSelectedDivisionsHandler = (event) => {
    setSelectedDivisions([...event.target.value]);
  };

  const changeDateRangeHandler = ({ from, to }) => {
    const fromDateTS = new Date(from);
    const toDateTS = new Date(to);
    const fromDate = fromDateTS.toISOString().split("T")[0];
    const toDate = toDateTS.toISOString().split("T")[0];

    setSelectedDateRange({ date_from: fromDate, date_to: toDate });
  };

  useEffect(() => {
    // Get and set Divisions to multiselect from
    getBranchInfo(branchID).then((branchInfo) => {
      const tempDivisionsOptions = branchInfo.divisions.map((divisionRaw) => {
        return {
          value: divisionRaw.id,
          label: divisionRaw.name,
        };
      });

      setCurrentBranchData(branchInfo);
      setDivisionsOptions([...tempDivisionsOptions]);
    });
  }, [branchID]);

  // Get Branch Progress Data
  useEffect(() => {
    getBranchProgressData(
      {
        date_from: selectedDateRange.date_from
          ? selectedDateRange.date_from
          : undefined,
        date_to: selectedDateRange.date_to
          ? selectedDateRange.date_to
          : undefined,
        divisions:
          selectedDivisions.length > 0
            ? selectedDivisions.join(",")
            : undefined,
      },
      branchID
    ).then((branchProgressData) => {
      setLoadingProgressData(true);
      setBranchProgressData(mapInternalProgressData(branchProgressData));
      setLoadingProgressData(false);
    });
  }, [
    branchID,
    selectedDateRange.date_from,
    selectedDateRange.date_to,
    selectedDivisions,
  ]);

  useEffect(() => {
    if (user && user.subscription) {
      if (user.subscription.status === PaymentStatuses.DeadlinePhase) {
        setRenderAsSkeleton(true);
      }
    }
  }, [user, user.subscription]);

  return (
    <Container maxWidth={false} sx={{ paddingTop: "1rem" }}>
      <Typography sx={{ fontSize: "1.3rem", fontWeight: "bold" }}>
        Branch Progress - {currentBranchData?.name}
      </Typography>

      <Box display="flex" gap={2} paddingTop="1rem">
        <DateRangePickerInput
          callbackHandler={changeDateRangeHandler}
          resetDateHandler={() => {
            setSelectedDateRange({ date_from: undefined, date_to: undefined });
          }}
          defaultDate={{
            startDate:
              selectedDateRange.date_from !== undefined
                ? moment(selectedDateRange.date_from, "YYYY-MM-DD").toDate()
                : undefined,
            endDate:
              selectedDateRange.date_to !== undefined
                ? moment(selectedDateRange.date_to, "YYYY-MM-DD").toDate()
                : undefined,
          }}
          //   skeleton={renderAsSkeleton}
        />
        <ColoredMultiSelectWithInfo
          label="Divisions"
          labelSingular="Division"
          options={divisionsOptions}
          selectedValues={selectedDivisions}
          onChangeHandler={updateSelectedDivisionsHandler}
          //   skeleton={renderAsSkeleton}
        />
      </Box>
      {/* {renderAsSkeleton ? (
        <ProgressSectionSkeleton />
      ) : ( */}
      <Box marginTop="1rem">
        {loadingProgressData ? (
          <LoadingPage />
        ) : branchProgressData !== null ? (
          <InternalProgressView data={branchProgressData} />
        ) : (
          <p
            style={{
              textAlign: "center",
              marginTop: "40px",
              color: COLORS.failRed,
            }}
          >
            No data available for currently selected filters.
          </p>
        )}
      </Box>
      {/* )} */}
    </Container>
  );
};

const mapInternalProgressData = (data) => {
  if (isEmpty(data)) return null;
  const represntations = {};
  data.questions.map((question) =>
    question.representations?.map((representation) => {
      if (!!represntations[representation.name]) {
        return (represntations[representation.name] = [
          ...represntations[representation.name],
          {
            text: question.text,
            data: representation.data,
            colors: representation.colors,
          },
        ]);
      } else {
        return (represntations[representation.name] = [
          {
            text: question.text,
            data: representation.data,
            colors: representation.colors,
          },
        ]);
      }
    })
  );
  represntations["reviewsCount"] = data["total_count"];
  return represntations;
};

export const ProgressSectionSkeleton = () => {
  return (
    <Box width={"100%"} display="flex" flexDirection="column" marginTop={5}>
      {progressSkeleton}
      {progressSkeleton}
      {progressSkeleton}
    </Box>
  );
};

const progressSkeleton = (
  <Box width={"100%"} display="flex" flexDirection="column" marginBottom={5}>
    <Skeleton
      variant="rounded"
      animation="wave"
      width={"100%"}
      height={100}
      sx={{ marginBottom: 4 }}
    />
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={"12%"}
      height={10}
      sx={{ marginBottom: 2 }}
    />
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={"13%"}
      height={10}
      sx={{ marginBottom: 2 }}
    />
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={"10%"}
      height={10}
      sx={{ marginBottom: 2 }}
    />
    <Skeleton
      variant="rectangular"
      animation="wave"
      width={"11%"}
      height={10}
      sx={{ marginBottom: 2 }}
    />
  </Box>
);
