import { Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import GoogleSVG from "../../../../assets/icons/reputation-dashboard/google-logo.svg";
import CheckIcon from "@mui/icons-material/Check";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import {
  GOOGLE_CLIENT_ID,
  GOOGLE_OAUTH_API_URL,
} from "../../../../helpers/constants";
import { useContext, useEffect, useState } from "react";
import { useExchangeGoogleCodeForTokens, useSaveGoogleTokens } from "../../api";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";

export const PlaformsSection = () => {
  const { fireNotification } = useContext(NotificationContext);
  const { hasSignedInGoogle, canUseReputationDashboard } = useContext(
    AuthenticationContext
  );
  const exchangeGoogleCodeForTokensMutation = useExchangeGoogleCodeForTokens();
  const saveGoogleTokensMutation = useSaveGoogleTokens();

  const [dataLoadingState, setDataLoadingState] = useState(
    hasSignedInGoogle ? GOOGLE_DATA_STATE.loaded : undefined
  );

  useEffect(
    function updateLoadingState() {
      if (dataLoadingState === GOOGLE_DATA_STATE.loading) {
        setTimeout(() => {
          setDataLoadingState(GOOGLE_DATA_STATE.loaded);
        }, 300000);
      }
    },
    [dataLoadingState]
  );
  useEffect(function exchangeAuthorizationCode() {
    if (localStorage.getItem("googleOAuthCode") && !hasSignedInGoogle) {
      exchangeGoogleCodeForTokensMutation
        .mutateAsync({
          authorizationCode: localStorage.getItem("googleOAuthCode"),
        })
        .then(function saveTokens(response) {
          setDataLoadingState(GOOGLE_DATA_STATE.loading);
          saveGoogleTokensMutation
            .mutateAsync({ tokensData: response.data })
            .catch(function logTokensSavingError(error) {
              setDataLoadingState(undefined);
              console.error("Error in saving Google tokens", error);
              fireNotification({
                title: "An error occurred, please try again",
                target: NOTIFICATIONS_TARGET.everyone,
                type: NOTIFICATIONS_TYPE.error,
                verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
              });
              localStorage.removeItem("googleOAuthCode");
            });
        })
        .catch(function logTokensRetrievalError(error) {
          console.error("Error in retrieving Google tokens", error);
          fireNotification({
            title: "An error occurred, please try again",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        });
    }
  }, []);

  return (
    <Grid
      id="platforms"
      container
      justifyContent="flex-start"
      direction="column"
      padding="20px 0px"
      borderBottom={`1px solid ${primaryColors.gray[200]}`}
      gap="6px"
    >
      <Typography
        sx={{
          color: primaryColors.gray[700],
          fontWeight: fontWeightVariations.medium,
          fontSize: fontSizeVariations["text-sm"],
        }}
      >
        Platforms
      </Typography>
      <Box
        display="flex"
        alignItems={{ sm: "flex-start", md: "center" }}
        flexDirection={{ sm: "column", md: "row" }}
        gap={2}
      >
        <SignInWithGoogleButton
          isSignedIn={hasSignedInGoogle}
          canUseReputationDashboard={canUseReputationDashboard}
        />
        {/*data loading state */}
        {<DataLoadingState dataState={dataLoadingState} />}
      </Box>
    </Grid>
  );
};

const SignInWithGoogleButton = ({ isSignedIn, canUseReputationDashboard }) => {
  const { fireNotification } = useContext(NotificationContext);
  const redirectionURI = window.location.origin;
  return (
    <Button
      disabled={isSignedIn || localStorage.getItem("googleOAuthCode")}
      sx={{
        background: primaryColors.base.white,
        padding: "10px 16px",
        borderRadius: "8px",
        gap: "12px",
        alignItems: "center",
      }}
      onClick={function redirectToOAuthConsentScreen() {
        if (!canUseReputationDashboard) {
          return fireNotification({
            title: "Your plan doesn't include this feature",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        }
        window.open(
          `${GOOGLE_OAUTH_API_URL}/auth?redirect_uri=${redirectionURI}&prompt=consent&response_type=code&client_id=${GOOGLE_CLIENT_ID}&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fbusiness.manage&access_type=offline`,
          "_self"
        );
      }}
    >
      <Box component="img" src={GoogleSVG} alt="Google Icon" width="24px" />
      <Typography
        variant="text-md"
        textTransform="none"
        color={primaryColors.gray[700]}
        fontWeight={fontWeightVariations.medium}
      >
        {isSignedIn ? "You're signed In" : "Sign in with Google"}
      </Typography>
    </Button>
  );
};

const DataLoadingState = ({ dataState }) => {
  switch (dataState) {
    case GOOGLE_DATA_STATE.loading: {
      return (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <CircularProgress
            size="1rem"
            sx={{ color: primaryColors.brand[500] }}
          />
          <Typography>
            Your data will be ready soon, check after 5 minutes
          </Typography>
        </Box>
      );
    }
    case GOOGLE_DATA_STATE.processing: {
      return (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <CircularProgress
            size="1rem"
            sx={{ color: primaryColors.brand[500] }}
          />
          <Typography>processing your data</Typography>
        </Box>
      );
    }
    case GOOGLE_DATA_STATE.loaded: {
      return (
        <Box display="flex" flexDirection="row" alignItems="center" gap={1}>
          <CheckIcon size="1rem" sx={{ color: primaryColors.success[500] }} />
          <Typography>Reviews loaded</Typography>
        </Box>
      );
    }
    default:
      return <></>;
  }
};

const GOOGLE_DATA_STATE = {
  loading: "loading",
  processing: "processing",
  loaded: "loaded",
};
