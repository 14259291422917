import { API_URL, melior } from "../../../helpers/constants";

export const getBranchInfo = (branchId) => {
  let requestURL = `${API_URL}/branches/` + branchId;
  return new Promise((resolve, reject) => {
    melior
      .get(requestURL)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};