import { melior, URL } from "../../../helpers/constants";

export const registerUser = (userData, token, type) => {
  let requestURL = `${URL}/registration/`;
  if (type === "invite") requestURL = `${URL}/user/invitation/registration/`;
  let config = {
    headers: {
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    melior
      .post(requestURL, userData, config)
      .then(async (response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
