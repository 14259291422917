import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";

export const FormMultiSelect = ({
  id,
  name,
  label,
  children,
  enableSearch = false,
  sx,
  width = undefined,
  defaultValueByLabel,
}) => {
  const {
    control,
    formState: { errors },
    watch,
  } = useFormContext();

  const errorMessage = errors[name] ? errors[name].message : null;
  //   const MenuProps = {
  //     PaperProps: {
  //       style: {
  //         maxHeight: 280,
  //         width: 250,
  //       },
  //     },
  //   };
  return (
    <FormControl
      size="small"
      error={errorMessage !== null}
      style={{
        width: width ? width : "100%",
      }}
    >
      <InputLabel>{label}</InputLabel>
      <Controller
        render={({ field: fieldProps }) => {
          return (
            <Select
              multiple
              id={id}
              {...fieldProps}
              value={fieldProps.value ? fieldProps.value : watch(name)}
              renderValue={(selected) => {
                return (
                  <Typography sx={{ fontSize: "0.9rem" }}>
                    {selected.length} Selected
                  </Typography>
                );
              }}
              label={label}
              sx={sx}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 280,
                    width: 250,
                  },
                },
                variant: "menu",
                getContentAnchorEl: null,
              }}
            >
              {children}
            </Select>
          );
        }}
        control={control}
        name={name}
      />
      <Box display={"block"}>
        <FormHelperText style={{ color: "red" }}>
          {errorMessage ? errorMessage : ""}
        </FormHelperText>
      </Box>
    </FormControl>
  );
};
