import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { Grid, Button } from "@mui/material";
import {
  primaryColors,
  secondaryColors,
} from "../../../../helpers/customColors";
import Mic from "@mui/icons-material/Mic";
import Stop from "@mui/icons-material/Stop";
import Play from "@mui/icons-material/PlayArrow";
import Pause from "@mui/icons-material/Pause";
import Rerecord from "@mui/icons-material/Replay";
import RecordVoiceOver from "@mui/icons-material/RecordVoiceOver";
import Send from "@mui/icons-material/Send";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";
import { useContext } from "react";

const MicRecorder = require("mic-recorder-to-mp3");
const recorder = new MicRecorder({
  bitRate: 128,
});

export const FormVoiceNoteInput = ({ name }) => {
  const { control, setValue, getValues } = useFormContext();
  const { fireNotification } = useContext(NotificationContext);

  const [recording, setRecording] = useState({
    isRecording: false,
    recordingFile: null,
  });
  const [playing, setPlaying] = useState({
    isPlaying: false,
    playFile: null,
  });
  const [recordDuration, setRecordDuration] = useState(0);

  function startRecording() {
    if (recording.isRecording === false) {
      // start recording
      recorder
        .start()
        .then(function setRecordingState() {
          setRecording({ isRecording: true, recordingFile: null });
        })
        .catch(function fireErrorNotification() {
          setRecording({ isRecording: false, recordingFile: null });
          fireNotification({
            title:
              "We couldn't start the recorder, Make sure mic access is granted from your browser",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        });
    }
  }
  function stopRecording() {
    if (recording.isRecording === true) {
      recorder
        .stop()
        .getMp3()
        .then(function setRecordedFile([buffer, blob]) {
          const file = new File(buffer, "voice_recording", {
            type: blob.type,
            lastModified: Date.now(),
          });
          setRecording({ isRecording: false, recordingFile: file });
        })
        .catch(function fireErrorNotification() {
          fireNotification({
            title: "We could not retrieve your recording, Please try again",
            target: NOTIFICATIONS_TARGET.everyone,
            type: NOTIFICATIONS_TYPE.error,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          });
        });
    }
  }
  function playRecord() {
    if (!recording.recordingFile) return;
    if (!playing.isPlaying) {
      const player = new Audio(URL.createObjectURL(recording.recordingFile));
      player.play();
      setPlaying({ isPlaying: true, playFile: player });
    }
  }
  function pauseRecord() {
    if (!playing.playFile) return;
    if (playing.isPlaying) {
      playing.playFile.pause();
      setPlaying({ ...playing, isPlaying: false });
    }
  }
  function reRecord() {
    if (playing.isPlaying) pauseRecord();

    if (playing.playFile) {
      setPlaying({ isPlaying: false, playFile: null });
    }
    setRecordDuration(0);
    setRecording({ isRecording: false, recordingFile: null });
  }
  function encodeRecording(record = recording.recordingFile) {
    return new Promise(function returnEncodedRecord(resolve) {
      var reader = new FileReader();
      reader.onload = function resolveInputData(event) {
        var data = event.target.result.split(",");
        resolve(data[1]);
      };
      reader.readAsDataURL(record);
    });
  }

  function formatRecordDuration(seconds) {
    const date = new Date(0); // Create a date object with the initial epoch time
    date.setSeconds(seconds); // Set the seconds value

    const minutes = date.getUTCMinutes();
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    const formattedSeconds =
      date.getUTCSeconds() < 10
        ? `0${date.getUTCSeconds()}`
        : `${date.getUTCSeconds()}`;

    return `${formattedMinutes}:${formattedSeconds}`;
  }

  function submitRecordHandler() {
    encodeRecording()
      .then(function setInputValueState(encodedRecord) {
        setValue(name, encodedRecord, {
          shouldTouch: true,
          shouldDirty: true,
        }); //send record
      })
      .catch(function fireErrorNotification(error) {
        fireNotification({
          title: "We could not retrieve your recording, Please try again",
          target: NOTIFICATIONS_TARGET.everyone,
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        });
      });
  }

  useEffect(
    function handleRecordTimer() {
      let interval;
      if (recording.isRecording) {
        interval = setInterval(function incrementRecordTimer() {
          setRecordDuration((prevSeconds) => prevSeconds + 1);
        }, 1000);
      } else {
        clearInterval(interval);
        recording.recordingFile && submitRecordHandler();
      }
      return function resetRecordTimer() {
        clearInterval(interval);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [recording]
  );
  useEffect(
    function resetVoiceRecorder() {
      getValues(name) === undefined && reRecord();
    },
    [useWatch({ control, name })]
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <Grid
          container
          display="flex"
          flex-direction="row"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="58px"
          borderRadius="5px"
          bgcolor={primaryColors.gray[50]}
        >
          <Grid item xs={2}>
            {!recording.recordingFile ? (
              <Button onClick={!recording.isRecording ? startRecording : null}>
                {!recording.isRecording ? <Mic /> : <RecordVoiceOver />}
              </Button>
            ) : (
              <Button
                sx={{ width: "38px", height: "auto" }}
                onClick={!playing.isPlaying ? playRecord : pauseRecord}
              >
                {!playing.isPlaying ? <Play /> : <Pause />}
              </Button>
            )}
          </Grid>
          <Grid item xs={4} display="flex" gap="5%" paddingX="5%">
            {formatRecordDuration(recordDuration)}
            {recording.recordingFile && (
              <Rerecord
                sx={{
                  width: "24px",
                  height: "auto",
                  color: secondaryColors.blueSecondary[500],
                }}
                onClick={reRecord}
              />
            )}
          </Grid>
          <Grid item xs={1}>
            {recording.isRecording && !recording.recordingFile && (
              <Stop
                sx={{
                  width: "38px",
                  height: "auto",
                  color: secondaryColors.blueSecondary[500],
                }}
                onClick={stopRecording}
              />
            )}
          </Grid>
        </Grid>
      )}
    />
  );
};
