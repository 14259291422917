import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";
import { useContext } from "react";
import {
  NOTIFICATIONS_TARGET,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NotificationContext,
} from "../../../../contexts/NotificationContext";

export const createBranchDivision = ({
  newDivisionName,
  branchID,
  branchName,
}) => {
  let requestURL = `${API_URL}/divisions`;

  return melior.post(requestURL, {
    branch: branchID,
    name: newDivisionName,
    name_ar: newDivisionName,
  });
};

export const useCreateBranchDivision = ({ config } = {}) => {
  const queryClient = useQueryClient();
  const { fireNotification } = useContext(NotificationContext);

  return useMutation({
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(["branch-details"], { type: "active" });

      fireNotification({
        title: `Branch Updated Successfully`,
        type: NOTIFICATIONS_TYPE.success,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    onError: (error, variables) => {
      fireNotification({
        title: `Failed update Branch, please try again later.`,
        description: error.response.data.detail,
        type: NOTIFICATIONS_TYPE.error,
        verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
        target: NOTIFICATIONS_TARGET.admin,
      });
    },
    ...config,
    mutationFn: createBranchDivision,
  });
};
