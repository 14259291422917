import { makeStyles, withStyles } from "@mui/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import CircleCheckedFilled from "@mui/icons-material/CheckCircle";
import CircleUnchecked from "@mui/icons-material/RadioButtonUnchecked";

const ColoredCheckbox = withStyles({
  root: {
    color: (props) => props.colorhex,
    "&$checked": {
      color: (props) => props.colorhex,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  noRightMargin: {
    marginRight: "0px",
  },
  graphLabels: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "14px",
    marginLeft: "0px",
    margin: "10px",
  },
}));

export const RunChartCheckbox = (props) => {
  const classes = useStyles();
  const { color, checked, uncheck, check, label, disabled, quickNavLink } = props;

  return (
    <div style={{ display: "flex", alignItems: 'center', margin: "0 25px" }}>
      <FormControlLabel
        className={classes.noRightMargin}
        label=""
        control={
          <ColoredCheckbox
            colorhex={color}
            icon={<CircleUnchecked />}
            checkedIcon={<CircleCheckedFilled />}
            checked={checked}
            onChange={(e) => {
              !!e.target.checked ? check() : uncheck();
            }}
            disabled={disabled}
          />
        }
      />
      <h1 className={classes.graphLabels}> {label} </h1>
      {quickNavLink}
    </div>
  );
};