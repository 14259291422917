import { API_URL, melior } from "../../../helpers/constants";

export const getReviews = async (reviewsQueryStrings) => {
    const {
      date_from,
      date_to,
      branches,
      divisions,
      templates,
      questions,
      pagination,
      archived,
      bookmarked,
    } = reviewsQueryStrings;
  
    const requestURL = `${API_URL}/reviews`;
  
    return await melior
      .get(requestURL, {
        params: {
          ...reviewsQueryStrings,
        },
      })
      .then(async (response) => {
        return response.data;
      })
      .catch((error) => {
        throw new Error(error);
      });
  };