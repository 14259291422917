import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { COLORS } from "../../../../helpers/Colors";

import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import ShiftRow from "./ShiftRow";

const useStyles = makeStyles(theme => ({
  formButton: {
    fontSize: 20,
    marginLeft: "2%",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    marginTop: "1%",
    textTransform: "none",
    borderRadius: "10px",
    "&:disabled": {
      backgroundColor: COLORS.meliorYellowDisabled,
      color: COLORS.grey
    }
  },
  deleteDevisionButton: {
    fontSize: 20,
    marginLeft: "2%",
    fontWeight: "normal",
    color: "red",
    marginTop: "1%",
    textTransform: "none"
  }
}));

const ShiftRows = () => {
  const classes = useStyles();
  const [morningDetails, setMorningDetails] = useState({
    checked: true,
    from: "06:00",
    to: "12:00"
  });
  const [afternoonDetails, setAfternoonDetails] = useState({
    checked: true,
    from: "12:00",
    to: "18:00"
  });
  const [eveningDetails, setEveningDetails] = useState({
    checked: true,
    from: "18:00",
    to: "00:00"
  });
  const [nightDetails, setNightDetails] = useState({
    checked: false,
    from: "00:00",
    to: "06:00"
  });

  return (
    <div style={{ width: "100%" }}>
      {/* Morning */}
      <ShiftRow
        time="Morning"
        details={morningDetails}
        setDetails={setMorningDetails}
      />

      {/* Afternoon */}
      <ShiftRow
        time="Afternoon"
        details={afternoonDetails}
        setDetails={setAfternoonDetails}
      />

      {/* Evening */}
      <ShiftRow
        time="Evening"
        details={eveningDetails}
        setDetails={setEveningDetails}
      />

      {/* Night  */}
      <ShiftRow
        time="Night"
        details={nightDetails}
        setDetails={setNightDetails}
      />

      <Grid
        container
        justifyContent="flex-end"
        direction="row"
        alignItems="flex-start"
        style={{ marginTop: "3%" }}
      >
        <Button variant="contained" className={classes.formButton}>
          Save
        </Button>
        <Button
          style={{ backgroundColor: COLORS.yellowBGTextField }}
          variant="contained"
          className={classes.formButton}
        >
          Cancel
        </Button>
      </Grid>

      <Grid
        container
        justifyContent="flex-end"
        direction="row"
        alignItems="flex-start"
        style={{ marginTop: "1%" }}
      >
        <Button className={classes.deleteDevisionButton}>
          Delete Division
        </Button>
      </Grid>
    </div>
  );
};

export default ShiftRows;
