import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
	Grid,
	Container,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
	Collapse,
	Chip,
	IconButton,
} from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { COLORS } from "../../../helpers/Colors";
import { AdminContext } from "../contexts/AdminContext";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import LoadingPage from "../../../layout/LoadingPage";

const useStyles = makeStyles({
	container: {
		padding: "30px",
	},
	newTabLink: {
		padding: "6px 16px",
		paddingLeft: "6px",
		color: COLORS.black,
	},
	body: {
		maxWidth: "90vw",
		margin: "auto",
	},
	TableContainer: {
		overflow: "auto",
		border: `1px solid ${COLORS.meliorYellowGentle} `,
		maxHeight: "600px",
	},
	table: {
		minWidth: "650px",
		overflow: "auto",
	},
	fixedWidth: {
		minWidth: "160px",
		maxWidth: "160px",
	},
	warning: {
		color: COLORS.failRed,
	},
	safe: {
		color: COLORS.successGreen,
	},
	row: {
		height: "60px",
		"&:nth-of-type(odd)": {
			backgroundColor: COLORS.silver,
		},
	},
	createTemplateButton: {
		textTransform: "none",
		margin: "20px",
		backgroundColor: COLORS.meliorYellow,
		float: "right",
		marginRight: "0",
		"&:hover": {
			backgroundColor: COLORS.meliorYellowGentle,
		},
	},
	textField: {
		background: COLORS.yellowBGTextField,
		width: "300px",
		maxWidth: "100%",
		borderRadius: "10px",
		border: "1px solid",
		borderColor: COLORS.meliorYellow,
		marginTop: "0px",
	},
	resizeFont: {
		fontSize: "14px",
	},
	header: {
		fontWeight: "normal",
		textTransform: "none",
		fontSize: "18px",
	},
	section: {
		margin: "20px",
	},
	formHeader: {
		fontWeight: "normal",
		textTransform: "none",
		fontSize: "20px",
	},
	choiceChip: {
		margin: "0px 6px",
		background: COLORS.meliorYellowGentle,
	},
	questionListItem: {
		padding: "0px",
	},
	expandIcon: {
		color: COLORS.meliorYellow,
	},
	collapsedItem: {
		paddingLeft: "45px",
		margin: "10px 0",
	},
});

const ValueContainer = withStyles({
	root: () => ({
		width: "270px",
		border: "1px solid",
		maxWidth: "100%",
		background: "#fbf1c8",
		marginTop: "0px",
		borderColor: "#ffcf3e",
		borderRadius: "10px",
		padding: "14px 18px",
	}),
})(Grid);

const Actions = {
	View: "View",
	Edit: "Edit",
	Create: "Create",
	None: "None",
};

const ViewTemplate = ({ action, disableEdits = false, template }) => {
	const classes = useStyles();

	const { templateQuestions, getTemplateQuestionsHandler, templateQuestionsError } =
		useContext(AdminContext);

	useEffect(() => {
		if (action === Actions.Create) return;
		// No template id
		if (!template?.id) return;
		// template question already exist
		if (!!templateQuestions && !!templateQuestions[template.id]) return;
		getTemplateQuestionsHandler(false, template.id);
	}, [action, template]);

	const QuestionItem = ({ question }) => {
		const [open, setOpen] = useState(false);

		return <>
            <ListItem className={classes.questionListItem}>
                <ListItemIcon>
                    <IconButton
                        className={classes.expandIcon}
                        onClick={() => setOpen(!open)}
                        size="large">
                        {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={question.text} />
            </ListItem>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <div className={classes.collapsedItem}>
                    {question?.choices?.map((choice) => (
                        <Chip className={classes.choiceChip} label={choice.text} />
                    ))}
                </div>
            </Collapse>
        </>;
	};

	return (
        <Container className={classes.container} maxWidth="xl">
			<Grid className={classes.body}>
				<Grid container justifyContent="center">
					{!templateQuestionsError.isError ? null : (
						<UserErrorSuccessFormDisplay
							color={COLORS.white}
							type="error"
							message={templateQuestionsError.errorMessage}
							width="90%"
							margin="10px auto"
							padding="15px 15px"
							bg={COLORS.failRed}
						/>
					)}
				</Grid>
				<h1 className={classes.formHeader}>Template details</h1>
				<div className={classes.section}>
					<h1 className={classes.header}>Template Name</h1>
					<ValueContainer>{template?.name}</ValueContainer>
				</div>
				{templateQuestionsError.isError ? null : !templateQuestions ||
				  !templateQuestions[template?.id] ? (
					<LoadingPage height="30vh" />
				) : (
					<div className={classes.section}>
						<h1 className={classes.header}>Questions</h1>
						<List>
							{templateQuestions[template?.id].map((question) => (
								<QuestionItem question={question} />
							))}
						</List>
					</div>
				)}
			</Grid>
		</Container>
    );
};

export default ViewTemplate;
