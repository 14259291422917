import { useContext, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Grid,
  Container,
  TextField,
  Select,
  MenuItem,
  Switch,
  FormControlLabel,
  Button,
  Tooltip,
} from "@mui/material";
import { COLORS } from "../../../helpers/Colors";
import { SettingsContext } from "../../../contexts/SettingsContext";
import { AdminContext } from "../contexts/AdminContext";
import UserErrorSuccessFormDisplay from "../../../reusable/components/UserErrorSuccessFormDisplay";
import { Add, CancelOutlined, Remove } from "@mui/icons-material";
import LoadingPage from "../../../layout/LoadingPage";
import MenuProps from "../../../reusable/components/shared-settings-components/MenuProps";
import QuestionsTransferList from "./QuestionsTransferList";
import { getBranchInfo } from "../../overall-view/api";
import { createTemplate } from "../api";

const useStyles = makeStyles({
  container: {
    padding: "30px",
    position: "relative",
  },
  createTemplate: {
    textTransform: "none",
    fontSize: "16px",
    background: COLORS.meliorYellow,
    fontWeight: "normal",
    color: COLORS.black,
    borderRadius: "10px",
    marginTop: "10px",
    textAlign: "center",
    "&:hover": {
      backgroundColor: COLORS.meliorYellowGentle,
    },
  },
  cancelCreateTemplate: {
    float: "right",
    position: "absolute",
    right: "10px",
    top: "90px",
  },
  textField: {
    background: COLORS.yellowBGTextField,
    width: "300px",
    maxWidth: "100%",
    borderRadius: "10px",
    border: "1px solid",
    borderColor: COLORS.meliorYellow,
    marginTop: "0px",
  },
  resizeFont: {
    fontSize: "14px",
  },
  header: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "16px",
  },
  section: {
    margin: "10px",
  },
  formHeader: {
    fontWeight: "normal",
    textTransform: "none",
    fontSize: "20px",
  },
  menu: {
    width: "250px",
  },
  selectedMenuItem: {
    color: COLORS.meliorYellow,
    fontFamily: "Avenir-Heavy",
  },
});

const CreateTemplate = ({ cancelAdd, disableEdits }) => {
  const classes = useStyles();

  const [error, setError] = useState(false);

  const { getBranches, branches } = useContext(SettingsContext);
  const { getGeneralQuestions, generalQuestions } = useContext(AdminContext);
  const { getFacilityTemplatesHandler } = useContext(AdminContext);

  const [CBopen, setCBOpen] = useState(false);
  const [CDopen, setCDOpen] = useState(false);

  const [checkedQuestions, setCheckedQuestions] = useState([]);

  const [divisions, setDivisions] = useState({});
  const [newTemplateName, setNewTemplateName] = useState();
  const [branchSelected, setBranchSelected] = useState([]);
  const [divisionSelected, setDivisionSelected] = useState([]);
  const [isAllDivisions, setIsAllDivisions] = useState(false);

  const getBranchName = (id) => {
    const selectedBranch = branches?.items?.filter(
      (branch) => branch.id === id
    );
    return !!selectedBranch.length ? selectedBranch[0].name : "";
  };

  useEffect(() => {
    if (!!branches) return;
    getBranches(false);
  }, [branches, getBranches]);

  useEffect(() => {
    if (!!generalQuestions) return;
    getGeneralQuestions(false);
  }, [generalQuestions, getGeneralQuestions]);

  useEffect(() => {
    if (!branchSelected?.length) {
      return;
    }
    branchSelected.map((branch) => {
      if (!divisions[branch]) {
        const newDivisions = { ...divisions };
        getBranchInfo(branch)
          .then((response) => {
            newDivisions[branch] = response.divisions;
            setDivisions(newDivisions);
          })
          .catch(() =>
            setError({
              isError: true,
              errorMessage: "Netword Error, please try again later",
            })
          );
      }
    });
  }, [branchSelected]);

  return (
    <Container className={classes.container} maxWidth="xl">
      <Grid className={classes.body}>
        <Grid container justifyContent="center">
          {!error.isError ? null : (
            <UserErrorSuccessFormDisplay
              color={COLORS.white}
              type="error"
              message={error.errorMessage}
              width="90%"
              margin="10px auto"
              padding="15px 15px"
              bg={COLORS.failRed}
            />
          )}
        </Grid>
        <h1 className={classes.formHeader}>Template details</h1>
        <Button
          className={classes.cancelCreateTemplate}
          onClick={() => {
            cancelAdd();
          }}
        >
          <CancelOutlined />
        </Button>
        <div className={classes.section}>
          <h1 className={classes.header}>Template Name</h1>
          <TextField
            id="newTemplateName"
            className={classes.textField}
            margin="normal"
            variant="outlined"
            value={newTemplateName}
            InputProps={{ classes: { input: classes.resizeFont } }}
            onChange={(e) => {
              setNewTemplateName(e.target.value);
            }}
          />
        </div>
        {!branches?.items.length ? (
          <LoadingPage height="10vh" />
        ) : (
          <div className={classes.section}>
            <h1 className={classes.header}>Assign to</h1>
            <Grid
              container
              justifyContent="flex-start"
              style={{
                width: "100%",
                margin: "20px 0",
              }}
            >
              <Select
                MenuProps={MenuProps}
                disableUnderline={true}
                displayEmpty={true}
                className={classes.menu}
                open={CBopen}
                onClose={() => setCBOpen(false)}
                onOpen={() => setCBOpen(true)}
                value={branchSelected}
                multiple
                renderValue={() => "Choose branches"}
                onChange={(event) => {
                  setBranchSelected(event.target.value);
                }}
                inputProps={{
                  classes: {
                    icon: classes.dropdownIcon,
                  },
                }}
              >
                {branches?.items?.map((branch) => (
                  <MenuItem
                    key={branch.id}
                    value={branch.id}
                    className={classes.menuItem}
                    classes={{
                      selected: classes.selectedMenuItem,
                    }}
                  >
                    {branchSelected.includes(branch.id) ? (
                      <Remove className={classes.menuItemIcon} />
                    ) : (
                      <Add className={classes.menuItemIcon} />
                    )}
                    {branch.name}
                  </MenuItem>
                ))}
              </Select>

              {!branchSelected.length ? null : (
                <Select
                  MenuProps={MenuProps}
                  disableUnderline={true}
                  displayEmpty={true}
                  className={classes.menu}
                  open={CDopen}
                  onClose={() => setCDOpen(false)}
                  onOpen={() => setCDOpen(true)}
                  value={divisionSelected}
                  disabled={isAllDivisions}
                  style={{
                    marginLeft: "40px",
                  }}
                  multiple
                  renderValue={() => "Choose divisions"}
                  onChange={(event) => {
                    setDivisionSelected(event.target.value);
                  }}
                  inputProps={{
                    classes: {
                      icon: classes.dropdownIcon,
                    },
                  }}
                >
                  <MenuItem className={classes.menuItem} disabled value={0}>
                    Choose divisions
                  </MenuItem>

                  {branchSelected.map((currBranch) =>
                    divisions[currBranch]?.map((division) => (
                      <MenuItem
                        key={division.id}
                        value={division.id}
                        className={classes.menuItem}
                        classes={{
                          selected: classes.selectedMenuItem,
                        }}
                      >
                        {divisionSelected.includes(division.id) ? (
                          <Remove className={classes.menuItemIcon} />
                        ) : (
                          <Add className={classes.menuItemIcon} />
                        )}
                        {getBranchName(currBranch)} - {division.name}
                      </MenuItem>
                    ))
                  )}
                </Select>
              )}
              <FormControlLabel
                style={{ marginLeft: "40px" }}
                control={
                  <Switch
                    checked={isAllDivisions}
                    disabled={!branchSelected.length}
                    onChange={(e) => {
                      setIsAllDivisions(e.target.checked);
                      setDivisionSelected([]);
                    }}
                    name="all-divisions-in-branch"
                  />
                }
                label="Apply on all branch divisions"
              />
            </Grid>
          </div>
        )}
        {!generalQuestions ? (
          <LoadingPage height="30vh" />
        ) : (
          <div className={classes.section}>
            <h1 className={classes.header}>Questions</h1>
            <QuestionsTransferList
              items={generalQuestions}
              rightListItems={checkedQuestions}
              setRightListItems={setCheckedQuestions}
            />
          </div>
        )}
      </Grid>
      <Tooltip
        title={
          !newTemplateName?.length
            ? "You have to provide a value for the name"
            : !checkedQuestions.length || checkedQuestions.length > 7
            ? "Questioned must be min 1 and max 7"
            : "Create a new template"
        }
      >
        <span>
          <Button
            variant="contained"
            component="span"
            className={classes.createTemplate}
            disabled={
              !newTemplateName?.length ||
              !checkedQuestions.length ||
              checkedQuestions.length > 13
            }
            onClick={() => {
              createTemplate({
                name: newTemplateName,
                divisions: divisionSelected,
                branches: branchSelected,
                allDivisionsInBranch: isAllDivisions,
                questions: checkedQuestions.map((question) => question.id),
              })
                .then((response) => {
                  getFacilityTemplatesHandler(true);
                  cancelAdd();
                })
                .catch((error) => {
                    console.log(error, '????')
                  setError({
                    isError: true,
                    errorMessage:
                      "Netword Error, We couldn't create a template for you",
                  });
                });
            }}
          >
            Create Template
          </Button>
        </span>
      </Tooltip>
    </Container>
  );
};

export default CreateTemplate;
