import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const assignLabelToComplaint = ({ complaintID, newLabelID }) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/labels/add`;

  return melior.post(requestURL, {
    labels: [newLabelID],
  });
};

export const useAssignLabelToComplaint = ({ config } = {}) => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(["complaint-activity-logs"], {
        type: "active",
      });
      queryClient
        .invalidateQueries(["complaints"], { type: "active" })
        .then(() => mutation.reset());
    },
    ...config,
    mutationFn: assignLabelToComplaint,
  });

  return mutation;
};
