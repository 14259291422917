import { API_URL, melior } from "../../../helpers/constants";

export const createTemplate = ({
    name,
    branches,
    divisions,
    questions,
    allDivisionsInBranch,
}) => {
    let requestURL = `${API_URL}/templates`;
    const postData = {
        name: name,
        questions_schema: questions.map((item) => {
            return {
                question: item
            }
        }),
    };

    if (allDivisionsInBranch) postData.branches = branches;
    else postData.divisions = divisions;

    return new Promise((resolve, reject) => {
        melior
            .post(requestURL, postData)
            .then(async (response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};
