import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

const getAllowedSubscriptionPlans = () => {
  const requestURL = `${API_URL}/upgrade-package/available-plans`;

  return melior.get(requestURL);
};

export const useGetAllowedSubscriptionPlans = ({ config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["allowed-subscription-plans"],
    queryFn: () => getAllowedSubscriptionPlans(),
    keepPreviousData: true,
  });
};
