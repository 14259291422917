import { useState, useRef, useMemo } from "react";
import { makeStyles } from "@mui/styles";
import Grid from "@mui/material/Grid";
import ArrowRight from "@mui/icons-material/ArrowRight";
import ArrowLeft from "@mui/icons-material/ArrowLeft";
import { IconButton, Tooltip, Typography } from "@mui/material";
import KeyboardTabIcon from "@mui/icons-material/KeyboardTab";
import { COLORS } from "../../../helpers/Colors";
import { downloadAsPNG } from "../../../helpers/General";
import { primaryColors } from "../../../helpers/customColors";

const useStyles = makeStyles(() => ({
  body: {
    padding: "10px",
    borderRadius: "10px",
    margin: "10px",
    boxShadow:
      "rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px, rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px",
    "&:hover": {
      boxShadow:
        "rgba(0, 0, 0, 0.11) 0px 0.8px 2.9px, rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px",
    },
  },
  section: {
    marginTop: "40px",
  },
  title: {
    fontWeight: "400",
    letterSpacing: "0.7px",
    fontSize: "18px",
    color: COLORS.titleGrey,
  },
}));

export const PaginatedRepresentation = (props) => {
  const { title, subTitle, children, representationNames } = props;
  const controlsRef = useRef();
  const classes = useStyles();
  const [page, setPage] = useState(0);

  const finalChildren = useMemo(() => children && children.flat(), [children]);

  return (
    <Grid
      className={classes.body}
      container
      direction="column"
      id={`${title}-${representationNames[page]}-chart`}
      item
      sm={11}
    >
      <Grid container flexDirection="row" justifyContent="space-between" marginBottom="25px">
        <Grid
          container
          xs={10}
          flexDirection="column"
          justifyContent="space-around"
        >
          <h1 className={classes.title}>{title}</h1>
          <Typography
            variant="text-sm"
            sx={{
              fontFamily: "Nunito",
              color: primaryColors.base.black,
            }}
          >
            {subTitle}
          </Typography>
        </Grid>
        <Grid
          container
          xs={2}
        >
          <div ref={controlsRef}>
            <IconButton
              disabled={page === 0}
              onClick={() => setPage(page - 1)}
              size="large"
            >
              <ArrowLeft
                style={{
                  height: "40px",
                  width: "40px",
                  color: page === 0 ? COLORS.greyDisabled : COLORS.meliorYellow,
                }}
              />
            </IconButton>
            <IconButton
              disabled={page === finalChildren?.length - 1}
              onClick={() => setPage(page + 1)}
              size="large"
            >
              <ArrowRight
                style={{
                  height: "40px",
                  width: "40px",
                  color:
                    page === finalChildren.length - 1
                      ? COLORS.greyDisabled
                      : COLORS.meliorYellow,
                }}
              />
            </IconButton>
            <Tooltip title={`Save as PNG`}>
              <IconButton
                onClick={() =>
                  downloadAsPNG(
                    `${title}-${representationNames[page]}-chart`,
                    `${title} ${representationNames[page]} chart`,
                    controlsRef
                  )
                }
                style={{
                  transform: "rotate(90deg)",
                }}
                size="large"
              >
                <KeyboardTabIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Grid>
      </Grid>
      {finalChildren?.[page]}
    </Grid>
  );
};
