import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import { primaryColors } from "../../../../helpers/customColors";
import {
  fontSizeVariations,
  fontWeightVariations,
} from "../../../../helpers/customFont";
import { useEffect, useState } from "react";
import { changeFacilityLogo, changeFacilityName } from "../../api";
import { FormProvider } from "react-hook-form";
import * as yup from "yup";
import {
  FormTextInput,
  FormImageInput,
} from "../../../../reusable/components/form-inputs/controlled";
import { useReactHookFormWithYup } from "../../../../reusable/hooks";
import { useContext } from "react";
import { AuthenticationContext } from "../../../login/contexts/AuthenticationContext";
import {
  NotificationContext,
  NOTIFICATIONS_TYPE,
  NOTIFICATIONS_VERTICAL_POSITION,
  NOTIFICATIONS_TARGET,
} from "../../../../contexts/NotificationContext";

export const FacilityInformationSection = () => {
  const { user, setUserProfile, getUser } = useContext(AuthenticationContext);
  const { fireNotification } = useContext(NotificationContext);

  const [submitting, setSubmitting] = useState(false);

  const validationSchema = yup.object({
    facility_name: yup.string(),
  });
  const reactHookFormMethods = useReactHookFormWithYup({
    validationSchema,
    defaultValues: {
      facility_name: user.profile["facility_name"],
      facility_logo: user.profile["facility_logo"],
    },
  });
  const { handleSubmit, formState } = reactHookFormMethods;

  const updateFacilityInfoHandler = async (facilityData) => {
    setSubmitting(true);
    changeFacilityName(facilityData.facility_name)
      .then((data) => {
        if (facilityData.facility_logo instanceof File) {
          //logo changed
          changeFacilityLogo(facilityData.facility_logo)
            .then(() => {
              getUser();
              fireNotification({
                title: "Changed successfully",
                type: NOTIFICATIONS_TYPE.success,
                verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
                target: NOTIFICATIONS_TARGET.everyone,
              });
              setSubmitting(false);
            })
            .catch(() => {
              fireNotification({
                title: "An error occurred, please try again.",
                type: NOTIFICATIONS_TYPE.error,
                verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
                target: NOTIFICATIONS_TARGET.everyone,
              });
              setSubmitting(false);
            });
        } else {
          fireNotification({
            title: "Changed successfully",
            type: NOTIFICATIONS_TYPE.success,
            verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
            target: NOTIFICATIONS_TARGET.everyone,
          });
          setSubmitting(false);
        }
      })
      .catch((error) => {
        fireNotification({
          title: "An error occurred, please try again.",
          type: NOTIFICATIONS_TYPE.error,
          verticalPosition: NOTIFICATIONS_VERTICAL_POSITION.top,
          target: NOTIFICATIONS_TARGET.everyone,
        });
        setSubmitting(false);
      });
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Nunito",
          fontSize: fontSizeVariations["text-lg"],
          fontWeight: fontWeightVariations.bold,
          color: primaryColors.gray[900],
          marginBottom: "20px",
        }}
      >
        Facility Information
      </Typography>
      <FormProvider {...reactHookFormMethods}>
        <form method="PATCH" onSubmit={handleSubmit(updateFacilityInfoHandler)}>
          <Grid
            container
            justifyContent="flex-start"
            direction="row"
            width={{ sm: "100%", lg: "660px", md: "660px", xl: "660px" }}
            mr={"50%"}
            spacing={2}
          >
            <Grid item xs={12} md={6}>
              <FormImageInput name="facility_logo" />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                sx={{
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations.medium,
                  color: primaryColors.gray[700],
                  marginBottom: "6px",
                }}
              >
                Facility Name
              </Typography>
              <FormTextInput
                name="facility_name"
                placeholder={user.profile["facility_name"]}
                type="text"
                sx={{
                  color: primaryColors.gray[500],
                  backgroundColor: primaryColors.base.white,
                  border: `1px solid ${primaryColors.gray[300]}`,
                  borderRadius: "8px",
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            direction="row"
            padding="20px 0px"
            borderBottom={`1px solid ${primaryColors.gray[200]}`}
            gap="10px"
          >
            <Button
              variant="text"
              type="submit"
              disabled={submitting}
              sx={{
                backgroundColor: !submitting
                  ? primaryColors.warning[500]
                  : primaryColors.warning[200],
                border: "1px solid",
                borderColor: submitting
                  ? primaryColors.warning[500]
                  : primaryColors.warning[200],
                borderRadius: "8px",
                "&:hover": {
                  backgroundColor: primaryColors.warning[500],
                },
              }}
            >
              <Typography
                sx={{
                  textTransform: "capitalize",
                  fontFamily: "Nunito",
                  fontSize: fontSizeVariations["text-sm"],
                  fontWeight: fontWeightVariations.medium,
                  color: primaryColors.base.white,
                }}
              >
                {submitting ? <CircularProgress size={20} /> : "Save"}
              </Typography>
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </>
  );
};
