import { useParams } from "react-router-dom";
// import UserSettings from "../components/UserSettings";
import { ProfileSettingsSubScreen } from "./ProfileSettingsSubScreen";
import { BranchesAndDivisionsSubScreen } from "./BranchesAndDivisionsSubScreen";
import { MembersSubScreen } from "./MembersSubScreen";
import { BillingSubScreen } from "./BillingSubScreen";
import { GiveFeedbackSubScreen } from "./GiveFeedbackSubScreen";
import { MeliorTabsRevamped } from "../../../reusable/components/MeliorTabsRevamped";
import { ScreenContainer } from "../../../layout/ScreenContainer";

export const SettingsScreen = () => {
  const { settingsTab } = useParams();

  return (
    <ScreenContainer>
      <MeliorTabsRevamped
        width="52rem"
        rootRouteParam="settings"
        dynamicRouteParam={settingsTab}
        tabsData={[
          {
            label: "Profile",
            routeParam: "profile",
            tabContent: <ProfileSettingsSubScreen />,
            permitted: true,
          },
          {
            label: "Branches & Divisions",
            routeParam: "branches-and-divisions",
            tabContent: <BranchesAndDivisionsSubScreen />,
            permitted: true,
          },
          {
            label: "Team",
            routeParam: "members",
            tabContent: <MembersSubScreen />,
            permitted: true,
          },
          {
            label: "Billing",
            routeParam: "billing",
            tabContent: <BillingSubScreen />,
            permitted: true,
          },
          {
            label: "Give us feedback",
            routeParam: "give-feedback",
            tabContent: <GiveFeedbackSubScreen />,
            permitted: true,
          },
        ]}
      />
    </ScreenContainer>
  );
};
