import { useMutation } from "@tanstack/react-query";
import { API_URL, melior } from "../../../../helpers/constants";

export const triggerEmailUserMentionInComplaint = ({
  complaintID,
  mentionText,
  mentionedUserID,
}) => {
  let requestURL = `${API_URL}/complaints/${complaintID}/mention`;

  return melior.post(requestURL, {
    text: mentionText,
    mentioned_user: mentionedUserID,
  });
};

export const useTriggerEmailForUserMentionInComplaint = ({ config } = {}) => {
  return useMutation({
    ...config,
    mutationFn: triggerEmailUserMentionInComplaint,
  });
};
