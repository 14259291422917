import { useQuery } from "@tanstack/react-query";
import { API_URL, melior } from "../../../helpers/constants";

export const getReports = ({ year, month }) => {
  const requestURL = `${API_URL}/reports`;
  return melior.get(requestURL, { params: { year, month } });
};

export const useGetReports = ({ params, config } = {}) => {
  return useQuery({
    ...config,
    queryKey: ["reports", params],
    queryFn: () => getReports(params),
    keepPreviousData: true,
  });
};
