import { useForm } from "react-hook-form";
import { useYupValidationResolver } from "./useYupValidationResolver";

/**
 * A hook to integrate and use React Hook Form with Yup
 *
 * @param {*} validationSchema
 * Yup Validation Schema
 *
 * @param {import('react-hook-form').UseFormProps} useFormProps
 * Accepts all the settings used with useForm() hook from React Hook Form
 *
 * @returns
 * Return all React Hook Form methods as returned from useForm() hook
 */

export const useReactHookFormWithYup = ({
  validationSchema,
  useFormProps = { mode: "all" },
  defaultValues,
}) => {
  const resolver = useYupValidationResolver(validationSchema);
  const reactHookFormMethods = useForm({
    resolver,
    ...useFormProps,
    defaultValues,
  });

  return {
    ...reactHookFormMethods,
  };
};
